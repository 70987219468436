import { Dispatch } from 'react';
import { AppActions } from '../../../../../redux/actions/AppActions.type';
import { CarDetailsDataType } from '../service/CarDetails.type';
import { CarDetailsClearedAction, CarDetailsSavedAction, CAR_DETAILS_ACTIONS } from './CarDetailsActions.type';

export const saveCarDetails = (carDetailsData: CarDetailsDataType) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(saveCarDetailsAction(carDetailsData));
  };
};

export const saveCarDetailsAction =
  (carInfoData: CarDetailsDataType): CarDetailsSavedAction => {
    return {
      type: CAR_DETAILS_ACTIONS.CAR_DETAILS_SAVED,
      data: carInfoData
    };
  };

export const clearCarDetails = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(clearCarDetailsAction());
  };
};

export const clearCarDetailsAction =
  (): CarDetailsClearedAction => {
    return {
      type: CAR_DETAILS_ACTIONS.CLEAR_DATA,
      data: {} as CarDetailsDataType
    };
  };
