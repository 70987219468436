import styled from 'styled-components';
import { BeeTextInput } from '@sky-distribution/react-commons-ui';
import euLogo from './eu.svg';

type StyledVehiclePlateInputProps = {
  $height?: string;
};

export const StyledVehiclePlateInput = styled(BeeTextInput)<StyledVehiclePlateInputProps>`
  height: ${({ $height }) => $height ?? '3.5rem'};
`;
export const StyledVehiclePlateInputContainer = styled.div<StyledVehiclePlateInputProps>`
  position: relative;
  height: ${({ $height }) => $height ?? '3.5rem'};
  padding-left: 2.5rem;
  margin-bottom: 1rem;
  &::before {
    content: 'PL';
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: ${({ theme }) => theme.palette.commons.background.container};
    position: absolute;
    width: 2.5rem;
    height: 100%;
    left: 0;
    top: 1rem;
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
    font-family: 'Poppins';
    font-size: 0.625rem;
    font-weight: 500;
    box-sizing: border-box;
    padding-bottom: 0.3rem;
    background-image: url(${euLogo});
    background-size: 55%;
    background-repeat: no-repeat;
    background-position: center 25%;
    background-color: #4545FF;
  }
`;
