import triggerDataLayerEvent, { TriggerDataLayerProps } from './Gtm.util';


export const triggerDataLayerPhoneEvent = ({ quoteID, quoteUuid, sessionUUID }: TriggerDataLayerProps): void => {
  triggerDataLayerEvent({
    event: 'leavePhoneNumber',
    quoteID,
    quoteUuid,
    sessionUUID
  });
};

export const triggerDataLayerErrorBoundaryEvent = ( error: Error | undefined): void => {
  triggerDataLayerEvent({
    event: 'errorBoundary',
    ...error
  });
};

export const triggerDataLayerSubmissionFormShow = ({ quoteID, quoteUuid, sessionUUID }: TriggerDataLayerProps): void => {
  triggerDataLayerEvent({
    event: 'submissionFormShow',
    sessionUUID,
    quoteUuid,
    quoteID
  });
};

export const triggerDataLayerSubmissionFormCreate = ({ quoteID, quoteUuid, sessionUUID }: TriggerDataLayerProps): void => {
  triggerDataLayerEvent({
    event: 'submissionFormCreate',
    sessionUUID,
    quoteUuid,
    quoteID
  });
};

export const triggerDataLayerSubmissionFormUpdate = ({ quoteUuid, sessionUUID }: TriggerDataLayerProps): void => {
  triggerDataLayerEvent({
    event: 'submissionFormUpdate',
    sessionUUID,
    quoteUuid
  });
};

export const triggerDataLayerTransaction = ({ quoteID, transactionID }: TriggerDataLayerProps): void => {
  triggerDataLayerEvent({
    event: 'transaction',
    transactionID,
    quoteID
  });
};

export const triggerDataLayerPageChange = ({ templateName }: TriggerDataLayerProps): void => {
  triggerDataLayerEvent({
    event: 'pageChange',
    templateName
  });
};


