import { MEDIA_QUERY } from '@sky-distribution/react-commons-ui';
import styled from 'styled-components';

export const HeaderContainer = styled.div<{ isFulfilled?: boolean }>`
  & > svg,
  a {
    cursor: pointer;
    height: 1rem;
  }

  svg {
    path {
      fill: ${({ theme, isFulfilled }) =>
        isFulfilled
          ? theme.palette.brand.color
          : theme.palette.commons.background.container};
    }
  }
`;

export const HeaderControl = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  z-index: 1;
  right: 1.5rem;
  top: 1.5rem;

  ${MEDIA_QUERY.below('sm')} {
    right: 1.5rem;
  }
`;
