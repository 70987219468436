import { DISCOUNT_ACTIONS, GrantedDiscount, InitialOfferId } from './discount.type';

export const setGrantedDiscount = (discount: number): GrantedDiscount => ({
  type: DISCOUNT_ACTIONS.SET_GRANTED_DISCOUNT,
  payload: discount
});

export const setInitialOfferId = (offerId: string): InitialOfferId => ({
  type: DISCOUNT_ACTIONS.SET_INITIAL_OFFER_ID,
  payload: offerId
});
