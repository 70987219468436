import { ConfigurationKey, CallbackIconConfigurationType } from '../tenant.type';
import callbackImage from './assets/callback.png';

const tenantConfiguration: CallbackIconConfigurationType = {
  [ConfigurationKey]: {
    callbackImage,
    callbackCaption: true,
    styles: {
      wrapperDisplay: 'flex',
      phoneImage: {
        smWidth: '1.1rem',
        smHeight: '1.1rem'
      },
      callBackContainer: {
        background: ''
      },
      callbackImageContainer: {
        smHeight: '6.075rem'
      },
      callbackImage: {
        borderRadius: '100%',
        smWidth: '5.3125rem',
        smHeight: '5.3125rem'
      },
      phoneContainer: {
        borderRadius: '100%',
        smHeight: '2.375rem',
        smBottom: '0.7rem'
      },
      clipPath: ''
    }
  }
};

export default tenantConfiguration;
