import styled from 'styled-components';

export const StyledPageLoader = styled.div`
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 170px;
    height: 170px;
`;
