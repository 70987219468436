import React from 'react';
import { languages } from '../../BeeDatePicker.const';
import { BeeDatePickerProps } from '../../BeeDatePicker.types';
import { isDatePickerFormFilled } from '../../helpers/isDatePickerFormFilled';
import { pickerReducer } from './PickerContext.reducer';
import { PickerContextType } from './PickerContext.types';
import { getPickerDate } from './PickerContext.utils';

export const PickerContext = React.createContext<PickerContextType | undefined>(
  undefined,
);

export const PickerProvider: React.FC<BeeDatePickerProps> = ({
  children,
  retrieveDate,
  dateIsInvalidMsg,
  validatorProps,
  datesRange,
  showErrMsg = true,
  required = false,
  error = null,
  languageCode = 'pl',
  ...props
}) => {
  const { dayNames, monthNames } = languages[languageCode];
  const dayRef = React.useRef<HTMLInputElement | null>(null);
  const monthRef = React.useRef<HTMLInputElement | null>(null);
  const yearRef = React.useRef<HTMLInputElement | null>(null);
  const today = React.useMemo(() => new Date(), []);
  const date = React.useMemo(() => getPickerDate(retrieveDate), [retrieveDate]);
  const isFormFilled = React.useMemo(
    () => isDatePickerFormFilled(date),
    [date],
  );

  const [pickerState, dispatch] = React.useReducer(pickerReducer, {
    date,
    initialDate: retrieveDate,
    dateIsInvalidMsg,
    today,
    dayRef,
    monthRef,
    yearRef,
    monthNames,
    dayNames,
    required,
    datesRange,
    validatorProps,
    isFormFilled,
    isFocused: false,
    isDirty: false,
    visiblePicker: null,
    errorMsg: error,
    radioSlideIndex: 0,
  });

  const value = React.useMemo(
    () => ({
      state: {
        ...pickerState,
        ...props,
        showErrMsg,
        retrieveDate,
        required,
        error,
        datesRange,
        dateIsInvalidMsg,
        validatorProps,
      },
      dispatch,
    }),
    [
      pickerState,
      props,
      retrieveDate,
      required,
      error,
      datesRange,
      dateIsInvalidMsg,
      validatorProps,
      showErrMsg,
    ],
  );

  return (
    <PickerContext.Provider value={value}>{children}</PickerContext.Provider>
  );
};

export const usePickerContext = (): PickerContextType => {
  const pickerContext = React.useContext(PickerContext);

  if (pickerContext === undefined) {
    throw new Error('usePickerContext must be used within a PickerProvider');
  }

  return pickerContext;
};
