import { ErrorMessage } from 'formik';
import React from 'react';
import { StyledFormikErrorMessage } from './FormikErrorMessage.style';

type ErrorMessageHandlerProps = {
  name: string;
  isModal?: boolean;
}

const FormikErrorMessage = ({ name, isModal }: ErrorMessageHandlerProps) => {
  return (
    <StyledFormikErrorMessage $isModal={isModal}>
      <ErrorMessage name={name} component="span" className="invalid-feedback" />
    </StyledFormikErrorMessage>
  );
};

export default FormikErrorMessage;
