"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.plMessages = void 0;
exports.plMessages = {
    FOOTER_POPULAR_QUESTIONS: 'Popularne pytania do zakupu ubezpieczenia OC AC',
    FOOTER_QUESTION_0_HEAD: '',
    FOOTER_QUESTION_1_HEAD: 'Jak działa kalkulator OC AC NNW Compensa?',
    FOOTER_QUESTION_2_HEAD: 'Czy muszę odpowiadać na wszystkie pytania w formularzu?',
    FOOTER_QUESTION_3_HEAD: 'Czy skorzystanie z kalkulatora ubezpieczenia OC samochodu jest darmowe?',
    FOOTER_QUESTION_4_HEAD: 'Ile czasu zajmuje wypełnienie formularza w kalkulatorze OC AC?',
    FOOTER_QUESTION_5_HEAD: 'Czy można skorzystać z kalkulatora OC za pomocą smartfona?',
    FOOTER_QUESTION_6_HEAD: 'Jakie inne polisy można nabyć za pośrednictwem kalkulatora ubezpieczeń?',
    FOOTER_QUESTION_7_HEAD: 'Czy kalkulator OC Compensa uwzględnia zniżki na ubezpieczenie?',
    FOOTER_QUESTION_8_HEAD: 'Jakie inne polisy można zakupić za pośrednictwem naszego kalkulatora ubezpieczeń?',
    FOOTER_QUESTION_9_HEAD: 'Czy ubezpieczenie OC i AC jest obowiązkowe w Polsce?',
    FOOTER_QUESTION_10_HEAD: 'Dla jakich pojazdów obliczysz ceny polis w kalkulatorze?',
    FOOTER_QUESTION_11_HEAD: 'Czy współwłaściciel samochodu może skorzystać z kalkulatora Compensa?',
    FOOTER_QUESTION_12_HEAD: 'Czy po wykonaniu kalkulacji muszę kupić ubezpieczenie w Compensa?',
    FOOTER_QUESTION_13_HEAD: 'Ile czasu potrzebuje kalkulator OC Compensa na przedstawienie oferty?',
    FOOTER_QUESTION_14_HEAD: 'Dlaczego zakup OC i AC w Compensa jest opłacalny?',
    FOOTER_QUESTION_15_HEAD: 'Wybrałem ubezpieczenie samochodu. Czy mogę je kupić online?',
    FOOTER_QUESTION_16_HEAD: 'Czy moje dane w kalkulatorze ubezpieczeń Compensa są bezpieczne?',
    FOOTER_QUESTION_17_HEAD: 'Dlaczego warto zaufać Compensa?',
    FOOTER_QUESTION_18_HEAD: 'Jak weryfikowane są dane dotyczące historii ubezpieczeniowej?',
    FOOTER_QUESTION_19_HEAD: 'Jakie dane są potrzebne do skorzystania z kalkulatora OC Compensa?',
    FOOTER_QUESTION_20_HEAD: 'Masz wątpliwości przy korzystaniu z kalkulatora OC Compensa?',
    FOOTER_QUESTION_21_HEAD: 'Dlaczego kalkulator OC Compensa ma tak mało pól do wypełnienia? Czy jest to bezpieczne?',
    FOOTER_QUESTION_22_HEAD: 'Czy kalkulator OC Compensa dotyczy tylko samochodów osobowych?',
    FOOTER_QUESTION_23_HEAD: 'Jakie dokumenty są potrzebne do skorzystania z kalkulatora OC Compensa?',
    FOOTER_QUESTION_0: 'Skorzystaj z kalkulatora OC AC Compensa, aby sprawdzić oferty na ubezpieczenie OC. Dodatkowo, masz możliwość zakupu polis dobrowolnych, takich jak pełne assistance NNW, ubezpieczenie opon lub szyb. Cały proces zajmie tylko kilka chwil, a wybrane ubezpieczenie możesz nabyć szybko i bezpiecznie online. Jeśli masz jakiekolwiek pytania, zachęcamy do kontaktu z naszymi konsultantami, którzy są dostępni pod numerem telefonu 500 965 626.',
    FOOTER_QUESTION_1: 'Kalkulator ubezpieczeń samochodowych Compensa działa w sposób innowacyjny. Dzięki niemu w krótkim czasie poznasz dostępne oferty ubezpieczenia pojazdu. Na podstawie zaledwie dwóch udzielonych odpowiedzi, kalkulator generuje oferty polis komunikacyjnych. Nasze narzędzie oszczędza Twój czas, ponieważ nie musisz odpowiadać na zbędne pytania. Za pomocą kalkulatora ubezpieczeń możesz także dokonać zakupu ubezpieczenia OC online. Jeśli masz jakiekolwiek wątpliwości, zawsze możesz skontaktować się z naszymi doradcami.',
    FOOTER_QUESTION_2: 'Nasz formularz składa się z zaledwie dwóch pytań, dzięki czemu oszczędzasz swój czas, a my jesteśmy w stanie przedstawić Ci dobrą ofertę. W naszym kalkulatorze ubezpieczenia OC i AC unikamy zbędnych pytań. Prosimy jedynie o podanie niezbędnych danych, tj. daty urodzenia właściciela pojazdu oraz numeru rejestracyjnego, które są istotne dla obliczenia wysokości składki ubezpieczeniowej.',
    FOOTER_QUESTION_3: 'Z radością informujemy, że korzystanie z naszego kalkulatora OC AC jest darmowe. Oddajemy w Twoje ręce bezpłatny kalkulator, który nie tylko oszczędza Twój czas, ale także jest bardzo prosty w obsłudze. Sprawdź teraz, jak szybko otrzymasz wyliczenie składki na ubezpieczenie komunikacyjne dla swojego auta lub jednośladu!',
    FOOTER_QUESTION_4: 'Dzięki naszemu narzędziu, cały proces można zakończyć w kilka chwil. Przed rozpoczęciem kalkulacji wystarczy przygotować dwa dokumenty: prawo jazdy oraz dowód rejestracyjny. Po udzieleniu odpowiedzi, otrzymasz od nas ofertę na ubezpieczenie OC samochodu i Autocasco w Compensa.',
    FOOTER_QUESTION_5: 'Nasz kalkulator ubezpieczeń jest zoptymalizowany pod kątem obsługi na wszystkich urządzeniach mobilnych i komputerach. Dlatego, jeśli korzystasz z telefonu, przekonaj się, jak wygodne jest obliczanie ubezpieczenia OC samochodu lub jednośladu!',
    FOOTER_QUESTION_6: 'Kalkulator ubezpieczeniowy Compensa oferuje możliwość wyboru i zakupu polisy, która najlepiej odpowiada Twoim potrzebom. Oprócz standardowego OC, można także nabyć dodatkowe produkty ubezpieczeniowe. Na przykład Autocasco, assistance czy NNW oraz inne rozszerzenia do ww. ryzyk.',
    FOOTER_QUESTION_7: 'Jak najbardziej tak! Nie musisz ograniczać się jedynie do zakupu obowiązkowego OC lub Autocasco. W naszym kalkulatorze ubezpieczeń Compensa możesz rozszerzyć zakres ochrony dla swojego samochodu i zapewnić kompleksowe ubezpieczenie. Nasza oferta obejmuje bogaty wybór dodatków. ',
    FOOTER_QUESTION_8: '<span>NNW</span> – polisa, która gwarantuje ochronę życia i zdrowia pasażerów podróżujących samochodem w przypadku nieszczęśliwych wypadków. W przypadku uszczerbku na zdrowiu lub śmierci wypłacane jest świadczenie. </br> <span>Ubezpieczenie assistance opony</span> – w przypadku uszkodzenia opony, polisa zapewnia możliwość wymiany przez nas oraz ewentualne odholowanie pojazdu do najbliższego warsztatu, gdy jest to potrzebne.</br> <span>Ubezpieczenie  szyb </span> – w przypadku uszkodzenia szyby w samochodzie, zapewniamy szybką wymianę. Nie wpływa to na zniżki na Autocasco w Compensie.</br> <span>Ubezpieczenie assistance</span> – usługa zapewnia pomoc specjalistów w przypadku awarii samochodu. W zależności od wybranego pakietu, możemy dostarczyć pojazd zastępczy lub zapewnić nocleg w hotelu.',
    FOOTER_QUESTION_9: 'Ubezpieczenie OC jest obowiązkowe. Brak lub przerwa w ochronie ubezpieczeniowej OC może skutkować wysokimi karami finansowymi, nawet kilkoma tysiącami złotych. Polisa OC musi być ważna, niezależnie od tego, czy pojazd jest w ruchu czy nie jest używany (wycofany z ruchu).</br></br> Ubezpieczenie AC (Autocasco) jest dobrowolne, podobnie jak ubezpieczenie assistance, opon czy inne. Warto jednak rozważyć zakup tej polisy, zwłaszcza jeżeli wartość naszego samochodu jest wysoka. Ubezpieczenie AC zabezpiecza nas finansowo w przypadku kradzieży lub uszkodzenia auta.',
    FOOTER_QUESTION_10: 'Obecnie nasz kalkulator OC AC pozwala obliczyć ceny polis komunikacyjnych dla prywatnych samochodów osobowych i motocykli oraz skuterów. W ciągu kilkudziesięciu sekund przedstawimy wiążące oferty ',
    FOOTER_QUESTION_11: 'Oczywiście! Składkę może obliczyć zarówno właściciel, jak i każdy współwłaściciel. Wystarczy podać wszystkie niezbędne dane w formularzu, a my przygotujemy ofertę uwzględniającą historię ubezpieczeniową wszystkich współwłaścicieli. Wyświetlona cena będzie wiążąca.',
    FOOTER_QUESTION_12: 'Nie, przeprowadzenie kalkulacji ma jedynie na celu przedstawienie dostępnych ofert. Decyzja o zakupie należy do Ciebie. Jeśli zdecydujesz się na zakup, będziesz mógł skorzystać z dostępnych opcji i dodatkowych usług oferowanych przez Compensa. Nasz kalkulator OC AC pozwala zapoznać się z ofertami, a wybór należy do Ciebie.',
    FOOTER_QUESTION_13: 'Cenę ubezpieczenia swojego samochodu poznasz w zaledwie kilkudziesięciu sekundach, korzystając z naszego kalkulatora OC. To niespotykane tempo w Polsce! Oszczędność czasu to tylko początek korzyści, jakie płyną z zakupu OC i/lub AC w Compensa. </br> Zakup ubezpieczenia komunikacyjnego na compensa.pl to także ogromna wygoda. Możesz obliczyć składkę w dowolnym miejscu i czasie, za pomocą telefonu, komputera lub tabletu. Co więcej, możesz dokonać zakupu w dogodnym dla Ciebie terminie, aby uniknąć przerwy w ubezpieczeniu oraz ewentualnych kar z UFG za za brak polisy OC. Nie musisz już odpowiadać na dziesiątki pytań. Wystarczy wypełnić kilka pól formularza, aby poznać obowiązującą cenę polisy, którą możesz kupić online!',
    FOOTER_QUESTION_14: 'Ubezpieczenie OC online to obecnie najbardziej wygodne rozwiązanie. W Compensa możesz przejść przez cały proces, od rozpoczęcia kalkulacji po zakup polisy, całkowicie online. Bez konieczności opuszczania domu! Ubezpieczenie można zakupić przez Internet za pośrednictwem kalkulatora OC AC, natychmiast po wyborze oferty. Cały proces zajmie zaledwie kilka minut!',
    FOOTER_QUESTION_15: 'Blik, tradycyjny przelew bankowy, a może szybkie przelewy online? To zależy od Ciebie, jaki wybierzesz sposób płatności. Co istotne, cały proces zakupu ubezpieczenia przez nasz kalkulator OC jest całkowicie bezpieczny i chroniony przed dostępem osób nieuprawnionych.',
    FOOTER_QUESTION_16: 'Oczywiście! Każda informacja, którą podajesz w formularzu naszego kalkulatora OC AC, jest w pełni bezpieczna. Chronimy je przed dostępem osób trzecich. Dzięki rozbudowanej infrastrukturze zabezpieczeń, licznym procedurom oraz certyfikatowi SSL, Twoje dane nie zostaną ujawnione nieuprawnionym podmiotom. Pamiętaj, że potrzebujemy ich jedynie do obliczenia składki polisy OC AC.',
    FOOTER_QUESTION_17: 'Jesteśmy jednym z największych towarzystw ubezpieczeniowych w Polsce. Compensa jest zarejestrowana na liście zakładów ubezpieczeń Komisji Nadzoru Finansowego (KNF). Należymy do międzynarodowej grupy ubezpieczeniowej Vienna Insurance Group (VIG), działającej na 25 rynkach Europy Środkowej i Wschodniej. </br> Compensa składa się z doświadczonych specjalistów ds. ubezpieczeń, którzy mają wieloletnie doświadczenie w branży. Nasz kalkulator OC i AC powstał po wielu miesiącach pracy i testów, aby stworzyć narzędzie odpowiadające Twoim potrzebom i oczekiwaniom. Nasi doradcy służą pomocą przy zakupie ubezpieczenia samochodu, które najlepiej odpowiada Twoim potrzebom.',
    FOOTER_QUESTION_18: 'Wszystkie dane dotyczące Twoich polis OC i AC są gromadzone przez Ubezpieczeniowy Fundusz Gwarancyjny (UFG). To tam przechowywane są informacje dotyczące historii zawieranych umów ubezpieczeniowych i zgłoszonych szkód. Nasz kalkulator OC uwzględnia te dane i nalicza dodatkowe zniżki na podstawie historii ubezpieczeń z UFG, pod warunkiem braku zgłoszonych szkód .',
    FOOTER_QUESTION_19: 'Obliczenie wysokości składki na ubezpieczenie OC jeszcze nie było tak proste. Wystarczy podać datę urodzenia właściciela i numer rejestracyjny pojazdu. To wszystko! Nie pytamy o nic więcej, aby przeprowadzić szybką kalkulację. Ubezpieczenie samochodu z Compensa jest proste, wygodne i szybkie.',
    FOOTER_QUESTION_20: 'Jeśli napotkasz jakiekolwiek problemy podczas korzystania z kalkulatora OC Compensa, skorzystaj z naszej linii telefonicznej pod numerem 500 965 626. Nasi doradcy z przyjemnością odpowiedzą na Twoje pytania. Nasz kalkulator OC to bezpieczne narzędzie, które nie udostępnia Twojego numeru osobom trzecim ani innym podmiotom. ',
    FOOTER_QUESTION_21: 'Dlaczego przy zakupie ubezpieczenia samochodu musimy wypełniać długie formularze? Czy nie można tego zrobić łatwiej? Zdecydowaliśmy się ograniczyć ilość pytań do 2 niezbędnych informacji: daty urodzenia właściciela i numeru rejestracyjnego pojazdu. Resztą zajmuje się nasz system, który pobiera dane z Ubezpieczeniowego Funduszu Gwarancyjnego (UFG), Eurotax oraz Centralnej Ewidencji Pojazdów i Kierowców (CEPiK). Na podstawie tych danych tworzymy ofertę na ubezpieczenie samochodu lub motocykla. Ważne jest, że cały proces jest w pełni bezpieczny. Przykładamy szczególną wagę do zabezpieczeń i ochrony naszego systemu, aby nie doszło do naruszenia danych.',
    FOOTER_QUESTION_22: 'Nie, kalkulator OC Compensa umożliwia obliczenie składki ubezpieczeniowej także dla jednośladów. Jeśli posiadasz motocykl, możesz skorzystać z naszego kalkulatora w ten sam sposób jak w przypadku samochodów. Wystarczy podać nam numer rejestracyjny i datę urodzenia właściciela. Jeśli masz jakiekolwiek problemy, zadzwoń pod numer 500 965 626. Nasi doradcy chętnie pomogą na każdym etapie zakupu ubezpieczenia.',
    FOOTER_QUESTION_23: 'Jeśli nie pamiętasz numeru rejestracyjnego pojazdu, przed rozpoczęciem kalkulacji przygotuj dowód rejestracyjny samochodu. To wszystko! Jeśli chcesz sprawdzić cenę ubezpieczenia OC lub AC dla pojazdu, który nie jest w Twoim posiadaniu, będziesz również potrzebował dowodu osobistego właściciela, aby podać jego datę urodzenia. Możesz również skorzystać z tradycyjnego formularza zawierającego więcej pytań, w którym kalkulator OC Compensa poprosi Cię także o dane z prawa jazdy.',
};
