import React, { FC } from 'react';
import { StyledOrTextLabel, StyledOrTextContainer, OrLine } from './OrTextLabel.style';
import Translate from '../../../../../../translations/translate/Translate.component';

export const OrTextLabel: FC = () => {
  return (
    <StyledOrTextContainer>
      <OrLine />
      <StyledOrTextLabel>
        <span>
          <Translate keyName='OR_TEXT'/>
        </span>
      </StyledOrTextLabel>
      <OrLine />
    </StyledOrTextContainer>
  );
};
