import React, { createContext, FC, useEffect, useContext, PropsWithChildren, useCallback } from 'react';
import { AppState } from '../../redux/store/AppStore';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';

import route from '../../routing/Routes.config';

type Props = PropsWithChildren<StateProps & DispatchProps & RouteComponentProps>;

export const ErrorHandlerContext = createContext('');

const CAR_CAN_NOT_BE_INSURED_CREATE_ERROR_CODE = 1000;
const CAR_CAN_NOT_BE_INSURED_UPDATE_ERROR_CODE = 2000;
export const EMPTY_MODAL = 3000;
const PERSON_CAN_NOT_BE_INSURED_ERROR_CODE = 8000;
const PESELS_CAN_NOT_BE_THE_SAMES = 8001;
export const VALIDATION_PAYMENT_ERROR_CODE = 60000;
export const OFFER_IS_ALREADY_BOUND_ERROR_CODE = 9400;
const EXCLUDED_ERROR_CODES = [
  CAR_CAN_NOT_BE_INSURED_CREATE_ERROR_CODE,
  CAR_CAN_NOT_BE_INSURED_UPDATE_ERROR_CODE,
  PERSON_CAN_NOT_BE_INSURED_ERROR_CODE,
  PESELS_CAN_NOT_BE_THE_SAMES,
  VALIDATION_PAYMENT_ERROR_CODE,
  OFFER_IS_ALREADY_BOUND_ERROR_CODE
];

export const ErrorHandlerProvider: FC<Props> = (props: Props) => {
  const context = useContext(ErrorHandlerContext);
  const history = useHistory();

  const handleRedirect = useCallback(() => {
    history.push(route.GENERIC_ERROR); // TODO: in ccportal push to 'onboarding/blad' (BEE-5628)
  }, [history]);

  const isErrorOccurred = useCallback(() => {
    return props.error.code;
  }, [props.error.code]);

  useEffect(() => {
    // Some error codes expect custom error handler
    // @ts-ignore
    if (isErrorOccurred() && !EXCLUDED_ERROR_CODES.includes(isErrorOccurred())) {
      handleRedirect();
    }
  }, [handleRedirect, isErrorOccurred]);

  return (
    <ErrorHandlerContext.Provider value={context}>{props.children}</ErrorHandlerContext.Provider>
  );
};

const mapDispatchToProps = () => ({});

const mapStateToProps = (state: AppState) => ({
  error: state.errorHandler
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorHandlerProvider));
