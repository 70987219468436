import { ConfigurationKey, StepsIndicatorConfigurationType } from '../tenant.type';

const tenantConfiguration: StepsIndicatorConfigurationType = {
  [ConfigurationKey]: {
    retrieve: { 
      isSaveOfferButtonShown: true,
      phoneContainer: {
        phoneContainerId: 'bee_callback_icon',
        shouldRedirectToPhone: false,
        phoneNumber: undefined
      } 
    }
  } 
};

export default tenantConfiguration;
