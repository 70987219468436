import { useModal } from '@sky-distribution/react-commons-ui';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { OnboardingStateType } from '../../../../../redux/types/AppStore.type';
import Translate from '../../../../../translations/translate/Translate.component';
import { getDeployEnvName } from '../../../../../utils/getDeployEnvName';
import { StyledDebugInfoButton } from './DebugInfo.styled';
import DebugInfoModalContent from './DebugInfoModal.component';
import { clear } from './redux/DebugInfo.actions';

type Props = DispatchProps & StateProps;

const DebugInfo: FC<Props> = (props: Props) => {
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  // eslint-disable-next-line complexity
  useEffect(() => {
    const isCCUserTokenSet = () => Boolean(props.ccUserToken);
    const currentEnvironment = String(getDeployEnvName());
    const isDebugInfoBtnEnabled = String(process.env.REACT_APP_DEBUG_INFO_BTN_ENABLED ?? window.DEBUG_INFO_BTN_ENABLED) === 'true';
    const isProdOnCcPortalEnabled = currentEnvironment === 'prod' && isCCUserTokenSet() && isDebugInfoBtnEnabled;
    const isDevelopmentEnvEnabled = ['dev', 'uat'].includes(currentEnvironment) && isDebugInfoBtnEnabled;
    if (isDevelopmentEnvEnabled || isProdOnCcPortalEnabled) {
      setIsButtonVisible(true);
      return;
    }
    setIsButtonVisible(false);
  }, [props.ccUserToken, setIsButtonVisible]);

  const showModal = () => setIsVisible(true);
  const { component, setIsVisible } = useModal({
    parent: document.getElementById('root'),
    content: <DebugInfoModalContent />,
    isBlurred: true,
    isNarrow: false,
    isClosable: true
  });

  return (
    <>
      {isButtonVisible && (
        <>
          <StyledDebugInfoButton type='button' onClick={showModal}>
            <Translate keyName='DEBUG_INFO' />
          </StyledDebugInfoButton>
          {component}
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: Function) => ({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clearDebugInfo: () => dispatch(clear())
});

const mapStateToProps = (state: OnboardingStateType) => ({
  ccUserToken: state.user?.token?.value
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(DebugInfo);
