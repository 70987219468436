import {
  selectQuoteSubmissionRequest,
  createSubmissionRequest,
  updateSubmissionRequest,
  quoteSubmissionRequest,
  selectCoveragesSubmissionRequest
} from '../../service/Submission.service';
/* eslint-disable complexity */
import {
  QuoteSubmissionRequest,
  CreateSubmissionApiRequest,
  UpdateSubmissionObjectType,
  CreateSubmissionResult,
  QuoteSubmissionResult,
  SelectQuoteApiRequest
} from '../../service/Submission.type';
import { Dispatch } from 'redux';
import { AppActions } from '../../../../redux/actions/AppActions.type';

import {
  SessionCreateSubmissionAction,
  SESSION_ACTIONS,
  SessionUpdateSubmissionAction,
  SessionQuoteSubmissionAction,
  SessionSelectQuoteSubmissionAction,
  SessionState,
  MarkAsRenewalActionType
} from './Session.type';
import { Offer } from '../../../../common/components/offerCard/model/Offer.model';
import { AddonModel } from '../../../../common/components/addon/service/AddonModel';
import {
  clearUserInfoAction,
  retrieveUserInfoAction,
  clearUserInfo,
  clearUserInfoServerFlag
} from '../userInfo/UserInfo.actions';
import { OfferData, resetOfferDataAction, saveOfferInfo, addonsUpdated } from '../../containers/OfferInfo/redux/OfferInfo.actions';
import { resetVaseDataAction } from '../../components/vases/redux/Vases.action';
import {
  closeLoadingQuoteModalAction,
  finishQuoteAction,
  resetQuoteStatusAction,
  startQuoteAction
} from '../quote/Quote.action';
import { AppDispatch, OnboardingStateType } from '../../../../redux/types/AppStore.type';
import { USER_INFO_ACTIONS } from '../userInfo/UserInfoActions.type';
import { resetPaymentFrequencyAction } from '../../containers/PaymentPlan/redux/PaymentFrequency.action';
import { initSession } from '../../containers/BasicInfo/service/Session.service';
import { clearSelectedPath, selectManualPathAction } from '../../containers/BasicInfo/redux/BasicInfo.actions';
import { VIEW_IDENTIFIER } from '../../containers/BasicInfo/constants';
import { format } from 'date-fns';
import { createUtmObject } from '../../utils/Utm.utils';
import { getUTCDate } from '../../../../common/utils/Date.utils';
import { PersonalDataDataType } from '../../containers/PersonalData/service/PersonalData.type';
import { CarDetailsDataType } from '../../containers/CarDetails/service/CarDetails.type';
import { AppState } from '../../../../redux/store/AppStore';
import { triggerDataLayerSubmissionFormCreate, triggerDataLayerSubmissionFormUpdate } from '../../utils/Gtm.events';
import { setInitialOfferId } from '../../components/AppDiscount/redux/discount.actions';
import { Tenant } from '../../../../config/tenants/tenant.type';
import { getRequestHeaders } from '../../../../common/http/utils/getRequestHeaders.util';
import { setTenants } from '../../../../common/components/appState/redux/AppState.actions';

export const startNewSession = () => async (dispatch: AppDispatch) => {
  await initSession();

  dispatch(clearState()); // clear whole session data
};

export const createSubmissionApiPayload = (
  isManual: boolean,
  personInfo: PersonalDataDataType,
  selectedVehicleDetails: Pick<CarDetailsDataType, 'plateNumber' | 'year'>,
  ccUserToken: string
): CreateSubmissionApiRequest => {
  let birthDate = new Date();
  if (personInfo.birthDate instanceof Date) {
    birthDate = personInfo.birthDate;
  } else if (personInfo.birthDate) {
    birthDate = getUTCDate(personInfo.birthDate);
  }
  return {
    step: VIEW_IDENTIFIER,
    basic: {
      isManual,
      pesel: personInfo.pesel,
      plateNumber: selectedVehicleDetails.plateNumber,
      vehicleYear: selectedVehicleDetails.year,
      birthDate: format(birthDate, 'yyyy-MM-dd'),
      trackingCode: createUtmObject(window.location.search ?? '')
    },
    userData: {
      pesel: personInfo.pesel
    },
    agentToken: ccUserToken
  };
};

export const pushUserData = (data: CreateSubmissionApiRequest, personInfo: PersonalDataDataType): CreateSubmissionApiRequest => {
  return {
    ...data,
    userData: {
      name: personInfo.name,
      surname: personInfo.surname,
      pesel: personInfo.pesel
    }
  };
};

export const createSubmission = (createSubmissionApiRequest: CreateSubmissionApiRequest) => {
  return async (dispatch: Dispatch<AppActions>): Promise<void | unknown> => {
    try {
      const response = await createSubmissionRequest(createSubmissionApiRequest);
      triggerDataLayerSubmissionFormCreate({
        sessionUUID: response.payload?.sessionUuid,
        quoteUuid: response.payload?.quoteUuid,
        quoteID: response.payload?.quoteId
      });
      dispatch(createSubmissionAction(response.payload));
      dispatch(canFinishFetchUserData());

      if (response.tenants) {
        dispatch(setTenants({ tenants: response.tenants }));
      }

    } catch (error) {
      return error;
    }
    return undefined;
  };
};

const canFinishFetchUserData = () => {
  return {
    type: USER_INFO_ACTIONS.CLOSE_BASIC_INFO_LOADING_MODAL
  };
};

const createSubmissionAction = (createSubmissionResult: CreateSubmissionResult): SessionCreateSubmissionAction => {
  return {
    type: SESSION_ACTIONS.CREATE_SUBMISSION,
    data: createSubmissionResult
  };
};

export const clearState = () => {
  return (dispatch: AppDispatch) => {
    dispatch(clearUserInfo()); // clear user data
    dispatch(clearUserInfoServerFlag()); // clear server flag for user data
    dispatch(clearUserInfoAction()); // clear quote data
    dispatch(clearSelectedPath()); // selected path value
    dispatch(resetQuoteStatusAction()); // reset quote status
    dispatch(resetOfferDataAction()); // reset offer data
    dispatch(resetVaseDataAction()); // reset vases data
    dispatch(resetPaymentFrequencyAction()); // reset payment frequency
  };
};

export const updateSubmission = (
  updateObject: UpdateSubmissionObjectType,
  session?: SessionState,
  step?: string,
  nextToViewPage?: string
) => {
  return async (dispatch: Dispatch<AppActions>, getState: () => AppState): Promise<void | unknown> => {
    try {
      const result = await updateSubmissionRequest({
        step: step,
        quoteUuid: session?.quoteUuid,
        updateObject: { ...updateObject, returnPath: nextToViewPage },
        isRenewal: getState().userData.session.isRenewal
      });
      triggerDataLayerSubmissionFormUpdate({
        sessionUUID: session?.sessionUuid,
        quoteUuid: session?.quoteUuid
      });
      if (!result?.payload?.dataStatus?.ufg) {
        dispatch(selectManualPathAction());
      }

      if (result?.success && result.payload && result?.payload?.personalData?.surname !== 'SKYSurname') {
        dispatch(retrieveUserInfoAction(result.payload));
      }

      if (result?.payload?.basicInfo?.isAnonymized === false && !result?.payload?.personalData?.surname?.length) {
        dispatch(selectManualPathAction());
      }

      if (result.tenants) {
        dispatch(setTenants({ tenants: result.tenants }));
      }

    } catch (error) {
      return error;
    }
    return undefined;
  };
};

export const updateSubmissionAction = (submissionStateUpdateData: SessionState): SessionUpdateSubmissionAction => ({
  type: SESSION_ACTIONS.UPDATE_SUBMISSION,
  data: submissionStateUpdateData
});

export const quoteSubmission = (quoteSubmissionParams: QuoteSubmissionRequest, nextToViewPage?: string) => {
  return async (dispatch: AppDispatch, getState: () => OnboardingStateType) => {
    const recalculate = quoteSubmissionParams?.options?.recalculate ?? false;
    dispatch(startQuoteAction(recalculate));
    dispatch(addonsUpdated(false));
    const {
      offerInfo: {
        selectedOffer,
        selectedAddons,
        selectedVases,
        quotedTenant
      }
    } = getState().userData;

    const headers = getRequestHeaders({
      agentToken: quoteSubmissionParams?.additionalParams?.agentToken,
      quotedTenant
    });

    const quoteResponse = await quoteSubmissionRequest({
      quoteUuid: quoteSubmissionParams?.session?.quoteUuid,
      returnPath: nextToViewPage,
      ...quoteSubmissionParams?.additionalParams
    }, headers).catch(error => {
      dispatch(closeLoadingQuoteModalAction());
      throw error;
    });

    const {
      offers
    } = quoteResponse.payload;
    let newSelectedOffer = {} as Offer;

    if (selectedOffer) {
      const offer = offers.find((offer) => offer.id === selectedOffer?.id);
      if (offer) {
        newSelectedOffer = offer;
      }
    }

    const selectedAddonsIds = selectedAddons.map((addon) => addon.termCode ?? addon.id);
    const selectedVasesIds = selectedVases.map((vase) => vase.termCode ?? vase.id);

    const packageAddon = quoteResponse.payload.packageAddons.find((packageAddon) => packageAddon.id === newSelectedOffer?.id);
    const packageVases = quoteResponse.payload.vases.find((vase) => vase.id === newSelectedOffer?.id);

    const offerChanged = newSelectedOffer?.id !== selectedOffer?.id;

    let addonsIds = selectedAddonsIds;
    let vasesIds = selectedVasesIds;
    if (offerChanged) {
      addonsIds = [];
      vasesIds = [];
    }

    const offerData: OfferData = {
      offer: newSelectedOffer,
      addons: packageAddon?.addons.filter((addon) => addonsIds.includes(addon.termCode ?? addon.id)) ?? [],
      vases: packageVases?.addons.filter((vase) => vasesIds.includes(vase.termCode ?? vase.id)) ?? []
    };

    dispatch(setInitialOfferId(offerData.offer?.id));
    dispatch(saveOfferInfo(offerData));
    dispatch(quoteSubmissionAction(quoteResponse.payload));
    dispatch(finishQuoteAction());
  };
};

const quoteSubmissionAction = (quoteResponse: QuoteSubmissionResult): SessionQuoteSubmissionAction => {
  return {
    type: SESSION_ACTIONS.QUOTE_SUBMISSION,
    offers: quoteResponse.offers,
    packageAddons: quoteResponse.packageAddons,
    vases: quoteResponse.vases,
    maximumDiscountAmount: quoteResponse.maximumDiscountAmount,
    discountAmount: quoteResponse.discountAmount
  };
};

export const selectQuoteWithAddonsSubmission = ({
  selectedOffer,
  addons,
  selectedAddons,
  vases,
  selectedVases,
  session,
  step,
  nextToViewPage,
  discount,
  quotedTenant
}: {
  selectedOffer: Offer;
  addons: AddonModel[];
  selectedAddons: AddonModel[];
  vases: AddonModel[];
  selectedVases: AddonModel[];
  session?: SessionState;
  step?: string;
  nextToViewPage?: string;
  discount?: number;
  quotedTenant?: Tenant;
}) => {
  return async (dispatch: Dispatch<AppActions>) => {
    const headers = getRequestHeaders({
      quotedTenant
    });

    const selectQuoteRequestData: SelectQuoteApiRequest = {
      step,
      quoteUuid: session?.quoteUuid,
      returnPath: nextToViewPage,
      selectedTenant: quotedTenant,
      selectedOffer,
      discountAmount: discount
    };

    await selectQuoteSubmissionRequest(selectQuoteRequestData, headers);
    await selectCoveragesSubmissionRequest({
      step,
      quoteUuid: session?.quoteUuid,
      coverages: {
        addons,
        selectedAddons,
        vases,
        selectedVases
      }
    }, headers);

    dispatch(selectQuoteSubmissionAction());
  };
};

export const hideNavigation = () => (dispatch: Dispatch<AppActions>) =>
  dispatch({
    type: SESSION_ACTIONS.HIDE_NAVIGATION
  });

export const showNavigation = () => (dispatch: Dispatch<AppActions>) =>
  dispatch({
    type: SESSION_ACTIONS.SHOW_NAVIGATION
  });

const selectQuoteSubmissionAction = (): SessionSelectQuoteSubmissionAction => {
  return {
    type: SESSION_ACTIONS.SELECT_QUOTE
  };
};

export const markAsRenwalAction = (payload: boolean): MarkAsRenewalActionType => {
  return {
    type: SESSION_ACTIONS.MARK_AS_RENEWAL,
    data: payload
  };
};
