import styled from 'styled-components';
import { ReactComponent as DownloadIcon } from '../../../../global/icons/download.svg';

export const ContentLink = styled.a`
  align-items: center;
  color: ${({ theme }) => theme.palette.text.link};
  display: flex;
`;

export const DownloadLinkIcon = styled(DownloadIcon)`
  display: inline-block;
  margin-right: 0.625rem;

  path {
    fill: ${({ theme }) => theme.palette.brand.color};
  }
`;
