import { Consent } from '@sky-distribution/react-commons-ui';
import { ActionCreator, Dispatch } from 'redux';
import {
  AppStateBackPop, AppStateIsCallBackWidgetOpen, AppStateLastViewedPages, AppStateViewedPages, APP_STATE_ACTION_TYPES
} from './AppStateAction.type';

import { Tenant } from '../../../../config/tenants/tenant.type';
import { AppActions } from '../../../../redux/actions/AppActions.type';
import { AppState } from '../../../../redux/store/AppStore';
import { getRequestHeaders } from '../../../http/utils/getRequestHeaders.util';
import { fetchConsentsList } from '../service/AppState.service';

export const setBackHistory: ActionCreator<AppStateBackPop> = (viewedPages: string[]) => ({
  type: APP_STATE_ACTION_TYPES.BACK_POP,
  payload: { viewedPages }
});
export const setViewingPages: ActionCreator<AppStateViewedPages> = (currentPage: string) => ({
  type: APP_STATE_ACTION_TYPES.VIEWED_PAGES,
  payload: { currentPage }
});

export const setNextToViewingPages: ActionCreator<AppStateLastViewedPages> = (nextToView: string) => ({
  type: APP_STATE_ACTION_TYPES.LAST_VIEWED_PAGES,
  payload: { nextToView }
});

export const setCallBackWidgetOpen: ActionCreator<AppStateIsCallBackWidgetOpen> = (isCallBackWidgetOpen: boolean) => ({
  type: APP_STATE_ACTION_TYPES.IS_CALL_BACK_WIDGET_OPEN,
  payload: { isCallBackWidgetOpen }
});

export const getConsentsList = () =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const headers = getRequestHeaders({
      quotedTenant: getState().userData.offerInfo.quotedTenant
    });

    const consentsList = await fetchConsentsList(headers);
    dispatch(getConsentsListAction({ consentsList }));
  };

export const getConsentsListAction = (payload: { consentsList: Consent[] }) => ({
  type: APP_STATE_ACTION_TYPES.CONSENTS_LIST,
  payload
});

export const setTenants = (payload: { tenants: Tenant[] }) => ({
  type: APP_STATE_ACTION_TYPES.TENANTS_LIST,
  payload: { tenants: payload.tenants }
});
