export const BASIC_INFO_ACTIONS = {
  MANUAL_PATH: 'USER_DATA/MANUAL_PATH_SELECTED',
  CLEAR_PATH_SELECTION: 'USER_DATA/CLEAR_PATH_SELECTION',
  CLEAR_ANONYMIZED_STATUS: 'USER_DATA/CLEAR_ANONYMIZED_STATUS',
  VEHICLE_SIMPLIFIED: 'USER_DATA/VEHICLE_SIMPLIFIED',
  UTM_DATA: 'UTM_DATA',
  START_POLICY_FROM_CEP: 'START_POLICY_FROM_CEP'
} as const;

export interface ManualPathSelectedAction {
  type: typeof BASIC_INFO_ACTIONS.MANUAL_PATH;
}

export interface ClearPathSelectionAction {
  type: typeof BASIC_INFO_ACTIONS.CLEAR_PATH_SELECTION;
}

export interface VehicleSimplifiedSelectedAction {
  type: typeof BASIC_INFO_ACTIONS.VEHICLE_SIMPLIFIED;
  data: boolean;
}

export interface UtmDataAction {
  type: typeof BASIC_INFO_ACTIONS.UTM_DATA;
  data: string;
}

export interface StartPolicyFromCepDataAction {
  type: typeof BASIC_INFO_ACTIONS.START_POLICY_FROM_CEP;
  data: boolean;
}
export interface ClearAnonymizedStatusAction {
  type: typeof BASIC_INFO_ACTIONS.CLEAR_ANONYMIZED_STATUS;
}

export type BasicInfoActionsType =
  | ManualPathSelectedAction
  | ClearPathSelectionAction
  | VehicleSimplifiedSelectedAction
  | UtmDataAction
  | ClearAnonymizedStatusAction
  | StartPolicyFromCepDataAction;
