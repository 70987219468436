import { useModal } from '@sky-distribution/react-commons-ui';
import {
  Modal,
  ModalProps,
} from '@sky-distribution/react-commons-ui/lib/Modal/Modal.types';
import React from 'react';
import { ItemDetailsModalContent } from './ItemDetailsModalContent';
import { ItemDetailsModalHeader } from '.';
import { OfferDetailItem } from './ItemDetailsModal.types';
import { AssetsProvider } from '..';

type ItemDetailsModal = {
  component: Modal['component'];
  setItem: (
    details: OfferDetailItem | undefined,
    options?: {
      subHeader?: React.ReactNode;
      onAccept?: (details?: OfferDetailItem) => void;
    },
  ) => void;
  isVisible: Modal['isVisible'];
  setIsVisible: Modal['setIsVisible'];
};

interface ItemDetailsProps {
  buttonText: string;
  assets?: Record<string, string | undefined>;
  showCloseIcon?: boolean;
  showBackIcon?: boolean;
}

export const useItemDetailsModal = ({
  buttonText,
  assets,
  showCloseIcon = true,
  showBackIcon = false,
  ...props
}: ModalProps & ItemDetailsProps): ItemDetailsModal => {
  const { component, setIsVisible, isVisible, setContent, hide, setHeader } =
    useModal({
      isBlurred: true,
      isNarrow: true,
      showCloseIcon: showCloseIcon,
      ...props,
    });

  const setItem = (
    details: OfferDetailItem | undefined,
    options?: {
      subHeader?: React.ReactNode;
      onAccept?: (details?: OfferDetailItem) => void;
    },
  ): void => {
    const handleButtonClick = (): void => {
      if (options?.onAccept) {
        options.onAccept(details);
      }
      hide();
    };

    setContent(
      <AssetsProvider assets={assets}>
        <ItemDetailsModalContent
          details={details}
          buttonText={buttonText}
          handleOnClick={handleButtonClick}
        />
      </AssetsProvider>,
    );

    setHeader(
      <>
        <ItemDetailsModalHeader
          details={details}
          handleOnClick={hide}
          showBackIcon={showBackIcon}
        />
        {options?.subHeader}
      </>,
    );
  };

  return { component, setItem, isVisible, setIsVisible };
};
