import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';

export const StyledToggle = styled.div<{ activeIndex: number }>`
  background-color: ${({ theme }) => theme.palette.switch.background.selected};
  border-radius: 1rem;
  box-shadow: ${({ theme }) => theme.shadow.switch};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  height: 100%;
  left: ${({ activeIndex }) => (100 / 3) * activeIndex}%;
  position: absolute;
  transition: left 0.2s;
  width: calc(100% / 3);
`;

export const StyledToggleLabelsWrapper = styled.div`
  display: flex;
  height: 100%;
  position: absolute;
  width: 100%;
`;

export const StyledRadioSlideWrapperBackground = styled.div`
  background-color: ${({ theme }) => theme.palette.commons.tonal.base};
  border-radius: ${isMobile ? '0' : '1rem'};
  ${isMobile &&
  css`
    margin-top: 60px;
    position: fixed;
    width: 100%;
    z-index: 1;
  `}
`;

export const StyledRadioSlideWrapper = styled.div`
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  height: 3.5rem;
  margin: 0 auto;
  position: relative;
  width: ${isMobile ? `calc(100% - 2rem);` : `100%;`};
`;

export const StyledToggleButton = styled.button<{ isActive: boolean }>`
  align-items: center;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.palette.button.background.primary.after : `transparent`};
  border: 1px dashed rgba(0, 0, 0, 0);
  border-radius: 1rem;
  box-sizing: border-box;
  color: ${({ theme, isActive }) =>
    isActive ? theme.palette.brand.color : theme.palette.switch.text.inactive};
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-size: 1rem;
  font-weight: bold;
  height: 100%;
  justify-content: center;
  outline: none;
  padding: 0;
  transition: color 0.2s;
  transition: border-color 0.2s;
  width: 100%;
  &:focus {
    border: 1px dashed ${({ theme }) => theme.shadow.contentBox};
  }
`;
