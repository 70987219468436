import BasicInfo from './BasicInfo.containers';
import withTagManager from '../../../../routing/withTagManager.hoc';
import { VIEW_IDENTIFIER } from './constants';
import { withTenant } from '../../../../config/tenants/Tenants.provider';

const TENANT_NAME = require('../../../../config/tenants').TENANT_NAME;
const tenantConfiguration = require(`./tenants/${TENANT_NAME}`).default;

export const BasicInfoWithTenant = withTenant(BasicInfo, tenantConfiguration);

export default withTagManager(BasicInfoWithTenant, VIEW_IDENTIFIER);
