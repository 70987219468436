import { UriBuilder } from '../../../../../common/http/UriBuilder';
import { onboardingRequestService } from '../../../../../http/RequestService.http';
import { OnboardingResponseType } from '../../../service/Response.type';

export const initSession = (): Promise<SessionResult> => {
  const url = new UriBuilder().all('init').build();
  return onboardingRequestService.post<SessionResult>(url);
};

export type SessionResult = OnboardingResponseType<undefined>;
