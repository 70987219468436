import { CarDetailsDataType } from '../service/CarDetails.type';

export const CAR_DETAILS_ACTIONS = {
  CAR_DETAILS_SAVED: 'USER_DATA/CAR_DETAILS_SAVED',
  VIN_SAVED: 'USER_DATA/CAR_DETAILS_VIN_SAVED',
  CLEAR_DATA: 'CAR_DETAILS_ACTIONS/CLEAR_DATA'
} as const;
export interface CarDetailsSavedAction {
  type: typeof CAR_DETAILS_ACTIONS.CAR_DETAILS_SAVED;
  data: CarDetailsDataType;
}

export interface CarDetailsClearedAction {
  type: typeof CAR_DETAILS_ACTIONS.CLEAR_DATA;
  data: CarDetailsDataType;
}

export type CarDetailsActionsType = 
 | CarDetailsSavedAction 
 | CarDetailsClearedAction
