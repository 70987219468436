import { createGlobalStyle } from 'styled-components';

export const CommonStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
  }
  p, a, span, h1, h2, h3, h4, h5, h6, input{
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  .grayBckgnd{
    background-color: ${({ theme }) => theme.palette.commons.background.container}
  }
`;
