import styled, { createGlobalStyle } from 'styled-components';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';

type StyledInputProps = KeyboardDatePickerProps & {
  $checked?: boolean;
}

export const IconWrapper = styled.div`
  display: flex;
`;


export const KeyboardDatePickerStyled = styled(KeyboardDatePicker)<StyledInputProps>`
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }

  .MuiIconButton-root:hover {
    background-color: ${({ theme }) => theme.palette.button.background.select};
  }

  .MuiFormLabel-root {
    color: ${p => p.$checked ? p.theme.palette.button.text.select : p.theme.palette.input.text.base}; 
  }

  .Mui-focused {
    &.MuiFormLabel-root {
      color: ${({ theme }) => theme.palette.button.background.select.selected}; 
    }
  }

  .Mui-error {
    &.MuiFormLabel-root {
      color: ${({ theme }) => theme.palette.error.text}; 
    }
  }

  .MuiInputBase-input {
    color: ${({ theme }) => theme.palette.input.border.checkbox};
  }
  
  .MuiOutlinedInput-root {

    .MuiOutlinedInput-notchedOutline {
      border-color: ${p => p.$checked ? p.theme.palette.input.border.selected : p.theme.palette.input.border.base}; 
    }   

    &.Mui-focused {
      color:  ${({ theme }) => theme.palette.input.text.base}; 
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.palette.input.border.selected}; 
      }
    }
    &.Mui-error {
      color:  ${({ theme }) => theme.palette.input.border.selected}; 
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.palette.error.background.base}; 
      }
    }
  }

  label {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 77%;
    white-space: nowrap;
  }
`;

export const DatePickerGlobalStyles = createGlobalStyle`
  .beeSafeDatePicker {
    .MuiPickersCalendar-week {
      .MuiButtonBase-root {
        &.MuiPickersDay-day {
          font-size: 0.75rem;
          font-weight: 500;
          &:not(.MuiPickersDay-dayDisabled) {
            color: ${({ theme }) => theme.palette.input.border.hover};
          }
          &.MuiPickersDay-daySelected {
            color: ${({ theme }) => theme.palette.commons.background.container};
            &:hover {
              background-color: ${({ theme }) => theme.palette.button.background.select.after};
            }
          }
        }
      }
    }
    .MuiPickersToolbarButton-toolbarBtn {
      text-transform: none;
      padding: 0;
      min-width: 1rem;
    }
    .MuiPickersToolbar-toolbar, .MuiPickersDay-daySelected {
      background-color: ${({ theme }) => theme.palette.button.background.select.after}}; 
    }
    .MuiTypography-colorPrimary, .MuiButton-textPrimary {
      color: ${({ theme }) => theme.palette.button.background.select.after}; 
    }
    .MuiPickersYear-root:focus, .MuiPickersMonth-root:focus, .MuiPickersDay-root:focus {
      color: ${({ theme }) => theme.palette.button.background.select.after}; 
    }
  }
`;
