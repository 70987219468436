import styled from 'styled-components';

export const GenericErrorPageContent = styled.div<{
  $wrapperJustifyContent?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: ${(p) => p.$wrapperJustifyContent};
  padding: 9rem 0 1rem;
  overflow: hidden;


  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 4rem 0 1rem;
    flex-direction: column-reverse;
    position: relative;
  }

  & .tempClassNext, .tempClassBack {
    width: 20.5rem;
    ${({ theme }) => theme.breakpoints.down('sm')} {
      width: 19.5rem;
    }
  }

  & > div {
    ${({ theme }) => theme.breakpoints.down('sm')} {
      text-align: center;
    }

    & img {
      margin-left: 1.375rem;
    }
  }
`;

export const ContentWrapper = styled.div``;

export const ImageWrapper = styled.div<{
  url: string;
}>`
  background: no-repeat url(${({ url }) => url}) center center;
  background-size: contain;
  width: clamp(17rem, 35vw, 38rem);
  height: clamp(14rem, 25rem, 30rem);

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 14rem;
  }
`;

export const ButtonContainer = styled.div`
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }
`;

export const OrTextLabelWrapper = styled.div`
  width: 20.5rem;
  height: 4rem;
  display: flex;
  justify-content: center;
`;
