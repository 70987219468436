import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { getIn, useFormikContext } from 'formik';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { isInputChecked } from '../../../utils/isInputChecked';
import {
  getFieldValueFromObject,
  isDefined
} from '../../../utils/Object.utils';
import { FormikDateFieldPickerProps } from '../formik/FormikDatePicker.type';
import {
  localeCancelLabelMap,
  localeMap,
  localeUtilsMap
} from './datePickerLocalization/DatePickerLocalization';
import {
  DatePickerGlobalStyles,
  IconWrapper,
  KeyboardDatePickerStyled
} from './FormikDatePicker.style';

export const FormikDatePicker: FC<FormikDateFieldPickerProps> = (
  props: FormikDateFieldPickerProps
) => {
  const { openOnIconClick } = props;

  const [pickerOpenStatus, setPickerOpenStatus] = useState(false);
  const [disablePicker, setDisablePicker] = useState(false);

  useEffect(() => {
    if (props.disablePopup) {
      setDisablePicker(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.disablePopup]);

  const formikProps = useFormikContext<{ [key: string]: unknown }>();
  const handleOnChange = useCallback(
    (value: Date | null): void => {
      if (isDefined(props.setFieldTouched)) {
        props.setFieldTouched(props.fieldName, true, false);
      }

      props.setFieldValue(
        props.fieldName,
        value,
        !!getIn(formikProps.errors, props.fieldName)
      );
    },
    [props, formikProps]
  );

  const handleOnBlur = useCallback(() => {
    if (isDefined(props.setFieldTouched)) {
      props.setFieldTouched(props.fieldName, true, true);
    }
  }, [props]);

  // TODO check is possible is change logic to const onClick = () => !disablePicker && setPickerOpenStatus(!pickerOpenStatus);
  const onClick = () => setPickerOpenStatus(!disablePicker);

  return (
    <>
      <MuiPickersUtilsProvider
        locale={localeMap[props.lang]}
        utils={localeUtilsMap[props.lang]}
      >
        <KeyboardDatePickerStyled
          id={props.id}
          DialogProps={{
            className: `beeSafeDatePicker`
          }}
          inputVariant="outlined"
          type={props.type}
          fullWidth
          format={props.format ?? 'dd/MM/yyyy'}
          onClick={openOnIconClick ? undefined : onClick}
          onAccept={() => setPickerOpenStatus(false)}
          onClose={() => setPickerOpenStatus(false)}
          onBlur={handleOnBlur}
          open={pickerOpenStatus}
          placeholder={props.placeholder}
          className={props.className}
          openTo={props.openTo}
          views={props.views}
          cancelLabel={localeCancelLabelMap[props.lang]}
          name={props.fieldName}
          label={props.label}
          KeyboardButtonProps={{
            'aria-label': props.label
          }}
          value={getFieldValueFromObject(props.values, props.fieldName)}
          onChange={handleOnChange}
          minDate={props.minDate}
          maxDate={props.maxDate}
          disabled={props.disabled}
          minDateMessage=''
          maxDateMessage=''
          invalidDateMessage=''
          error={props.error}
          initialFocusedDate={props.initialFocusedDate}
          keyboardIcon={
            <IconWrapper onClick={openOnIconClick ? onClick : undefined}>
              {props.keyboardIcon}
            </IconWrapper>
          }
          autoOk={props.autoOk}
          $checked={isInputChecked(
            getFieldValueFromObject(props.values, props.fieldName),
            props.error
          )}
        />
      </MuiPickersUtilsProvider>
      <DatePickerGlobalStyles />
    </>
  );
};

FormikDatePicker.defaultProps = {
  lang: 'en'
};
