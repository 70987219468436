import { PersonalDataDataType } from '../service/PersonalData.type';

export const PERSONAL_DATA_ACTIONS = {
  DATA_SAVED: 'USER_DATA/PERSONAL_DATA_SAVED',
  CLEAR_DATA: 'USER_DATA/CLEAR_PERSONAL_DATA'
} as const;

export interface PersonalDataSavedAction {
  type: typeof PERSONAL_DATA_ACTIONS.DATA_SAVED;
  data: PersonalDataDataType;
}

export interface PersonalDataClearAction {
  type: typeof PERSONAL_DATA_ACTIONS.CLEAR_DATA;
}

export type PersonalDataActionsType = PersonalDataSavedAction | PersonalDataClearAction;
