import { Reducer } from 'redux';
import { i18nState } from 'redux-i18n';
import { translateInitMethodReducer } from '../../../translations/translate/globalTranslate/redux/Translate.reducer';
import { appStateReducer } from '../../components/appState/redux/AppState.reducer';
import { errorHandlerReducer } from '../../components/errorHandler/redux/ErrorHandler.reducer';
import { LanguageType } from '../../../translations/Translate.type';

const commonRootReducer = {
  i18nState: i18nState as Reducer<I18nStateType>,
  translate: translateInitMethodReducer,
  errorHandler: errorHandlerReducer,
  appState: appStateReducer
};

type I18nStateType = ReturnType<typeof i18nState> & { lang: LanguageType };
type TranslateStateType = ReturnType<typeof translateInitMethodReducer>;
type AppStateType = ReturnType<typeof appStateReducer>;
type ErrorHandlerStateType = ReturnType<typeof errorHandlerReducer>;

export type CommonRootStateType = {
  i18nState: I18nStateType & { lang: LanguageType };
  translate: TranslateStateType;
  appState: AppStateType;
  errorHandler: ErrorHandlerStateType;
};

export default commonRootReducer;
