
import React, { FC } from 'react';
import { ContainerTranslateProps } from './Translate.type';
import { useTranslation } from './Translate.provider';

export const ContainerTranslate: FC<ContainerTranslateProps> = ({ children, containerTranslations }: ContainerTranslateProps) => {
  const contextTranslations = useTranslation();
  contextTranslations.mergeTranslations(containerTranslations);
  return (
    <>
      { children }
    </>
  );
};

export default ContainerTranslate;
