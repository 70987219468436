import { AddressInfoDataType } from '../service/AddressInfo.type';

export const ADDRESS_ACTIONS = {
  DATA_SAVED: 'USER_DATA/ADDRESS_SAVED'
} as const;

export interface AddressSavedAction {
  type: typeof ADDRESS_ACTIONS.DATA_SAVED;
  data: AddressInfoDataType;
}

export type AddressActionsType = AddressSavedAction;
