import React, { FC } from 'react';
import { FormRowContainer } from './FormRow.style';

const GRID_XS_SIZE = 12;

interface FormRowProps {
  disableVerticalPadding?: boolean;
  children: React.ReactElement[] | React.ReactElement;
  marginBottom?: string;
  padding?: string;
}

const FormRow: FC<FormRowProps> = ({
  disableVerticalPadding,
  children,
  marginBottom,
  padding
}: FormRowProps) => (
  <FormRowContainer
    item
    xs={GRID_XS_SIZE}
    $disableVerticalPadding={disableVerticalPadding}
    $marginBottom={marginBottom}
    $padding={padding}
  >
    {children}
  </FormRowContainer>
);

export default FormRow;
