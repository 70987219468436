/* eslint-disable complexity */
import React, { FC, MouseEventHandler, PropsWithChildren } from 'react';
import { LoadingLabel, StyledBaseButton, StyledCircularProgress } from './BaseButton.style';
import { useTranslate } from '../../../translations/translate/globalTranslate/TranslationProvider';

export interface BaseButtonProps {
  id?: string;
  type: 'submit' | 'reset' | 'button';
  variant?: 'outlined' | 'contained';
  colors?: {
    backgroundColor?: string;
    textColor?: string;
  };
  style?: {
    height?: number | string;
    width?: number | string;
    fontSize?: string;
  };
  onClick?: MouseEventHandler;
  disabled?: boolean;
  className?: string;
  isLoading?: boolean;
  isLoadingLabel?: string;
  $isHidden?: boolean;
  children: React.ReactNode;
}

type Props = PropsWithChildren<BaseButtonProps>;

export const BaseButton: FC<Props> = (props: Props) => {
  const translate = useTranslate();
  const loadingLabel = props.isLoadingLabel ?? translate('PAGE_IS_LOADING');
  const customClassName = `${props.className ? props.className : ''}${props.isLoading ? ' base-button-loading-state' : ''}`;
  return (
    <StyledBaseButton
      id={props.id}
      fullWidth
      type={props.type}
      disabled={props.disabled}
      $isHidden={props.$isHidden}
      variant={props.variant ? props.variant : 'contained'}
      className={customClassName}
      onClick={props.onClick}
      colors={props.colors}
      style={{
        ...props.style
      }}
    >

      {
        props.isLoading && (
          <>
            <StyledCircularProgress size={20}/>
            <LoadingLabel>
              {loadingLabel ?? props.children}
            </LoadingLabel>
          </>
        )
      }

      {
        !props.isLoading && props.children
      }

    </StyledBaseButton>
  );
};
