import React, { FC } from 'react';
import { connect } from 'react-redux';
import { closeLoadingQuoteModalAction } from '../../redux/quote/Quote.action';
import { OnboardingStateType } from '../../../../redux/types/AppStore.type';
import WaitingWindowLoader from '../../../../common/components/WaitingWindowLoader';

type OfferLoadingModalType = StateProps & DispatchProps;

export const OfferLoadingModal: FC<OfferLoadingModalType> = ({
  isRecalculation,
  isLoadingModalVisible,
  canFinishQuotation,
  closeModal,
  translate
}: OfferLoadingModalType) => {
  const label = isRecalculation ? 'WAITING_OFFER_HEADER_RECALCULATION' : 'WAITING_OFFER_HEADER';

  const infoWaitingWindowContentText = [
    'WAITING_OFFER_STEP_ONE',
    'WAITING_OFFER_STEP_TWO',
    'WAITING_OFFER_STEP_THREE',
    'WAITING_OFFER_STEP_FOUR'
  ];

  return isLoadingModalVisible ? (
    <WaitingWindowLoader
      headerText={translate(label)}
      stepsText={infoWaitingWindowContentText}
      canClose={canFinishQuotation}
      setVisible={closeModal}
    />
  ) : null;
};

const mapStateToProps = (state: OnboardingStateType) => ({
  translate: state.translate.t,
  isLoadingModalVisible: state.userData.quote.isLoadingModalVisible,
  isRecalculation: state.userData.quote.isRecalculation,
  canFinishQuotation: state.userData.quote.canFinishQuotation
});

const mapDispatchToProps = (dispatch: Function) => ({
  closeModal: () => dispatch(closeLoadingQuoteModalAction())
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(OfferLoadingModal);
