import React, { createContext, useContext } from 'react';
import type { ModalContextType, ModalContextProps } from '../types';
import { useModalProvider } from './useModalProvider';

const defaultState: ModalContextType = {
  isModalVisible: false,
  setIsModalVisible: () => null,
  setModal: () => null
};

export const ModalContext = createContext<ModalContextType>(defaultState);

export function ModalProvider(props: ModalContextProps) {
  const { children } = props;
  const hook = useModalProvider();

  return (
    <ModalContext.Provider value={hook}>
      {children}
    </ModalContext.Provider>
  );
}

export const useModalContext = () => useContext(ModalContext);
