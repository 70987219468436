import { Grid } from '@material-ui/core';
import Faq from '@sky-distribution/mf-faq';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import compensaLogo from '../../../../../assets/images/userData/pageTemplate/compensa-logo.svg';
import knfLogo from '../../../../../assets/images/userData/pageTemplate/knf-logo.png';
import securityLock from '../../../../../assets/images/userData/pageTemplate/security-lock.svg';
import { CommonRootStateType } from '../../../../../common/redux/reducers/CommonRootReducer';
import { TenantsEnum } from '../../../../../config/tenants/tenant.type';
import { useTenant } from '../../../../../config/tenants/Tenants.provider';
import Translate from '../../../../../translations/translate/Translate.component';
import CallbackIcon from '../../callbackIcon';
import { FooterLink } from '../PageTemplate.component';
import {
  CallBackIconWrapper, FooterContainer,
  FooterImagesWrapper, FooterLogoContainer,
  FooterLogoImage,
  FooterLogoInfo
} from './FooterImages.style';


interface Props extends StateProps {
  withoutDrawer?: boolean;
  withCallIcon?: boolean;
  footerText?: string;
  containerMaxWidth?: string;
  isParentContainerCentered?: boolean;
  footerLinks?: FooterLink[];
}

const FooterImages: FC<Props> = (props: Props) => {
  const { tenantType } = useTenant();

  const {
    withoutDrawer,
    withCallIcon,
    containerMaxWidth,
    isParentContainerCentered
  } = props;

  const footerImages = [
    {
      url: securityLock,
      translateKeyName: 'FOOTER_ENCRYPTED_MESSAGE'
    },
    {
      url: knfLogo,
      translateKeyName: 'FOOTER_KNF_MESSAGE'
    }
  ];
  tenantType !== TenantsEnum.Compensa && footerImages.unshift({
    url: compensaLogo,
    translateKeyName: 'FOOTER_COMPENSA_MESSAGE'
  });

  const footerImagesJsx = footerImages.map((el, i) => {
    return (
      <Grid item sm='auto' key={i}>
        <FooterLogoContainer>
          <FooterLogoImage src={el.url} alt='' className='imageClass' />
          <FooterLogoInfo className='imageDesc'>
            <Translate keyName={el.translateKeyName} />
          </FooterLogoInfo>
        </FooterLogoContainer>
      </Grid>
    );
  });

  const renderFAQsection = () => {
    return (
      <Faq
        tenantType={tenantType}
        footerText={props.footerText}
        footerLinks={props.footerLinks}
      />
    );
  };

  return (
    <>
      <FooterContainer
        withoutDrawer={withoutDrawer}
        containerMaxWidth={containerMaxWidth}
        isParentContainerCentered={isParentContainerCentered}
      >
        <FooterImagesWrapper>
          {footerImagesJsx}
          {withCallIcon && (
            <CallBackIconWrapper>
              <CallbackIcon />
            </CallBackIconWrapper>
          )}
        </FooterImagesWrapper>
      </FooterContainer>
      {props.footerLinks ? renderFAQsection() : null}
    </>
  );
};

const mapStateToProps = (state: CommonRootStateType) => {
  return {
    translate: state.translate.t
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(FooterImages);


