const NAMESPACE = 'QUOTE';

export const QUOTE_ACTIONS = {
  RESET_QUOTATION: `${NAMESPACE}/RESET_QUOTATION`,
  START_QUOTATION: `${NAMESPACE}/START_QUOTATION`,
  CLOSE_LOADING_MODAL: `${NAMESPACE}/CLOSE_LOADING_MODAL`,
  FINISH_QUOTATION: `${NAMESPACE}/FINISH_QUOTATION`
} as const;

export interface QuoteState {
  isRecalculation: boolean;
  isLoadingModalVisible: boolean;
  canFinishQuotation: boolean;
}

/* ACTIONS */

export interface ResetQuoteStatusAction {
  type: typeof QUOTE_ACTIONS.RESET_QUOTATION;
}

interface StartQuotePayload {
  isRecalculation: boolean;
}

export interface StartQuoteAction {
  type: typeof QUOTE_ACTIONS.START_QUOTATION;
  payload: StartQuotePayload;
}

export interface CloseLoadingQuoteModalAction {
  type: typeof QUOTE_ACTIONS.CLOSE_LOADING_MODAL;
}

export interface FinishQuoteAction {
  type: typeof QUOTE_ACTIONS.FINISH_QUOTATION;
}

export interface QuoteRetrieveQueryStringParams {
  quoteUuid?: string;
  quoteId?: string;
}

export type QuoteActions = 
  | ResetQuoteStatusAction
  | StartQuoteAction
  | CloseLoadingQuoteModalAction
  | FinishQuoteAction;
