import { CSSProperties } from 'styled-components';
import { LanguageDictionaryType as BeesafeLanguageDictionaryType, TranslationsType as BeesafeTranslationsType } from './beesafe/translations/beesafe.type';
import { LanguageDictionaryType as BenefiaLanguageDictionaryType, TranslationsType as BenefiaTranslationsType } from './benefia/translations/benefia.type';

export const ConfigurationKey = 'GenericErrorPageConfiguration';

export type GenericErrorPageConfigurationType = {
  [ConfigurationKey]: {
    contactUrl: string;
    errorImage: string;
    styles: {
      GenericErrorPageContent: {
        justifyContent: CSSProperties['justifyContent'];
      };
    };
  };
};

export type GenericErrorPageTranslationsType = BeesafeTranslationsType | BenefiaTranslationsType;
export type GenericErrorPageLanguageDictionaryType = BeesafeLanguageDictionaryType | BenefiaLanguageDictionaryType;
