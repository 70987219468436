import { CoreUTMType } from '../service/Utm.type';
import { URLSearchParams } from '@sky-distribution/react-commons-ui';

export const createUtmObject = (url: string): Array<CoreUTMType> => {
  const queryParams = new URLSearchParams(url);
  return Object.entries(queryParams.dict)
    .map(([key, val]) => [key.toLowerCase(), Array.isArray(val) ? val[0] : val])
    .filter(([key]) => key.startsWith('utm'))
    .map(([key, val]) => ({
      codeName: key,
      codeValue: val
    })) as CoreUTMType[];
};
