import { MfPolicySummaryState, MF_POLICY_SUMMARY_ACTIONS, ShouldRecalculateSetFlagAction } from './MfPolicySummary.type';
import { createReducer } from '@reduxjs/toolkit';

const initialState: MfPolicySummaryState = {
  shouldRecalculate: false
};

export const mfPolicySummaryReducer = createReducer(initialState, {
  [MF_POLICY_SUMMARY_ACTIONS.SET_SHOULD_RECALCULATE_FLAG]: (
    state: MfPolicySummaryState, 
    action: ShouldRecalculateSetFlagAction): MfPolicySummaryState => ({
    ...state,
    shouldRecalculate: action.payload
  })
}); 
