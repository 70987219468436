"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FAQContainer = void 0;
const onboarding_commons_ui_1 = require("@sky-distribution/onboarding-commons-ui");
const react_commons_ui_1 = require("@sky-distribution/react-commons-ui");
const react_1 = __importStar(require("react"));
const FAQcontent_1 = require("../../components/FAQ/FAQcontent");
const Tenants_provider_1 = require("../../config/tenants/Tenants.provider");
const Translations_lang_1 = require("../../lang/Translations.lang");
const EN_lang_1 = require("../../lang/translations/EN.lang");
const PL_lang_1 = require("../../lang/translations/PL.lang");
const FAQContainer = ({ tenantType, footerText, footerLinks, }) => {
    const themes = {
        beesafe: react_commons_ui_1.beesafeTheme,
        compensa: react_commons_ui_1.compensaTheme,
        benefia: react_commons_ui_1.benefiaTheme,
    };
    const getTheme = (0, react_1.useCallback)(() => {
        if (!themes[tenantType]) {
            throw new Error(`Theme not found for tenant: ${tenantType}`);
        }
        return themes[tenantType];
    }, [tenantType]);
    const getTranslationProps = (0, react_1.useCallback)(() => ({
        commonPLMessages: PL_lang_1.commonPLMessages,
        commonENMessages: EN_lang_1.commonENMessages,
        plMessages: Translations_lang_1.translations[tenantType].pl,
        enMessages: Translations_lang_1.translations[tenantType].en,
        language: 'pl',
        fallBackLanguage: 'en',
    }), [tenantType]);
    return (react_1.default.createElement(Tenants_provider_1.TenantProvider, { tenantType: tenantType },
        react_1.default.createElement(onboarding_commons_ui_1.TranslationWrapper, Object.assign({}, getTranslationProps()),
            react_1.default.createElement(react_commons_ui_1.Themed, { theme: getTheme() },
                react_1.default.createElement(FAQcontent_1.FAQcontent, { footerText: footerText, footerLinks: footerLinks })))));
};
exports.FAQContainer = FAQContainer;
