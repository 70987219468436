import BenefiaSpinner from '../../../../../modules/userdata/components/spinners/benefiaSpinner/BenefiaSpinner';
import { ConfigurationKey, WaitingWindowLoaderConfigurationType } from '../tenant.type';

const configuration: WaitingWindowLoaderConfigurationType = {
  [ConfigurationKey]: {
    loader: {
      spinner: BenefiaSpinner
    }
  }
};

export default configuration;
