import { ConfigurationKey, WaitingWindowLoaderConfigurationType } from '../tenant.type';
import BeesafeSpinner from '../../../../../modules/userdata/components/spinners/beesafeSpinner/BeesafeSpinner';

const configuration: WaitingWindowLoaderConfigurationType = {
  [ConfigurationKey]: {
    loader: {
      spinner: BeesafeSpinner
    }
  }
};

export default configuration;
