import { BeeTextInput, Button } from '@sky-distribution/react-commons-ui';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { CallNowSection } from '../../../../../../components/CallNowSection';
import { FormSection } from '../../../../../../components/FormSection';
import { HeaderWithIcon } from '../../../../../../components/HeaderWithIcon/HeaderWithIcon.component';
import { useSearchRequest } from '../../../../../../components/helpers/dataSender';
import { CALL_CENTER_PHONE } from '../../../../../../constants';
import { StyledForm, StyledHeader } from './Base.styles';
import { ReactComponent as Phone } from './assets/phone.svg';
export const Base = (): JSX.Element => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const { loading, sendNumberToApi } = useSearchRequest();

  const instantCallAction = (): string =>
    (document.location.href = `tel:${CALL_CENTER_PHONE}`);

  return (
    <div>
      <StyledHeader>
        <HeaderWithIcon>
          <h3>{`Zamów 
          rozmowę`}</h3>
          <Phone width="87" />
        </HeaderWithIcon>
      </StyledHeader>

      <StyledForm>
        <FormSection>
          <BeeTextInput
            name="phoneNumber"
            placeholder="Numer telefonu"
            label='Numer telefonu'
            type="tel"
            maskProps={{
              mask: "+48 999-999-999",
              alwaysShowMask: false,
              maskChar: "",
            }}
            validatorProps={{
              validate: {
                isValidPhoneNumber: true,
              },
              onCorrectChange: (value) => setPhoneNumber(value),
              onIncorrectChange: () => setPhoneNumber(""),
              normalize: true,
            }}
            tabIndex={13}
          />
        </FormSection>
        <FormSection>
          <Button
            loader={loading}
            loaderDesc="Uwijamy się jak w ulu"
            typeStyle="primary"
            blockMode
            disabled={phoneNumber.length < 12}
            onClick={() => sendNumberToApi(phoneNumber)}
          >
            Zamów rozmowę
          </Button>
        </FormSection>
      </StyledForm>
      {isMobile ? (
        <CallNowSection onClick={instantCallAction}>
          Zadzwoń teraz
        </CallNowSection>
      ) : (
        <CallNowSection />
      )}
    </div>
  );
};

export default Base;
