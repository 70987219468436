import breakpoints from '../../common/breakpoints';
import { compensaTheme } from '@sky-distribution/react-commons-ui';

export const theme = {
  ...compensaTheme,
  breakpoints: breakpoints,
  colors: compensaTheme.palette
};

export default theme;
