import { IGetTranslateFunctionResponse } from 'redux-i18n';

export const TRANSLATE_ACTIONS = {
  METHOD_INIT: 'TRANSLATE/METHOD_INIT'
} as const;

export interface TranslateMethodInitAction {
  type: typeof TRANSLATE_ACTIONS.METHOD_INIT;
  t: IGetTranslateFunctionResponse;
}
