import type { CustomerConsents, ConsentSchema, ConsentsTypes } from '../../modules/userdata/containers/ContactInfo/components/ConsentForm/Consent';
const TENANT_NAME = require('../../config/tenants').TENANT_NAME;
const tenantConfiguration = require(`../../modules/userdata/containers/ContactInfo/components/ConsentForm/tenants/${TENANT_NAME}`).default;
export default class Consents {
  public static getRepository = (): ConsentSchema => {
    //TODO: dump the consents_schema.json and grab the consents from Core
    // through API call to /rest/consents/list (REST Consents dictionary)
    return tenantConfiguration.ConsentsConfiguration.defaultConsents;
  };

  public static get = (searchTerms?: { where: keyof ConsentsTypes; is: string[] | boolean}): ConsentSchema => {
    const all = Consents.getRepository();

    if (!searchTerms) {
      return all;
    }

    const { where: filterName, is: filterValue } = searchTerms;

    if (typeof filterValue === 'boolean') {
      return all.filter((consentElement: ConsentsTypes) => consentElement[filterName] === filterValue);
    }

    return all.filter((consentElement: ConsentsTypes) => filterValue.includes(consentElement[filterName]));
  };

  public static getStatusForForm = (consents: ConsentSchema, consentsStore: Partial<CustomerConsents>) => {
    const status = consents.map(consent => {
      return {
        [consent.rawName]: !!consentsStore[consent.rawName as keyof CustomerConsents]
      };
    });

    return Object.assign({}, ...status);
  };
}
