/* eslint-disable max-lines, camelcase */
import { plMessages as basicInfoMessages } from '../../../../modules/userdata/containers/BasicInfo/tenants/benefia/translations/languages/PL.lang';


export const plMessages = {
  //BasicInfo
  ...basicInfoMessages,

  PAGE_IS_LOADING: 'Praca w toku…',

  //validation
  TOO_SHORT: 'Zbyt krótki',
  TOO_LONG: 'Za długi',
  REQUIRED: 'Wymagany',
  MIN_LENGTH_OF_VIN: 'VIN musi być dłuższy niż 12 znaków',
  MAX_LENGTH_OF_VIN: 'VIN musi mieć mniej niż 17 znaków',
  FIELD_MUST_BE_CHECKED: 'Pole musi być zaznaczone',
  ADDRESS_STREET_NOT_CONTAIN_SPECIAL_CHARACTERS: 'Nazwa ulicy nie może zawierać znaków specjalnych',
  ADDRESS_CITY_NOT_CONTAIN_SPECIAL_CHARACTERS: 'Nazwa miasta nie może zawierać znaków specjalnych',
  ADDRESS_HOUSE_NO_NOT_CONTAIN_SPECIAL_CHARACTERS: 'Nr domu nie może zawierać znaków specjalnych',
  ADDRESS_FLAT_NOT_CONTAIN_SPECIAL_CHARACTERS: 'Nr mieszkania nie może zawierać znaków specjalnych',
  WRONG_ZIP_CODE: 'Błędny format kodu pocztowego właściwy to: xx-xxx',
  ZIP_CODE_NOT_EXIST: 'Podany kod pocztowy nie istnieje',
  WRONG_FORMAT_OF_PHONE: 'Powinien być w formacie 48 000-000-000',
  PHONE_CANNOT_START_WITH_ZERO: 'Numer telefonu nie może zaczynać się od 0',
  WRONG_PLATE_FORMAT: 'Nieprawidłowa tablica rejestracyjna',
  USER_SHOULD_BE_ADULT: 'Użytkownik musi mieć minimum 18 lat',
  WRONG_PESEL_FORMAT: 'Nieprawidłowy numer pesel',
  PESEL_HAS_ALREADY_BEEN_PROVIDED: 'PESEL został już podany w innym miejscu',
  WRONG_EMAIL_ADDRESS: 'Nieprawidłowy adres e-mail',
  NAME_CONTAINS_LETTERS_ONLY: 'Imię może zawierać tylko litery i znak specjalny: “-”',
  SURNAME_CONTAINS_LETTERS_ONLY: 'Nazwisko może zawierać tylko litery i znak specjalny: “-”',
  SURNAME_NOT_MATCH: 'Podane nazwisko nie pokrywa się z zapisanym',
  EMAILS_NOT_MATCH: 'Podany adres e-mail nie pokrywa się z zapisanym',
  ENTER_A_VALID_NUMBER: 'Podaj prawidłowy numer',
  VEHICLE_YEAR_IS_NOT_SUPPORTED: 'Niestety nie wspieramy pojazdów tego roku',
  NAME_NOT_CONTAIN_WHITESPACES: 'Imię nie może zawierac spacji',
  ONLY_FIRST_NAME_ALLOWED: 'Tylko pierwsze imię, nie może zawierać spacji',

  //ordinal
  '2_ORDINAL': 'Drugi',
  '3_ORDINAL': 'Trzeci',
  '4_ORDINAL': 'Czwarty',
  '5_ORDINAL': 'Piąty',

  //page template
  FOOTER_ENCRYPTED_MESSAGE: 'Zaszyfrowane połączenie',
  FOOTER_KNF_MESSAGE: 'Komisja Nadzoru Finansowego',
  FOOTER_COMPENSA_MESSAGE: 'Należymy do grupy Compensa',
  // steps bar
  STEP: 'Krok:',
  STEPS_BAR_CAR: 'Pojazd',
  STEPS_BAR_OWNER: 'Właściciel',
  STEPS_BAR_OFFER: 'Oferta',
  STEPS_BAR_DETAILS: 'Szczegóły',
  STEPS_BAR_ADDRESS: 'Adres',
  STEPS_BAR_SUMMARY: 'Podsumowanie',
  STEPS_BAR_PAYMENT: 'Płatność',
  DONE: 'Gotowe',
  // basic info
  OR_TEXT: 'lub',
  NEXT: 'Dalej',
  EDIT: 'Edytuj',
  CAR_PLATE_PLACEHOLDER: 'Numer rejestracyjny',
  REG_ELECTRONIC_SERVICES: 'Regulamin świadczenia usług drogą elektroniczną',
  PROCESSING_PERSONAL_DATA_INFO_BEESAFE: 'Informacje dotyczące przetwarzania danych osobowych przez Beesafe oraz Compensa',
  PROCESSING_PERSONAL_DATA_INFO_BENEFIA: 'Informacje dotyczące przetwarzania danych osobowych przez Benefia oraz Compensa',
  PROCESSING_PERSONAL_DATA_INFO_COMPENSA: 'Informacje dotyczące przetwarzania danych osobowych przez Compensa',
  BACK: 'Wstecz',
  FOOTER_DOCUMENTS: 'Dokumenty',

  // personal data
  PERSONAL_DATA_FIRSTNAME: 'Imię',
  PERSONAL_DATA_SURNAME: 'Nazwisko',


  // additional data in manual path
  PESEL_LABEL: 'Numer PESEL',

  // address
  ADDRESS_STREET: 'Ulica',
  ADDRESS_NO: 'Nr domu',
  ADDRESS_FLAT: 'Nr mieszkania',
  ADDRESS_CITY: 'Miasto',
  ADDRESS_ZIPCODE: 'Kod pocztowy',

  //car info
  CAR_INFO_YEAR_PLACEHOLDER: 'Rok produkcji',
  CAR_INFO_VIN_NUMBER_PLACEHOLDER: 'Numer VIN',
  CAR_INFO_DO_NOT_HAVE_MODEL: 'Nie widzę modelu mojego auta',
  CAR_INFO_DO_NOT_HAVE_VERSION: 'Nie widzę wersji mojego auta',

  // confirm data

  SHOW_MORE: 'Pokaż kolejne dodatki',
  SHOW_LESS: 'Pokaż mniej',
  // offer waiting popup
  WAITING_OFFER_HEADER: 'Teraz nasza kolej',
  WAITING_OFFER_STEP_ONE: 'Analizujemy Twoje dane ...',
  WAITING_OFFER_STEP_TWO: 'Układamy w spójną całość ...',
  WAITING_OFFER_STEP_THREE: 'Jeszcze drobne poprawki ...',
  WAITING_OFFER_STEP_FOUR: 'Przygotowanie oferty...',

  // Ufg waiting popup
  WAITING_BASIC_INFO_HEADER: 'Pobieramy dane z bazy Ubezpieczeniowego Funduszu Gwarancyjnego (UFG)',
  WAITING_BASIC_INFO_STEP_ONE: 'Analizujemy dane Twojego pojazdu…',
  WAITING_BASIC_INFO_STEP_TWO: 'Pobieramy dane osobowe…',
  WAITING_BASIC_INFO_STEP_THREE: 'Pobieramy dane adresowe…',

  // offer card
  AS_A_GIFT: 'na nagrody',
  // offer info
  ADD_CO_OWNER: 'Dodaj współwłaściciela pojazdu',
  DELETE_CO_OWNER: 'usuń',
  OWNER: 'właściciel',
  ALREADY_PAYED_OR_INVALID: 'Oferta została opłacona lub uległa przedawnieniu',

  // vase selection page
  OFFER_PAGE_DISCLAIMER: 'Chcesz dostosować zakres ubezpieczenia do swoich potrzeb?',
  // policy start date page
  POLICY_START_HEADER: 'Od kiedy potrzebujesz ubezpieczenia?',

  // offer summary
  YOUR_OFFER_LABEL: 'Twoja oferta:',
  YOUR_OFFER_LABEL_NEW: 'Twoja nowa oferta:',
  SUM_LABEL: 'Suma',

  //Rewards program
  POINTS: 'pkt',

  //policy info
  EMAIL_PLACEHOLDER: 'Adres e-mail',

  //Email component
  EMAIL_HEADER: 'Podaj dane kontaktowe do zawarcia polisy',
  PHONE_PLACEHOLDER: 'Numer telefonu',
  WHY_WE_NEED_EMAIL: 'Te dane są niezbędne do:',
  EMAIL_SEND_INFO: 'Wysłania polisy i dokumentów informacyjnych',
  EMAIL_COMMUNICATION_INFO: 'naszej komunikacji z Tobą',
  EMAIL_FIELD_INFORMATION: 'Na e-mail otrzymasz link do swojej oferty',
  PHONE_NUMBER_FIELD_INFORMATION: 'Kontakt na wypadek problemów z zakupem',
  PHONE_NUMBER_CONTACT_FIELD_INFORMATION: 'Niezbędny do realizacji umowy oraz do ewentualnych oględzin pojazdu przy zakupie AC',


  // appBar
  LOGO_TITLE: 'Strona główna BeeSafe',
  LOGO_ALT: 'kalkulator OC',
  FROM: 'Od',
  CLOSE: 'Zamknij',

  //Drawer
  THE_PRICE_WILL_APPEAR: 'Tutaj za chwilę pojawi się cena',
  YOUR_OFFER: 'Cena za wszystko:',
  CALLBACK_TITLE: 'Masz pytania?',
  CALLBACK_PHONE_CAPTION: 'Zadzwoń: ',
  CALLBACK_PHONE_NUMBER: '22 544 14 14',
  DISCOUNT: 'Rabat',
  DISCOUNT_ERROR: 'Nie można wyświetlić rabatu',
  DISCOUNT_VALIDATION_VALUE_TOO_BIG: 'Za duża zniżka',
  DISCOUNT_VALIDATION_REQUIRED: 'Wymagane',
  DISCOUNT_VALIDATION_POSITIVE: 'Liczba nie może być ujemna',

  // canNotBeInsured
  CAN_NOT_BE_INSURED: 'Wygląda na to, że nie możemy przedstawić Ci oferty.',
  CAN_NOT_BE_INSURED_MORE_INFO: 'W celu otrzymania oferty ubezpieczenia skontaktuj się z konsultantem pod numerem +48 500 965 626',

  // Owner summary
  OWNER_VEHICLE_INFORMATION: 'Dane właściciela pojazdu',
  OWNER_VEHICLE_SUBHEADER: 'Potrzebujemy tych informacji, aby wystawić Twoją polisę.',
  OWNER_VEHICLE_SUBHEADER_GDPR: 'Informację o nazwisku ukryliśmy w trosce o bezpieczeństwo Twoich danych',

  // Confirmation of insurance
  COI_HEADER: 'Tu będzie się działo!',
  COI_INFO: 'W tym miejscu wymienisz punkty na nagrody, znajdziesz dodatkowe usługi i informacje o Twojej polisie. Pracujemy nad tą częścią serwisu. Niebawem skończymy.',

  //pay by link
  PAY_BY_LINK_WAITING_WINDOW_HEADER: 'Poczekaj chwilę - Generujemy polisę.',
  PAY_BY_LINK_WAITING_WINDOW_STEP_ONE: 'Zapisujemy dane Twojego pojazdu…',
  PAY_BY_LINK_WAITING_WINDOW_STEP_TWO: 'Zapisujemy dane osobowe…',
  PAY_BY_LINK_WAITING_WINDOW_STEP_THREE: 'Zapisujemy dane adresowe…',


  //different quotes
  CONTACT_US: 'Skontaktuj się z nami',
  SOMETHING_WENT_WRONG: 'Wygląda na to, że coś poszło nie tak - może spróbujemy jeszcze raz?',

  //  retrieve
  RETRIEVE_HEADER: 'Jesteśmy w trakcie pobierania danych Twojej oferty. Poczekaj chwilkę.',

  NOT_FOUND_TITLE: 'Ups, strony nie znaleziono.',
  NOT_FOUND_DESCRIPTION: 'Za chwilkę przekierujemy Cię na działającą stronę:',

  // payment plans
  PAYMENT_FREQUENCY_HEADER: 'W ilu ratach chcesz zapłacić?',
  PAYMENT_FREQUENCY_everyyear: 'Raz w roku',
  PAYMENT_FREQUENCY_everysixmonths: 'Pierwsza rata',
  PAYMENT_FREQUENCY_INSTALLMENT_SUMMARY: 'Płatność ratalna',
  PAYMENT_FREQUENCY_DOWN_PAYMENT: 'Pierwsza rata',
  PAYMENT_FREQUENCY_DRAWER_SUMMARY: 'Razem',
  PAYMENT_FREQUENCY_SAVINGS_TEXT: 'Oszczędzasz:',
  PAYMENT_FREQUENCY_INSTALLMENT_everyyear: 'Jednorazowo',
  PAYMENT_FREQUENCY_INSTALLMENT_everysixmonths: '2 Raty',
  PAYMENT_FREQUENCY_INSTALLMENT_everythreemonths: '4 Raty',
  PAYMENT_FREQUENCY_everythreemonths: 'Pierwsza rata',

  //cookies
  ACCEPT: 'Zgoda',
  OK_ACCEPT: 'OK',
  USE_COOKIES: 'Zobacz politykę cookies.',
  COOKIES_PREFIX: 'Serwis internetowy www.benefia24.pl wykorzystuje pliki cookies, które umożliwiają i ułatwiają Ci korzystanie z jego zasobów. Korzystając z serwisu wyrażasz jednocześnie zgodę na wykorzystanie plików cookies. ',
  COOKIES_URL: 'https://www.benefia24.pl/ubezpieczenia24/CookiePolicy.aspx',
  COOKIES_AGREEMENT_TEXT: ' ',

  // details modal
  GET_IT: 'Ok, biorę',
  CLOSE_DETAILS: 'Zamknij',

  // payment interrupted
  PAYMENT_INTERRUPTED_HEADER: 'Twoja płatność została przerwana',

  // payment timeout
  PAYMENT_TIMEOUT_HEADER: 'Potrzebujemy czasu na zaksięgowanie płatności.',
  PAYMENT_TIMEOUT_DESC: 'Najpóźniej następnego dnia roboczego prześlemy Ci odpowiedź o jej statusie.',
  PAYMENT_TIMEOUT_DESC_2: 'Masz pytania? Zadzwoń:',

  // contact
  CONTACT_PHONE: '500 96 56 26',

  // declarative path
  DECLARATIVE_HEADER: 'Podaj dane właściciela',

  SAVE_CALCULATION_TITLE: 'Zapisz ofertę',
  SAVE_CALCULATION_INFO: 'Wpisz swój adres e-mail, by dostać na niego link do powrotu do oferty.',
  SAVE_CALCULATION: 'Zapisz kalkulację',
  SAVE_CALCULATION_SHORT: 'Zapisz',
  SAVE_CALCULATION_SAVED: 'Zapisalismy ofertę!',
  SAVE_CALCULATION_SAVED_DESCRIPTION: 'Twoja oferta jest ważna przez 30 dni. Uzupełnij pozostałe dane w wolnej chwili. Poczekamy!',

  // info modal
  MODAL_INFO_SAVE: 'Zapisz',
  MODAL_INFO_TITLE: 'Dane osobowe',
  MODAL_INFO_HEADING: 'Zmiana danych osobowych może wpłynąć na zmianę ceny ubezpieczenia.',

  // vehicle types
  VEHICLE_TYPE_CAR: 'Samochód',
  VEHICLE_TYPE_MOTORCYCLE: 'Motocykl',

  VEHICLE_USER_AGE_CONSENT_RANGE_INEXPERIENCED: 'Klikając "Dalej" deklarujesz, że pojazd będzie kierowany wyłącznie przez osoby powyżej 25 lat',
  VEHICLE_USER_AGE_CONSENT_RANGE_EXPERIENCED: 'Klikając "Dalej" deklarujesz, że pojazd będzie kierowany wyłącznie przez osoby powyżej 28 lat'
};
