import React, { FC, PropsWithChildren, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { setBackHistory, setNextToViewingPages, setViewingPages } from '../../common/components/appState/redux/AppState.actions';
import { CommonRootStateType } from '../../common/redux/reducers/CommonRootReducer';
import route from '../../routing/Routes.config';
interface RedirectToCorrectPageProps extends StateProps {
  currentPageComponentProp: string;
  nexPageToViewComponentProp: string | undefined;
  currentPageViewed: Function;
  nextToView: Function;
  setBackHistory: Function;
  noBackRedirect?: boolean;
  historyState?: { recalculate?: boolean };
}

type Props = PropsWithChildren<RedirectToCorrectPageProps>;

const RedirectToCorrectPage: FC<Props> = (props: Props) => {
  const {
    children,
    currentPageComponentProp,
    nexPageToViewComponentProp,
    noBackRedirect,
    historyState
  } = props;
  const location = useLocation();
  const history = useHistory<{ recalculate?: boolean }>();
  useEffect(() => {
    if (nexPageToViewComponentProp !== undefined || route.BASE_PATH === currentPageComponentProp) {
      props.currentPageViewed(nexPageToViewComponentProp);
    } else {
      if (props.viewedPages.length === 0) {
        history?.push(route.BASE_PATH);
      } else if (!props.viewedPages.includes(currentPageComponentProp) && !noBackRedirect) {
        // eslint-disable-next-line no-magic-numbers
        history.push(props.viewedPages.slice(-1)[0]);
      }
    }

    if (nexPageToViewComponentProp) {
      props.nextToView(nexPageToViewComponentProp);
      history.push(nexPageToViewComponentProp, historyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nexPageToViewComponentProp, currentPageComponentProp]);
  useEffect(() => {
    if (history.action === 'POP') {
      const viewedPages = [...props.viewedPages];
      viewedPages.length > 1 && viewedPages.pop();
      props.setBackHistory(viewedPages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      {children as ReactElement}
    </>
  );
};

const mapStateToProps = (state: CommonRootStateType) => {
  return {
    viewedPages: state.appState.viewedPages,
    nextPageToView: state.appState.nextToView
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  currentPageViewed: (pagePatch: string) => dispatch(setViewingPages(pagePatch)),
  nextToView: (pagePatch: string) => dispatch(setNextToViewingPages(pagePatch)),
  setBackHistory: (viewedPages: string[]) => dispatch(setBackHistory(viewedPages))
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(RedirectToCorrectPage);
