const NAMESPACE = 'TRANSACTION';

export const TRANSACTION_ACTIONS = {
  TRANSACTION_QUOTATION: `${NAMESPACE}/TRANSACTION_QUOTATION`,
  START_QUOTATION: `${NAMESPACE}/START_QUOTATION`,
  FINISH_QUOTATION: `${NAMESPACE}/FINISH_QUOTATION`,
  CLOSE_LOADING_MODAL: `${NAMESPACE}/CLOSE_LOADING_MODAL`,
  CLEAR_REDIRECT_URL: `${NAMESPACE}/CLEAR_REDIRECT_URL`
} as const;

export interface TransactionState {
  redirectUrl: string;
  isLoading: boolean;
  isLoadingModalVisible: boolean;
  canFinish: boolean;
}

export interface SelectPaymentPlanRequest {
  selectedPaymentPlanId: string;
}

export interface PaymentUrlRequest {
  amount?: number;
  returnUrl: string;
  returnErrorUrl: string;
  skipCrcSuffix?: boolean;
}

export interface PaymentUrlResponse {
  url: string;
}

/* ACTIONS */

export interface SetRedirectUrlAction {
  type: typeof TRANSACTION_ACTIONS.TRANSACTION_QUOTATION;
  payload: string;
}

export interface ClearRedirectUrlAction {
  type: typeof TRANSACTION_ACTIONS.CLEAR_REDIRECT_URL;
}

export interface StartQuoteAction {
  type: typeof TRANSACTION_ACTIONS.START_QUOTATION;
}

export interface CloseLoadingQuoteModalAction {
  type: typeof TRANSACTION_ACTIONS.CLOSE_LOADING_MODAL;
}

export interface FinishQuoteAction {
  type: typeof TRANSACTION_ACTIONS.FINISH_QUOTATION;
}
