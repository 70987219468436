import { compensaTheme } from '@sky-distribution/react-commons-ui';
import { BasicInfoConfigurationType, ConfigurationKey } from '../tenant.type';
import alertIcon from './assets/alert.svg';
import homeBgDesktop from './assets/bg_desktop.svg';
import datePickerIcon from './assets/datepicker.svg';

const tenantConfiguration: BasicInfoConfigurationType = {
  [ConfigurationKey]: {
    datePickerIcon: datePickerIcon,
    alertIcon: alertIcon,
    homeBgDesktop: homeBgDesktop,
    homeBgMobile: undefined,
    displayOrLabel: false,
    desktopVerticalBackgroundPosition: '7.75rem',
    styledHeaderMarginBottom: '0',
    shortPathButton: {
      height: '3.1rem'
    },
    manualButton: {
      width: 'auto',
      radius: '2rem',
      borderColor: compensaTheme.palette.input.border.base,
      color: compensaTheme.palette.text.link,
      fontSize: '1rem'
    },
    footerLinks: [
      {
        href: 'https://www.compensa.pl/app/uploads/2023/06/Regulamin-Swiadczenia-Uslug-Droga-Elektroniczna-OMNI-Direct.pdf',
        translationKey: 'REG_ELECTRONIC_SERVICES'
      },
      {
        href: 'https://www.compensa.pl/app/uploads/2023/06/CNL_obowiazek_informacyjny_ADO.pdf',
        translationKey: 'PROCESSING_PERSONAL_DATA_INFO_COMPENSA'
      },
      {
        href: 'https://www.compensa.pl/ubezpieczenia/samochod/',
        translationKey: 'FOOTER_DOCUMENTS'
      }
    ],
    homeBgStyles: {
      backgroundSize: '500px 280px',
      backgroundRepeat: 'repeat-x'
    },
    startTitle: {
      displayTitle: true,
      displaySubtitle: true,
      style: {
        customMargin: '1rem',
        customWidth: '100%',
        customH1Color: compensaTheme.palette.brand.color
      }
    },
    startPageForm: {
      width: '25.125rem',
      height: '17.75rem',
      padding: '0.5rem',
      inputs: {
        height: '3.5rem'
      }
    },
    boxShadow: compensaTheme.shadow.contentBox
  }
};
export default tenantConfiguration;
