import { safeWindow } from '../../../utils/window';

export function prepareBasePath(defaultBasePath: string): string {
  if (!defaultBasePath) {
    throw Error('Base path is required for proper setup of tenant configuration');
  }

  const pathname = safeWindow?.location?.pathname ?? defaultBasePath;
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const [, match] = new RegExp(`^/(.*${defaultBasePath})/`).exec(pathname) || [];

  const result = match ?? defaultBasePath;
  return result;
}
