import styled from 'styled-components';

export const ContentPartners = styled.div`
  align-content: flex-start;
  display: flex;
  flex-direction: column;

  .iconContainer {
    border-bottom: 0.0625rem solid
      ${(props) => props.theme.palette.commons.divider};
    margin-top: 1rem;
    padding: 0.625rem 0;

    img.icon {
      max-height: 3.125rem;
      max-width: 5.625rem;
    }
  }
`;
