import { useModal } from '@sky-distribution/react-commons-ui';
import { ModalProps } from '@sky-distribution/react-commons-ui/lib/Modal/Modal.types';
import React from 'react';
import { useEffect } from 'react';

export type ItemDetailsModalProps = {
  isModalOpen?: boolean;
  handleClose?: () => void;
} & ModalProps;

export const ItemDetailsModal = (props: ItemDetailsModalProps): JSX.Element => {
  const { handleClose, isModalOpen } = props;

  const onClose = (): void => {
    if (handleClose) {
      hide();
      handleClose();
    } else {
      hide();
    }
  };

  const {
    component: ModalComponent,
    setIsVisible,
    hide,
  } = useModal({
    ...props,
    onClose,
  });

  useEffect(() => {
    if (isModalOpen !== undefined) {
      setIsVisible(isModalOpen);
    }
  }, [isModalOpen]);

  return <>{ModalComponent}</>;
};
