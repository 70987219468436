import { AxiosError } from 'axios';
import { ServerError } from '../../../http/RequestInterceptor.service';

export const ERROR_HANDLER_ACTIONS = {
  REDIRECT_ERROR_HANDLER: 'ERROR_HANDLER/REDIRECT',
  RESET_ERROR_HANDLER: 'ERROR_HANDLER/RESET'
};

export interface ErrorHandlerRedirectAction {
  type: typeof ERROR_HANDLER_ACTIONS.REDIRECT_ERROR_HANDLER;
  error: ErrorObject;
}

export interface ErrorHandlerResetAction {
  type: typeof ERROR_HANDLER_ACTIONS.REDIRECT_ERROR_HANDLER;
}

export interface ErrorObject {
  message?: string;
  code?: number;
  raw?: AxiosError<ServerError> | unknown;
}

export type ErrorHandlerActionsType =
  | ErrorHandlerRedirectAction
  | ErrorHandlerResetAction;
