import styled from 'styled-components';
import { Button } from '@sky-distribution/react-commons-ui';


export const StyledDebugInfoButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    margin: 4rem;
    background-color: ${({ theme }) => theme.palette.error.background.base};
    color: ${({ theme }) => theme.palette.button.text.secondary.base};

    ${({ theme }) => theme.breakpoints.only('xs')} {
        margin: 2rem;
  }
`;

export const StyledDebugInfoRedirectButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    margin: 2rem 0rem 0rem 15rem;
    background-color: ${({ theme }) => theme.palette.brand.background};
    color: ${({ theme }) => theme.palette.commons.divider};
    text-decoration: none;
`;

export const StyledDebugInfoMessage = styled.h3`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
`;

export const StyledDebugInfoEmptyError = styled.div`
    justify-content: center;
    align-items: center;
    font-family: 'Poppins',sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: ${({ theme }) => theme.palette.error.background.base};
`;
