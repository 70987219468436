import React from 'react';
import { isMobile } from 'react-device-detect';
import { usePrevious } from '../Hooks/UsePrevious/usePrevious';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';
import { VALID_DATE_PICKER_LENGTH } from './BeeDatePicker.const';
import { DatePickerWrapper, StyledHelperTxt } from './BeeDatePicker.styled';
import { DateForm, RenderPicker } from './components';
import {
  convertPickerDateToString,
  getPickerDate,
  usePickerContext,
} from './contexts';

export const DatePicker: React.FC = () => {
  const {
    state: {
      id,
      inputLabel,
      error,
      retrieveDate,
      date,
      isDirty,
      visiblePicker,
      showErrMsg,
      errorMsg,
      isFormFilled,
      validatorProps: { onCorrectChange, onIncorrectChange },
    },
    dispatch,
  } = usePickerContext();
  const previousValue = usePrevious(retrieveDate);
  const pickerStringDate = convertPickerDateToString(date);

  React.useEffect(() => {
    if (isMobile) {
      if (visiblePicker) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflowY = 'auto';
        document.body.style.overflowX = 'hidden';
      }
    }
  }, [visiblePicker]);

  React.useEffect(() => {
    if (retrieveDate !== undefined && retrieveDate !== previousValue) {
      dispatch({ type: 'SET_DATE', payload: getPickerDate(retrieveDate) });
    }
  }, [retrieveDate, previousValue, dispatch]);

  React.useEffect(() => {
    if (isFormFilled || (pickerStringDate === '' && isDirty)) {
      dispatch({ type: 'VALIDATE_PICKER_DATE' });
    }
  }, [pickerStringDate, isDirty, isFormFilled, dispatch]);

  React.useEffect(() => {
    if (pickerStringDate === previousValue) {
      return;
    }
    if (errorMsg) {
      onIncorrectChange?.(pickerStringDate);
      return;
    }

    if (pickerStringDate.length === VALID_DATE_PICKER_LENGTH) {
      onCorrectChange(pickerStringDate);
    }
  }, [errorMsg, pickerStringDate, previousValue]);

  return (
    <DefaultThemed>
      <DatePickerWrapper id={id || inputLabel.replace(' ', '_')}>
        <DateForm />

        {showErrMsg && <StyledHelperTxt>{errorMsg || error}</StyledHelperTxt>}

        {visiblePicker && <RenderPicker />}
      </DatePickerWrapper>
    </DefaultThemed>
  );
};
