import styled from 'styled-components';

type Size = { size?: number };

export const TextContainer = styled.p<Size>`
  color: ${({ theme }) => theme.palette.text.paragraph.secondary};
  display: block;
  font-family: Poppins, sans-serif;
  font-size: ${(p: Size) => (p.size ? `${p.size}rem` : '1rem')};
  line-height: 1.5;
  margin: 0 0 1rem;
`;
