import React, { ReactElement, FC, useState, useEffect } from 'react';
import { OfferDropdownType } from './OfferDropdown.type';
import { ValueType } from '../ToggleSelect/ToggleSelect.type';
import { OfferSelectContent } from '../OfferSelectContent/OfferSelectContent';
import { Dialog, Menu } from '@sky-distribution/react-commons-ui';
import { ReactComponent as Close } from './assets/close.svg';
import { isMobile } from 'react-device-detect';
import { OfferDropdownLabel } from './OfferDropdownLabel/OfferDropdownLabel';
import {
  OfferDropdownLabelContainer,
  OfferDropdownWrapper,
  DialogWrapper,
  DialogHeaderContent,
  StyledDialogCloseButton,
  OfferDropdownContent,
} from './OfferDropdown.style';

export const OfferDropdown: FC<OfferDropdownType> = ({
  children,
  options,
  label,
  onChange,
  value,
  dataE2e,
  labelWidth = 10,
  contentWidth = 19.2,
  showSelectedText = true,
  closeOnChange = true,
  useModal = false,
}: OfferDropdownType) => {
  const [isOpened, setIsOpened] = useState(false);

  const onChangeHandle = (newVal: ValueType): void => {
    onChange(newVal);
    closeOnChange && setIsOpened(false);
  };

  const getLabel = (): string | ReactElement => {
    if (label) {
      return label;
    }

    const selectedOption = options.find((option) => option.value === value);
    return selectedOption ? selectedOption.label : '';
  };

  useEffect(() => {
    if (label && closeOnChange) {
      setIsOpened(false);
    }
  }, [label, closeOnChange]);

  const dialogWrapper = (
    <OfferDropdownWrapper>
      <OfferDropdownLabelContainer
        width={labelWidth}
        onClick={() => setIsOpened(true)}
      >
        <OfferDropdownLabel label={getLabel()} isExpanded={isOpened} />
      </OfferDropdownLabelContainer>
      <Dialog value={isOpened} onChange={setIsOpened} position="bottom">
        <DialogWrapper>
          <DialogHeaderContent>
            <StyledDialogCloseButton onClick={() => setIsOpened(false)}>
              <Close />
            </StyledDialogCloseButton>
          </DialogHeaderContent>
          <OfferSelectContent
            dataE2e={dataE2e}
            options={options}
            value={value}
            onChange={onChangeHandle}
            useModal={true}
            showSelectedText={showSelectedText}
          >
            {children}
          </OfferSelectContent>
        </DialogWrapper>
      </Dialog>
    </OfferDropdownWrapper>
  );
  const dropdownLabel = (
    <OfferDropdownLabelContainer width={labelWidth}>
      <OfferDropdownLabel label={getLabel()} isExpanded={isOpened} />
    </OfferDropdownLabelContainer>
  );
  const dropdownContent = (
    <OfferDropdownContent
      data-e2e={`OfferDropDown Content: ${String(dataE2e)}`}
      width={contentWidth}
    >
      <OfferSelectContent
        dataE2e={dataE2e}
        options={options}
        value={value}
        onChange={onChangeHandle}
        showSelectedText={showSelectedText}
      >
        {children}
      </OfferSelectContent>
    </OfferDropdownContent>
  );
  const offsetTopInPx = 16;
  const menuWrapper = (
    <OfferDropdownWrapper>
      {useModal ? (
        <Dialog value={isOpened} onChange={setIsOpened} position="bottom">
          {dropdownContent}
        </Dialog>
      ) : (
        <Menu
          value={isOpened}
          onChange={setIsOpened}
          el={dropdownLabel}
          offsetTop={offsetTopInPx}
          anchor="bottom middle"
          self="top middle"
          autoWidth={false}
        >
          {dropdownContent}
        </Menu>
      )}
    </OfferDropdownWrapper>
  );

  return isMobile ? dialogWrapper : menuWrapper;
};

export default OfferDropdown;
