import * as Yup from 'yup';
import { isValid } from 'date-fns';

export const dateValidator = (
  isRequired = false, requiredErrorMessage = ''
): Yup.DateSchema<Date> => {
  return Yup.date().typeError(requiredErrorMessage)
    .nullable(!isRequired)
    .test('required', requiredErrorMessage,
      value => isRequired ? isValid(value) : true
    );
};
