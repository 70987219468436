import { Consent } from '@sky-distribution/react-commons-ui';
import { Action } from 'redux';
import { Tenant } from '../../../../config/tenants/tenant.type';

export const APP_STATE_ACTION_TYPES = {
  BACK_POP: 'appState/backPop',
  VIEWED_PAGES: 'appState/viewedPages',
  LAST_VIEWED_PAGES: 'appState/lastViewedPages',
  IS_CALL_BACK_WIDGET_OPEN: 'appState/isCallBackWidgetOpen',
  CONSENTS_LIST: 'appState/consentsList',
  TENANTS_LIST: 'appState/tenantsList'
} as const;

export interface AppStateBackPop extends Action {
  type: typeof APP_STATE_ACTION_TYPES.BACK_POP;
  payload: { viewedPages: string[] };
}
export interface AppStateViewedPages extends Action {
  type: typeof APP_STATE_ACTION_TYPES.VIEWED_PAGES;
  payload: { currentPage: string };
}
export interface AppStateLastViewedPages extends Action {
  type: typeof APP_STATE_ACTION_TYPES.LAST_VIEWED_PAGES;
  payload: { nextToView: string };
}

export interface AppStateIsCallBackWidgetOpen extends Action {
  type: typeof APP_STATE_ACTION_TYPES.IS_CALL_BACK_WIDGET_OPEN;
  payload: { isCallBackWidgetOpen: boolean };
}

export interface AppStateConsentsList extends Action {
  type: typeof APP_STATE_ACTION_TYPES.CONSENTS_LIST;
  payload: { consentsList: Consent[] };
}

export interface AppStateTenantsList extends Action {
  type: typeof APP_STATE_ACTION_TYPES.TENANTS_LIST;
  payload: { tenants: Tenant[] };
}

export type AppStateActions =
  | AppStateBackPop
  | AppStateViewedPages
  | AppStateLastViewedPages
  | AppStateIsCallBackWidgetOpen
  | AppStateConsentsList
  | AppStateTenantsList;
