import styled from 'styled-components';

export const ContentUnorderedList = styled.ul`
  margin: 0;
  padding: 0;
`;

export const ContentListItem = styled.li`
  list-style: none;

  &::before {
    content: '-';
    margin-right: 0.5rem;
  }

  .listText {
    color: ${(props) => props.theme.palette.text.paragraph.secondary};
    font-size: 0.875rem;
  }
`;
