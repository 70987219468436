import { isNotDefined } from '../utils/Object.utils';

const PLATE_NUMBER_MIN_LENGTH = 3;
const PLATE_NUMBER_MAX_LENGTH = 8;
const CONTAINS_NUMBER = /\d/;

// eslint-disable-next-line complexity
const plateNumberValidator = (plateNumber: string, canBeNull?: boolean) => {
  if (canBeNull && isNotDefined(plateNumber)) {
    return true;
  }
  if (plateNumber?.length) {
    const sanitizedPlateNumber = plateNumber.replace(/\s/g, '').trim();
    const sanPlateNumber = sanitizedPlateNumber;
    if (
      sanPlateNumber.length >= PLATE_NUMBER_MIN_LENGTH &&
      sanPlateNumber.length <= PLATE_NUMBER_MAX_LENGTH &&
      sanPlateNumber.match(/^[a-zA-Z]/) &&
      sanPlateNumber.match(/[a-z0-9]+$/i) &&
      CONTAINS_NUMBER.test(sanPlateNumber)
    ) {
      return true;
    }
  }
  return false;
};

export default plateNumberValidator;
