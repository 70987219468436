import { Button } from "@sky-distribution/react-commons-ui";
import React from "react";
import { ThankYouPage } from "../../../../../../pages/ThankYou.page";
import { ThankYouProps } from "../../../Tenants.types";
import { StyledContainer } from "./ThankYou.style";

const ThankYou = (props: ThankYouProps): JSX.Element => {
  const { handleCloseCallBackWidget } = props;

  return (
    <StyledContainer>
      <ThankYouPage>
        <Button
          onClick={handleCloseCallBackWidget}
          blockMode
          typeStyle="secondary"
        >
          Zamknij
        </Button>
      </ThankYouPage>
    </StyledContainer>
  );
};

export default ThankYou;
