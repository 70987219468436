import React, { createContext, useContext } from 'react';
import type { ModalContextProps } from '../types';
import { useRenewalProvider } from './useRenewalProvider';

export const RenewalContext = createContext<void>(undefined);

export function RenewalProvider(props: ModalContextProps) {
  const { children } = props;
  useRenewalProvider();

  return (
    <RenewalContext.Provider value={undefined}>
      {children}
    </RenewalContext.Provider>
  );
}

export const useRenewalContext = () => useContext(RenewalContext);
