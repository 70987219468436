import { MEDIA_QUERY } from '@sky-distribution/react-commons-ui';
import styled from 'styled-components';
import { CallNowSection } from './CallNowSection';

export const CallNowSectionContainer = styled.div<CallNowSection>`
  align-items: center;
  color: ${({ theme }) => theme.palette.text.paragraph.primary};
  display: flex;
  font-family: 'Poppins', sans-serif;
  font-size: 0.75rem !important;
  padding: 2rem 0 1rem;
  margin: 0 2rem;

  & > div {
    font-size: 0.75rem !important;
  }

  & > svg {
    margin-right: 1rem;
  }

  ${MEDIA_QUERY.below('sm')} {
    padding: 1rem 0 2rem;
  }
`;
