import { useTheme } from '@sky-distribution/react-commons-ui';
import React, { FC } from 'react';
import { ItemDetailsModalProps } from '../ItemDetailsModal.types';
import { HeroBoxContainer } from './HeroBox.styled';
import { ContentBlock } from './content/ContentBlock/ContentBlock';

export const HeroBox: FC<ItemDetailsModalProps> = ({
  details,
}: ItemDetailsModalProps) => {
  const theme = useTheme();
  if (!details) {
    return null;
  }
  const descriptionBlocks = details.description;

  return (
    <HeroBoxContainer>
      {descriptionBlocks.map((block, bidx) => (
        <ContentBlock
          color={theme.palette.text.paragraph.primary}
          key={`d-${bidx}`}
          block={block}
        />
      ))}
    </HeroBoxContainer>
  );
};
