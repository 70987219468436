import { theme } from '../../../../../../config/tenants/beesafe/theme';
import { ConfigurationKey, CallbackIconConfigurationType } from '../tenant.type';
import callbackImage from './assets/callback.png';

const tenantConfiguration: CallbackIconConfigurationType = {
  [ConfigurationKey]: {
    callbackImage,
    styles: {
      wrapperDisplay: 'block',
      phoneImage: {
        smWidth: '1.375rem',
        smHeight: '1.375rem'
      },
      callBackContainer: {
        background: theme.palette.brand.color
      },
      callbackImage: {
        borderRadius: '',
        smWidth: '5.3125rem',
        smHeight: '5.9375rem'
      },
      callbackImageContainer: {
        smHeight: '6.075rem'
      },
      phoneContainer: {
        borderRadius: '',
        smHeight: '3rem',
        smBottom: 0
      },
      clipPath: 'polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%)'
    }
  }
};

export default tenantConfiguration;
