import { formatISO } from 'date-fns';
import { Dispatch } from 'redux';
import { setTenants } from '../../../../common/components/appState/redux/AppState.actions';
import { getRequestHeaders } from '../../../../common/http/utils/getRequestHeaders.util';
import { AppActions } from '../../../../redux/actions/AppActions.type';
import { AppState } from '../../../../redux/store/AppStore';
import { saveStartPolicyFromCepData } from '../../containers/BasicInfo/redux/BasicInfo.actions';
import { BasicInfoDataType } from '../../containers/BasicInfo/service/BasicInfo.type';
import { clearCarDetailsAction } from '../../containers/CarDetails/redux/CarDetails.actions';
import { CarDetailsSavedAction, CAR_DETAILS_ACTIONS } from '../../containers/CarDetails/redux/CarDetailsActions.type';
import { CarDetailsDataType } from '../../containers/CarDetails/service/CarDetails.type';
import { clearCustomerConsentsAction } from '../../containers/ContactInfo/components/ConsentForm/redux/Consents.actions';
import { getPaymentFrequencyOptionsAction, savePaymentFrequencyDataAction } from '../../containers/PaymentPlan/redux/PaymentFrequency.action';
import { PaymentPlanType } from '../../containers/PaymentPlan/redux/PaymentFrequency.type';
import { fetchPaymentFrequencyOptions } from '../../containers/PaymentPlan/service/PaymentFrequency.service';
import { personalDataClearAction, personalDataSavedAction } from '../../containers/PersonalData/redux/PersonalData.actions';
import { PersonalDataDataType } from '../../containers/PersonalData/service/PersonalData.type';
import { savePolicyStartDateAction } from '../../containers/PolicyStartDate/redux/PolicyDate.actions';
import { getUserInfoRequest } from '../../service/FetchUserInfo.service';
import { FetchUserInfoResultType, UserInfoData } from '../../service/FetchUserInfo.type';
import { retrieveSubmissionFromCCRequest, retrieveSubmissionRequest } from '../../service/Submission.service';
import { UserDataRootReducer } from '../UserDataRootReducer';
import {
  UserInfoClearedDataAction,
  UserInfoClearedServerFlagAction,
  UserInfoCloseBasicInfoLoadingModalAction,
  UserInfoFetchedAction,
  UserInfoRetrievedDataAction,
  USER_INFO_ACTIONS
} from './UserInfoActions.type';

const HIDE_WINDOW_TIMEOUT = 1000;

export const saveCarDetailsAction = (carInfoData: CarDetailsDataType ):CarDetailsSavedAction => {
  return {
    type: CAR_DETAILS_ACTIONS.CAR_DETAILS_SAVED,
    data: carInfoData
  };
};

export const fetchUserInfo = (userInfoData: UserInfoData) => {
  return async (dispatch: Dispatch<AppActions>) => {
    const userInfoFetchedData = await getUserInfoRequest(userInfoData).catch((e) => {
      return { payload: e.response?.data };
    });

    const userInfoResultData = userInfoFetchedData.payload ?? {};

    if (userInfoResultData?.error && userInfoResultData?.error?.errorCode) {
      userInfoResultData.errorDetails = {
        errorCode: userInfoResultData?.error?.errorCode
      };
    }

    if (userInfoResultData?.coOwners) {
      dispatch(personalDataSavedAction({ coOwners: prepareCoOwnersData(userInfoResultData) }));
    } 

    if (userInfoResultData?.car && userInfoResultData.person?.dateTo) {
      const dateTo = new Date(userInfoResultData.person.dateTo);
      dispatch(savePolicyStartDateAction(formatISO(dateTo)));
      dispatch(saveStartPolicyFromCepData(userInfoResultData.person?.startPolicyData));
    }

    dispatch(saveCarDetailsAction({ plateNumber: userInfoData.plateNumber, year: userInfoData?.vehicleYear, vin: userInfoResultData?.car?.vin } as CarDetailsDataType));
    dispatch(fetchUserInfoAction(userInfoResultData, userInfoData));
  };
};

export const fetchUserInfoAction = (
  fetchedUserInfo: FetchUserInfoResultType,
  userInfoData?: UserInfoData
): UserInfoFetchedAction => {
  const personInfoData = preparePersonInfoData(fetchedUserInfo, userInfoData);
  const basicInfo = prepareBaseInfo(fetchedUserInfo);
  const validDataFromServer = !!fetchedUserInfo?.car;
  const responseError = !!fetchedUserInfo?.error;
  const errorDetails = fetchedUserInfo?.errorDetails;

  return {
    type: USER_INFO_ACTIONS.FETCH_USER_INFO,
    data: fetchedUserInfo,
    validDataFromServer: validDataFromServer,
    personInfo: personInfoData,
    basicInfo: basicInfo,
    responseError,
    errorDetails
  };
};


// TODO it is required ?
export const clearUserInfo = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(clearUserInfoAction());
  };
};

// clear all data for application
export const clearUserInfoAction = (): UserInfoClearedDataAction => ({
  type: USER_INFO_ACTIONS.CLEAR_USER_INFO
});

export const clearUserInfoServerFlag = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(clearServerFlagAction());
    setTimeout(() => {
      dispatch(closeBasicInfoLoadingModal());
    }, HIDE_WINDOW_TIMEOUT);

  };
};

export const closeBasicInfoLoadingModal = (): UserInfoCloseBasicInfoLoadingModalAction => {
  return {
    type: USER_INFO_ACTIONS.CLOSE_BASIC_INFO_LOADING_MODAL
  };
};

export const clearServerFlagAction = (): UserInfoClearedServerFlagAction => {
  return {
    type: USER_INFO_ACTIONS.CLEAR_SERVER_FLAG
  };
};

export const retrieveUserInfoFromSubmission = (quoteUuid: string, confirmData?: { surname?: string; email?: string }) => {
  return async (dispatch: Dispatch<AppActions>, getState: () => AppState): Promise<boolean> => {
    try {
      const headers = getRequestHeaders({
        quotedTenant: getState().userData.offerInfo.quotedTenant
      });

      const retrieveResponse = await retrieveSubmissionRequest(
        { quoteUuid, confirmData, isRenewal: getState().userData.session.isRenewal },
        headers
      );
      const fetchPlansRes = await fetchPaymentFrequencyOptions({
        quoteID: retrieveResponse.userData.session.quoteId,
        sessionUUID: retrieveResponse.userData.session.sessionUuid,
        isRenewal: getState().userData.session.isRenewal
      }, headers);
      const retrievedSelectedPaymentPlan = retrieveResponse.userData.paymentFrequency.selectedPaymentPlan;
      const paymentPlans = fetchPlansRes.paymentPlans;
      let selectedPaymentPlan: PaymentPlanType;
      if (paymentPlans.length > 0 && retrievedSelectedPaymentPlan?.billingId) {
        selectedPaymentPlan = paymentPlans.find(plan => plan?.billingId === retrievedSelectedPaymentPlan?.billingId);
      }

      dispatch(retrieveUserInfoAction(retrieveResponse.userData));
      dispatch(getPaymentFrequencyOptionsAction(paymentPlans));
      dispatch(savePaymentFrequencyDataAction(selectedPaymentPlan));
      dispatch(setTenants({ tenants: retrieveResponse.tenants }));
      return retrieveResponse.isAnonymized;
    } catch (error) {
      return error as boolean;
    }
  };
};

export const ccRetrieveUserInfoFromSubmission = (quoteId: string) => {
  return async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const clearingActions = [
      clearUserInfoAction,
      personalDataClearAction,
      clearCarDetailsAction,
      clearCustomerConsentsAction
    ];
    clearingActions.forEach((clearingAction) => {
      dispatch(clearingAction());
    });
    const isRenewal = Boolean(getState().userData.session.isRenewal);
    const retrieveResponse = await retrieveSubmissionFromCCRequest({ quoteId }, isRenewal);
    dispatch(retrieveUserInfoAction(retrieveResponse.userData));
  };
};

export const retrieveUserInfoAction = (userData: UserDataRootReducer): UserInfoRetrievedDataAction => ({
  type: USER_INFO_ACTIONS.RETRIEVE_USER_INFO,
  data: userData
});


const prepareBaseInfo = (userInfoResultData?: FetchUserInfoResultType) => {
  const baseInfo = {} as BasicInfoDataType;
  if (userInfoResultData?.isAnonymized !== undefined) {
    baseInfo.isAnonymized = userInfoResultData.isAnonymized;
  }
  return baseInfo;
};

const preparePersonInfoData = (userInfoResultData?: FetchUserInfoResultType, userInfoData?: UserInfoData) => {
  let personInfoData = {} as PersonalDataDataType;
  if (userInfoResultData?.car) { // if there is a car we want to update person data
    personInfoData = {
      name: userInfoResultData.person?.firstName,
      surname: userInfoResultData.person?.lastName,
      pesel: userInfoResultData.person?.pesel
    };
  }

  if (userInfoData) {
    personInfoData.birthDate = userInfoData?.birthDate;
  }

  return personInfoData;
};

const prepareCoOwnersData = (userInfoResultData?: FetchUserInfoResultType) => {
  return userInfoResultData?.coOwners?.map((coowner) => {
    return {
      name: coowner.firstName,
      surname: coowner.lastName,
      pesel: coowner.pesel,
      isLoadedFromCepik: true
    };
  }) ?? [];
};
