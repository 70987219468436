import { Typography } from '@sky-distribution/react-commons-ui';
import styled from 'styled-components';
import { ReactComponent as CheckIco } from '../global/icons/check.svg';

export const LabelWithCheckContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  width: fit-content;
`;

export const Check = styled(CheckIco)`
  fill: ${(props) => props.theme.palette.brand.color};
  height: 0.875rem;
  width: 1.2rem;
`;

export const LabelTextContainer = styled.div`
  margin-left: 0.5rem;
`;

export const LabelText = styled(Typography)`
  height: 1.438rem;
  letter-spacing: normal;
  text-align: left;
  width: 1.563rem;

  &:hover {
    color: ${(props) => props.theme.palette.brand.color} !important;
    text-decoration: underline;
  }
`;
