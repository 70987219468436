import { CoOwner } from '../../../containers/PersonalData/service/PersonalData.type';

export const CO_OWNERS_ACTIONS = {
  DATA_SAVED: 'CO_OWNERS/DATA_SAVED'
} as const;
export interface CoOwnerDataSavedAction {
  type: typeof CO_OWNERS_ACTIONS.DATA_SAVED;
  data: CoOwner[];
}

export type CoOwnerAddressActionsType = CoOwnerDataSavedAction;
