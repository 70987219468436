import React, { FC } from 'react';
import { ToggleSelectType } from './ToggleSelect.type';
import ToggleSelectOption from './ToggleSelectOption';
import { ToggleSelectOptionsWrapper } from './ToggleSelect.style';

export const ToggleSelect: FC<ToggleSelectType> = ({
  options,
  onChange,
  value,
  dataE2e,
  showSelectedText,
}: ToggleSelectType) => {
  const optionsJsx = options.map((el) => {
    return (
      <ToggleSelectOption
        dataE2e={dataE2e}
        title={el.title}
        caption={el.caption}
        label={el.label}
        price={el.price}
        value={el.value}
        onClick={onChange}
        selectedValue={value}
        key={el.value}
        showSelectedText={showSelectedText}
      />
    );
  });
  return <ToggleSelectOptionsWrapper>{optionsJsx}</ToggleSelectOptionsWrapper>;
};
