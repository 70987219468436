import { IAxiosCacheAdapterOptions } from 'axios-cache-adapter';
import { EXCLUDED_PATHS } from './excludedPathsFromCache';

const {
  REACT_APP_AXIOS_CACHE_MAX_AGE_MINUTES
} = process.env;

const convertToMiliseconds = (value: string | undefined): number => {
  if (value && !isNaN(+value)) {
    return +value * 60 * 1000; // eslint-disable-line no-magic-numbers
  }
  return 0;
};

export const cacheConfig: IAxiosCacheAdapterOptions = {
  maxAge: convertToMiliseconds(REACT_APP_AXIOS_CACHE_MAX_AGE_MINUTES),
  exclude: {
    query: false,
    paths: EXCLUDED_PATHS
  }
};

export const getCacheConfig = () => {
  if (convertToMiliseconds(REACT_APP_AXIOS_CACHE_MAX_AGE_MINUTES)) {
    return cacheConfig;
  }
  return undefined;
};
