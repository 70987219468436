import errorImage from '../../assets/errorBear.svg';
import { ConfigurationKey } from '../tenant.type';

const tenantConfiguration = {
  [ConfigurationKey]: {
    contactUrl: 'https://beesafe.pl/kontakt',
    errorImage,
    styles: {
      GenericErrorPageContent: {
        justifyContent: 'space-around'
      }
    } 
  }
};

export default tenantConfiguration;
