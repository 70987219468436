import { USER_INFO_ACTIONS } from './../../../redux/userInfo/UserInfoActions.type';
import { PaymentFrequencyType, PaymentPlanType } from './PaymentFrequency.type';
import {
  PaymentFrequencyActionsType, PAYMENT_FREQUENCY_ACTIONS
} from './PaymentFrequencyActions.type';

const initialState = {
  paymentPlans: [],
  selectedPaymentPlan: undefined as PaymentPlanType
} as PaymentFrequencyType;

export const paymentFrequencyReducer =
  (state = initialState, action: PaymentFrequencyActionsType): PaymentFrequencyType => {
    switch (action.type) {
      case PAYMENT_FREQUENCY_ACTIONS.SAVE_PAYMENT_FREQUENCY_DATA: {
        return {
          ...state,
          selectedPaymentPlan: action.data
        };
      }

      case PAYMENT_FREQUENCY_ACTIONS.GET_PAYMENT_FREQUENCY_OPTIONS: {
        return {
          ...state,
          paymentPlans: action.data
        };
      }

      case PAYMENT_FREQUENCY_ACTIONS.RESET_PAYMENT_FREQUENCY: {
        return {
          ...state,
          selectedPaymentPlan: undefined,
          paymentPlans: []
        };
      }
      case USER_INFO_ACTIONS.RETRIEVE_USER_INFO: {
        const retrievedPaymentFrequency = action.data.paymentFrequency;
        return {
          ...state,
          ...retrievedPaymentFrequency
        };
      }
      default:
        return state;
    }
  };
