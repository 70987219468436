import styled from 'styled-components';
import { Grid } from '@material-ui/core';

interface FormRowContainerProps {
  $disableVerticalPadding?: boolean;
  $marginBottom?: string;
  $padding?: string;
}

const generatePadding = (props: FormRowContainerProps) =>
  props.$disableVerticalPadding ? `padding: 0;` : `padding: 0.875rem 0;`;
const generatePaddingSM = (props: FormRowContainerProps) =>
  props.$disableVerticalPadding ? `padding: 0 1rem;` : `padding: ${props.$padding ?? '1rem'};`;

export const FormRowContainer = styled(Grid)<FormRowContainerProps>`
  &&& {
    ${generatePadding}

    ${({ theme }) => theme.breakpoints.up('sm')} {
      ${generatePaddingSM}
    }

    ${(props) => props.$marginBottom && `margin-bottom: ${props.$marginBottom};`}
  }
`;
