import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import plLocale from 'date-fns/locale/pl';
import { PlLocalizedUtils } from './countyDateFormat/PlLocalization';

export const localeMap: { [char: string]: object } = {
  en: enLocale,
  pl: plLocale
};

export const localeUtilsMap: { [char: string]: Function } = {
  en: DateFnsUtils,
  pl: PlLocalizedUtils
};

export const localeFormatMap: { [char: string]: string } = {
  en: 'MMMM d, yyyy',
  pl: 'd MMM yyyy'
};

export const localeCancelLabelMap: { [char: string]: string } = {
  en: 'cancel',
  pl: 'anuluj'
};
