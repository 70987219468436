/* eslint-disable complexity */
import { AppActions } from '../../../../../redux/actions/AppActions.type';
import { USER_INFO_ACTIONS } from '../../../redux/userInfo/UserInfoActions.type';
import { BasicInfoDataType } from '../service/BasicInfo.type';
import { BASIC_INFO_ACTIONS } from './BasicInfoActions.type';

const initialState = {
  isBasicInfoLoadingModalVisible: false
} as BasicInfoDataType;

export const basicInfoReducer = (
  state = initialState,
  action: AppActions
): BasicInfoDataType => {
  switch (action.type) {
    case USER_INFO_ACTIONS.FETCH_USER_INFO:
      return { ...state, ...action.basicInfo, isBasicInfoLoadingModalVisible: true, responseError: action.responseError };
    case USER_INFO_ACTIONS.RETRIEVE_USER_INFO:
      return { ...state, ...action.data.basicInfo };
    case BASIC_INFO_ACTIONS.MANUAL_PATH:
      return { ...state, isManual: true };
    case BASIC_INFO_ACTIONS.CLEAR_PATH_SELECTION:
      return { ...state, isManual: false };
    case USER_INFO_ACTIONS.CLEAR_USER_INFO:
    case BASIC_INFO_ACTIONS.CLEAR_ANONYMIZED_STATUS:
      return { ...state, isAnonymized: undefined };
    case USER_INFO_ACTIONS.FINISH_FETCH_USER_INFO:
      return { ...state, canFinishFetchUserData: true };
    case USER_INFO_ACTIONS.CLOSE_BASIC_INFO_LOADING_MODAL:
      return { ...state, isBasicInfoLoadingModalVisible: false };
    case BASIC_INFO_ACTIONS.VEHICLE_SIMPLIFIED:
      return { ...state, isVehicleDataSimplified: action.data };
    case BASIC_INFO_ACTIONS.UTM_DATA:
      return { ...state, utmData: action.data };
    case BASIC_INFO_ACTIONS.START_POLICY_FROM_CEP:
      return { ...state, isStartPolicyFromCep: action.data };
    default:
      return state;
  }
};
