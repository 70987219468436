const getDefaultConsents = () => {
  return [
    {
      'name': 'CONTACT_CONSENT_1',
      'rawName': 'consentMarketingDigital',
      'required': false
    },
    {
      'name': 'CONTACT_CONSENT_2',
      'rawName': 'consentMarketingVoice'
    },
    {
      'name': 'CONTACT_CONSENT_3',
      'rawName': 'consentMarketingDirect'
    },
    {
      'name': 'CONTACT_CONSENT_4',
      'rawName': 'consentCompensaAutomatedProcessing'
    },
    {
      'name': 'CONTACT_CONSENT_5',
      'rawName': 'consentCompensaMarketing'
    }
  ];
};

export default getDefaultConsents;
