import { Typography, useTheme } from '@sky-distribution/react-commons-ui';
import React, { FC } from 'react';
import { ContentBlockProps } from '../content.type';
import { ContentLink, DownloadLinkIcon } from './DownloadLink.styled';

export const DownloadLink: FC<ContentBlockProps> = ({
  block,
}: ContentBlockProps) => {
  const theme = useTheme();

  return (
    <ContentLink href={block.link} target="_blank" rel="noopener noreferrer">
      <DownloadLinkIcon />
      <Typography
        component="span"
        variant={theme.typography.body1}
        color={theme.palette.brand.color}
      >
        {block.content}
      </Typography>
    </ContentLink>
  );
};
