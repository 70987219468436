import styled from 'styled-components';

export const GenericErrorPageHeaderWrapper = styled.div`
  width: 46rem;
  box-sizing: border-box;

  ${({ theme }) => theme.breakpoints.only('xs')} {
    width: 100%;
    margin: 0 auto;
  }
  ${({ theme }) => theme.breakpoints.only('sm')} {
    width: 70%;
    margin: 0 auto;
  }
  ${({ theme }) => theme.breakpoints.only('md')} {
    width: 30rem;
  }
`;

export const StyledHeader = styled.h1`
  color: ${({ theme }) => theme.palette.text.header};

  line-height: 1.5;
  font-size: 3rem;

  ${({ theme }) => theme.breakpoints.only('xs')} {
    padding: 0 1rem;
    font-size: 1.5rem;
  }
  ${({ theme }) => theme.breakpoints.only('sm')} {
    text-align: center;
    padding: 0 1rem;
    font-size: 1.5rem;
  }
  ${({ theme }) => theme.breakpoints.only('md')} {
    font-size: 2rem;
  }
  ${({ theme }) => theme.breakpoints.only('lg')} {
    font-size: 2.25rem;
  }
`;
