import {
  ErrorObject,
  ERROR_HANDLER_ACTIONS,
  ErrorHandlerActionsType,
  ErrorHandlerRedirectAction
} from './ErrorHandlerAction.type';

const initialState = {} as ErrorObject;

export const errorHandlerReducer = (state = initialState, action: ErrorHandlerActionsType): ErrorObject => {
  switch (action.type) {
    case ERROR_HANDLER_ACTIONS.REDIRECT_ERROR_HANDLER:
      return {
        ...state,
        ...(action as ErrorHandlerRedirectAction).error
      };
    case ERROR_HANDLER_ACTIONS.RESET_ERROR_HANDLER:
      return {
        ...state,
        ...{
          message: '',
          code: 0,
          errorCode: 0,
          raw: {}
        }
      };
    default:
      return state;
  }
};
