
export const ConfigurationKey = 'StepsIndicatorConfiguration';

export type StepsIndicatorConfigurationType = {
  [ConfigurationKey]: {
    retrieve: {
      phoneContainer: {
        phoneContainerId: string;
        shouldRedirectToPhone: boolean;
        phoneNumber: string | undefined;
      };
      isSaveOfferButtonShown: boolean;
    };
  };
};

