import {
  beesafeTheme,
  benefiaTheme,
  compensaTheme,
} from '@sky-distribution/react-commons-ui';
import {
  ThemeConfiguration,
  Themed,
} from '@sky-distribution/react-commons-ui/lib/ThemeProvider';
import React, { Suspense, useCallback } from 'react';
import { ReactComponent as CloseIcon } from '../../../assets/x.svg';
import {
  CallBackWindowContainer,
  CallBackWindowContent,
} from '../../../components/CallBackWindow/CallBackWindow.style';
import { useSearchRequest } from '../../../components/helpers/dataSender';
import { getComponents } from '../tenants';
import { Tenant } from '../tenants/Tenants.types';

interface CallBackWidgetComponentProps {
  isCallBackWidgetOpen: boolean;
  handleCloseCallBackWidget: () => void;
  tenantId: Tenant;
}

export const CallBackWidgetComponent = ({
  isCallBackWidgetOpen,
  handleCloseCallBackWidget,
  tenantId,
}: CallBackWidgetComponentProps): JSX.Element => {
  const { goto } = useSearchRequest();
  const { ThankYou, Failed, Base, Header } = getComponents(tenantId);

  const themes = {
    beesafe: beesafeTheme,
    compensa: compensaTheme,
    benefia: benefiaTheme,
  };

  const renderCallBackWidgetContent = useCallback(() => {
    switch (goto) {
      case '':
        return <Base />;
      case '/thanks':
        return (
          <ThankYou handleCloseCallBackWidget={handleCloseCallBackWidget} />
        );
      case '/failed':
        return <Failed />;
      default:
        return <></>;
    }
  }, [goto]);

  const isFulfilled = Boolean(goto && goto !== '');

  const getTheme = useCallback((): ThemeConfiguration => {
    if (!themes[tenantId]) {
      throw new Error(`Theme not found for tenant: ${tenantId}`);
    }
    return themes[tenantId];
  }, [tenantId]);

  return (
    <Themed theme={getTheme()}>
      {isCallBackWidgetOpen && (
        <CallBackWindowContainer isOnWordpress={() => false} isAnimate={true}>
          <Suspense fallback="Ładowanie...">
            <Header isFulfilled={isFulfilled}>
              <CloseIcon onClick={handleCloseCallBackWidget} />
            </Header>
            <CallBackWindowContent>
              {renderCallBackWidgetContent()}
            </CallBackWindowContent>
          </Suspense>
        </CallBackWindowContainer>
      )}
    </Themed>
  );
};
