import styled from 'styled-components';

export const StyledOrTextContainer = styled.div`
  margin: 0 0 0;
  padding: 0 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  
   ${({ theme }) => theme.breakpoints.down('sm')} {
      margin: 2rem 0 2rem;
   }
`;

export const OrLine = styled.hr`
  color: ${({ theme }) => theme.palette.input.border.base};
  width: 1.25rem;
  height: 0.063rem;
  margin: 0;
  padding: 0;
`;

export const StyledOrTextLabel = styled.p`
    text-align: center;
    line-height: 0.1em;
    color: ${({ theme }) => theme.palette.input.text.base};
    span {
      text-transform: uppercase;
      padding: 0 1.25rem;
      font-size: 0.875rem;
    }
`;
