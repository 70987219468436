import { DEBUG_INFO_TYPES } from './DebugInfo.type';
import * as handlers from './DebugInfo.handlers';
import { AppActions } from '../../../../../../redux/actions/AppActions.type';

const initialState = { };

export const debugInfoReducer = (state = initialState, action: AppActions) => {
  switch (action.type) {
    case DEBUG_INFO_TYPES.ADD:
      return handlers.add(state, action);
    case DEBUG_INFO_TYPES.CLEAR:
      return handlers.clear();
    default: 
      return state;
  }
};
