import { BeeTextInputWrapperProps } from '@sky-distribution/react-commons-ui/lib/BeeTextInput/BeeTextInput.types.d';
import { InitialValuesType } from '../../../modules/userdata/containers/BasicInfo/BasicInfo.interfaces';

export enum BeeFieldState {
    INACTIVE = 'inactive',
    HOVER = 'hover',
    FOCUSED = 'focused',
    CHECKED = 'checked',
    ERROR = 'error',
    DISABLED = 'disabled',
}

export type VehiclePlateNumberInputProps = BeeTextInputWrapperProps & {
    setFieldValue: (
        field: string,
        value: string,
        shouldValidate?: boolean
    ) => void;
    setFieldTouched: (
        field: string,
        touched?: boolean,
        shouldValidate?: boolean
    ) => void;
    setIsInputActive?: (isActive: boolean) => void;
    values: InitialValuesType;
    ignoreSpaces?: boolean;
    toUpperCase?: boolean;
    name: string;
}
