import { AppActions } from '../../../../../redux/actions/AppActions.type';
import { USER_INFO_ACTIONS } from '../../../redux/userInfo/UserInfoActions.type';
import {
  CarInfoDataType, CarInfoState
} from '../service/CarInfo.type';
import { CAR_INFO_ACTIONS } from './CarInfoActions.type';

const initialState = {
  selectedVehicle: {} as CarInfoDataType,
  isFromServer: undefined,
  errorDetails: undefined
} as CarInfoState;

/* eslint-disable complexity */
export const carInfoReducer = (
  state = initialState,
  action: AppActions
): CarInfoState => {
  switch (action.type) {
    case CAR_INFO_ACTIONS.CAR_INFO_SAVED:
      return { ...state, selectedVehicle: action.data };
    case USER_INFO_ACTIONS.FETCH_USER_INFO:
      return {
        ...state,
        selectedVehicle: action.data?.car ?? state.selectedVehicle,
        validDataFromServer: action.validDataFromServer,
        errorDetails: action.errorDetails
      };
    case USER_INFO_ACTIONS.RETRIEVE_USER_INFO:
      return { ...state, ...action.data.carInfo };
    case USER_INFO_ACTIONS.CLEAR_USER_INFO:
      return {
        ...state,
        selectedVehicle: {} as CarInfoDataType,
        validDataFromServer: undefined
      };
    case USER_INFO_ACTIONS.CLEAR_SERVER_FLAG:
      return {
        ...state,
        validDataFromServer: undefined
      };

    default:
      return state;
  }
};
