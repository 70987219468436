import { PersonalDataDataType } from '../service/PersonalData.type';
import {
  PERSONAL_DATA_ACTIONS,
  PersonalDataSavedAction,
  PersonalDataClearAction
} from './PersonalDataActions.type';
import { Dispatch } from 'redux';
import { AppActions } from '../../../../../redux/actions/AppActions.type';
import { getBirthDateFromPesel } from '../../../utils/GetBirthDateFromPesel.util';

export const savePersonalData = (personalData: PersonalDataDataType) => {
  return (dispatch: Dispatch<AppActions>) => {
    if (personalData.pesel && !personalData.birthDate) {
      personalData.birthDate = getBirthDateFromPesel(personalData.pesel);
    }
    dispatch(personalDataSavedAction(personalData));
  };
};

export const personalDataSavedAction = (
  personalData: PersonalDataDataType
): PersonalDataSavedAction => {
  return {
    type: PERSONAL_DATA_ACTIONS.DATA_SAVED,
    data: personalData
  };
};

export const clearPersonalData = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(personalDataClearAction());
  };
};

export const personalDataClearAction = (
): PersonalDataClearAction => {
  return {
    type: PERSONAL_DATA_ACTIONS.CLEAR_DATA
  };
};
