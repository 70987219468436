import { Typography, useTheme } from '@sky-distribution/react-commons-ui';
import React, { FC } from 'react';
import {
  ContentParagraph,
  StyledTypography,
} from '../Paragraph/Paragraph.styled';
import { ContentBlockProps } from '../content.type';
import { ContentListItem, ContentUnorderedList } from './UnorderedList.styled';

export const UnorderedList: FC<ContentBlockProps> = ({
  block,
}: ContentBlockProps) => {
  const theme = useTheme();

  return (
    <>
      <ContentParagraph>
        <StyledTypography
          className="text"
          variant={theme.typography.body1}
          color={theme.palette.text.paragraph.secondary}
        >
          {block.content}
        </StyledTypography>
      </ContentParagraph>
      <ContentUnorderedList>
        {block?.items?.map((item, idx) => (
          <ContentListItem key={`li-${idx}`}>
            <Typography
              component="span"
              variant={theme.typography.body1}
              color={theme.palette.text.paragraph.secondary}
            >
              {item}
            </Typography>
          </ContentListItem>
        ))}
      </ContentUnorderedList>
    </>
  );
};
