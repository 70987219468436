import axios from 'axios';
import qs from 'qs';
import React, { createContext, useContext, useState } from 'react';
import { request } from '../../api/request.config';

export type SearchRequestContextType = {
  goto: string;
  loading: boolean;
  sendNumberToApi: (phoneNumber: string) => void;
};

interface SearchRequestProviderType {
  children: JSX.Element;
}

const SearchRequestContext = createContext<SearchRequestContextType | null>(
  null,
);

const SearchRequestProvider = ({
  children,
}: SearchRequestProviderType): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [goto, setGoto] = useState('');
  const sendNumberToApi = (phoneNumber: string): void => {
    const requestBody = {
      wsName: 'wsImportRecord',
      CampaignId: 10,
      PhoneField: 'Numer telefonu 1',
      PhoneNumber: phoneNumber,
      PhoneCallDateField: 'calldate',
    };

    setLoading(true);
    axios
      .post(request.apiURL, qs.stringify(requestBody), request.config)
      .then(() => {
        setLoading(false);
        setGoto('/thanks');
      })
      .catch(() => {
        setGoto('/failed');
      });
  };

  return (
    <SearchRequestContext.Provider value={{ goto, loading, sendNumberToApi }}>
      {children}
    </SearchRequestContext.Provider>
  );
};

export const useSearchRequest = (): SearchRequestContextType =>
  useContext(SearchRequestContext) as SearchRequestContextType;

export default SearchRequestProvider;
