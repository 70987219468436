import React from 'react';
import { Picker } from '../../../BeeDatePicker.const';
import { usePickerContext } from '../../../contexts';
import { convertToTwoDigitsString } from '../../../helpers/convertToTwoDigitsString';
import { isDatePickerFormFilled } from '../../../helpers/isDatePickerFormFilled';
import { MONTHS, MONTH_LENGTH } from '../../RenderPicker';
import { Placeholder } from '../DateForm.const';
import { StyledInput } from '../DateForm.styled';
import { DateInputProps } from '../DateForm.types';
import { inputFocus } from '../DateForm.utils';

export const MonthInput: React.FC<DateInputProps> = ({
  isVisible,
  setVisiblePicker,
}) => {
  const {
    state: { date, dayRef, monthRef, yearRef, readOnly },
    dispatch,
  } = usePickerContext();

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value, maxLength } = event.target;

      if (Number(value) > MONTHS) {
        return;
      }

      dispatch({
        type: 'SET_DATE',
        payload: {
          month: value.replace(/\D/g, ''),
        },
      });

      if (value.length !== maxLength) {
        return;
      }

      if (
        isDatePickerFormFilled({
          day: dayRef.current?.value,
          month: value,
          year: yearRef.current?.value,
        })
      ) {
        monthRef.current?.blur();
        dispatch({ type: 'SET_VISIBLE_PICKER', payload: null });
      } else {
        inputFocus(dayRef, monthRef, yearRef);
      }
    },
    [dayRef, monthRef, yearRef, dispatch],
  );

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === 'Backspace' && event.currentTarget.value.length < 1) {
        dayRef.current?.focus();
      }
    },
    [dayRef],
  );

  const handleBlur = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const { value } = event.target;
      dispatch({
        type: 'SET_DATE',
        payload: {
          month: value !== '' ? convertToTwoDigitsString(value) : '',
        },
      });
    },
    [dispatch],
  );

  return (
    <StyledInput
      autoComplete={'off'}
      name={Picker.MONTH}
      placeholder={isVisible ? Placeholder.MONTH : undefined}
      value={date.month}
      maxLength={MONTH_LENGTH}
      ref={monthRef}
      readOnly={readOnly}
      disabled={readOnly}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      onClick={setVisiblePicker}
      onFocus={setVisiblePicker}
    />
  );
};
