import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { thunk } from 'redux-thunk';
import { offline } from '@redux-offline/redux-offline';
import defaultConfig from '@redux-offline/redux-offline/lib/defaults';
import rootReducer, { RootState } from '../reducers/RootReducer';
import { asyncSessionStorage } from 'redux-persist/storages';
import { createReduxEnhancer } from '@sentry/react';
import { actionTransformerActions, transformedState } from './utils/sentry';

export type AppState = ReturnType<typeof rootReducer>;

const compose = composeWithDevTools({});

export const sentryReduxEnhancer = createReduxEnhancer({
  stateTransformer: (state: RootState) => {
    return transformedState(state);
  },

  actionTransformer: action => {
    return actionTransformerActions(action);
  }
});

const store =
  createStore(
    rootReducer,
    compose(applyMiddleware(thunk), sentryReduxEnhancer,
      offline({
        ...defaultConfig,
        persistOptions: {
          storage: asyncSessionStorage
        }
      }))
  );

export default store;
