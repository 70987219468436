import { DebugInfoObject } from '../utils/DebugInfoUtil.type';
import { DEBUG_INFO_TYPES } from './DebugInfo.type';

interface Payload extends ActionType {
  payload: DebugInfoObject;
}
export interface ActionType {
  type: string;
}
// exception for RequestInterceptor.service.ts
export const add = (payload: DebugInfoObject): Payload => ({
  type: DEBUG_INFO_TYPES.ADD,
  payload
});

export const clear = (): ActionType => ({
  type: DEBUG_INFO_TYPES.CLEAR
});
