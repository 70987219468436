import { MEDIA_QUERY } from '@sky-distribution/react-commons-ui';
import styled from 'styled-components';
import { ReactComponent as BackIcon } from '../../assets/chevron-left.svg';
import { StyledProps } from './CallBackWindow.types';

export const CallBackWindowContainer = styled.div`
  animation-timing-function: ease-in-out;
  background-color: ${({ theme }) =>
    theme.palette.commons.background.container};
  border-radius: 1rem;
  box-shadow: ${({ theme }) => theme.shadow.modal};
  bottom: ${({ isOnWordpress }) => (isOnWordpress() ? "5.5rem" : "1rem")};
  display: flex;
  flex-direction: column;
  opacity: ${(p: StyledProps) => (p.isAnimate ? "1" : "0")};
  overflow: hidden;
  position: fixed;

  right: 1rem;
  transition-duration: 0.2s;
  transition-property: opacity;
  width: 24.1875rem;
  will-change: opacity;
  z-index: 9999;

  ${MEDIA_QUERY.below("sm")} {
    -webkit-overflow-scrolling: touch;
    border: 0;
    bottom: 0;
    box-shadow: none;
    right: 0;
    height: 100%;
    border-radius: 0;
    overflow-y: auto;
    position: fixed;
    width: 100vw;
  }
`;

export const CallBackWindowContent = styled.div`
  box-sizing: border-box;
  position: relative;
`;

export const StyledBackIcon = styled(BackIcon)`
  box-sizing: unset;
  fill: yellow;
  padding: 0.25rem;
`;
