import { FC } from 'react';
import { SpinnerType } from '../../../../modules/userdata/components/spinners/spinner.type';

export const ConfigurationKey = 'WaitingWindowLoaderConfiguration';

export type WaitingWindowLoaderConfigurationType = {
    [ConfigurationKey]: {
        loader: {
            spinner: FC<SpinnerType>;
          };
    };
}
