import React, { FC, useContext } from 'react';
import { AppHeaderContainer, FromText, AppHeaderLogo } from './AppHeader.style';
import Translate from '../../../../translations/translate/Translate.component';
import { TranslationContext } from '../../../../translations/translate/globalTranslate/TranslationProvider';
import { useTenant } from '../../../../config/tenants/Tenants.provider';

const AppHeader: FC = () => {
  const { t } = useContext(TranslationContext);
  const { firstLogo, secondLogo } = useTenant();
  const SecondLogo = secondLogo.component;
  
  return (
    <AppHeaderContainer maxWidth='xl' >
      {!!firstLogo.display && <a href={firstLogo.href} target="blank">
        <AppHeaderLogo $width={firstLogo.width} src={firstLogo.url} alt={t('LOGO_ALT')} />
      </a>}
      {!!secondLogo.display && <FromText>
        <Translate keyName='FROM' />
      </FromText>}
      {!!secondLogo.display && <SecondLogo />}
    </AppHeaderContainer>
  );
};

export default AppHeader;
