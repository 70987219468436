import { ModalProvider } from '../providers/ModalProvider/ModalProvider';
import React from 'react';
import { RoutesComponent } from './Routes.component';
import { RenewalProvider } from '../providers/RenewalProvider/RenewalProvider';

const RoutesComponentWrapped = () => {
  return (
    <ModalProvider>
      <RenewalProvider>
        <RoutesComponent/>
      </RenewalProvider>
    </ModalProvider>
  );
};

export default RoutesComponentWrapped;
