import { AppActions } from '../../../../../redux/actions/AppActions.type';
import { CO_OWNERS_ACTIONS } from '../../../containers/CoOwnerAddressInfo/redux/CoOwnerAddressInfoActions.type';
import { USER_INFO_ACTIONS } from '../../../redux/userInfo/UserInfoActions.type';
import { PersonalDataDataType } from '../service/PersonalData.type';
import { PERSONAL_DATA_ACTIONS } from './PersonalDataActions.type';

const initialState = {} as PersonalDataDataType;

export const personalDataReducer = (
  state = initialState,
  action: AppActions
): PersonalDataDataType => {
  switch (action.type) {
    case USER_INFO_ACTIONS.FETCH_USER_INFO:
      return { ...state, ...action.personInfo };
    case USER_INFO_ACTIONS.RETRIEVE_USER_INFO:
      return { ...state, ...action.data.personalData };
    case CO_OWNERS_ACTIONS.DATA_SAVED:
      return {
        ...state,
        coOwners: action.data
      };
    case PERSONAL_DATA_ACTIONS.DATA_SAVED:
      return { ...state, ...action.data };
    case PERSONAL_DATA_ACTIONS.CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
};
