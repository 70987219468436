import { AxiosRequestConfig } from 'axios';
import { UriBuilder } from '../../../../../common/http/UriBuilder';
import { onboardingRequestService } from '../../../../../http/RequestService.http';
import { PaymentPlanTypeRaw } from '../redux/PaymentFrequency.type';
import { sortPaymentPlans } from './PaymentFrequency.utils';

export interface PaymentFrequencyResponse {
  paymentPlans: PaymentPlanTypeRaw[];
}
export interface PaymentFrequencySession {
  quoteID?: string;
  sessionUUID?: string;
  isRenewal?: boolean;
  agentToken?: string;
}

export const fetchPaymentFrequencyOptions = (data : PaymentFrequencySession, headers: AxiosRequestConfig['headers']): Promise<PaymentFrequencyResponse> => {
  const url = new UriBuilder().all('submission').all('retrieve-payment-plans').build();
  return onboardingRequestService.post<PaymentFrequencyResponse>(url, data, headers);
};

export const highlightSingleInstalmentPayment = (payments: PaymentPlanTypeRaw[]): PaymentPlanTypeRaw[] => {
  if (payments.length > 1 ) {
    const sorted = [...payments].sort(sortPaymentPlans);
    const cheapPrize = sorted[0].total.amount;
    const expensivePrize = sorted[sorted.length - 1].total.amount;

    const savings = 100 * (expensivePrize - cheapPrize) / expensivePrize; // eslint-disable-line no-magic-numbers
    sorted[0].isHighlighted = true;
    sorted[0].savings = Math.floor(savings);
    return sorted;
  }
  return payments;
};

export interface CarDataRequestParams {
    brand?: string;
    model?: string;
    year?: string;
    fuelType?: string;
    engineCapacity?: string;
    bodyType?: string;
    doors?: string;
    version?: string;
}
