import { AppActions } from '../../../../../../../redux/actions/AppActions.type';
import { USER_INFO_ACTIONS } from '../../../../../redux/userInfo/UserInfoActions.type';
import type { CustomerConsents } from '../Consent';
import { CONSENTS_ACTIONS } from './ConsentsActions.type';

const initialState = {} as CustomerConsents;

export const consentsReducer = (
  state = initialState,
  action: AppActions
): CustomerConsents => {
  switch (action.type) {
    case CONSENTS_ACTIONS.DATA_SAVED:
      return { ...state, ...action.data };
    case USER_INFO_ACTIONS.RETRIEVE_USER_INFO:
      return { ...state, ...action.data.customerConsents };
    case CONSENTS_ACTIONS.CLEAR_CUSTOMER_CONSENTS:
      return initialState;
    default:
      return state;
  }
};
