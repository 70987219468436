/* eslint-disable max-lines, camelcase */
export const plMessages = {
  BASIC_INFO_HEADER_B: 'Kalkulator OC AC',
  BASIC_INFO_HEADER_C: 'Oblicz składkę w 60 sek i kup online',
  MANUAL_INPUT_DESCRIPTION: 'Nie znasz numeru rejestracyjnego?',
  MANUAL_INPUT_BUTTON: 'Wybierz pojazd ręcznie',
  BIRTH_DATE_PLACEHOLDER: 'Data urodzenia właściciela',
  CAR_NOT_FOUND_HEADER: 'Nie znaleźliśmy Twojego pojazdu',
  CAR_NOT_FOUND_INFO: 'Sprawdź czy dane są poprawne lub',
  TRY_AGAIN: 'Spróbuj ponownie'
};
