import React, { FC } from 'react';
import { TitleType, CaptionType } from './ToggleSelectOptions.type';
import {
  StyledFlexWrapper,
  StyledTitleBold,
  StyledTitleNormal,
  StyledCaptionNormal,
  StyledCaptionBold,
} from './ToggleSelectOptions.style';

export const Title: FC<TitleType> = ({ bolt, normal }: TitleType) => {
  return (
    <StyledFlexWrapper>
      {!!bolt && <StyledTitleBold>{bolt}</StyledTitleBold>}
      {!!normal && <StyledTitleNormal>{normal}</StyledTitleNormal>}
    </StyledFlexWrapper>
  );
};

export const Caption: FC<CaptionType> = ({ normal, bolt }: CaptionType) => {
  return (
    <StyledFlexWrapper>
      {!!normal && <StyledCaptionNormal>{normal}</StyledCaptionNormal>}
      {!!bolt && <StyledCaptionBold>{bolt}</StyledCaptionBold>}
    </StyledFlexWrapper>
  );
};

export const options = [
  {
    title: <Title bolt="AC" normal="Partner" />,
    caption: <Caption normal="Warsztaty partnerskie" />,
    label: 'Warsztaty partnerskie',
    price: 800,
    value: 1,
  },
  {
    title: <Title bolt="Standard" />,
    caption: <Caption normal="Holowanie" bolt="200 km" />,
    label: 'Holowanie 200 km',
    price: 1120,
    value: 2,
  },
];
