import { AppConfig } from "../common/types";
import { checkStringifiedBoolean } from "../common/utils/string";

const isLocalEnvironment = window.location.hostname === 'localhost';

const envSource = isLocalEnvironment ? process.env : window;

export const appConfig: AppConfig = {
    featureFlags: {
        showNewCookieModal: checkStringifiedBoolean(envSource.REACT_APP_SHOW_NEW_COOKIE_MODAL)
    }
}
