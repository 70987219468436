import { Typography, useTheme } from '@sky-distribution/react-commons-ui';
import React, { FC } from 'react';
import { ItemDetailsModalProps } from '../ItemDetailsModal.types';
import {
  OfferModalHeader,
  StyledBackIcon,
  TitleTypography,
} from './ItemDetailsModalHeader.styled';

export const ItemDetailsModalHeader: FC<ItemDetailsModalProps> = ({
  details,
  showBackIcon,
  handleOnClick,
}: ItemDetailsModalProps) => {
  const theme = useTheme();

  if (!details) {
    return null;
  }

  return (
    <OfferModalHeader>
      {showBackIcon && <StyledBackIcon onClick={handleOnClick} />}
      <TitleTypography
        component="span"
        variant={theme.typography.subtitle1}
        color={theme.palette.brand.color}
      >
        {details.name}
      </TitleTypography>
      {details.subtitle && (
        <Typography
          component="span"
          variant={theme.typography.h4}
          color={theme.palette.text.paragraph.primary}
        >
          {details.subtitle}
        </Typography>
      )}
    </OfferModalHeader>
  );
};
