import { DeclarativeData } from '@sky-distribution/mf-declarative-path';

export const DECLARATIVE_DATA_ACTIONS = {
  DATA_SAVED: 'DECLARATIVE_DATA/DATA_SAVED',
  CLEAR_DATA: 'DECLARATIVE_DATA/DATA_CLEAR'
} as const;

export interface DeclarativeDataSavedAction {
  type: typeof DECLARATIVE_DATA_ACTIONS.DATA_SAVED;
  data: DeclarativeData;
}

export interface DeclarativeDataClearAction {
  type: typeof DECLARATIVE_DATA_ACTIONS.CLEAR_DATA;
}

export type DeclarativeDataActionsType = DeclarativeDataSavedAction | DeclarativeDataClearAction;
