import styled from 'styled-components';

export const ContentPoints = styled.div`
  .points {
    background-color: ${(props) => props.theme.palette.brand.color};
    border-radius: 1.875rem;
    color: ${(props) => props.theme.palette.text.paragraph.secondary};
    font-size: 0.875rem;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    letter-spacing: normal;
    line-height: normal;
    margin: 0 0 0 0.25rem;
    padding: 0.5rem 0.563rem 0.563rem 0.625rem;
    text-align: center;
  }
`;
