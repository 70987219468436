import React from 'react';
import { ReactComponent as Phone } from '../ExportedComponents/components/tenants/beesafe/components/Base/assets/phone.svg';
import { Description } from '../components/Description';
import { FormSection } from '../components/FormSection';
import { HeaderWithIcon } from '../components/HeaderWithIcon/HeaderWithIcon.component';

export type CloseComponentType = {
  children?: JSX.Element;
};

export function FailedPage(): JSX.Element {
  return (
    <>
      <HeaderWithIcon>
        <Phone width="180" />
        <h3>Coś poszło nie tak...</h3>
      </HeaderWithIcon>
      <FormSection>
        <Description>
          Wróć aby spróbować ponownie lub skontaktuj się z nami
          bezpośrednio:&nbsp;
          <strong>500 965 626</strong>
        </Description>
      </FormSection>
    </>
  );
}
