import React, { ReactNode } from 'react';
import { FormContainer } from './FormSection.style';

export interface DescriptionProps {
  children: ReactNode;
}

export function FormSection({ children }: DescriptionProps): JSX.Element {
  return <FormContainer>{children}</FormContainer>;
}
