import React, { FC } from 'react';
import { DownloadLink } from '../DownloadLink/DownloadLink';
import { Paragraph } from '../Paragraph/Paragraph';
import { Partners } from '../Partners/Partners';
import { Points } from '../Points/Points';
import { UnorderedList } from '../UnorderedList/UnorderedList';
import { ContentBlockProps } from '../content.type';

export const ContentBlock: FC<ContentBlockProps> = (
  props: ContentBlockProps,
) => {
  const { block, data, color } = props;

  switch (block.type) {
    case 'p': {
      return <Paragraph block={block} color={color} />;
    }
    case 'ul': {
      return <UnorderedList block={block} />;
    }
    case 'a': {
      return <DownloadLink block={block} />;
    }
    case 'points': {
      return <Points block={block} data={data} />;
    }
    case 'partners': {
      return <Partners />;
    }
    default: {
      return null;
    }
  }
};
