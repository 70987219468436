const initialState = {} as ActivityState;

export const activityReducer = (state = initialState): ActivityState => {
  return {
    ...state,
    lastActivity: new Date()
  };
};

export interface ActivityState {
  lastActivity: Date;
}
