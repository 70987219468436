export enum TenantsEnum {
  Beesafe = 'beesafe',
  Benefia = 'benefia',
  Compensa = 'compensa',
}

export type Tenant = `${TenantsEnum}`;

export interface ThankYouProps {
  handleCloseCallBackWidget: () => void;
}
