import { BreakpointsKeys, Breakpoints } from './breakpoints.type';

const unit = 'px';
const step = 5;
const persent = 100;

const breakpoints: Breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1408
  },
  up: function(key: BreakpointsKeys) : string {
    const min = typeof key === 'number' ? key : this.values[key];
    return `@media (min-width:${min}${unit})`;
  },
  down: function(key: BreakpointsKeys) : string {
    const max = typeof key === 'number' ? key : this.values[key];
    return `@media (max-width:${max - step / persent}${unit})`;
  },
  between: function(start: BreakpointsKeys, end: BreakpointsKeys) : string {
    const min = typeof start === 'number' ? start : this.values[start];
    const max = typeof end === 'number' ? end : this.values[end];
    return (
      `@media (min-width:${min}${unit}) and ` +
      `(max-width:${
        max - step / persent
      }${unit})`
    );
  },
  only: function(key: BreakpointsKeys) : string {
    const keys = Object.keys(this.values) as BreakpointsKeys[];
    if (keys.indexOf(key) + 1 < keys.length) {
      return this.between(key, keys[keys.indexOf(key) + 1]);
    }
    return this.up(key);
  }
};

export default breakpoints;
