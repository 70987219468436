import styled from 'styled-components';

export const StyledContainer = styled.div`
  background: ${({ theme }) => theme.palette.commons.background.container};

  .call-now-section {
    padding-top: 1rem;
    color: ${({ theme }) => theme.palette.text.paragraph.primary};

    svg {
      path,
      use {
        fill: ${({ theme }) => theme.palette.brand.color};
      }
    }
  }
`;

export const FormInfo = styled.p`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.palette.text.paragraph.secondary};
  margin: 0 0 1rem 0;
`;

export const ShadowContainer = styled.div`
  box-shadow: ${({ theme }) => theme.shadow.contentBox};
  padding: 1rem;
  border-radius: 1rem;
  margin: -3rem 1.5rem 0 1.5rem;
  background: ${({ theme }) => theme.palette.commons.background.container};
`;

export const StyledHeader = styled.div`
  border-radius: 0 0 1rem 1rem;
  background: ${({ theme }) => theme.palette.brand.color};
  padding: 2.5rem 1.5rem 5rem 1.5rem;

  h3,
  h4 {
    color: ${({ theme }) => theme.palette.commons.background.container};
    margin: 0;
  }

  h4 {
    margin-top: 0.5rem;
  }
`;
