import { AppActions } from '../../../../redux/actions/AppActions.type';
import { USER_INFO_ACTIONS } from '../userInfo/UserInfoActions.type';
import { SessionState, SESSION_ACTIONS } from './Session.type';

const initialState = {} as SessionState;

export const sessionReducer =
  // eslint-disable-next-line complexity
  (state = initialState, action: AppActions): SessionState => {
    switch (action.type) {
      case SESSION_ACTIONS.CREATE_SUBMISSION: {
        const data = action.data;
        return { ...state, quoteUuid: data.quoteUuid, sessionUuid: data.sessionUuid, quoteId: data.quoteId };
      }
      case USER_INFO_ACTIONS.RETRIEVE_USER_INFO:
        return { ...state, ...action.data.session };
      case USER_INFO_ACTIONS.CLEAR_USER_INFO:
        return { ...state, quoteUuid: undefined, sessionUuid: undefined, quoteId: undefined };
      case SESSION_ACTIONS.UPDATE_SUBMISSION: {
        const updateData = action.data;
        return { ...state, ...updateData };
      }
      case SESSION_ACTIONS.MARK_AS_RENEWAL:
        return {
          ...state,
          isRenewal: action.data
        };
      case SESSION_ACTIONS.HIDE_NAVIGATION: {
        return { ...state, isNavigationHidden: true };
      }
      case SESSION_ACTIONS.SHOW_NAVIGATION: {
        return { ...state, isNavigationHidden: false };
      }
      case SESSION_ACTIONS.QUOTE_SUBMISSION:
      case SESSION_ACTIONS.SELECT_QUOTE:
      default:
        return state;
    }
  };
