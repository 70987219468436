import { DeclarativeData } from '@sky-distribution/mf-declarative-path';
import { AppActions } from '../../../../../redux/actions/AppActions.type';
import { USER_INFO_ACTIONS } from '../../../redux/userInfo/UserInfoActions.type';
import { DECLARATIVE_DATA_ACTIONS } from './DeclarativeDataActions.type';

export interface DeclarativeDataState{
  data: DeclarativeData | null;
}

const initialState = {
  data: null
} as DeclarativeDataState;

export const declarativeDataReducer = (
  state = initialState,
  action: AppActions
): DeclarativeDataState => {
  switch (action.type) {
    case DECLARATIVE_DATA_ACTIONS.DATA_SAVED:
      return { ...state, data: action.data };
    case DECLARATIVE_DATA_ACTIONS.CLEAR_DATA:
      return initialState;
    case USER_INFO_ACTIONS.RETRIEVE_USER_INFO:
      return { ...state, ...action.data.declarativeData };
    default:
      return state;
  }
};
