
/* eslint-disable max-lines, camelcase */
export const enMessages = {
  //Contact agreements
  CONSENTS_REQUIRED: 'First two agreements are required to finish this step.',
  ACCEPT_ALL_CONSENTS: 'Accept all consents',
  SHOW_CONSENTS: 'Show consents',

  CONTACT_CONSENT_1:
    'I agree to receive from BeeSafe spółka z o.o. commercial and marketing information using telecommunication'
    + ' devices and so-called automatic calling systems, choosing as a form of contact electronic messages'
    + ' (e-mail, SMS/MMS, internet services, including social networks).',
  CONTACT_CONSENT_2:
    'I agree to receive from BeeSafe spółka z o.o. commercial and marketing information using telecommunication devices'
    + ' and so-called automatic calling systems, choosing as a form of contact voice'
    + ' calls (telephone conversation, IVR voice messages).',
  CONTACT_CONSENT_3:
    'I agree that BeeSafe spółka z o.o. will make decisions towards me based solely on automated processing,'
    + ' including through profiling, of my personal data such as: first name, last name, contact details,'
    + ' data about the place of residence, date of birth, information about types of insurance, for its own'
    + ' marketing purposes and in order to present an appropriate offer.',
  CONTACT_CONSENT_4:
    'I agree that Compensa Insurance Company S. A. Vienna Insurance Group has made decisions towards'
    + ' me based solely on automated processing, including through profiling, of my personal data such'
    + ' as: first name, last name, contact details, residence, gender, date of birth, information on'
    + ' insurance types and insurance contract history (including information about insurance products'
    + ' I have used in the past), including to the extent that these data may be protected by insurance,'
    + ' for its own marketing purposes and in order to present an appropriate offer, during the duration'
    + ' of the insurance contract.',
  CONTACT_CONSENT_5:
    'I agree to receive from Compensa Insurance Company S. A. Vienna Insurance Group and compensa towarzystwo'
    + ' ubezpieczeń na życie S. A. Vienna Insurance Group with their registered offices in Warsaw commercial'
    + ' and marketing information using telecommunication devices and so-called automatic calling systems, '
    + 'choosing as a form of contact: electronic messages (e-mail, SMS/MMS, internet services, including social networks).',
  CONTACT_CONSENT_6:
    'I have read and accept the terms and conditions for the provision of electronic services and the attached'
    + ' terms and conditions of the loyalty programme.',
  CONTACT_CONSENT_6_REQUIRED_TEXT: '',
  DOCUMENT_LINK_CONSENT_6_TEXT_1: '',
  CONTACT_CONSENT_7: '',

  DETAILS: 'Details'
};
