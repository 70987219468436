import styled from 'styled-components';
import { CallbackIconStyles } from './tenants/tenant.type';
import { ReactComponent as PhoneIcon } from './tenants/beesafe/assets/phone.svg';

export const PhoneImage = styled(PhoneIcon)<{$styles: CallbackIconStyles}>`
  width: 0.75rem;
  height: 0.75rem;
  use {
    fill: ${({ theme }) => theme.palette.brand.color};
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: ${p => p.$styles.phoneImage.smWidth};
    height: ${p => p.$styles.phoneImage.smHeight};
  }
`;

export const Wrapper = styled.div<{$styles: CallbackIconStyles}>`
  position: relative;
  margin-left: 1.5rem;
  display: none;
  cursor: pointer;
  z-index: 998;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: ${p => p.$styles.wrapperDisplay};
  }
`;

export const CallbackCaptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-left: 1rem;
  font-family: ${({ theme }) => theme.typography.fontFamily};
`;

export const CallbackTitle = styled.p`
  margin: 0;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.text.header};
`;

export const CallbackPhoneDescription = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.palette.text.paragraph};
`;

export const CallBackPhoneNumber = styled.span`
  color: ${({ theme }) => theme.palette.text.link};
  font-weight: bold;
`;

export const CallbackWrapper = styled.div<{$styles: CallbackIconStyles}>`
  position: relative;
  width: 3.0625rem;
  height: 3.5rem;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 5.4375rem;
    height: ${p => p.$styles.callbackImageContainer.smHeight}
  }
`;

export const CallbackContainer = styled.div<{$styles: CallbackIconStyles}>`
  display: inline-block;
  position: relative;
  width: 3.0625rem;
  height: 3.5rem;
  box-sizing: border-box;
  clip-path: ${p => p.$styles.clipPath};
  background: ${p => p.$styles.callBackContainer.background};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 5.4375rem;
    height: ${p => p.$styles.callbackImageContainer.smHeight}
  }
`;

export const CallbackImage = styled.img<{$styles: CallbackIconStyles}>`
  display: block;
  top: 0.0625rem;
  left: 0.0625rem;
  position: absolute;
  width: 2.9375rem;
  height: 3.375rem;
  clip-path: ${p => p.$styles.clipPath};
  border-radius: ${p => p.$styles.callbackImage.borderRadius};
  
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: ${p => p.$styles.callbackImage.smWidth};
    height: ${p => p.$styles.callbackImage.smHeight};
  }
`;

export const PhoneContainer = styled.div<{$styles: CallbackIconStyles}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: -0.5rem;
  width: 1.3125rem;
  height: 1.5rem;
  z-index: 5;
  background: ${({ theme }) => theme.palette.commons.background.infotip};
  clip-path: ${p => p.$styles.clipPath};
  border-radius: ${p => p.$styles.phoneContainer.borderRadius};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 2.375rem;
    height: ${p => p.$styles.phoneContainer.smHeight};
    right: -1.125rem;
  }
`;
