import React, { FC } from 'react';
import Translate from '../../../../../../translations/translate/Translate.component';
import type { BasicInfoConfigurationType } from '../../tenants/tenant.type';
import {
  StartTitleWrapper,
  StyledSubtitleParagraph,
  StyledTitleH1,
  StyledTitleParagraph
} from './StartTitle.style';

type StartTitleProps =
  BasicInfoConfigurationType['BasicInfoConfiguration']['startTitle'];
  
export const StartTitle: FC<StartTitleProps> = ({
  displaySubtitle,
  displayTitle,
  style
}: StartTitleProps) => {
  return (
    <StartTitleWrapper customStyle={style}>
      {displayTitle && (
        <StyledTitleParagraph>
          <Translate keyName="BASIC_INFO_HEADER_A" />
        </StyledTitleParagraph>
      )}
      &nbsp;
      <StyledTitleH1>
        <Translate keyName="BASIC_INFO_HEADER_B" />
      </StyledTitleH1>
      {displaySubtitle && (
        <StyledSubtitleParagraph customStyle={style}>
          <Translate keyName="BASIC_INFO_HEADER_C" />
        </StyledSubtitleParagraph>
      )}
    </StartTitleWrapper>
  );
};
