import { Button } from '@sky-distribution/react-commons-ui';
import styled from 'styled-components';
import { ShortPathButtonStyles } from '../tenants/tenant.type';

type StyledVehicleInfoFormButtonProps = {
    $styles?: ShortPathButtonStyles;
};

export const StyledVehicleInfoFormButton = styled(Button)<StyledVehicleInfoFormButtonProps>`
    height: ${({ $styles }) => $styles?.height ?? 'none'};
`;
