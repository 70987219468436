import { Picker } from '../../BeeDatePicker.const';
import { DateFromPickerType } from '../../BeeDatePicker.types';
import { RadioSlideIndex } from '../../components/RenderPicker/components/RadioSlide/RadioSlide.const';
import { convertToTwoDigitsString } from '../../helpers/convertToTwoDigitsString';

export function convertPickerDateToString(date: DateFromPickerType): string {
  if (!date.day && !date.month && !date.year) {
    return '';
  }
  return `${date.year}-${date.month}-${date.day}`;
}

export function getPickerDate(retrieveDate?: string): DateFromPickerType {
  if (retrieveDate && Date.parse(retrieveDate)) {
    return convertStringToPickerDate(retrieveDate);
  } else {
    return {
      day: '',
      month: '',
      year: '',
    };
  }
}

export function convertStringToPickerDate(
  stringDate: string,
): DateFromPickerType {
  const date = new Date(stringDate);

  const day = convertToTwoDigitsString(date.getDate());
  const month = convertToTwoDigitsString(date.getMonth() + 1);
  const year = date.getFullYear().toString();

  return { day, month, year };
}

export function getMonthIndex(month: string): number {
  return Number(month) - 1;
}

export function getRadioSlideIndex(picker: keyof typeof Picker | null): number {
  switch (picker) {
    case Picker.MONTH:
      return RadioSlideIndex.MONTH;
    case Picker.DAY:
      return RadioSlideIndex.DAY;
    default:
      return RadioSlideIndex.YEAR;
  }
}
