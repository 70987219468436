import { combineReducers } from 'redux';
import { aggregatorReducer } from '../components/aggregator/redux/Aggregator.reducer';
import { grantedDiscountReducer } from '../components/AppDiscount/redux/discount.reducer';
import { vasesReducer } from '../components/vases/redux/Vases.reducer';
import { addressReducer } from '../containers/AddressInfo/redux/AddressInfo.reducer';
import { basicInfoReducer } from '../containers/BasicInfo/redux/BasicInfo.reducer';
import { carDetailsReducer } from '../containers/CarDetails/redux/CarDetails.reducer';
import { carInfoReducer } from '../containers/CarInfo/redux/CarInfo.reducer';
import { consentsReducer } from '../containers/ContactInfo/components/ConsentForm/redux/Consents.reducer';
import { declarativeDataReducer } from '../containers/DeclarativeData/redux/DeclarativeData.reducer';
import { mfPolicySummaryReducer } from '../containers/MF-PolicySummary/redux/MfPolicySummary.reducer';
import { offerInfoReducer } from '../containers/OfferInfo/redux/OfferInfo.reducer';
import { paymentInfoReducer } from '../containers/PaymentInfo/redux/PaymentInfo.reducer';
import { paymentFrequencyReducer } from '../containers/PaymentPlan/redux/PaymentFrequency.reducer';
import { personalDataReducer } from '../containers/PersonalData/redux/PersonalData.reducer';
import { policyDateReducer } from '../containers/PolicyStartDate/redux/PolicyDate.reducer';
import { quoteReducer } from './quote/Quote.reducer';
import { sessionReducer } from './session/Session.reducer';
import { transactionReducer } from './transaction/Transaction.reducer';

export const userDataRootReducer = combineReducers({
  aggregator: aggregatorReducer,
  basicInfo: basicInfoReducer,
  personalData: personalDataReducer,
  declarativeData: declarativeDataReducer,
  carInfo: carInfoReducer,
  carDetails: carDetailsReducer,
  offerInfo: offerInfoReducer,
  vases: vasesReducer,
  session: sessionReducer,
  addressData: addressReducer,
  policyDate: policyDateReducer,
  customerConsents: consentsReducer,
  paymentInfo: paymentInfoReducer,
  paymentFrequency: paymentFrequencyReducer,
  quote: quoteReducer,
  transaction: transactionReducer,
  mfPolicySummary: mfPolicySummaryReducer,
  discount: grantedDiscountReducer
});


export type UserDataRootReducer = ReturnType<typeof userDataRootReducer>;
