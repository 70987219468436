import styled from 'styled-components';

export const StyledAppContainer = styled.div<{isCookieBarVisible: boolean; cookieBarHeight: number}>`
    position: relative;

    .view-routes {
        min-height: 100vh;
        max-width: 100vw;
    }

    .MuiIconButton-root:hover, .MuiIconButton-colorSecondary:hover {
      background-color:  ${({ theme }) => theme.palette.commons.tonal.hover};
    }

    ${(props) => props.isCookieBarVisible && `
    .MuiDrawer-paperAnchorDockedBottom {
      bottom: ${props.cookieBarHeight}px;
    }
    `}}

    .scrollbar-container {
      overflow: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
        .ps__thumb-y {
            width: .5rem;
            background-color: ${({ theme }) => theme.palette.commons.tonal.base};
            border: 1px solid ${({ theme }) => theme.palette.commons.tonal.hover};
        }

        .ps__rail-y {
          display: none;
        }
    }
`;
