import { Typography, useTheme } from '@sky-distribution/react-commons-ui';
import React, { FC } from 'react';
import { ContentBlockProps } from '../content.type';
import { ContentPoints } from './Points.styled';

export const Points: FC<ContentBlockProps> = ({
  block,
  data = {},
}: ContentBlockProps) => {
  const points = data?.points;

  const theme = useTheme();

  return (
    <ContentPoints>
      <Typography
        component="span"
        variant={theme.typography.body1}
        color={theme.palette.text.paragraph.secondary}
      >
        {block.content}
      </Typography>
      {points && (
        <Typography component="span" data-e2e="points">
          {points}
        </Typography>
      )}
    </ContentPoints>
  );
};
