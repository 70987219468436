import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useOnClickOutside } from '../../../Hooks';
import { MobilePopupTopNavbar } from '../../../MobilePopupTopNavbar/MobilePopupTopNavbar';
import { Picker } from '../../BeeDatePicker.const';
import { usePickerContext } from '../../contexts';
import { StyledPicker } from './RenderPicker.styled';
import { DayPicker, MonthPicker, RadioSlide, YearPicker } from './components';

export const RenderPicker: React.FC = () => {
  const pickerRef = React.useRef<HTMLDivElement | null>(null);
  const {
    state: {
      date,
      visiblePicker,
      inputLabel,
      pickerZIndex,
      isDirty,
      isFormFilled,
    },
    dispatch,
  } = usePickerContext();

  const onCloseHandler = React.useCallback((): void => {
    dispatch({ type: 'VALIDATE_PICKER_DATE' });

    if (!isDirty || isFormFilled) {
      dispatch({ type: 'SET_VISIBLE_PICKER', payload: null });
    }
  }, [dispatch, isDirty, isFormFilled]);

  useEffect(() => {
    if (!date.year) {
      dispatch({ type: 'SET_VISIBLE_PICKER', payload: Picker.YEAR });
      return;
    }
    if (!date.month) {
      dispatch({ type: 'SET_VISIBLE_PICKER', payload: Picker.MONTH });
      return;
    }
    if (!date.day) {
      dispatch({ type: 'SET_VISIBLE_PICKER', payload: Picker.DAY });
    }
  }, [date, dispatch]);

  useOnClickOutside(pickerRef, onCloseHandler);

  return (
    <StyledPicker pickerZIndex={pickerZIndex} ref={pickerRef}>
      {isMobile && visiblePicker && (
        <MobilePopupTopNavbar title={inputLabel} onClose={onCloseHandler} />
      )}
      <RadioSlide />
      {(() => {
        switch (visiblePicker) {
          case Picker.YEAR:
            return <YearPicker />;
          case Picker.MONTH:
            return <MonthPicker />;
          case Picker.DAY:
            return <DayPicker />;
          default:
            return null;
        }
      })()}
    </StyledPicker>
  );
};
