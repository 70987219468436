import { enMessages } from './languages/EN.lang';
import { plMessages } from './languages/PL.lang';


import { TranslationsType } from './compensa.type';

export const translations: TranslationsType = {
  pl: plMessages,
  en: enMessages
};
export default translations;
