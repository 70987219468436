import React, { FC, PropsWithChildren, useContext } from 'react';
import {
  StyledModal,
  StyledClose,
  StyledSaveButton,
  StyledPerfectScrollbar,
  StyledModalHeaderWrapper,
  StyledModalContainer,
  StyledModalBelowHeaderWrapper,
  StyledModalButtonsWrapper
} from './Modal.style';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import { TranslationContext } from '../../../../../translations/translate/globalTranslate/TranslationProvider';
import { useModalContext } from '../../../../../providers/ModalProvider/ModalProvider';
import { wrapWith } from '../../../utils/WrapWith.util';
import type { ModalPropsType } from './Modal';

type Props = PropsWithChildren<ModalPropsType> & {
  styledModalButtonsWrapperProps?: {
    isInputActive?: boolean;
  };
};

export const Modal: FC<Props> = (props: Props) => {
  const {
    onClose,
    onSubmit,
    buttons,
    content,
    belowHeader,
    isNarrow,
    isScrolled = true,
    header,
    styledModalButtonsWrapperProps
  } = props;
  const { t } = useContext(TranslationContext);
  const { isModalVisible, setIsModalVisible } = useModalContext();

  const hideModal = () => setIsModalVisible(false);

  const handleClose = (): void => onClose ? onClose(hideModal) : hideModal();

  const handleSubmit = (data: object): void =>
    onSubmit ? onSubmit(data, hideModal) : hideModal();

  return (
    <StyledModal open={isModalVisible} onClose={handleClose} disablePortal>
      <Fade in={isModalVisible}>
        <StyledModalContainer $isNarrow={isNarrow}>
          <StyledModalHeaderWrapper>
            {header}
            <StyledClose type='button' onClick={handleClose}>
              <CloseIcon role='svg' />
            </StyledClose>
          </StyledModalHeaderWrapper>
          <StyledModalBelowHeaderWrapper>
            {belowHeader}
          </StyledModalBelowHeaderWrapper>
          {wrapWith(isScrolled && StyledPerfectScrollbar, content, {
            $hasBelowHeader: !!belowHeader
          })}
          <StyledModalButtonsWrapper
            styledModalButtonsWrapperProps={styledModalButtonsWrapperProps}
          >
            {buttons ?? (
              <StyledSaveButton type='button' onClick={handleSubmit}>
                {t('OK')}
              </StyledSaveButton>
            )}
          </StyledModalButtonsWrapper>
        </StyledModalContainer>
      </Fade>
    </StyledModal>
  );
};
