import { Dispatch } from 'redux';
import { AppActions } from '../../../../../redux/actions/AppActions.type';
import { PolicyDateActionsType, POLICY_DATE_ACTIONS } from './PolicyDate.type';

export const savePolicyStartDate = (startDate: string) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(savePolicyStartDateAction(startDate));
  };
};

export const savePolicyStartDateAction =
  (startDate: string): PolicyDateActionsType => {
    return {
      type: POLICY_DATE_ACTIONS.SAVE_POLICY_START,
      data: startDate
    };
  };
