import { useContext } from 'react';
import {
  TranslationContext
} from '../../../../translations/translate/globalTranslate/TranslationProvider';
import { emailYupValidator } from '../../../../common/formikValidators/emailValidator';
import * as Yup from 'yup';

export const useEmailValidationMessages = () => {
  const { t } = useContext(TranslationContext);

  return {
    wrongFormat: t('WRONG_EMAIL_ADDRESS'),
    required: t('REQUIRED')
  };
};

export const useEmailValidation = () => Yup.object()
  .shape({ email: emailYupValidator(true, useEmailValidationMessages()) });
