import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { ReactComponent as PhoneIcon } from '../../assets/navBar/phone.svg';
import { Button } from '@sky-distribution/react-commons-ui/lib/Button';


interface StepsIndicatorWrapperProps {
    $notVisible: boolean;
}

const getVisible = ({ $notVisible }: StepsIndicatorWrapperProps) => $notVisible ? 'display: none' : '';

export const StepsIndicatorWrapper = styled.div<StepsIndicatorWrapperProps>`
    ${getVisible}
`;
export const Logo = styled.img`
    width: 8.75rem;
`;

export const StepsIndicatorContainer = styled.div`
    font-family: ${({ theme }) => theme.typography.fontFamily};  
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5rem;
`;

export const LogoContainer = styled.div`
    width: 33vw;
    flex-grow: 1;
    display: flex;
    padding: 0 1rem;
    align-items: center;
    
     ${({ theme }) => theme.breakpoints.up('sm')} {
       justify-content: center;
       display: flex;
    }
`;

export const ActiveStep = styled.div`
    width: 33vw;
    flex-grow: 1;
    display: none;
    justify-content: center;
    align-items: center;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 0.875rem;
    color: ${({ theme }) => theme.palette.brand.color};
    
    ${({ theme }) => theme.breakpoints.up('sm')} {
        display: flex;
    }
`;

export const ActiveStepMobile = styled(Grid)`
    && {
        display: flex;
        padding-left: 1rem;
        font-family: ${({ theme }) => theme.typography.fontFamily};
        font-size: 0.875rem;
        color: ${({ theme }) => theme.palette.button.text.outline};
      
        p {
            margin-bottom: 0;
        }
        
        ${({ theme }) => theme.breakpoints.up('sm')} {
            display: none;
        }
    }
`;

export const ActiveStepName = styled.span`
    font-weight: bold;
    margin-left: 0.25rem;
`;

export const SaveContainer = styled.div`
    width: 33vw;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 1rem;
`;

// TODO move to common
const CLIP_PATH = 'polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);';
export const PhoneContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${({ theme }) => theme.palette.commons.background.infotip};
  width: 2rem;
  height: 2rem;
  clip-path: ${CLIP_PATH};
  
  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.md)} {
      display: none;
   }
  
  a {
     opacity: 0;
     top: 0;
     position: absolute;
     z-index: 999;
     width: 100%;
     height: 100%;
  }
`;

export const Phone = styled(PhoneIcon)`
  width: 1.125rem;
  height: 1.125rem;

  use {
    fill: ${({ theme }) => theme.palette.button.background.secondary}
  }
`;

export const ProgressBarContainer = styled.div`
    background-color: ${({ theme }) => theme.palette.commons.background.infotip};
    width: 100%;
    height: 0.25rem;
`;

export const ProgressBarProgress = styled.div<{progressPercentage: number}>`
    background-color: ${({ theme }) => theme.palette.button.background.select.after};
    width: ${({ progressPercentage }) => `${progressPercentage}%`};
    height: 0.25rem;
    transition: all 0.5s ease;
`;

export const SaveOfferContainer = styled.div`
    display: flex;
    margin: auto;
`;

export const StyledSaveOfferButton = styled(Button)`
    && {
        width: 12.875rem;
        height: 2.5rem;
        font-size: .875rem;
        border-radius: .5rem;
        
        ${({ theme }) => theme.breakpoints.down(theme.breakpoints.values.md)} {
            width: 4.875rem;
            height: 2.25rem;
        }
    }
`;

export const StyledSaveOfferText = styled.span`
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.palette.text.link};
`;
