import { useTheme } from '@sky-distribution/react-commons-ui';
import React, { FC } from 'react';
import { useAssets } from '../../../../AssetsProvider/Assets.provider';
import { ContentBlockProps } from '../content.type';
import {
  ContentIcon,
  ContentParagraph,
  StyledTypography,
} from './Paragraph.styled';

export const Paragraph: FC<ContentBlockProps> = ({
  block,
  color,
}: ContentBlockProps) => {
  const assets = useAssets();
  const icon = block.icon && assets[block.icon];

  const theme = useTheme();

  return (
    <ContentParagraph>
      {icon && <ContentIcon alt="icon" src={icon} />}
      <StyledTypography
        variant={theme.typography.body1}
        color={color ?? theme.palette.text.paragraph.secondary}
      >
        {block.content}
      </StyledTypography>
    </ContentParagraph>
  );
};
