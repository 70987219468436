import { Dispatch } from 'redux';
import { AppActions } from '../../../../../../../redux/actions/AppActions.type';
import type { CustomerConsents } from '../Consent';
import { CONSENTS_ACTIONS, CustomerConsentsClearedAction, CustomerConsentsSavedAction } from './ConsentsActions.type';

export const saveCustomerConsents = (customerConsents: Partial<CustomerConsents>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(customerConsentsSavedAction(customerConsents));
  };
};

export const clearCustomerConsents = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(clearCustomerConsentsAction());
  };
};

export const customerConsentsSavedAction = (
  customerConsents: Partial<CustomerConsents>
): CustomerConsentsSavedAction => {

  return {
    type: CONSENTS_ACTIONS.DATA_SAVED,
    data: customerConsents
  };
};


export const clearCustomerConsentsAction = (): CustomerConsentsClearedAction => ({
  type: CONSENTS_ACTIONS.CLEAR_CUSTOMER_CONSENTS
});
