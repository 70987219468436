"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translations = void 0;
const PL_lang_1 = require("./translations/beesafe/PL.lang");
const EN_lang_1 = require("./translations/beesafe/EN.lang");
const PL_lang_2 = require("./translations/benefia/PL.lang");
const EN_lang_2 = require("./translations/benefia/EN.lang");
const PL_lang_3 = require("./translations/compensa/PL.lang");
const EN_lang_3 = require("./translations/compensa/EN.lang");
exports.translations = {
    beesafe: {
        en: EN_lang_1.enMessages,
        pl: PL_lang_1.plMessages,
    },
    benefia: {
        en: EN_lang_2.enMessages,
        pl: PL_lang_2.plMessages,
    },
    compensa: {
        en: EN_lang_3.enMessages,
        pl: PL_lang_3.plMessages,
    },
};
