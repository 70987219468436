import { AppDispatch } from '../../../../redux/types/AppStore.type';
import { BasicInfoDataType } from '../../containers/BasicInfo/service/BasicInfo.type';
import { PersonalDataDataType } from '../../containers/PersonalData/service/PersonalData.type';
import { FetchUserInfoResultType } from '../../service/FetchUserInfo.type';
import { UserDataRootReducer } from '../UserDataRootReducer';

export const USER_INFO_ACTIONS = {
  FETCH_USER_INFO: 'USER_DATA/FETCH_USER_INFO',
  CLEAR_USER_INFO: 'USER_DATA/CLEAR_USER_INFO',
  CLEAR_SERVER_FLAG: 'USER_DATA/CLEAR_SERVER_FLAG',
  RETRIEVE_USER_INFO: 'USER_DATA/RETRIEVE',
  SET_PAYMENT_STATUS: 'USER_DATA/PAYMENT',
  START_FETCH_USER_INFO: 'USER_DATA/START_FETCH_USER_INFO',
  CLOSE_BASIC_INFO_LOADING_MODAL: 'USER_DATA/CLOSE_BASIC_INFO_LOADING_MODAL',
  FINISH_FETCH_USER_INFO: 'USER_DATA/FINISH_FETCH_USER_INFO'
} as const;

export interface UserInfoFetchedAction {
  type: typeof USER_INFO_ACTIONS.FETCH_USER_INFO;
  data: FetchUserInfoResultType;
  validDataFromServer?: boolean;
  basicInfo?: BasicInfoDataType;
  personInfo?: PersonalDataDataType;
  dispatch?: AppDispatch;
  responseError: boolean;
  errorDetails?: {
    errorCode: number;
  };
}

export interface UserInfoClearedDataAction {
  type: typeof USER_INFO_ACTIONS.CLEAR_USER_INFO;
}

export interface UserInfoClearedServerFlagAction {
  type: typeof USER_INFO_ACTIONS.CLEAR_SERVER_FLAG;
}

export interface UserInfoRetrievedDataAction {
  type: typeof USER_INFO_ACTIONS.RETRIEVE_USER_INFO;
  data: UserDataRootReducer;
}

export interface UserInfoFinishFetchDataAction {
  type: typeof USER_INFO_ACTIONS.FINISH_FETCH_USER_INFO;
}

export interface UserInfoCloseBasicInfoLoadingModalAction {
  type: typeof USER_INFO_ACTIONS.CLOSE_BASIC_INFO_LOADING_MODAL;
}

export type UserInfoActionsType =
  | UserInfoFetchedAction
  | UserInfoClearedDataAction
  | UserInfoClearedServerFlagAction
  | UserInfoFinishFetchDataAction
  | UserInfoCloseBasicInfoLoadingModalAction
  | UserInfoRetrievedDataAction;
