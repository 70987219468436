import { ToolTipConsents } from '../../../TooltipConsent/ToolTipConsent.component';
import { ConfigurationKey, ConsentsConfigurationType } from '../consentFormTenants.type';
import getDefaultConsents from '../shared/getDefaultConsents';

const tenantConfiguration: ConsentsConfigurationType = {
  [ConfigurationKey]: {
    ToolTipConsentsComponent: ToolTipConsents,
    defaultConsents: [
      {
        'name': 'CONTACT_CONSENT_6',
        'rawName': 'consentTerms',
        'required': true,
        'requiredText': 'CONTACT_CONSENT_6_REQUIRED_TEXT',
        documentLink: [
          {
            href:
              'https://www.benefia24.pl/NewAssets/docs/Regulamin_ubezpieczenia_komunikacyjne.pdf',
            txt: 'DOCUMENT_LINK_CONSENT_6_TEXT_1'
          }
        ]
      },
      ...getDefaultConsents(),
      {
        'name': 'CONTACT_CONSENT_7',
        'rawName': 'consentCompensaVoiceMarketing',
        'required': false
      },
      {
        'name': 'CONTACT_CONSENT_8',
        'rawName': 'consentCompensaCustomerProfiling',
        'required': false
      }
    ]
  }
};

export default tenantConfiguration;
