import { AppActions } from '../../../../../redux/actions/AppActions.type';
import { USER_INFO_ACTIONS } from '../../../redux/userInfo/UserInfoActions.type';
import {
  CarDetailsDataType,
  CarDetailsState
} from '../service/CarDetails.type';
import { CAR_DETAILS_ACTIONS } from './CarDetailsActions.type';

const initialState = {
  selectedVehicleDetails: {
    alarm: true,
    immobilizer: true
  } as CarDetailsDataType
} as CarDetailsState;

export const carDetailsReducer = (
  state = initialState,
  action: AppActions
): CarDetailsState => {

  switch (action.type) {
    case CAR_DETAILS_ACTIONS.CAR_DETAILS_SAVED:
      return { ...state, selectedVehicleDetails: action.data };
    case CAR_DETAILS_ACTIONS.CLEAR_DATA:
      return initialState;
    case USER_INFO_ACTIONS.RETRIEVE_USER_INFO:
      return { ...state, ...action.data.carDetails };
    default:
      return state;
  }
};
