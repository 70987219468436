import styled from 'styled-components';

export const StyledFormikErrorMessage = styled.div<{$isModal?: boolean}>`
  position: relative;
  .invalid-feedback{
    color: ${({ theme }) => theme.palette.error.background.base};
    font-size: .75rem;
    position: ${props => !props.$isModal && 'absolute'};
    top: 0.1875rem;
    margin-left: ${props => props.$isModal ? '2.625rem' : '0.625rem'}
  }
`;
