import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import { useEmailValidation } from '../../../inputs/Email/Email.validation';
import SaveOfferModal from './SaveOfferModal.component';
import { OnboardingStateType } from '../../../../../redux/types/AppStore.type';
import type { CustomerConsents } from '../../../containers/ContactInfo/components/ConsentForm/Consent';
import { saveCustomerConsents } from '../../../containers/ContactInfo/components/ConsentForm/redux/Consents.actions';
import { connect } from 'react-redux';
import { FormikInputFormProps } from '../../../../../common/components/formik/Formik.type';
import * as Yup from 'yup';
import { useSaveOfferModalProvider } from '../../../../../providers/SaveOfferModalProvider/useSaveOfferModalProvider';
import { validate } from '../../../forms/validate';
import type { SaveOfferData } from '../../../../../providers/types';

type Props = DispatchProps & StateProps;

const SaveOfferModalWrapped: FC<Props> = ({ nextToViewPage }: Props) => {

  const { initialValues, onSubmit } = useSaveOfferModalProvider();

  const validationSchema = Yup.object().shape({})
    .concat(useEmailValidation());

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: SaveOfferData) => onSubmit(values, nextToViewPage)}
      validate={(value) => validate(value, validationSchema)}
      validateOnMount
      validateOnBlur
      enableReinitialize
    >
      { /* TODO: BEE-8095 */ }
      { /* @ts-ignore */ }
      {({ ...formikProps }: FormikInputFormProps) => (
        <Form>
          <SaveOfferModal {...formikProps} nextToViewPage={nextToViewPage} />
        </Form>
      )}
    </Formik>
  );
};


const mapStateToProps = (state: OnboardingStateType) => ({
  customerConsents: state.userData.customerConsents,
  translate: state.translate.t,
  nextToViewPage: state.appState.nextToView
});
const mapDispatchToProps = (dispatch: Function) => ({
  saveConsents: (consents: CustomerConsents) =>
    dispatch(saveCustomerConsents(consents))
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SaveOfferModalWrapped);
