import { FormikCustomInput } from '../../../../common/components/input/formik/FormikCustomInput.component';
import React, { useContext } from 'react';
import { TranslationContext } from '../../../../translations/translate/globalTranslate/TranslationProvider';
import { FormRowInfo } from '../../containers/ContactInfo/ContactInfo.style';
import ErrorMessageHandler from '../../../../common/components/formik/errorMessage/FormikErrorMessage.component';
import type { InputProps } from '../types';

export const EmailInput = (props: InputProps) => {
  const { t } = useContext(TranslationContext);
  const {
    values,
    setFieldTouched,
    setFieldValue,
    error,
    disabled,
    className,
    hideRowInfo,
    setIsInputActive
  } = props;

  return (
    <>
      <FormikCustomInput
        id='ContactEmail'
        className={className ?? 'inputMargin'}
        fieldName='email'
        label={t('EMAIL_PLACEHOLDER')}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        setIsInputActive={setIsInputActive}
        values={values}
        error={error}
        disabled={disabled}
      />
      {error ? 
        <ErrorMessageHandler name='email' />
        : 
        !hideRowInfo && 
          <FormRowInfo>{t('EMAIL_FIELD_INFORMATION')}</FormRowInfo>
      }
    </>
  );
};
