import type { SaveOfferModalContextType, SaveOfferData } from '../types';
import { save } from '../../modules/userdata/service/Submission.service';
import React, { useEffect, useState } from 'react';
import { OnboardingStateType } from '../../redux/types/AppStore.type';
import { useDispatch, useSelector } from 'react-redux';
import { SaveOfferSuccessModal } from '../../modules/userdata/components/modals/SaveOfferSuccessModal/SaveOfferSuccessModal.component';
import { useModalContext } from '../ModalProvider/ModalProvider';
import { updateSubmission } from '../../modules/userdata/redux/session/Session.action';


export const useSaveOfferModalProvider = (): SaveOfferModalContextType => {
  const { setModal, isModalVisible } = useModalContext();
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const { session, customerConsents, personalData } = useSelector((state: OnboardingStateType) => state.userData);

  const dispatch = useDispatch();

  const onSubmit = async (values: SaveOfferData, nextToViewPage: string) => {
    setIsFormSubmitting(true);
    if (customerConsents) {
      const updateSubmissionData = {
        consents: customerConsents,
        userData: {
          email: values.email
        }
      };
      /* TODO: BEE-8095 */
      /* @ts-ignore */
      await dispatch(updateSubmission(updateSubmissionData, session, undefined, nextToViewPage));
    }
    await save({
      quoteID: values.session.quoteId ?? '',
      sessionUUID: values.session.sessionUuid ?? '',
      emailType: 'email_save_offer',
      emailAddress1: values.email
    });
    setIsSuccessful(true);
  };

  useEffect(() => {
    if (isSuccessful && isModalVisible) {
      setModal(<SaveOfferSuccessModal />);
    }
  }, [isSuccessful, isModalVisible, setModal]);

  return {
    onSubmit,
    initialValues: {
      email: personalData.email ?? '',
      session,
      consents: customerConsents
    },
    isFormSubmitting,
    setIsSuccessful,
    isSuccessful
  };
};
