import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { TranslationContextType } from './globalTranslate/TranslationContext.type';

interface TranslateProps {
  keyName: string;
}

export default class Translate extends Component<TranslateProps> {
  static contextTypes = {
    t: PropTypes.func.isRequired
  };
  context!: TranslationContextType;

  render() {
    return (
      <>
        {this.context.t(this.props.keyName)}
      </>
    );
  }
}
