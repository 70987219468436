import { Typography } from '@sky-distribution/react-commons-ui';
import styled from 'styled-components';

export const ContentParagraph = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
`;

export const ContentIcon = styled.img`
  color: ${(p) => p.theme.palette.brand.color};
  display: flex;
  max-width: 2.5rem;
`;

export const StyledTypography = styled(Typography)`
  margin: 0;
`;
