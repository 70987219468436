import React from 'react';

import { Tenant } from '../tenants/Tenants.types';
import SearchRequestProvider from '../../../components/helpers/dataSender';

import { CallBackWidgetComponent } from '../CallBackWidgetComponent/CallBackWidgetComponent';

interface CallBackWidgetWrapperProps {
  isCallBackWidgetOpen: boolean;
  handleCloseCallBackWidget: () => void;
  tenantId: Tenant;
}

export const CallBackWidgetWrapper = ({
  isCallBackWidgetOpen,
  handleCloseCallBackWidget,
  tenantId,
}: CallBackWidgetWrapperProps): JSX.Element => {
  return (
    <SearchRequestProvider>
      <CallBackWidgetComponent
        isCallBackWidgetOpen={isCallBackWidgetOpen}
        handleCloseCallBackWidget={handleCloseCallBackWidget}
        tenantId={tenantId}
      />
    </SearchRequestProvider>
  );
};
