import React, { Component } from 'react';
import GenericErrorPage from '../userdata/containers/GeneralError';
import { triggerDataLayerErrorBoundaryEvent } from '../userdata/utils/Gtm.events';
import { ErrorBoundaryProps, ErrorBoundaryType } from './types.d';

class ErrorBoundary extends Component<{}, ErrorBoundaryType> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {};
  }

  reload() {
    window.location.reload();
  }

  passEvent() {
    triggerDataLayerErrorBoundaryEvent(
      this.state.error
    );
  }

  componentDidCatch(error: Error) {
    this.setState({ error }, () => {
      this.passEvent();
    });
  }

  render() {
    if (this.state.error) {
      return <GenericErrorPage handleBackButton={this.reload} />;
    }

    /* TODO: BEE-8095 */

    // @ts-ignore
    return this.props.children;
  }

}

export default ErrorBoundary;
