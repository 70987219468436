export class UriBuilder {
  private uri = '';
  private queryParamsList = [] as Record<string, string | number | boolean>[];

  one(path: string, value: string): UriBuilder {
    this.uri += `/${path}/${value}`;
    return this;
  }

  all(path: string): UriBuilder {
    this.uri += `/${path}`;
    return this;
  }

  build(): string {
    const queryString = this.queryParamsList.flatMap(params => Object.entries(params)).map((param) => param.join('=')).join('&');
    return this.uri + (queryString ? `?${queryString}` : '');
  }

  queryParams(params: Record<string, string | number | boolean>): UriBuilder {
    this.queryParamsList.push(Object.assign({}, params));
    return this;
  }
}
