import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SaveOfferModalProvider } from '../../../../providers/SaveOfferModalProvider/SaveOfferModalProvider';
import StepsIndicator, {
  StepListElementType
} from '../../../../common/components/stepsIndicator/StepsIndicator.component';
import { useModalContext } from '../../../../providers/ModalProvider/ModalProvider';
import SaveOfferModalWrapped from '../../components/modals/SaveOfferModal/SaveOfferModal.container';
import { RootState } from '../../../../redux/reducers/RootReducer';
import { getApplicationStepsData } from '../../components/pageTemplate';
import { useHistory } from 'react-router-dom';

type StepsIndicatorWrappedProps = {
  steps?: StepListElementType[];
  notVisible?: boolean;
};

const StepsIndicatorWrapped = (props: StepsIndicatorWrappedProps) => {
  const { setModal } = useModalContext();
  const history = useHistory();
  const steps = useMemo(() => getApplicationStepsData(history.location.pathname), [history.location.pathname]);
  const hasActiveStep = useMemo(() => steps.some((step) => step.active), [steps]);

  const isTransactionLoading = useSelector(
    (state: RootState) => state.userData.transaction.isLoading
  );

  return (
    <SaveOfferModalProvider>
      <StepsIndicator
        steps={props.steps ?? steps}
        notVisible={!hasActiveStep}
        showSaveOfferModal={() => setModal(<SaveOfferModalWrapped/>)} 
        isTransactionLoading={isTransactionLoading}
      />
    </SaveOfferModalProvider>
  );
};

export default StepsIndicatorWrapped;
