import { CarInfoDataType } from '../service/CarInfo.type';

export const CAR_INFO_ACTIONS = {
  CAR_INFO_SAVED: 'USER_DATA/CAR_INFO_SAVED'
} as const;

export interface CarInfoSavedAction {
  type: typeof CAR_INFO_ACTIONS.CAR_INFO_SAVED;
  data: CarInfoDataType;
}

export type CarInfoActionsType = CarInfoSavedAction;
