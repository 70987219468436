import { maskObject } from '@sky-distribution/mask-util';

export const anonymizeData = (payload: unknown) => maskObject(sanitizeData(payload));

export const sanitizeData = (payload: unknown) => {
  if (typeof payload !== 'string') return payload;

  try {
    return JSON.parse(payload);
  } catch (error) {
    return payload;
  }
};

export const sanitizeTimestamp = (payload: string) => {
  try {
    return new Date(payload).toISOString();
  } catch (error) {
    return payload;
  }
};
