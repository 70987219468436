import { Typography } from '@sky-distribution/react-commons-ui';
import styled, { keyframes } from 'styled-components';

export const ToolTipConsentWrapper = styled.div`
  position: relative;
`;

export const CloudInfoIconWrapper = styled.div` 
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.7rem;
  border-radius: 100%;
  &:hover {
    padding: 0.7rem;
    margin-left: 0;
  }
`;

export const CloudInfoIcon = styled.div`
  background-color: ${({ theme }) => theme.palette.commons.background.infotip};
  border-radius: 1rem;
  color: ${({ theme }) => theme.palette.brand.color};
  height: 1.1rem;
  line-height: 0.9rem;
  width: 1.1rem;
  font-size: 0.6rem;
  border: solid 0.15rem ${({ theme }) => theme.palette.brand.color};
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ToolTipInfoBoxPropsType {
  showToolInfo?: boolean;
  isDesktop?: boolean;
  width?: number;
}

const visibleAnimation = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

export const ToolTipInfoBox = styled.div<ToolTipInfoBoxPropsType>`
  animation-duration: 0.25s;
  animation-name: ${visibleAnimation};
  animation-timing-function: linear;  
  background-color: ${({ theme }) => theme.palette.commons.background.container};
  display: ${props => props.showToolInfo ? 'block' : 'none'};
  border-radius: 0.625rem;
  margin: 0.5rem 0;
  padding: 1rem 1.5rem;
  position: absolute;
  white-space: pre-wrap;
  top: 2.1rem;
  left: -2.5rem;
	min-width: ${props => !props.isDesktop && props.width ? `${props.width}px` : '31rem'};
  min-height: 18.75rem;
  box-shadow: ${({ theme }) => theme.shadow.modal};
  border-radius: 1rem;
  z-index: 29;
  ${({ theme }) => theme.breakpoints?.down('md')} {
    left: ${props => props.isDesktop ? '-13.5rem' : '-11.5rem'};
  }
`;

export const ToolTipBoxArrow = styled.div<ToolTipInfoBoxPropsType>`
    width: 3.125rem;
    height: 1.5625rem;
    position: absolute;
    top: 0%;
    transform: translateX(50%);
    
    &::before {
     content: "";
     position: absolute;
     width: 1.25rem;
     height: 1.25rem;
     background: ${({ theme }) => theme.palette.commons.background.container};
     transform: translateX(-50%) translateY(-50%) rotate(45deg);
     top: 0%;
     left: 0.625rem;
     ${({ theme }) => theme.breakpoints?.down('md')} {
      left: ${props => props.isDesktop ? '11.625rem' : '10.425rem'};
      }
     box-shadow: ${({ theme }) => theme.shadow.modal};
     clip-path: inset(-0.9375rem 0rem 0rem -0.9375rem);
    }`;

export const ToolTipConsent = styled(Typography)`
  display: inline-block;
  font-family: "Dosis",sans-serif;
  color: #000000;
  font-size: .75rem;
  margin: 0.1875rem 0 -1.3125rem 0.625rem;
  font-weight: 400;
`;

export const ToolTipConsentDesc = styled.div`
    background: ${({ theme }) => theme.palette.commons.background.infotip};
    padding: 0.4rem 0.4rem;

    margin: 0.5rem 0rem 1.7rem 0rem;
    border-radius: 0.25rem;
`;

