import { AxiosError } from 'axios';
import { add } from '../../modules/userdata/containers/GeneralError/DebugInfo/redux/DebugInfo.actions';
import { handleDisplayAlert } from '../../modules/userdata/containers/GeneralError/DebugInfo/utils/DebugInfo.util';
import { RequestHeaders } from '../../modules/userdata/containers/GeneralError/DebugInfo/utils/DebugInfoUtil.type';
import store from '../../redux/store/AppStore';
import { getDeployEnvName } from '../../utils/getDeployEnvName';
import { errorHandlerRedirect } from '../components/errorHandler/redux/ErrorHandler.actions';
import { ErrorObject as ErrorHandlerErrorObject } from '../components/errorHandler/redux/ErrorHandlerAction.type';
import { anonymizeData, sanitizeTimestamp } from './utils/sanitizePayload.util';


export interface ServerError {
  message: string;
  error?: {
    errorCode?: number;
  };
}

const MAX_USER_ERROR_CODE = 499;
const DEFAULT_SERVER_ERROR_CODE = 500;

export const handleErrorApiCalls = (error: AxiosError<ServerError>, requestHeaders: RequestHeaders) => {
  let message = 'Unknown error. Please try again later.';
  const errorCode = error.response?.status ?? DEFAULT_SERVER_ERROR_CODE;
  if (errorCode < MAX_USER_ERROR_CODE) { // TODO: possibly dead code (https://github.com/sky-distribution/frontend-common/pull/106)
    message = error.response?.data?.message ?? error.message;
  }

  const errorObject: ErrorHandlerErrorObject = {
    message,
    code: error?.response?.data?.error?.errorCode ?? errorCode,
    raw: error?.response
  };

  const debugInputPayload = {
    // requestId: 'TBD', // TODO: BEE-5521
    requestHeaders,
    requestPayload: anonymizeData(error?.response?.config.data),
    timestamp: sanitizeTimestamp(error?.response?.headers?.date), // date format to be in accordance with Grafana
    response: {
      httpStatus: error?.response?.status,
      data: anonymizeData(error?.response?.data)
    },
    environment: getDeployEnvName(),
    url: {
      windowLocationProtocol: window.location.protocol,
      windowLocationHost: window.location.host,
      baseUrl: error?.response?.config?.baseURL,
      url: error?.response?.config?.url
    }
  };

  store.dispatch(errorHandlerRedirect(errorObject));
  store.dispatch(add(debugInputPayload));
  console.error(message);
  handleDisplayAlert(debugInputPayload);
};
