import { ConfigurationKey, StepsIndicatorConfigurationType } from '../tenant.type';

const tenantConfiguration: StepsIndicatorConfigurationType = {
  [ConfigurationKey]: {
    retrieve: {
      isSaveOfferButtonShown: false,
      phoneContainer: {
        //ToDo change do compensa
        phoneContainerId: 'benefia_callback_icon',
        shouldRedirectToPhone: true,
        phoneNumber: 'tel:225441414'
      }
    }
  }
};

export default tenantConfiguration;
