import React from 'react';
import { StyledContainer, StyledHeader, StyledText } from './Failed.style';
const Failed = (): JSX.Element => {
  return (
    <StyledContainer>
      <StyledHeader>Coś poszło nie tak</StyledHeader>
      <StyledText>
        Nie udało się wysłać formularza. Prosimy o kontakt telefoniczny.
      </StyledText>
      <StyledText>Pracujemy w dni robocze: 8:00 - 18:00</StyledText>
    </StyledContainer>
  );
};

export default Failed;
