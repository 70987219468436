import React from 'react';
export const isDefined = (object: unknown): object is {} => {
  return object !== null && object !== undefined;
};

export const areAllDefined = (objectList: unknown[]): boolean => {
  return objectList.every(obj => isDefined(obj));
};

export const isNotDefined = (object: unknown): object is null | undefined => {
  return !isDefined(object);
};

// regex to replace [x] by .x
const REPLACE_REGEX = /\[([^\]]*)]/g;

// disabled during refactor in BEE-3367 - unable to find proper type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFieldValueFromObject = (object: any, fieldPath: string): any => {
  let fieldValue = object;
  const fieldPathKeys = fieldPath.replace(REPLACE_REGEX, `.$1`)
    .split('.');

  fieldPathKeys.forEach((name: string, index: number) => {
    if (isNotDefined(fieldValue)) {
      const errorMessage = generateErrorMessage(fieldPathKeys, index);
      throw new Error(errorMessage);
    }
    fieldValue = fieldValue[name];
  });
  return fieldValue;
};

const generateErrorMessage = (fieldPathKeys: string[],
  index: number): string => {
  if (index > 0) {
    const errorFieldPath = fieldPathKeys.slice(0, index - 1).join('.');
    return `Undefined value returned after extracting path: ${errorFieldPath}`;
  } else {
    return 'Input object is undefined';
  }
};

export function usePreviousState<T>(value: T): T | undefined {
  const ref = React.useRef<T>();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export const hasStateChanged = (prevValue: unknown, newValue: unknown) =>
  !!prevValue && JSON.stringify(prevValue) !== JSON.stringify(newValue);

