import { FormikInputFormProps } from '../../../common/components/formik/Formik.type';

interface ShouldDisplayErrorParams {
  fieldName: string;
  formikProps: Partial<FormikInputFormProps>;
}

export const shouldDisplayError = ({ fieldName, formikProps }: ShouldDisplayErrorParams) => {
  const errExist = Boolean(formikProps.errors[fieldName]);
  const isTouched = Boolean(formikProps.touched[fieldName]);
  return errExist && isTouched;
};
