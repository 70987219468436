import styled from 'styled-components';
import { Container } from '@material-ui/core';
import { TENANT_NAME } from '../../../../config/tenants/Tenants.provider';
import { TenantsEnum } from '../../../../config/tenants/tenant.type';

export const AppHeaderContainer = styled(Container)`
  && {
      color: ${({ theme }) => theme.palette.text.paragraph.secondary};
      padding: 1rem 1rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      
      ${({ theme }) => theme.breakpoints.up('sm')} {
           padding-top: 2vh;
      }
    
    margin-top: ${TENANT_NAME === TenantsEnum.Benefia && '2rem'}
  }
`;
export const AppHeaderLogo = styled.img<{ $width?: string }>`
    ${p => p.$width && `width: ${p.$width}`};
`;
export const FromText = styled.p`
    font-size: 0.5rem;
    line-height: 1.3rem;
    padding-right: 0.5rem;
    padding-left: 1.375rem;
`;
