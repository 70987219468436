import React, { FC } from 'react';
import styled from 'styled-components';
import {
  BaseButton,
  BaseButtonProps
} from '../../../../../common/components/buttons/BaseButton.component';
import Modal from '@material-ui/core/Modal';
import PerfectScrollbar from 'react-perfect-scrollbar';

type StyledModalButtonsWrapperProps = {
  styledModalButtonsWrapperProps?: {
    isInputActive?: boolean;
  };
  children: React.ReactNode;
};

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledModalContainer = styled.div<{ $isNarrow?: boolean }>`
  background-color: ${({ theme }) => theme.palette.commons.background.container};
  outline: none;
  padding: 3rem;
  border-radius: 1rem;
  box-shadow: 0 0.625rem 2.5rem 0 rgba(0, 0, 0, 0.15);
  border: solid 0.063rem #979797;
  width: ${(props) => props.$isNarrow ? '29.125rem' : '49.063rem'};
  position: relative;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 1.5rem 1rem;
    height: 100%;
    width: 100%;
    border-radius: 0;
    max-height: 100%;
  }
`;

export const StyledModalHeaderWrapper = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 1rem;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.brand.color};
  text-align: center;
  margin: 1rem 0 1rem 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin: 0.5rem 0 0.5rem 0;
  }
`;

export const StyledModalBelowHeaderWrapper = styled.div`
  color: ${({ theme }) => theme.palette.brand.color};
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 1rem 0 1rem 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin: 0.5rem 0 0.5rem 0;
  }
`;

export const StyledClose = styled.button`
  color: ${({ theme }) => theme.palette.button.text.outline.base};
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  right: 1.1rem !important;
  top: 1.3rem !important;
  transform: scale(1.2);
  position: absolute;

  ${({ theme }) => theme.breakpoints.up('md')} {
    right: 2.5rem;
    top: 3.5rem;
  }
`;

export const StyledModalButtonsWrapper = styled.div<StyledModalButtonsWrapperProps>`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    position: absolute;
    width: calc(100% - 2rem);

    ${(props) =>
    props.styledModalButtonsWrapperProps?.isInputActive
      ? 'bottom: auto;'
      : 'bottom: 2rem;'}
  }
`;

export const StyledSaveButton = styled<FC<BaseButtonProps>>((props) =>
  <BaseButton {...props} />
)`
  &&& {
    border-radius: 0.5rem;
    background: ${({ theme }) => theme.palette.button.background.secondary.base};
    color: ${({ theme }) => theme.palette.commons.background.container};
    height: 3.75rem;
    margin: 2rem auto 0 auto;
    display: block;

    &:hover {
      background: ${({ theme }) => theme.palette.button.background.secondary.hover};
      box-shadow: none;
    }

    .chooseButtonText {
      font-size: 1rem;
      line-height: 1.13;
    }
  }
`;

export const StyledPerfectScrollbar = styled(PerfectScrollbar)<{
  $hasBelowHeader?: boolean;
}>`
  max-height: 75%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-height: calc((100vh * 0.9) - 1.833rem - 10.5rem);
    padding-bottom: 1.33rem;
  }
`;
