import { ReactNode, useEffect, useState } from 'react';
import type { ModalContextType } from '../types';
import { errorHandlerRedirect } from '../../common/components/errorHandler/redux/ErrorHandler.actions';
import { useDispatch } from 'react-redux';
import { EMPTY_MODAL } from '../ErrorProvider/ErrorProvider';

export const useModalProvider = (): ModalContextType => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modal, setModal] = useState<ReactNode | undefined>(undefined);
  const [modalStateAdded, setModalStateAdded] = useState(false);
  const dispatch = useDispatch();

  const handleBackButton = async () => {
    await setModalStateAdded(false);
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (modal) {
      setModalStateAdded(true);
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener('popstate', handleBackButton);
      setIsModalVisible(true);
    }
  }, [modal]);

  useEffect(() => {
    if (!isModalVisible) {
      window.removeEventListener('popstate', handleBackButton);
      if (modalStateAdded) {
        window.history.back();
      }
      setModal(undefined);
    }
  }, [isModalVisible, modalStateAdded]);

  useEffect(() => {
    if (isModalVisible && !modal) {
      setIsModalVisible(false);
      dispatch(errorHandlerRedirect({ message: 'EmptyModal', code: EMPTY_MODAL }));
    }
  }, [dispatch, isModalVisible, modal]);

  return {
    isModalVisible,
    setIsModalVisible,
    modal,
    setModal
  };
};
