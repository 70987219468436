import { createTheme } from '@material-ui/core/styles';
import { Themed as CommonThemed } from '@sky-distribution/react-commons-ui';
import React, { FC, PropsWithChildren, useContext } from 'react';
import { ThemeContext, ThemeProvider } from 'styled-components';
import { TenantsEnum } from './tenant.type';
import { ThemeConfiguration } from './theme.type';

export const TENANT_NAME = process.env.REACT_APP_TENANT ?? TenantsEnum.Beesafe;
export const configuration: ThemeConfiguration = require(`./${TENANT_NAME}/theme/index`).default;
export const muiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1408
    }
  },
  palette: {
    text: {
      primary: configuration.palette.input.border.hover,
      secondary: configuration.palette.input.border.hover,
      disabled: configuration.palette.input.border.base,
      hint: configuration.palette.input.border.base
    },
    primary: {
      main: configuration.palette.brand.color
    },
    error: {
      main: configuration.palette.error.background.base
    }
  },
  typography: {
    fontFamily: configuration.typography.fontFamily
  },
  overrides: {
    MuiInputBase: {
      root: {
        letterSpacing: 'normal'
      }
    },
    MuiIconButton: {
      colorSecondary: {
        color: configuration.palette.input.border.hover
      }
    },
    MuiFormLabel: {
      root: {
        letterSpacing: 'normal'
      }
    }
  }
});
export const useTheme = () => {
  return useContext(ThemeContext);
};
export const Themed: FC<PropsWithChildren<{ theme?: ThemeConfiguration }>> = (
  props: PropsWithChildren<{ theme?: ThemeConfiguration }>
) => {
  return (
    <ThemeProvider theme={props.theme ?? configuration}>
      <CommonThemed theme={props.theme ?? configuration}>
        {props.children}
      </CommonThemed>
    </ThemeProvider>
  );
};
