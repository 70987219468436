import { History } from 'history';
import { AxiosError } from 'axios';
import route from './Routes.config';
import { OFFER_IS_ALREADY_BOUND_ERROR_CODE, VALIDATION_PAYMENT_ERROR_CODE } from '../providers/ErrorProvider/ErrorProvider';

export const CAR_CAN_NOT_BE_INSURED_CREATE_ERROR_CODE = 1000;
export const CAR_CAN_NOT_BE_INSURED_UPDATE_ERROR_CODE = 2000;
export const PERSON_CAN_NOT_BE_INSURED_ERROR_CODE = 8000;
export const CEPIK_CAR_NOT_FOUND_ERROR_CODE = 25000;

export const REST_ERROR_TYPE = 'REST_ERROR';
export const WS_ERROR_TYPE = 'WS_ERROR';
export const CODE_ERROR_TYPE = 'CODE_ERROR';

type WsErrorType = {
  message: string;
  code: number;
  raw: unknown;
}

export type CodeErrorType = {
  code: number;
}

export type ErrorGuardType = AxiosError & WsErrorType | CodeErrorType;

const getErrorCode = (error: ErrorGuardType, type: string) => {
  if (type === REST_ERROR_TYPE) {
    return (error as AxiosError)?.response?.data?.error?.errorCode;
  }

  if (type === WS_ERROR_TYPE) {
    return error?.code;
  }

  if (type === CODE_ERROR_TYPE) {
    return error?.code;
  }

  return '';
};

export const errorGuard = (error: ErrorGuardType, history: History, type: string = REST_ERROR_TYPE): boolean => {
  const errorCode = getErrorCode(error, type);

  switch (errorCode) {
    case CAR_CAN_NOT_BE_INSURED_CREATE_ERROR_CODE:
    case CAR_CAN_NOT_BE_INSURED_UPDATE_ERROR_CODE:
    case PERSON_CAN_NOT_BE_INSURED_ERROR_CODE:
      history.push(route.CAN_NOT_BE_INSURED);
      return true;
    case OFFER_IS_ALREADY_BOUND_ERROR_CODE:
    case VALIDATION_PAYMENT_ERROR_CODE:
      history.push(route.INVALID_OFFER_OR_ALREADY_PAYED);
      return true;
    case CEPIK_CAR_NOT_FOUND_ERROR_CODE:
      history.push(route.CAR_NOT_FOUND);
      return true;

    default:
  }

  return false;
};
