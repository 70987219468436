import { Tab, Tabs, useTheme } from '@sky-distribution/react-commons-ui';
import React, { FC } from 'react';
import { ToggleSelectHorizontalType } from './ToggleSelectHorizontal.type';
import ToggleSelectHorizontalOption from './ToggleSelectHorizontalOption';

export const ToggleSelectHorizontal: FC<ToggleSelectHorizontalType> = ({
  options,
  onChange,
  value,
  selected,
  selectedLabel,
  duration = '0.3s',
  backdropTabStyle = {},
  tabStyle = {},
  activeTabStyle = {},
  containerTabsStyle = {},
  haveValue,
}: ToggleSelectHorizontalType) => {
  const theme = useTheme();
  const selectedValue = selected !== undefined ? selected : value;
  const optionsJsx = options.map((el) => {
    return (
      <Tab name={el.value} key={el.value}>
        <ToggleSelectHorizontalOption
          title={el.title}
          caption={el.caption}
          value={el.value}
          recommendedText={el.recommendedText}
          duration={duration}
          selectedValue={selectedValue}
          selectedLabel={selectedLabel}
          haveValue={haveValue}
        />
      </Tab>
    );
  });

  const defaultContainerTabsStyle: React.CSSProperties = {
    backgroundColor: theme.palette.commons.tonal.base,
    borderRadius: '1rem',
    marginBottom: '1rem',
    ...containerTabsStyle,
  };
  const defaultBackdropTabStyle: React.CSSProperties = {
    boxShadow: theme.shadow.switch,
    borderRadius: '1rem',
    ...backdropTabStyle,
  };

  const tabWidth = 100 / options.length;

  const defaultTabStyle: React.CSSProperties = {
    position: 'relative',
    padding: '0 1rem',
    border: '1px dashed rgba(0, 0, 0, 0)',
    minHeight: '3.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: `${tabWidth}%`,
    color: theme.palette.switch.text.inactive,
    ...tabStyle,
  };

  const defaultActiveTabStyle: React.CSSProperties = {
    color: theme.palette.brand.color,
    ...activeTabStyle,
  };

  return (
    <Tabs
      backdropStyle={defaultBackdropTabStyle}
      style={defaultContainerTabsStyle}
      tabStyle={defaultTabStyle}
      activeTabStyle={defaultActiveTabStyle}
      value={value}
      onChange={onChange}
      duration={duration as string}
    >
      {optionsJsx}
    </Tabs>
  );
};
