/* eslint-disable max-lines, complexity */
import Grid from '@material-ui/core/Grid';
import { URLSearchParams } from '@sky-distribution/react-commons-ui';
import { parseISO } from 'date-fns';
import { Form, Formik, FormikProps } from 'formik';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { DatePicker } from '../../../../common/components/datePicker/formik/DatePicker/DatePicker.component';
import { FormikDatePicker } from '../../../../common/components/datePicker/formik/FormikDatePicker.component';
import FormikErrorMessage from '../../../../common/components/formik/errorMessage/FormikErrorMessage.component';
import { VehiclePlateInput } from '../../../../common/components/vehiclePlateNumberInput/vehiclePlateNumberInput.component';
import { dateValidator } from '../../../../common/formikValidators/DateValidator';
import { vehiclePlateValidator } from '../../../../common/formikValidators/VehiclePlateValidator';
import { isDefined } from '../../../../common/utils/Object.utils';
import { useFeatureFlag } from '../../../../common/utils/useFeatureFlags/useFeatureFlag';
import RedirectToCorrectPage from '../../../../providers/RedirectToCorrectPage/RedirectToCorrectPage';
import { OnboardingStateType } from '../../../../redux/types/AppStore.type';
import route from '../../../../routing/Routes.config';
import AppHeader from '../../components/AppHeader/AppHeader.component';
import FormContainer, { FormRow } from '../../components/FormContainer';
import PageTemplate from '../../components/pageTemplate/PageTemplate.component';
import {
  createSubmission,
  createSubmissionApiPayload,
  pushUserData,
  startNewSession,
  updateSubmissionAction
} from '../../redux/session/Session.action';
import { SessionState } from '../../redux/session/Session.type';
import { fetchUserInfo } from '../../redux/userInfo/UserInfo.actions';
import { UserInfoData } from '../../service/FetchUserInfo.type';
import { CreateSubmissionApiRequest } from '../../service/Submission.type';
import { shouldDisplayError } from '../../utils/ShouldDisplayError';
import { clearCarDetails } from '../CarDetails/redux/CarDetails.actions';
import { clearCustomerConsents } from '../ContactInfo/components/ConsentForm/redux/Consents.actions';
import { clearDeclarativeData, declarativeDataSavedAction } from '../DeclarativeData/redux/DeclarativeData.actions';
import { clearPersonalData } from '../PersonalData/redux/PersonalData.actions';
import { getLocation } from '../RedirectPage/utils/location';
import {
  DescriptionManual
} from './BasicInfo.style';
import { ManualWayButton } from './components/ManualWayButton/ManualWayButton.style';
import { NoFoundInfo } from './components/NoFoundInfo/NoFoundInfo.component';
import StartTitle from './components/StartTitle';
import {
  FORM_CONTAINER_SIZE_MD,
  FORM_CONTAINER_SIZE_SM,
  FORM_CONTAINER_SIZE_XS,
  GRID_SPACING,
  GRID_XS_SIZE,
  HEADLESS_PARAMS_NAME,
  INITIAL_HIGHLIGHTED_BIRTH_DATE,
  MAX_CAR_YEAR,
  MAX_USER_BIRTH_DATE,
  MIN_CAR_YEAR
} from './constants';
import {
  saveStartPolicyFromCepData,
  saveUtmData,
  selectManualPath
} from './redux/BasicInfo.actions';

import { DeclarativeData } from '@sky-distribution/mf-declarative-path';
import { useTenant } from '../../../../config/tenants/Tenants.provider';
import { CodeErrorType, CODE_ERROR_TYPE, errorGuard } from '../../../../routing/ErrorGuard.util';
import { InitialValuesType } from './BasicInfo.interfaces';
import type { BasicInfoConfigurationType, FooterLink } from './tenants/tenant.type';
import { StyledVehicleInfoFormButton } from './styles/BasicInfoContainer.styles';

export type Props = DispatchProps &
  StateProps & {
    isNotFound?: boolean;
  };

/* eslint-disable max-lines-per-function */
const BasicInfo: FC<Props> = (props: Props) => {
  const maxVehicleDate = useMemo(() => new Date(MAX_CAR_YEAR, 0, 1), []);
  const minVehicleDate = useMemo(() => new Date(MIN_CAR_YEAR, 0, 1), []);
  const CEPIK_ENABLED = useFeatureFlag('feature-CI-520-CEPIK');
  const { BasicInfoConfiguration } = useTenant<BasicInfoConfigurationType>();
  const [validationSchema, setValidationSchema] = useState(Yup.object().shape({}));
  const [isLoading, setIsLoading] = useState(false);
  const [nextPage, setNextPage] = useState('');
  const [forceSubmit, setForceSubmit] = useState<boolean>(false);
  const formikRef = useRef<FormikProps<InitialValuesType>>();
  const location = useLocation();
  const history = useHistory();
  const { lang, isNotFound, translate } = props;
  const canonicalUrl = getLocation() && new URL(
    ['/', route.BASENAME, route.BASIC_USERDATA].join(''),
    getLocation()
  );
  const hasHeadlessParams = useMemo<boolean>(
    () => location.search.includes(HEADLESS_PARAMS_NAME),
    [location.search]
  );

  const saveUtmDataUrl = (urlParams: string) => {
    const isUtmParams =
      urlParams
        .toLocaleLowerCase()
        .substring(1)
        .split('&')
        .filter((kv) => kv.startsWith('utm_')).length > 0;
    if (isUtmParams) {
      props.saveUtmData(urlParams);
    }
  };

  useEffect(() => {
    props.startNewSession();
    saveUtmDataUrl(location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDefined(props.carInfo?.validDataFromServer)) {
      changePageAccordingToServerState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.carInfo?.validDataFromServer]);

  useEffect(() => {
    forceSubmit &&
      hasHeadlessParams &&
      formikRef.current &&
      formikRef.current.initialValues &&
      !formikRef.current.isSubmitting &&
      nextButtonSubmit(formikRef.current.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceSubmit]);

  const changePageAccordingToServerState = async (): Promise<void> => {
    const { basicInfo, personInfo, carInfo, selectedVehicleDetails } = props;

    if (carInfo.validDataFromServer) {
      if (personInfo?.name && personInfo?.surname) {
        const response = await props.createSubmission(
          pushUserData(
            createSubmissionApiPayload(
              !!basicInfo.isManual,
              personInfo,
              selectedVehicleDetails,
              props.ccUserToken
            ),
            personInfo
          )
        );
        errorGuard(response, history);
      } else {
        props.selectManualPath();
        personInfo.birthDate &&
          props.declarativeDataSavedAction({
            birthDate: new Date(personInfo.birthDate).toISOString()
          });
      }
      setNextPage(route.VEHICLE);
    } else if (basicInfo.responseError) {
      if (carInfo.errorDetails) {
        errorGuard(
          {
            code: carInfo.errorDetails.errorCode
          } as CodeErrorType,
          history,
          CODE_ERROR_TYPE
        );
        carInfo.validDataFromServer = undefined;
      } else {
        props.selectManualPath();
        personInfo.birthDate && props.declarativeDataSavedAction({
          birthDate: new Date(personInfo.birthDate).toISOString()
        });
        setNextPage(route.VEHICLE);
      }
    } else {
      setIsLoading(false);
      setNextPage(route.CAR_NOT_FOUND);
    }
  };

  const nextButtonSubmit = async (values: InitialValuesType) => {
    setIsLoading(true);
    if (values.plateNumber) {
      const vehicleDate = values?.vehicleYear
        ? values?.vehicleYear.toString()
        : MIN_CAR_YEAR;
      const vehicleYear = !CEPIK_ENABLED
        ? new Date(vehicleDate).getFullYear().toString()
        : undefined;

      if (values.birthDate !== initialValues.birthDate) {
        props.clearPersonalData();
        props.clearCustomerConsents();
        props.clearDeclarativeData();
      }
      if (values.plateNumber !== initialValues.plateNumber) {
        props.clearCarDetails();
      }

      await props.fetchUserInfo({
        plateNumber: values.plateNumber,
        birthDate:
          values.birthDate instanceof Date
            ? values.birthDate
            : parseISO(String(values.birthDate)),
        vehicleYear
      });
    }
  };

  const handleManualButton = (): void => {
    setNextPage(route.VEHICLE);
    props.selectManualPath();
  };

  const initialVehicleYear = props?.selectedVehicleDetails?.year
    ? new Date(props.selectedVehicleDetails.year)
    : null;
  const initialValues: InitialValuesType = {
    birthDate: props.personInfo.birthDate ?? null,
    plateNumber: props.selectedVehicleDetails.plateNumber,
    vehicleYear: CEPIK_ENABLED ? null : initialVehicleYear
  };

  const plateValidatorErrorMessages = useMemo(() => ({
    required: translate('REQUIRED'),
    wrongFormat: translate('WRONG_PLATE_FORMAT')
  }), [translate]);

  const parseHeadlessParamsIntoFormik = ():
    | Partial<InitialValuesType>
    | undefined => {
    const searchParams = new URLSearchParams(location.search);

    try {
      const headlessParams = searchParams.getFirst(HEADLESS_PARAMS_NAME);

      if (!headlessParams) {
        return undefined;
      }

      const { plateNumber, birthDate } = JSON.parse(
        atob(headlessParams ?? '')
      ) as Partial<InitialValuesType>;

      return {
        plateNumber: plateNumber ?? '',
        ...birthDate
          ? { birthDate: new Date(birthDate as string) }
          : undefined
      };
    } catch {
      return undefined;
    }
  };

  const getInitialValues = (): InitialValuesType => {
    const headlessParams = hasHeadlessParams
      ? parseHeadlessParamsIntoFormik()
      : undefined;

    headlessParams && Promise.resolve().then(() => setForceSubmit(true));

    return {
      ...initialValues,
      ...headlessParams
    };
  };

  useEffect(() => {
    const isVehicleYearRequired = !CEPIK_ENABLED;
    setValidationSchema(
      Yup.object().shape({
        birthDate: dateValidator(true, props.translate('REQUIRED')).max(
          MAX_USER_BIRTH_DATE,
          props.translate('USER_SHOULD_BE_ADULT')
        ),
        vehicleYear: dateValidator(
          isVehicleYearRequired,
          props.translate('REQUIRED')
        )
          .max(
            new Date((new Date(maxVehicleDate).getFullYear() + 1).toString()),
            props.translate('VEHICLE_YEAR_IS_NOT_SUPPORTED')
          )
          .min(
            new Date((new Date(minVehicleDate).getFullYear() - 1).toString()),
            props.translate('VEHICLE_YEAR_IS_NOT_SUPPORTED')
          ),
        plateNumber: vehiclePlateValidator(true, plateValidatorErrorMessages)
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minVehicleDate, maxVehicleDate, plateValidatorErrorMessages]);

  const areValuesNotChangedAfterNotFoundResponse = (
    formikValues: InitialValuesType
  ): boolean => {
    if (!isNotFound) {
      return false;
    }

    const diff = Object.keys(initialValues).filter((key) => {
      return (
        formikValues[key as keyof InitialValuesType] !==
        initialValues[key as keyof InitialValuesType]
      );
    });

    return Boolean(!diff.length);
  };

  const footerLinks = useMemo(() => {
    return BasicInfoConfiguration.footerLinks.map(({ href, translationKey }: FooterLink) => ({
      href,
      txt: translate(translationKey)
    }));
  }, [BasicInfoConfiguration.footerLinks, translate]);

  return (
    <>
      <AppHeader />
      <RedirectToCorrectPage
        nexPageToViewComponentProp={nextPage}
        currentPageComponentProp={location.pathname}
      >
        <PageTemplate
          backgroundImage={BasicInfoConfiguration.homeBgDesktop}
          backgroundImageMd={null}
          currentStep={0}
          withoutDrawer
          footerLinks={footerLinks}
          verticalBackgroundPosition={{
            md: '10rem',
            lg: BasicInfoConfiguration.desktopVerticalBackgroundPosition
          }}
          style={BasicInfoConfiguration.homeBgStyles}
        >
          <Helmet>
            <link rel="canonical" href={canonicalUrl.toString()} />
          </Helmet>
          <StartTitle {...BasicInfoConfiguration.startTitle} />
          <NoFoundInfo
            onClick={handleManualButton}
            isNotFound={Boolean(isNotFound)}
          />
          <Formik
            innerRef={formikRef as React.Ref<FormikProps<InitialValuesType>>}
            initialValues={getInitialValues()}
            validationSchema={validationSchema}
            onSubmit={nextButtonSubmit}
            enableReinitialize
            validateOnBlur
            validateOnChange={false}
          >
            {({ ...formikProps }) => (
              <Form onSubmit={formikProps.handleSubmit} id="ShortPathForm">
                <FormContainer spacing={GRID_SPACING} boxShadow={BasicInfoConfiguration.boxShadow}
                  desktopWidth={BasicInfoConfiguration.startPageForm.width}
                  desktopHeight={BasicInfoConfiguration.startPageForm.height}
                >
                  <FormRow padding={BasicInfoConfiguration.startPageForm.padding}>
                    <VehiclePlateInput
                      id="PlateNumber"
                      name="plateNumber"
                      label={translate('CAR_PLATE_PLACEHOLDER')}
                      disabled={formikProps.isSubmitting || isLoading}
                      setFieldTouched={formikProps.setFieldTouched}
                      setFieldValue={formikProps.setFieldValue}
                      values={formikProps.values}
                      ignoreSpaces
                      toUpperCase
                    />
                    <FormikErrorMessage name="plateNumber" />
                  </FormRow>
                  {!CEPIK_ENABLED ? (
                    <FormRow>
                      <FormikDatePicker
                        id="BirthDate"
                        views={['year']}
                        fieldName="vehicleYear"
                        setFieldValue={formikProps.setFieldValue}
                        values={formikProps.values}
                        setFieldTouched={formikProps.setFieldTouched}
                        label={translate('CAR_INFO_YEAR_PLACEHOLDER')}
                        maxDate={maxVehicleDate}
                        minDate={minVehicleDate}
                        openTo="year"
                        format="yyyy"
                        type={'tel'}
                        lang={lang}
                        disablePopup={isLoading}
                        openOnIconClick
                        error={shouldDisplayError({
                          fieldName: 'vehicleYear',
                          //@ts-ignore
                          formikProps: formikProps
                        })}
                        keyboardIcon={
                          <img
                            src={BasicInfoConfiguration.datePickerIcon}
                            alt="calendar"
                          />
                        }
                        disabled={
                          formikProps.isSubmitting ||
                          isLoading ||
                          !maxVehicleDate ||
                          !minVehicleDate
                        }
                        autoOk
                      />
                      <FormikErrorMessage name="vehicleYear" />
                    </FormRow>
                  ) :
                    <></>
                  }

                  <FormRow padding={BasicInfoConfiguration.startPageForm.padding}>
                    <DatePicker
                      dateIsInvalidMsg={translate('REQUIRED')}
                      requiredMsg={translate('REQUIRED')}
                      id="BirthDate"
                      fieldName="birthDate"
                      setFieldValue={formikProps.setFieldValue}
                      //@ts-ignore
                      values={formikProps.values}
                      setFieldTouched={formikProps.setFieldTouched}
                      label={translate('BIRTH_DATE_PLACEHOLDER')}
                      maxDate={MAX_USER_BIRTH_DATE}
                      disabled={formikProps.isSubmitting || isLoading}
                      initialFocusedDate={INITIAL_HIGHLIGHTED_BIRTH_DATE}
                      genericStyles={BasicInfoConfiguration.startPageForm.inputs}
                    />
                  </FormRow>
                  <FormRow padding={BasicInfoConfiguration.startPageForm.padding}>
                    <StyledVehicleInfoFormButton
                      disabled={!formikProps.isValid || formikProps.isSubmitting || isLoading || areValuesNotChangedAfterNotFoundResponse(formikProps.values)}
                      id="ShortPathFormSubmitButton"
                      blockMode
                      typeStyle="primary"
                      type="submit"
                      loader={isLoading}
                      loaderDesc={translate('PAGE_IS_LOADING')}
                      $styles={BasicInfoConfiguration.shortPathButton}
                    >
                      {isNotFound ? translate('TRY_AGAIN') : translate('NEXT')}
                    </StyledVehicleInfoFormButton>
                  </FormRow>
                </FormContainer>
              </Form>
            )}
          </Formik>
          <Grid item container xs={GRID_XS_SIZE} justifyContent="center">
            {!isNotFound && (
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={FORM_CONTAINER_SIZE_XS}
                  sm={FORM_CONTAINER_SIZE_SM}
                  md={FORM_CONTAINER_SIZE_MD}
                >
                  <DescriptionManual>
                    {translate('MANUAL_INPUT_DESCRIPTION')}
                  </DescriptionManual>
                </Grid>
              </Grid>
            )}
            <ManualWayButton
              id="LongPathVehicleDetails"
              onClick={handleManualButton}
              $isNotFound={isNotFound}
              $styles={BasicInfoConfiguration.manualButton}
            >
              {translate('MANUAL_INPUT_BUTTON')}
            </ManualWayButton>
          </Grid>
        </PageTemplate>
      </RedirectToCorrectPage>
    </>
  );
};

const mapStateToProps = (state: OnboardingStateType) => ({
  selectedVehicle: state.userData.carInfo.selectedVehicle,
  selectedVehicleDetails: state.userData.carDetails.selectedVehicleDetails,
  basicInfo: state.userData.basicInfo,
  personInfo: state.userData.personalData,
  carInfo: state.userData.carInfo,
  carDetails: state.userData.carDetails,
  translate: state.translate.t,
  lang: state.i18nState.lang,
  ccUserToken: state.user?.token?.value
});

const mapDispatchToProps = (dispatch: Function) => ({
  fetchUserInfo: (userInfoData: UserInfoData) =>
    dispatch(fetchUserInfo(userInfoData)),
  clearCustomerConsents: () => dispatch(clearCustomerConsents()),
  clearPersonalData: () => dispatch(clearPersonalData()),
  clearDeclarativeData: () => dispatch(clearDeclarativeData()),
  clearCarDetails: () => dispatch(clearCarDetails()),
  createSubmission: (createLeadApiRequest: CreateSubmissionApiRequest) =>
    dispatch(createSubmission(createLeadApiRequest)),
  selectManualPath: () => dispatch(selectManualPath()),
  updateSessionSubmission: (updateSubmissionData: SessionState) =>
    dispatch(updateSubmissionAction(updateSubmissionData)),
  startNewSession: () => dispatch(startNewSession()),
  saveUtmData: (search: string) => dispatch(saveUtmData(search)),
  setStartDateFromCepFlag: (flag: boolean) =>
    dispatch(saveStartPolicyFromCepData(flag)),
  declarativeDataSavedAction: (personalData: DeclarativeData) =>
    dispatch(declarativeDataSavedAction(personalData))
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo);
