import { Grid } from '@material-ui/core';
import styled from 'styled-components';


const AppBarHeight = '5rem';

export const WaitingWindowLoaderContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.brand.color};
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  margin: 0 auto;
  top: ${AppBarHeight};
  z-index: 1201;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 3.25rem 1.625rem;
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-top: 5.8125rem;
  }
`;

export const WaitingWindowLoaderHeader = styled.h1`
  color: ${({ theme }) => theme.palette.commons.tonal.base};
  margin-top: 0;
  line-height: 1.5;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 1.125rem;
    text-align: center;
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 3rem;
    text-align: center;
  }
  ${({ theme }) => theme.breakpoints.between('sm', 'lg')} {
    font-size: 2rem;
    text-align: center;
  }
`;

export const WaitingWindowLoaderImg = styled.img`
  max-height: 10rem;
`;

export const WaitingWindowLoaderMobileContainer = styled(Grid)`
  transform: translate(-0.625rem, 0rem);
  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: none;
  }
`;

export const WaitingWindowLoaderDesktopContainer = styled(Grid)`
  min-width: 100%;
  transform: translate(-1.25rem, 0rem);
  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const WaitingWindowLoaderProgressContainer = styled(Grid)`
  margin: auto;
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 28.625rem;
  }
`;
