import styled from 'styled-components';

export const StyledContainer = styled.div`
  background: ${({ theme }) => theme.palette.commons.background.container};
  padding: 1.5rem 1rem 1rem 1rem;
  border-radius: 1rem;
`;

export const StyledHeader = styled.h3`
  margin: 0;
`;
export const StyledText = styled.p``;
