import React from 'react';
import Lottie from 'react-lottie';
import beesafeAnimationData from './beesafeAnimationData.json';
import { SpinnerType } from '../spinner.type';


const BeesafeSpinner: React.FC<SpinnerType> = (
  props: SpinnerType
) => {

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: beesafeAnimationData
  };
 
  return (
    <Lottie options={defaultOptions}
      height={props.size ? props.size.height : '250px'}
      width={props.size ? props.size.width : '250px'}
      isStopped={false}
      isPaused={false}
    />
  );
};

export default BeesafeSpinner;
