import React, { FC, useState, useCallback, useEffect } from 'react';
import { withProfiler } from '@sentry/react';
import { CookieModal } from '@sky-distribution/react-commons-ui';
import AppRoutes from '../routing/RoutesWrapped.component';
import { connect } from 'react-redux';
import { StyledAppContainer } from './App.styled';
import Cookies from '../common/components/cookies/Cookies.component';
import { useTenant } from '../config/tenants/Tenants.provider';
import { OnboardingStateType } from '../redux/types/AppStore.type';
import { OLD_COOKIE_BAR_HEIGHT_PX } from '../common/components/cookies/Cookies.style';
import { appConfig } from '../config/appConfig';
import { getConsentsList } from '../common/components/appState/redux/AppState.actions';

type Props = DispatchProps & StateProps;

const App: FC<Props> = (props: Props) => {
  const [cookie, setCookie] = useState('');
  const [domain, setDomain] = useState('');

  useEffect(() => {
    setDomain(document.domain);
    setCookie(document.cookie);
    props.getConsentsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const showNewCookieModal = appConfig.featureFlags.showNewCookieModal;
  const { tenantType } = useTenant();
  const DEFAULT_HEIGHT_PX = showNewCookieModal ? 0 : OLD_COOKIE_BAR_HEIGHT_PX;
  const [cookieBarHeight, setCookieBarHeight] = useState(DEFAULT_HEIGHT_PX);
  const [isCookieBarVisible, setIsCookieBarVisible] = useState(!document.cookie.includes('cookie_notice_accepted=true'));

  const acceptHandler = useCallback(() => {
    document.cookie = `${cookie} cookie_notice_accepted=true;max-age=2147483647;domain=${domain}`;
    setIsCookieBarVisible(false);
  }, [cookie, domain]);

  return (
    <StyledAppContainer isCookieBarVisible={isCookieBarVisible} cookieBarHeight={cookieBarHeight}>
      { !showNewCookieModal && isCookieBarVisible && <Cookies onAccept={acceptHandler} onClose={acceptHandler}/> }
      {showNewCookieModal && <CookieModal
        tenant={tenantType}
        language={props.lang}
        externalHandlers={{
          listenCookieBarHeight: setCookieBarHeight,
          onCookieBarVisible: setIsCookieBarVisible
        }}
      />}
      <AppRoutes />
    </StyledAppContainer>
  );
};

const mapStateToProps = (state: OnboardingStateType) => ({
  lang: state.i18nState.lang
});

type StateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Function) => ({
  getConsentsList: () => dispatch(getConsentsList())
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default withProfiler(connect(mapStateToProps, mapDispatchToProps)(App));
