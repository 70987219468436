import React, { FC, useCallback, FocusEvent, useMemo } from 'react';
import { VehiclePlateNumberInputProps, BeeFieldState } from './vehiclePlateNumberInput.type';
import { StyledVehiclePlateInput, StyledVehiclePlateInputContainer } from './vehiclePlateNumberInput.style';
import { getFieldValueFromObject, isDefined } from '../../utils/Object.utils';
import { getIn, useFormikContext } from 'formik';
import { useTenant } from '../../../config/tenants/Tenants.provider';
import { BasicInfoConfigurationType } from '../../../modules/userdata/containers/BasicInfo/tenants/tenant.type';

export const VehiclePlateInput: FC<VehiclePlateNumberInputProps> = (
  props: VehiclePlateNumberInputProps
) => {
  const formikProps = useFormikContext<{ [key: string]: unknown }>();
  const { BasicInfoConfiguration: { startPageForm } } = useTenant<BasicInfoConfigurationType>();
  const displayError = (fieldName: string): boolean => {
    const errExist = Boolean(formikProps.errors[fieldName]);
    const isTouched = Boolean(formikProps.touched[fieldName]);
    return isTouched && errExist;
  };
  const rebuildValue = useCallback(
    (value: string): string => {
      let destValue = value;
      if (props.ignoreSpaces) {
        destValue = destValue.replace(/\s/g, '').trim();
      }
      if (props.toUpperCase) {
        destValue = destValue.toUpperCase();
      }
      return destValue;
    },
    [props]
  );
  const handleOnChange = useCallback(
    (value: string): void => {
      if (isDefined(props.setFieldTouched)) {
        props.setFieldTouched(props.name, true, false);
      }
      props.setFieldValue(
        props.name,
        rebuildValue(value),
        !!getIn(formikProps.errors, props.name)
      );
    },
    [props, formikProps, rebuildValue]
  );
  const handleOnBlur = useCallback((event: FocusEvent<HTMLInputElement>) => {
    if (props.setIsInputActive) {
      props.setIsInputActive(false);
    }
    props.setFieldValue(props.name, rebuildValue(event.target.value), true);
    if (isDefined(props.setFieldTouched)) {
      props.setFieldTouched(props.name, true, true);
    }
  }, [props, rebuildValue]);
  const value = useMemo(
    (): string => {
      return getFieldValueFromObject(props.values, props.name);
    },
    [props.values, props.name]
  );
  return (
    <StyledVehiclePlateInputContainer $height={startPageForm.inputs.height}>
      <StyledVehiclePlateInput
        {...props}
        inputState={displayError(props.name) ? BeeFieldState.ERROR : undefined}
        onChange={(e) => handleOnChange(e.target.value)}
        onBlur={handleOnBlur}
        value={value}
        $height={startPageForm.inputs.height}
      />
    </StyledVehiclePlateInputContainer>
  );
};
