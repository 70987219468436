import styled from 'styled-components';
import { ThemeConfiguration } from '../../../../config/tenants/theme.type';

interface StyledFormWrapperProps {
     $backgroundImageSrc?: string;
     $backgroundImageMdSrc?: string | null;
     $horizontalPositionMd?: string;
     $verticalPositionMd?: string;
     $verticalPositionLg?: string;
     $customImagePaddingBottom?: string;
     theme: ThemeConfiguration;
}

const getBackgroundImageMd = (props: StyledFormWrapperProps) => {
  if (props.$backgroundImageMdSrc === null) {
    return `background-image: none`;
  }

  return props.$backgroundImageMdSrc ? `
     background-image: url(${props.$backgroundImageMdSrc});
     padding-bottom: ${props.$customImagePaddingBottom ?? '20rem'};
     background-position: right bottom;
` : `
    background-image: url(${props.$backgroundImageSrc});
    background-position: center 10.125rem;
`;
};
const getBackgroundImage = (props: StyledFormWrapperProps) => `url(${props.$backgroundImageSrc})`;
const getBackgroundHorizontalPositionMd = (props: StyledFormWrapperProps) => `${props.$horizontalPositionMd ?? 'center'} `;
const getBackgroundVerticalPositionMd = (props: StyledFormWrapperProps) => `${props.$verticalPositionMd ?? '13.125rem'} `;
const getBackgroundVerticalPositionLg = (props: StyledFormWrapperProps) => `${props.$verticalPositionLg ?? props.$verticalPositionMd ?? '13.125rem'} `;

export const StyledWrapper = styled.div<StyledFormWrapperProps>`
     && {
          width: 100%;
          min-height: 25vh;
          background-repeat: no-repeat;
          background-size: contain;
          border-radius: 32px;
          background-position: center center;
          ${({ theme }) => theme.breakpoints.between('sm', 'md')} {
               background-image: ${getBackgroundImage};
               background-position: 5rem 13.125rem;
          }
       
       
          ${({ theme }) => theme.breakpoints.up('md')} {
               background-image: ${getBackgroundImage};
               background-position: ${getBackgroundHorizontalPositionMd} ${getBackgroundVerticalPositionMd};
          }
          
          ${({ theme }) => theme.breakpoints.up('lg')} {
               background-image: ${getBackgroundImage};
               background-size: auto;
               background-position: ${getBackgroundHorizontalPositionMd} ${getBackgroundVerticalPositionLg};
          }
          ${({ theme }) => theme.breakpoints.between('sm', 'md')} {
               ${getBackgroundImageMd};
               background-size: ${props => props.style?.backgroundSize ?? 'auto'};
               background-repeat: ${props => props.style?.backgroundRepeat ?? 'no-repeat'};
          }
     }
`;
