import React, { FC } from 'react';
import { OptionComponentType, ValueType } from './ToggleSelect.type';
import {
  ToggleSelectOptionWrapper,
  ToggleSelectOptionTitle,
  ToggleSelectOptionCaption,
  ToggleSelectOptionPrice,
  ToggleSelectDescriptionWrapper,
} from './ToggleSelect.style';

export const ToggleSelectOption: FC<OptionComponentType> = ({
  title,
  caption,
  price,
  value,
  onClick,
  selectedValue,
  dataE2e,
  showSelectedText,
}: OptionComponentType) => {
  const isSelected = selectedValue === value && !!showSelectedText;
  const getPriceText = (): ValueType => {
    const formattedPrice =
      typeof price === 'number' ? `${price.toLocaleString()} zł` : price;

    return isSelected && showSelectedText ? 'wybrany' : formattedPrice;
  };
  return (
    <ToggleSelectOptionWrapper
      onClick={() => {
        onClick(value);
      }}
      isSelected={isSelected}
      isCentered={!title && !caption}
    >
      {(title || caption) && (
        <ToggleSelectDescriptionWrapper
          data-e2e={`OfferDropDown Option: ${String(dataE2e)}`}
        >
          <ToggleSelectOptionTitle>{title}</ToggleSelectOptionTitle>
          <ToggleSelectOptionCaption>{caption}</ToggleSelectOptionCaption>
        </ToggleSelectDescriptionWrapper>
      )}

      <ToggleSelectOptionPrice
        data-e2e={`OfferDropDown Price: ${String(dataE2e)}`}
      >
        {getPriceText()}
      </ToggleSelectOptionPrice>
    </ToggleSelectOptionWrapper>
  );
};

export default ToggleSelectOption;
