import { TenantConfiguration, TenantsEnum } from '../tenant.type';
import BenefiaSpinner from '../../../modules/userdata/components/spinners/benefiaSpinner/BenefiaSpinner';
import cookieConfiguration from '../../../common/components/cookies/tenants/benefia';
import firstLogoUrl, { ReactComponent as FirstLogo } from './assets/firstLogo.svg';
import secondLogoUrl, { ReactComponent as SecondLogo } from './assets/secondLogo.svg';
import OfferDetailsData from './OfferDetails.data.json';
import { prepareBasePath } from '../common';
const configuration: TenantConfiguration = {
  tenantType: TenantsEnum.Benefia,
  tenantId: TenantsEnum.Benefia, // X-Tenant-Id header value passed to backend in order to indicate brand for particular request
  basePath: prepareBasePath('kalkulator-oc-ac'),
  firstLogo: { url: firstLogoUrl, component: FirstLogo, display: true, width: '18rem', href: 'https://benefia24.pl' },
  secondLogo: { url: secondLogoUrl, component: SecondLogo, display: false },
  suspenseLoader: {
    spinner: BenefiaSpinner,
  },
  CookieConfiguration: cookieConfiguration,
  offerDetailsData: OfferDetailsData
};

export default configuration;
