import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { setCallBackWidgetOpen } from '../../../../common/components/appState/redux/AppState.actions';
import { useTenant } from '../../../../config/tenants/Tenants.provider';
import { useTranslate } from '../../../../translations/translate/globalTranslate/TranslationProvider';
import {
  CallbackCaptionWrapper,
  CallbackContainer,
  CallbackImage,
  CallbackPhoneDescription,
  CallBackPhoneNumber,
  CallbackTitle,
  CallbackWrapper,
  PhoneContainer,
  PhoneImage,
  Wrapper
} from './CallbackIcon.style';
import { CallbackIconConfigurationType } from './tenants/tenant.type';
import { TenantsEnum } from '../../../../config/tenants/tenant.type';

export const CallbackIcon = (props: DispatchProps) => {
  const {
    CallbackIconConfiguration: { styles, callbackImage, callbackCaption },
    tenantType
  } = useTenant<CallbackIconConfigurationType>();
  const t = useTranslate();
  const { setCallBackWidgetOpen } = props;

  const onClick = useCallback(() => {
    if (tenantType !== TenantsEnum.Benefia && setCallBackWidgetOpen) {
      setCallBackWidgetOpen(true);
    }
  }, [setCallBackWidgetOpen, tenantType]);

  return (
    <Wrapper onClick={onClick} $styles={styles}>
      <CallbackWrapper $styles={styles}>
        <CallbackContainer $styles={styles}>
          <CallbackImage src={callbackImage} $styles={styles} />
        </CallbackContainer>
        <PhoneContainer $styles={styles}>
          <PhoneImage $styles={styles} />
        </PhoneContainer>
      </CallbackWrapper>
      {callbackCaption && (
        <CallbackCaptionWrapper>
          <CallbackTitle>{t('CALLBACK_TITLE')}</CallbackTitle>
          <CallbackPhoneDescription>
            {t('CALLBACK_PHONE_CAPTION')}
            <CallBackPhoneNumber>
              {t('CALLBACK_PHONE_NUMBER')}
            </CallBackPhoneNumber>
          </CallbackPhoneDescription>
        </CallbackCaptionWrapper>
      )}
    </Wrapper>
  );
};

const mapDispatchToProps = (dispatch: Function) => ({
  setCallBackWidgetOpen: (isCallBackWidgetOpen: boolean) =>
    dispatch(setCallBackWidgetOpen(isCallBackWidgetOpen))
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(null, mapDispatchToProps)(CallbackIcon);
