import { Button, ButtonProps } from '@material-ui/core';
import styled from 'styled-components';

interface NoFoundInfoWrapperProps {
        $isNotFound: boolean;
}

export const NoFoundInfoWrapper = styled.div<NoFoundInfoWrapperProps>`
    &&& {
        color: ${({ theme }) => theme.palette.commons.UE};
        animation: slide-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;
         
        @keyframes slide-bottom {
          0% {
             transform: translateY(-6.25rem);
             opacity: 0;
             height: 0;
             padding-top: 0;
          }
          100% {
             transform: translateY(0);
             opacity: 1;
             height: 11.5rem;
             padding-top: 2rem;
          }
        }
        
        ${({ theme }) => theme.breakpoints.down('xs')} {
            margin-bottom: -2rem;
        }
     }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 0.938rem;
  width: 4.0625rem;
`;

export const Header = styled.h3`
  padding: 0;
  margin: 0.2rem 0;
  color: ${({ theme }) => theme.palette.text.header};
  font-size: 1rem;
  font-weight: bold;
`;

export const Info = styled.h3`
  padding:0;
  margin: 0.2rem 0;
  color: ${({ theme }) => theme.palette.input.text.base};
  font-size: 0.8rem;
  font-weight: normal;
`;

export const ManualWayLink = styled(Button)<ButtonProps>`
    && {
        margin: 0;
        padding: 0;
        width: 100%;
        justify-content: flex-start;
        color: ${({ theme }) => theme.palette.text.link};
        font-weight: 600;
        text-transform: none;
        text-decoration: underline;
        text-align: left;
        font-size: 0.9rem;
        line-height: 0.9rem;
    }
`;
