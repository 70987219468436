import { subYears } from 'date-fns';

const MIN_AGE_OF_USER = 18;
export const VIEW_IDENTIFIER = 'BasicInfo';
export const MIN_CAR_YEAR = 1970;
export const MAX_CAR_YEAR = new Date().getFullYear();
export const MAX_USER_BIRTH_DATE = subYears(new Date(), MIN_AGE_OF_USER);
export const FORM_CONTAINER_SIZE_XS = 12;
export const FORM_CONTAINER_SIZE_SM = 7;
export const FORM_CONTAINER_SIZE_MD = 4;
export const GRID_SPACING = 5;
export const GRID_XS_SIZE = 12;
export const INITIAL_HIGHLIGHTED_BIRTH_DATE = '1980.01.01';
export const HEADLESS_PARAMS_NAME = 'headlessParams';
