import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  AxiosRequestConfig
} from 'axios';
import { isDefined } from '../utils/Object.utils';
import {
  handleErrorApiCalls,
  ServerError
} from './RequestInterceptor.service';
import { setupCache, IAxiosCacheAdapterOptions } from 'axios-cache-adapter';
import { configuration } from '../../config/tenants/Tenants.provider';
import { prepareFeatureHeaders } from '../utils/useFeatureFlags/useFeatureFlag';
import { getCSOUHostHeader } from './utils/getCSOUHostHeader.util';

declare module 'axios' {
  interface AxiosRequestConfig {
    errorPassThroughParam?: boolean;
  }
}

export const TENANT_HEADER_NAME = 'X-Tenant-Id';
export const QUOTED_TENANT_HEADER_NAME = 'X-QuoteTenant-Id';
export const AGENT_TOKEN_HEADER_NAME = 'X-Agent-Token';
export const CSOU_HOST_HEADER = 'X-CSOU-Host';

export default class RequestService {
  private static readonly requestServiceInstances: Map<string, RequestService> =
    new Map();
  private instance!: AxiosInstance;

  constructor(
    baseUrl: string,
    onFulfilled?: (data: AxiosResponse) => void,
    onRejected?: (error: AxiosError<ServerError>) => void,
    cacheOptions?: IAxiosCacheAdapterOptions
  ) {
    if (!RequestService.requestServiceInstances.has(baseUrl)) {
      this.configureAxios(baseUrl, onFulfilled, onRejected, cacheOptions);
      RequestService.requestServiceInstances.set(baseUrl, this);
    }

    return RequestService.requestServiceInstances.get(baseUrl)!;
  }

  private configureAxios = (
    baseUrl: string,
    onFulfilled?: (data: AxiosResponse) => void,
    onRejected?: (error: AxiosError<ServerError>) => void,
    cacheOptions?: IAxiosCacheAdapterOptions
  ) => {
    const axiosRequestConfig: AxiosRequestConfig = {
      baseURL: baseUrl,
      headers: {
        [TENANT_HEADER_NAME]: configuration.tenantId,
        [CSOU_HOST_HEADER]: getCSOUHostHeader()
      }
    };

    if (cacheOptions) {
      const cache = setupCache(cacheOptions);
      axiosRequestConfig.adapter = cache.adapter;
    }
    this.instance = axios.create(axiosRequestConfig);
    this.instance.interceptors.response.use(
      (success: AxiosResponse) => {
        if (isDefined(onFulfilled)) {
          onFulfilled(success);
        }
        return success;
      },
      (error: AxiosError<ServerError>): Promise<AxiosResponse<ServerError>> => {
        if (!error?.config.errorPassThroughParam) {
          handleErrorApiCalls(error, this.instance.defaults.headers);
        }
        if (isDefined(onRejected)) {
          onRejected(error);
        }
        return Promise.reject(error);
      }
    );
  };

  setHeader<T>(headerName: string, headerValue: T) {
    this.instance.defaults.headers[headerName] = headerValue;
  }

  getHeaders = () => {
    return this.instance.defaults.headers;
  };

  async request<T extends unknown>(options: AxiosRequestConfig): Promise<T> {
    const {
      url,
      params,
      data,
      headers,
      withCredentials,
      responseType,
      method,
      errorPassThroughParam
    } = options;

    const response: AxiosResponse<T> = await this.instance.request<T>({
      url: url,
      method: method,
      params: params,
      data: data,
      headers: headers,
      withCredentials: withCredentials,
      responseType: responseType,
      errorPassThroughParam: errorPassThroughParam
    });

    return response?.data;
  }

  // eslint-disable-next-line require-await
  async get<T extends unknown>(
    url: string,
    params?: unknown,
    headers?: AxiosRequestConfig['headers'],
    config?: Pick<AxiosRequestConfig, 'responseType' | 'errorPassThroughParam'>
  ): Promise<T> {
    const {
      responseType,
      errorPassThroughParam
    } = config ?? {};
    const featureHeaders = prepareFeatureHeaders();
    return this.request({
      url,
      params,
      headers: { ...featureHeaders, ...headers },
      responseType,
      withCredentials: true,
      method: 'get',
      errorPassThroughParam
    });
  }

  // eslint-disable-next-line require-await
  async post<T extends unknown>(
    url: string,
    data?: unknown,
    headers?: AxiosRequestConfig['headers']
  ): Promise<T> {
    const featureHeaders = prepareFeatureHeaders();
    return this.request({
      url,
      data,
      headers: { ...featureHeaders, ...headers },
      withCredentials: true,
      method: 'post'
    });
  }

  // eslint-disable-next-line require-await
  async put<T extends unknown>(
    url: string,
    data?: unknown,
    headers?: AxiosRequestConfig['headers']
  ): Promise<T> {
    const featureHeaders = prepareFeatureHeaders();
    return this.request({
      url,
      data,
      headers: { ...featureHeaders, ...headers },
      withCredentials: true,
      method: 'put'
    });
  }
}
