import React, { createContext, useContext } from 'react';
import Consents from './Consents.util';
import type { ConsentsModalContextProps, ConsentsModalContextType } from '../types';
import { useConsentsModalProvider } from './useConsentsModalProvider';

const defaultState: ConsentsModalContextType = {
  setIsAllSelected: () => null,
  handleSelectAll: () => null,
  setConsent: () => null,
  // @ts-ignore
  onModalClose: () => null,
  // @ts-ignore
  setOnModalClose: () => null,
  saveConsents: () => null,
  isAllSelected: false,
  consentsData: Consents.get(),
  setConsentsNames: () => null
};

export const ConsentsModalContext = createContext<ConsentsModalContextType>(defaultState);

export function ConsentsModalProvider(props: ConsentsModalContextProps) {
  const { children } = props;
  const hook = useConsentsModalProvider();

  return (
    <ConsentsModalContext.Provider value={hook}>
      {children}
    </ConsentsModalContext.Provider>
  );
}

export const useConsentsModalContext = () => useContext(ConsentsModalContext);

