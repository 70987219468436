import {
  AppStateBackPop,
  AppStateViewedPages,
  APP_STATE_ACTION_TYPES
} from '../../../common/components/appState/redux/AppStateAction.type';
import route from '../../../routing/Routes.config';
import { FinishQuoteAction, QUOTE_ACTIONS } from './quote/Quote.type';

const setNotUpToDateUserData = [
  route.PERSONAL,
  route.OWNER_SUMMARY,
  route.VEHICLE_DETAILS,
  route.ADDRESS,
  route.BASE_PATH,
  route.VEHICLE,
  route.PERSONAL_DECLARATIVE
];

export const hasUserDataChangedReducer = (
  state = false,
  action: AppStateViewedPages | AppStateBackPop | FinishQuoteAction
) => {
  const viewedPages = (action as AppStateBackPop).payload?.viewedPages;
  if (
    action.type === APP_STATE_ACTION_TYPES.VIEWED_PAGES &&
      setNotUpToDateUserData.includes(action.payload?.currentPage) ||
    action.type === APP_STATE_ACTION_TYPES.BACK_POP &&
      setNotUpToDateUserData.includes(viewedPages?.[viewedPages?.length - 1])
  ) {
    return true;
  }

  if (action.type === QUOTE_ACTIONS.FINISH_QUOTATION) {
    return false;
  }
  return state;
};
