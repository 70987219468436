import React from 'react';
import { Button } from '..';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';
import {
  StyledCookiesContentWrapper,
  StyledCookiesWrapperBar,
  StyledLink,
} from './CookiesBar.styled';
import { CookiesBarProps } from './CookiesBar.types';

export const CookiesBar: React.FC<CookiesBarProps> = ({
  cookiesPrefix,
  cookiesPolicyUrl,
  cookiesPolicyLabel,
  agreeTxt,
  zIndex,
}: CookiesBarProps) => {
  const [isCookiesApproved, setIsCookiesApproved] = React.useState(
    Boolean(sessionStorage.getItem('cookiesConsent') === 'true'),
  );

  const onClickHandler = (): void => {
    sessionStorage.setItem('cookiesConsent', 'true');
    setIsCookiesApproved(true);
  };

  return (
    <DefaultThemed>
      <StyledCookiesWrapperBar {...{ zIndex, isCookiesApproved }}>
        <StyledCookiesContentWrapper>
          {cookiesPrefix}&nbsp;
          <StyledLink href={cookiesPolicyUrl} target="_blank">
            {cookiesPolicyLabel}
          </StyledLink>
        </StyledCookiesContentWrapper>
        <Button
          typeStyle="outlined"
          size="small"
          onClick={() => onClickHandler()}
        >
          {agreeTxt}
        </Button>
      </StyledCookiesWrapperBar>
    </DefaultThemed>
  );
};
