import { BeeTextInput, Button } from '@sky-distribution/react-commons-ui';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { CallNowSection } from '../../../../../../components/CallNowSection';
import { FormSection } from '../../../../../../components/FormSection';
import { useSearchRequest } from '../../../../../../components/helpers/dataSender';
import { CALL_CENTER_PHONE } from '../../../../../../constants';
import {
  FormInfo,
  ShadowContainer,
  StyledContainer,
  StyledHeader,
} from './Base.style';
const Base = (): JSX.Element => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const { sendNumberToApi, loading } = useSearchRequest();

  const instantCallAction = (): string =>
    (document.location.href = `tel:${CALL_CENTER_PHONE}`);

  return (
    <StyledContainer>
      <StyledHeader>
        <h3>Masz pytania?</h3>
        <h4>Nasi konsultanci są do Twojej dyspozycji</h4>
      </StyledHeader>
      <ShadowContainer>
        <FormSection>
          <BeeTextInput
            name="phoneNumber"
            placeholder="Numer telefonu"
            label="Numer telefonu"
            type="tel"
            maskProps={{
              mask: "+48 999-999-999",
              alwaysShowMask: false,
              maskChar: "",
            }}
            validatorProps={{
              validate: {
                isValidPhoneNumber: true,
              },
              onCorrectChange: (value) => setPhoneNumber(value),
              onIncorrectChange: () => setPhoneNumber(""),
              normalize: true,
            }}
            tabIndex={13}
          />
        </FormSection>
        <FormInfo>
          Wyrażam zgodę na przetwarzanie mojego numeru telefonu w celu
          oferowania mi produktów na potrzeby tej wyceny ubezpieczenia.
        </FormInfo>
        <FormSection>
          <>
            <Button
              loader={loading}
              loaderDesc="Uwijamy się jak w ulu"
              typeStyle="primary"
              blockMode
              disabled={phoneNumber.length < 12}
              onClick={() => sendNumberToApi(phoneNumber)}
            >
              ZOSTAW KONTAKT
            </Button>
          </>
        </FormSection>
      </ShadowContainer>
      {isMobile ? (
        <CallNowSection onClick={instantCallAction}>
          Zadzwoń teraz
        </CallNowSection>
      ) : (
        <CallNowSection />
      )}
    </StyledContainer>
  );
};

export default Base;
