import { DeclarativeData } from '@sky-distribution/mf-declarative-path';
import { Dispatch } from 'redux';
import { AppActions } from '../../../../../redux/actions/AppActions.type';
import { SessionState } from '../../../redux/session/Session.type';
import { UpdateSubmissionObjectType } from '../../../service/Submission.type';
import { submitDeclarationDataRequest } from '../service/DeclarativeData.service';
import { DeclarativeDataClearAction, DeclarativeDataSavedAction, DECLARATIVE_DATA_ACTIONS } from './DeclarativeDataActions.type';

export const updateDeclarativeData = (
  data: DeclarativeData,
  updateObject: UpdateSubmissionObjectType,
  session?: SessionState,
  step?: string
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    await submitDeclarationDataRequest({
      step: step,
      quoteUuid: session?.quoteUuid,
      data,
      updateObject
    });
    dispatch(declarativeDataSavedAction({ ...data }));
  };
};

export const declarativeDataSavedAction = (
  personalData: DeclarativeData
): DeclarativeDataSavedAction => {
  return {
    type: DECLARATIVE_DATA_ACTIONS.DATA_SAVED,
    data: personalData
  };
};

export const clearDeclarativeData = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(declarativeDataClearAction());
  };
};

export const declarativeDataClearAction = (
): DeclarativeDataClearAction => {
  return {
    type: DECLARATIVE_DATA_ACTIONS.CLEAR_DATA
  };
};
