import { useTheme } from '@sky-distribution/react-commons-ui';
import React from 'react';
import {
  Check,
  LabelText,
  LabelTextContainer,
  LabelWithCheckContainer,
} from './LabelWithCheck.styles';
import { LabelWithCheckProps } from './LabelWithCheck.types';

export const LabelWithCheck = ({
  labelText,
  handleOnClick,
}: LabelWithCheckProps): JSX.Element => {
  const theme = useTheme();

  const handleClick: React.MouseEventHandler<HTMLDivElement> = () => {
    if (handleOnClick && labelText) {
      handleOnClick(labelText);
    }
  };
  return (
    <LabelWithCheckContainer onClick={handleClick}>
      <Check />
      <LabelTextContainer>
        <LabelText
          component="span"
          color={theme.palette.text.paragraph.primary}
          variant={theme.typography.subtitle1}
        >
          {labelText}
        </LabelText>
      </LabelTextContainer>
    </LabelWithCheckContainer>
  );
};
