import React from 'react';
import { isMobile } from 'react-device-detect';
import { ReactComponent as Phone } from '../ExportedComponents/components/tenants/beesafe/components/Base/assets/phone.svg';
import { Description } from '../components/Description';
import { FormSection } from '../components/FormSection';
import { HeaderWithIcon } from '../components/HeaderWithIcon/HeaderWithIcon.component';

export type CloseComponentType = {
  children: JSX.Element;
};

export function ThankYouPage({ children }: CloseComponentType): JSX.Element {
  return (
    <>
      <HeaderWithIcon>
        <Phone width="180" />
        <h3>Dziękujemy za pozostawienie numeru.</h3>
      </HeaderWithIcon>
      <FormSection>
        <Description>
          Oddzwonimy do Ciebie w najbliższym możliwym terminie. <br />
          Pracujemy w dni robocze: 8:00-18:00
        </Description>
      </FormSection>
      {isMobile && <FormSection>{children}</FormSection>}
    </>
  );
}
