import { FlagsConfiguration } from '../common/utils/useFeatureFlags/prepareFeaturesFromEnv';
import { prepareFeatureFlags, prepareFeatureFlagsFromCookie, prepareFlagsFromConfigMap } from '../common/utils/useFeatureFlags/useFeatureFlag';
import { getDeployEnvName } from '../utils/getDeployEnvName';

export const FEATURE_COOKIE_NAME = 'featureflags';

export const getFeatureFlags = (flagsFromEnv?: FlagsConfiguration, envName?: string, ) => {
  const env = getDeployEnvName();

  return prepareFeatureFlags(
    prepareFlagsFromConfigMap(envName ?? env, flagsFromEnv),
    prepareFeatureFlagsFromCookie(FEATURE_COOKIE_NAME)
  );
};
