import { AxiosRequestConfig } from 'axios';
import { UriBuilder } from '../../../common/http/UriBuilder';
import { onboardingRequestService } from '../../../http/RequestService.http';
import {
  CheckQuoteApiRequest, CheckQuoteApiResponse, CreateSubmissionApiRequest,
  CreateSubmissionApiResponse, QuoteSubmissionApiRequest,
  QuoteSubmissionApiResponse,
  RetrieveSubmissionApiRequest,
  RetrieveSubmissionFromCCApiRequest,
  RetrieveSubmissionResponse,
  SaveSubmissionRequestType, SelectCoveragesApiRequest,
  SelectQuoteApiRequest,
  UpdateSubmissionApiRequest,
  UpdateSubmissionApiResponse
} from './Submission.type';

export const createSubmissionRequest = (
  createLeadApiRequest: CreateSubmissionApiRequest
): Promise<CreateSubmissionApiResponse> => {
  const url = new UriBuilder().all('submission').all('create').build();
  const headers = createLeadApiRequest?.agentToken ? { 'X-Agent-Token': `${createLeadApiRequest.agentToken}` } : undefined;
  return onboardingRequestService.post<CreateSubmissionApiResponse>(url, createLeadApiRequest, headers);
};

export const updateSubmissionRequest = (
  updateSubmissionApiRequest: UpdateSubmissionApiRequest
): Promise<UpdateSubmissionApiResponse> => {
  const url = new UriBuilder().all('submission').all('update').build();
  return onboardingRequestService.put<UpdateSubmissionApiResponse>(url, updateSubmissionApiRequest);
};

export const quoteSubmissionRequest = (
  quoteSubmissionApiRequest: QuoteSubmissionApiRequest,
  headers: AxiosRequestConfig['headers']
): Promise<QuoteSubmissionApiResponse> => {
  const url = new UriBuilder().all('submission').all('quote').build();
  return onboardingRequestService.put<QuoteSubmissionApiResponse>(url, quoteSubmissionApiRequest, headers);
};

export const recalculateQuoteSubmissionRequest = (
  recalculateQuoteSubmissionApiRequest: CheckQuoteApiRequest,
  headers: AxiosRequestConfig['headers']
): Promise<CheckQuoteApiResponse> => {
  const url = new UriBuilder().all('submission').all('check-quote').build();
  return onboardingRequestService.put<CheckQuoteApiResponse>(url, recalculateQuoteSubmissionApiRequest, headers);
};

export const retrieveSubmissionRequest = (
  retrieveSubmissionApiRequest: RetrieveSubmissionApiRequest,
  headers: AxiosRequestConfig['headers']
): Promise<RetrieveSubmissionResponse> => {
  const urlBuilder = new UriBuilder().all('submission');
  const url = retrieveSubmissionApiRequest.confirmData
    ? urlBuilder.all('recreate').build()
    : urlBuilder.all(retrieveSubmissionApiRequest.quoteUuid).all('retrieve').build();
  return onboardingRequestService.post<RetrieveSubmissionResponse>(url, {
    confirmData: retrieveSubmissionApiRequest.confirmData,
    isRenewal: retrieveSubmissionApiRequest.isRenewal
  }, headers);
};

export const retrieveSubmissionFromCCRequest = (
  retrieveSubmissionFromCCApiRequest: RetrieveSubmissionFromCCApiRequest,
  isRenewal: boolean
): Promise<RetrieveSubmissionResponse> => {
  const url = new UriBuilder().all('submission').all('ccretrieve').queryParams({ isRenewal }).build();
  return onboardingRequestService.get<RetrieveSubmissionResponse>(url, retrieveSubmissionFromCCApiRequest);
};

export const selectQuoteSubmissionRequest = (
  selectQuoteApiRequest: SelectQuoteApiRequest,
  headers: Record<string, string>
): Promise<void> => {
  const url = new UriBuilder().all('submission').all('select-quote').build();
  return onboardingRequestService.put<void>(url, selectQuoteApiRequest, headers);
};

export const selectCoveragesSubmissionRequest = (
  selectCoveragesApiRequest: SelectCoveragesApiRequest,
  headers: Record<string, string>
): Promise<void> => {
  const url = new UriBuilder().all('submission').all('select-coverages').build();
  return onboardingRequestService.put<void>(url, selectCoveragesApiRequest, headers);
};

export const save = (
  saveData: SaveSubmissionRequestType
): Promise<void> => {
  const url = new UriBuilder().all('submission').all('save').build();
  return onboardingRequestService.post<void>(url, saveData);
};
