/* eslint-disable no-magic-numbers */
import route from '../../../../routing/Routes.config';

export enum APPLICATION_STEPS {
  CAR = 0,
  OWNER = 1,
  OFFER = 2,
  DETAILS = 3,
  ADDRESS = 4,
  SUMMARY = 5,
  PAYMENT = 6,
  DONE = 7
}

const STEPS_ROUTE = {
  START: [
    route.BASE_PATH
  ],
  CAR: [
    route.VEHICLE
  ],
  OWNER: [
    route.PERSONAL,
    route.PERSONAL_DECLARATIVE,
    route.ADDITIONAL_USERDATA
  ],
  OFFER: [
    route.OFFER
  ],
  DETAILS: [
    route.POLICY_START,
    route.CONFIRM_DATA,
    route.OWNER_SUMMARY,
    route.VEHICLE_DETAILS
  ],
  ADDRESS: [
    route.ADDRESS,
    route.CO_OWNER_ADDRESS
  ],
  SUMMARY: [
    route.OFFER_SUMMARY,
    route.CONTACT,
    route.SUMMARY
  ],
  PAYMENT: [
    route.PAYMENT,
    route.PAYMENT_FREQUENCY,
    route.PAY_BY_LINK,
    route.PAYMENT_CONFIRMATION
  ],
  DONE: [
    route.THANKYOU
  ]
};

export const getApplicationStepsData = (currentRoute: string) => [
  {
    id: APPLICATION_STEPS.CAR,
    active: STEPS_ROUTE.CAR.includes(currentRoute),
    label: 'STEPS_BAR_CAR'
  },
  {
    id: APPLICATION_STEPS.OWNER,
    active: STEPS_ROUTE.OWNER.includes(currentRoute),
    label: 'STEPS_BAR_OWNER'
  },
  {
    id: APPLICATION_STEPS.OFFER,
    active: STEPS_ROUTE.OFFER.includes(currentRoute),
    label: 'STEPS_BAR_OFFER'
  },
  {
    id: APPLICATION_STEPS.DETAILS,
    active: STEPS_ROUTE.DETAILS.includes(currentRoute),
    label: 'STEPS_BAR_DETAILS'
  },
  {
    id: APPLICATION_STEPS.ADDRESS,
    active: STEPS_ROUTE.ADDRESS.includes(currentRoute),
    label: 'STEPS_BAR_ADDRESS'
  },
  {
    id: APPLICATION_STEPS.SUMMARY,
    active: STEPS_ROUTE.SUMMARY.includes(currentRoute),
    label: 'STEPS_BAR_SUMMARY'
  },
  {
    id: APPLICATION_STEPS.PAYMENT,
    active: STEPS_ROUTE.PAYMENT.includes(currentRoute),
    label: 'STEPS_BAR_PAYMENT'
  },
  {
    id: APPLICATION_STEPS.DONE,
    active: STEPS_ROUTE.DONE.includes(currentRoute),
    label: 'DONE'
  }
];
