import { RootState } from '../../reducers/RootReducer';
import { rewriteData, transformedActions } from './transformedActions';

interface Action<T = unknown> {
  type: T;
}
interface AnyAction extends Action {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [extraProps: string]: any;
}

const handlers = transformedActions.reduce<
  Record<string, (action: AnyAction) => AnyAction>
    >((acc, { actionName, transformer }) => {
      acc[actionName] = (action: AnyAction) => {
        return transformer(action);
      };
      return acc;
    }, {});

export const actionTransformerActions = (action: AnyAction) => {
  const { type } = action;
  return handlers[type as string]?.(action) ?? action;
};

export const transformedState = (state: RootState) => {
  return {
    ...state,
    userData: {
      ...state.userData,
      personalData: rewriteData,
      addressData: rewriteData,
      declarativeData: {
        data: {
          ...state.userData?.declarativeData?.data,
          zipCode: rewriteData,
          birthDate: rewriteData,
          phoneNumber: rewriteData,
          email: rewriteData
        }
      }
    }
  };
};
