import { Grid } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { useTenant } from '../../../config/tenants/Tenants.provider';
import { CommonRootStateType } from '../../redux/reducers/CommonRootReducer';
import { StyledCookies } from './Cookies.style';

interface Props extends StateProps {
  onAccept: () => void;
  onClose: () => void;
}

export const Cookies = (props: Props) => {
  const {
    CookieConfiguration: { cookieAnchorText }
  } = useTenant();

  const GRID_XS_FULL = 12;
  const GRID_XS_COLUMN = 1;
  const GRID_XS_CONTENT = 9;
  const GRID_MD_TEXT_ITEM = 11;

  return (
    <StyledCookies>
      <Grid item xs={GRID_XS_FULL} className="container">
        <Grid item container xs={GRID_XS_FULL} alignItems="center">
          <Grid
            item
            xs={GRID_XS_CONTENT}
            md={GRID_MD_TEXT_ITEM}
            className="content-grid"
          >
            {props.translate('COOKIES_PREFIX')}
            <a className="reference" href={props.translate('COOKIES_URL')}>
              {props.translate(cookieAnchorText)}
            </a>
            {props.translate('COOKIES_AGREEMENT_TEXT')}
          </Grid>
          <Grid item xs={GRID_XS_COLUMN} md={GRID_XS_COLUMN}>
            <button className="accept" onClick={props.onAccept}>
              {props.translate('OK_ACCEPT')}
            </button>
          </Grid>
        </Grid>
      </Grid>
    </StyledCookies>
  );
};

const mapStateToProps = (state: CommonRootStateType) => {
  return {
    translate: state.translate.t
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Cookies);
