/* eslint-disable max-lines-per-function */
import React, { lazy, Suspense } from 'react';
import { withSentryRouting } from '@sentry/react';
import TagManager from 'react-gtm-module';
import { Redirect, Route as DomRoute, Switch } from 'react-router-dom';
import PageLoader from '../modules/userdata/components/spinners/pageLoader/PageLoader.component';
import route from './Routes.config';
import { ConsentsModalProvider } from '../providers/ConsentsModalProvider/ConsentsModalProvider';
import { useFeatureFlag } from '../common/utils/useFeatureFlags/useFeatureFlag';
import StepsIndicatorWrapped from '../modules/userdata/containers/StepsIndicator';
import { safeWindow } from '../utils/window';
import BasicInfo from '../modules/userdata/containers/BasicInfo';

const PaymentFrequencyComponent = lazy(() => import('../modules/userdata/containers/PaymentPlan'));
const CarNotFound = lazy(() => import('../modules/userdata/containers/CarNotFound'));
const CarInfo = lazy(() => import('../modules/userdata/containers/CarInfo'));
const PersonalData = lazy(() => import('../modules/userdata/containers/PersonalData'));
const DeclarativeData = lazy(() => import('../modules/userdata/containers/DeclarativeData'));
const AdditionalOfferInfo = lazy(() => import('../modules/userdata/containers/AdditionalOfferInfo'));
const ConfirmData = lazy(() => import('../modules/userdata/containers/ConfirmData'));
const CarDetails = lazy(() => import('../modules/userdata/containers/CarDetails'));
const CoOwnerAddressInfo = lazy(() => import('../modules/userdata/containers/CoOwnerAddressInfo'));
const Address = lazy(() => import('../modules/userdata/containers/AddressInfo'));
const CanNotBeInsuredScreen = lazy(() => import('../modules/userdata/components/canNotBeInsuredScreen'));
const CCRetrieveUserData = lazy(() => import('../modules/userdata/components/ccRetrieveUserData/CCRetrieveUserData.component'));
const ConfirmationOfInsurance = lazy(() => import('../modules/userdata/components/confirmationOfInsurance/ConfirmationOfInsurance.component'));
const ContactInfo = lazy(() => import('../modules/userdata/containers/ContactInfo'));
const DifferentQuote = lazy(() => import('../modules/userdata/containers/DifferentQuote'));
const NoMatchedRoute = lazy(() => import('../modules/userdata/components/noMatchedRoute'));
const OfferInfo = lazy(() => import('../modules/userdata/containers/OfferInfo'));
const MfOffer = lazy(() => import('../modules/userdata/containers/MF-Offer'));
const OwnerSummary = lazy(() => import('../modules/userdata/components/ownerSummary'));
const PolicyStartDate = lazy(() => import('../modules/userdata/containers/PolicyStartDate'));
const RetrieveUserData = lazy(() => import('../modules/userdata/components/retrieveUserData/RetrieveUserData.component'));
const RetrievePaymentUserData = lazy(() => import('../modules/userdata/components/retrievePaymentUserData/RetrievePaymentUserData.component'));
const ThankYouPage = lazy(() => import('../modules/userdata/containers/ThankYouPage'));
const PaymentTimeoutPage = lazy(() => import('../modules/userdata/components/PaymentTimeoutPage'));
const PaymentConfirmation = lazy(() => import('../modules/userdata/components/paymentConfirmation/PaymentConfirmationPage.component'));
const MfPolicySummary = lazy(() => import('../modules/userdata/containers/MF-PolicySummary'));
const PolicyInfo = lazy(() => import('../modules/userdata/containers/PolicySummary'));
const GenericErrorPage = lazy(() => import('../modules/userdata/containers/GeneralError'));
const OfferInvalid = lazy(() => import('../modules/userdata/components/OfferInvalid'));
const PaymentInterrupted = lazy(() => import('../modules/userdata/components/PaymentInterrupted'));

if (safeWindow.GOOGLE_GTM_ID) {
  TagManager.initialize({ gtmId: window.GOOGLE_GTM_ID });
}

const Route = withSentryRouting(DomRoute);

export const RoutesComponent = () => {
  const MF_POLICY_SUMMARY_DISABLED = !useFeatureFlag('mf-policy-summary');
  const MF_OFFER_ENABLED = useFeatureFlag('mf-offer');

  return (
    <div className="view-routes">
      <ConsentsModalProvider>
        <Route component={() => <StepsIndicatorWrapped />} />
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route strict exact path={route.BASIC_USERDATA} component={BasicInfo} />
            <Route strict exact path={route.CAR_NOT_FOUND} component={CarNotFound} />
            <Route strict exact path={route.PRICE_ERROR} component={DifferentQuote} />
            <Route strict exact path={route.VEHICLE} component={CarInfo} />
            <Route strict exact path={route.PERSONAL} component={PersonalData} />
            <Route strict exact path={route.PERSONAL_DECLARATIVE} component={DeclarativeData} />
            <Route strict exact path={route.ADDITIONAL_USERDATA} component={AdditionalOfferInfo} />

            <Route strict exact path={route.OFFER} component={MF_OFFER_ENABLED ? MfOffer : OfferInfo} />

            <Route strict exact path={route.ADDRESS} component={Address} />
            <Route strict exact path={route.CO_OWNER_ADDRESS} component={CoOwnerAddressInfo} />
            {MF_POLICY_SUMMARY_DISABLED && <Route strict exact path={route.VEHICLE_DETAILS} component={CarDetails} />}
            <Route strict exact path={route.OWNER_SUMMARY} component={OwnerSummary} />

            {MF_POLICY_SUMMARY_DISABLED && <Route strict exact path={route.POLICY_START} component={PolicyStartDate} />}

            <Route strict exact path={route.CONFIRM_DATA} component={ConfirmData} />

            <Route strict exact path={route.SUMMARY} component={MF_POLICY_SUMMARY_DISABLED ? PolicyInfo : MfPolicySummary} />

            {MF_POLICY_SUMMARY_DISABLED && <Route strict exact path={route.CONTACT} component={ContactInfo} />}
            <Route strict exact path={route.CAN_NOT_BE_INSURED} component={CanNotBeInsuredScreen} />
            <Route strict exact path={route.PAYMENT_FREQUENCY} component={PaymentFrequencyComponent} />
            <Route strict exact path={route.CONFIRM_INSURANCE} component={ConfirmationOfInsurance} />
            <Route strict exact path={route.THANKYOU} component={ThankYouPage} />
            <Route strict exact path={route.PAYMENT_TIMEOUT} component={PaymentTimeoutPage} />
            <Route strict exact path={route.RETRIEVE_PAYMENT_BY_QUOTEUUID} component={RetrievePaymentUserData} />
            <Route strict exact path={route.RETRIEVE_BY_QUOTEUUID} component={RetrieveUserData} />
            <Route strict exact path={route.CC_RETRIEVE} component={CCRetrieveUserData} />
            <Route strict exact path={route.PAYMENT_CONFIRMATION} component={PaymentConfirmation} />
            <Route strict exact path={route.NOT_FOUND} component={NoMatchedRoute} />
            <Route strict exact path={route.GENERIC_ERROR} component={GenericErrorPage} />
            <Route strict exact path={route.INVALID_OFFER_OR_ALREADY_PAYED} component={OfferInvalid} />
            <Route strict exact path={route.PAYMENT_INTERRUPTED} component={PaymentInterrupted} />
            <Redirect to={route.NOT_FOUND} />
          </Switch>
        </Suspense>
      </ConsentsModalProvider>
    </div>
  );
};
