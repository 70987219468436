import { CSSProperties } from 'styled-components';
export const ConfigurationKey = 'CallbackIconConfiguration';

export interface CallbackIconStyles {
  wrapperDisplay: CSSProperties['display'];
  clipPath: CSSProperties['clipPath'];
  phoneImage: {
    smWidth: CSSProperties['width'];
    smHeight: CSSProperties['height'];
  };
  callBackContainer: {
    background: CSSProperties['background'];
  };
  callbackImage: {
    borderRadius: CSSProperties['borderRadius'];
    smWidth: CSSProperties['width'];
    smHeight: CSSProperties['height'];
  };
  callbackImageContainer: {
    smHeight: CSSProperties['height'];
  };
  phoneContainer: {
    borderRadius: CSSProperties['borderRadius'];
    smHeight: CSSProperties['height'];
    smBottom: CSSProperties['bottom'];
  };
}

export type CallbackIconConfigurationType = {
  [ConfigurationKey]: {
    callbackImage: string;
    callbackCaption?: boolean;
    styles: CallbackIconStyles;
  };
};
