import { Button, CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

interface StyledBaseButtonProps {
  colors?: {
    backgroundColor?: string;
    textColor?: string;
  };
  variant?: 'outlined' | 'text' | 'contained';
  isLoading?: boolean;
  $isHidden?: boolean;
}

const getVisibility = (props: StyledBaseButtonProps) => {
  return props.$isHidden ? 'hidden' : 'visible';
};

const getBackgroundColor = (props: StyledBaseButtonProps) => {
  return props.colors?.backgroundColor;
};

const getTextColor = (props: StyledBaseButtonProps) => {
  return props.colors?.textColor;
};

const getBorderColor = (props: StyledBaseButtonProps) => {
  return props.variant === 'outlined' ? props.colors?.textColor : '';
};

export const StyledBaseButton = styled(Button)`
  && {
    visibility: ${getVisibility};
    background-color: ${getBackgroundColor};
    color: ${getTextColor};
    border-color: ${getBorderColor};
    text-transform: none;
    box-shadow: none;

    :disabled {
      opacity: 0.5;
      background-color: ${getBackgroundColor};
      color: ${getTextColor};
      border-color: ${getBorderColor};
    }

    :hover {
      background-color: ${getBackgroundColor};
    }
  }
`;

export const LoadingLabel = styled.span`
  overflow: hidden;
  white-space: nowrap;
  max-height: 100%;
  text-overflow: ellipsis;
`;

export const StyledCircularProgress = styled(CircularProgress)`
  &&& {
    color: ${({ theme }) => theme.palette.commons.background.dimmed};
    margin-right: .5rem;
  }
`;
