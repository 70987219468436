import { IGetTranslateFunctionResponse } from 'redux-i18n';
import { TranslateMethodInitAction, TRANSLATE_ACTIONS } from './TranslateAction.type';

type TranslateStateType = { t: IGetTranslateFunctionResponse };

const initialState: TranslateStateType = { t: () => '' };

export const translateInitMethodReducer = (
  state = initialState,
  action: TranslateMethodInitAction
): TranslateStateType => {
  switch (action.type) {
    case TRANSLATE_ACTIONS.METHOD_INIT:
      return { ...state, t: action.t };
    default:
      return state;
  }
};
