import styled from 'styled-components';

export const DropdownLabel = styled.div`
  display: inline-block;
  font-size: 0.8rem;
  overflow: hidden;
  padding: 0.2rem 0 0.2rem 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Arrow = styled.div<{ isExpanded: boolean }>`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 0.25rem;
  margin-right: 1rem;
  stroke: ${(p) => p.theme.palette.brand.color};
  transform: rotate(${(p) => (p.isExpanded ? '0' : '180')}deg) scale(0.8);
  transition: all 0.5s ease;

  path {
    stroke-width: 2px;
  }
`;
