import React, { createContext, useContext } from 'react';
import type { ContextProps, SaveOfferModalContextType } from '../types';
import { useSaveOfferModalProvider } from './useSaveOfferModalProvider';

const defaultState: SaveOfferModalContextType = {
  isFormSubmitting: false,
  onSubmit: () => null,
  initialValues: {
    email: '',
    session: {}
  },
  setIsSuccessful: () => null
};

export const SaveOfferModalContext = createContext<SaveOfferModalContextType>(defaultState);

export function SaveOfferModalProvider(props: ContextProps) {
  const { children } = props;
  const hook = useSaveOfferModalProvider();

  return (
    <SaveOfferModalContext.Provider value={hook}>
      {children}
    </SaveOfferModalContext.Provider>
  );
}

export const useSaveOfferModalContext = () => useContext(SaveOfferModalContext);
