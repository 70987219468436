import { CSSProperties, ComponentType } from 'react';
import { LanguageDictionaryType as BeesafeLanguageDictionaryType, TranslationsType as BeesafeTranslationsType } from './beesafe/translations/beesafe.type';
import { LanguageDictionaryType as BenefiaLanguageDictionaryType, TranslationsType as BenefiaTranslationsType } from './benefia/translations/benefia.type';
import { LanguageDictionaryType as CompensaLanguageDictionaryType, TranslationsType as CompensaTranslationsType } from './compensa/translations/compensa.type';

export const ConfigurationKey = 'BasicInfoConfiguration';

export type LogoConfiguration = {
  url: string;
  display: boolean;
  component: ComponentType;
  width?: string;
  href?: string;
};

export type FooterLink = {
  href: string;
  translationKey: string;
};

export type ShortPathButtonStyles = {
  height?: string;
};

export type ManualButtonStyles = {
  radius?: CSSProperties['borderRadius'];
  width?: CSSProperties['width'];
  borderColor?: CSSProperties['color'];
  color?: CSSProperties['color'];
  fontSize?: CSSProperties['fontSize'];
};

export type StartPageInputs = {
  height: string;
};

export type StartPageFormConfig = {
  width: string;
  height?: string;
  padding: string;
  inputs: StartPageInputs;
};

export type BasicInfoConfigurationType = {
  [ConfigurationKey]: {
    datePickerIcon: string;
    alertIcon: string;
    homeBgDesktop: string;
    homeBgMobile: string | undefined;
    displayOrLabel: boolean;
    desktopVerticalBackgroundPosition: string;
    desktopBackgroundSize?: string;
    styledHeaderMarginBottom: string;
    boxShadow?: CSSProperties['boxShadow'];
    startPageForm: StartPageFormConfig;
    manualButton?: ManualButtonStyles;
    shortPathButton?: ShortPathButtonStyles;
    footerLinks: FooterLink[];
    homeBgStyles?: {
      backgroundSize?: string;
      backgroundRepeat?: string;
    };
    startTitle: {
      displayTitle: boolean;
      displaySubtitle: boolean;
      style:{
        customMargin: string;
        customWidth: string;
        customH1Color: string;
      };
    };
  };
};

export type BasicInfoTranslationsType = BeesafeTranslationsType | BenefiaTranslationsType | CompensaTranslationsType;
export type BasicInfoLanguageDictionaryType = BeesafeLanguageDictionaryType | BenefiaLanguageDictionaryType | CompensaLanguageDictionaryType;
