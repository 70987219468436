import { PaymentInfoState } from '../service/PaymentInfo.type';
import { PaymentAction, PAYMENT_INFO_ACTIONS } from './PaymentInfo.type';

const initialState = {
  finalPrice: undefined
} as PaymentInfoState;

export const paymentInfoReducer = (
  state = initialState,
  action: PaymentAction
): PaymentInfoState => {
  switch (action.type) {
    case PAYMENT_INFO_ACTIONS.SET_FINAL_PRICE:
      return { ...state, finalPrice: action.data };
    case PAYMENT_INFO_ACTIONS.RESET_FINAL_PRICE:
      return { ...state, finalPrice: undefined };
    default:
      return state;
  }
};
