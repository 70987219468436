import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { getCookie } from '../documentCookie';
import isEqual from 'lodash.isequal';
import { FlagsConfiguration, prepareFeatureFlagsForEnv } from './prepareFeaturesFromEnv';
import useDeepCompareEffect from 'use-deep-compare-effect';

export type FeatureRecord = Record<string, boolean>;
let FeaturesContainer: FeatureRecord = {};

export const readCookie = (name: string) => {
  const encoded = getCookie()
    .split(';')
    .find(record => record.trim().startsWith(name))
    ?.split('=')?.[1];
  return encoded && decodeURIComponent(encoded);
};

export const FeatureFlagContext = React.createContext<FeatureRecord>({});

export const prepareFeatureFlags = (...featureLists: FeatureRecord[]): FeatureRecord => {
  return featureLists.reduce((acc, list) => {
    return { ...acc, ...list };
  }, {});
};

export const prepareFeatureFlagsFromString = (featureString: string | undefined): FeatureRecord => {
  if (!featureString) {
    return {};
  }
  try {
    return JSON.parse(featureString);
  } catch (err) {
    return {};
  }
};

export const prepareFeatureFlagsFromCookie = (cookieName: string): FeatureRecord => {
  return prepareFeatureFlagsFromString(readCookie(cookieName));
};


export const prepareFlagsFromConfigMap = (rawEnvName?: string, flags?: FlagsConfiguration): FeatureRecord => {
  if (!flags) {
    return {};
  }
  return prepareFeatureFlagsForEnv(flags, rawEnvName);
};



export const useFeatureFlag = (name: string): boolean => {
  const features = useContext(FeatureFlagContext);
  return !!features[name];
};

const getFeatureFlag = (name: string): boolean => {
  const feature = FeaturesContainer[name];
  return !!feature;
};

export const prepareFeatureHeaders = () => {
  const headers: Record<string, string | number | boolean> = {};
  Object.keys(FeaturesContainer).filter(key => FeaturesContainer[key]).forEach(feature => {
    headers[`x-feature-${feature}`] = getFeatureFlag(feature);
  });
  return headers;
};

export const FeatureFlagProvider = (props: PropsWithChildren<{ getter: () => FeatureRecord ; eventName: string}>) => {
  const [features, setFeatures] = useState(props.getter());
  FeaturesContainer = features;

  useDeepCompareEffect(() => {
    setFeatures(props.getter());
  }, [props.getter()]);

  useEffect(() => {
    const listener = () => {
      const nextFeatures = props.getter();
      if (!isEqual(features, nextFeatures)) {
        setFeatures(nextFeatures);
      }
    };
    window.addEventListener(props.eventName, listener);

    return () => {
      window.removeEventListener(props.eventName, listener);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FeatureFlagContext.Provider value={features}>
      { props.children }
    </FeatureFlagContext.Provider>
  );
};
