import { Dispatch } from 'redux';
import { AppActions } from '../../../../../redux/actions/AppActions.type';
import {
  BASIC_INFO_ACTIONS, ClearPathSelectionAction, ManualPathSelectedAction, StartPolicyFromCepDataAction, UtmDataAction, VehicleSimplifiedSelectedAction
} from './BasicInfoActions.type';

export const selectManualPath = () => (dispatch: Dispatch<AppActions>) => {
  dispatch(selectManualPathAction());
};

export const selectManualPathAction = (): ManualPathSelectedAction => ({
  type: BASIC_INFO_ACTIONS.MANUAL_PATH
});

export const clearSelectedPath = () => (dispatch: Dispatch<AppActions>) => {
  dispatch(clearSelectedPathAction());
};

export const clearSelectedPathAction = (): ClearPathSelectionAction => {
  return {
    type: BASIC_INFO_ACTIONS.CLEAR_PATH_SELECTION
  };
};


export const selectVehicleSimplified = (data: boolean) => (dispatch: Dispatch<AppActions>) => {
  dispatch(selectVehicleSimplifiedAction(data));
};

const selectVehicleSimplifiedAction = (data: boolean): VehicleSimplifiedSelectedAction => {
  return {
    type: BASIC_INFO_ACTIONS.VEHICLE_SIMPLIFIED,
    data
  };
};

export const saveUtmData = (data: string): UtmDataAction => ({
  type: BASIC_INFO_ACTIONS.UTM_DATA,
  data
});

export const saveStartPolicyFromCepData = (data: boolean): StartPolicyFromCepDataAction => ({
  type: BASIC_INFO_ACTIONS.START_POLICY_FROM_CEP,
  data
});
