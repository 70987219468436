import { configuration } from '../config/tenants/Tenants.provider';

const route = {
  BASENAME: configuration.basePath,
  BASE_PATH: '/',
  BASIC_USERDATA: '/',
  CAR_NOT_FOUND: '/nie-znaleziono-samochodu',
  PRICE_ERROR: '/blad-wyceny',
  CAN_NOT_BE_INSURED: '/nie-mozemy-wycenic',
  PERSONAL: '/wlasciciel',
  PERSONAL_DECLARATIVE: '/wlasciciel-deklaracja',
  OFFER: '/oferta',
  SUMMARY: '/podsumowanie',
  PAYMENT_FREQUENCY: '/platnosci',
  PAYMENT: '/platnosc',
  PAYMENT_CONFIRMATION: '/platnosc-potwierdzenie',
  OFFER_SUMMARY: '/podsumowanie-oferty',
  VEHICLE: '/samochod',
  VEHICLE_DETAILS: '/dodatkowe-dane-samochodu',
  POLICY_START: '/start-polisy',
  CONFIRM_DATA: '/potwierdz-dane',
  ADDRESS: '/adres',
  CONTACT: '/kontakt',
  THANKYOU: '/podziekowanie',
  PAYMENT_TIMEOUT: '/platnosc-oczekiwanie',
  ADDITIONAL_USERDATA: '/dodatkowe-dane',
  OWNER_SUMMARY: '/podsumowanie-wlasciciel',
  CO_OWNER_ADDRESS: '/adres-wspolwlasciciela/:coOwnerIndex',
  CONFIRM_INSURANCE: '/potwierdz-dane-polisy',
  PAY_BY_LINK: '/zaplac',
  RETRIEVE_BY_QUOTEUUID: '/przywroc/:quoteUuid',
  RETRIEVE_PAYMENT_BY_QUOTEUUID: '/zaplac/:quoteUuid',
  CC_RETRIEVE: '/ccprzywroc/:quoteId',
  NOT_FOUND: '/nie-znaleziono',
  GENERIC_ERROR: '/blad',
  INVALID_OFFER_OR_ALREADY_PAYED: '/oferta-wygasla-lub-oplacona',
  PAYMENT_INTERRUPTED: '/platnosc-przerwana'
};

export default route;
