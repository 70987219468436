import { combineReducers } from 'redux';
import commonRootReducer from '../../common/redux/reducers/CommonRootReducer';
import { userDataRootReducer } from '../../modules/userdata/redux/UserDataRootReducer';
import { activityReducer } from './activity/Activity.reducer';
import { debugInfoReducer } from '../../modules/userdata/containers/GeneralError/DebugInfo/redux/DebugInfo.reducer';
import { hasUserDataChangedReducer } from '../../modules/userdata/redux/HasUserDataChanged.reducer';

const rootReducer = combineReducers({
  ...commonRootReducer,
  userData: userDataRootReducer,
  activity: activityReducer,
  debugInfo: debugInfoReducer,
  hasUserDataChanged: hasUserDataChangedReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
