"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.plMessages = void 0;
exports.plMessages = {
    FOOTER_POPULAR_QUESTIONS: 'Popularne pytania do zakupu ubezpieczenia OC AC Benefia',
    FOOTER_QUESTION_0_HEAD: 'Kalkulator OC/AC Oblicz składkę w 60 sek i kup online',
    FOOTER_QUESTION_1_HEAD: 'Kalkulator ubezpieczenia OC AC Benefia24.pl – dlaczego warto?',
    FOOTER_QUESTION_2_HEAD: 'Jak działa kalkulator ubezpieczenia samochodu OC AC online?',
    FOOTER_QUESTION_3_HEAD: 'Ile kosztuje skorzystanie z kalkulatora OC?',
    FOOTER_QUESTION_4_HEAD: 'Jakie ubezpieczenia komunikacyjne znajdziesz w kalkulatorze OC AC Benefia24.pl?',
    FOOTER_QUESTION_5_HEAD: 'Dla jakich pojazdów mogę obliczyć składkę na ubezpieczenie OC i AC?',
    FOOTER_QUESTION_6_HEAD: 'Jak zapłacę za ubezpieczenie OC lub AC?',
    FOOTER_QUESTION_7_HEAD: 'Kupiłem polisę poprzez kalkulator OC AC Benefia24.pl, jak otrzymam dokumenty?',
    FOOTER_QUESTION_8_HEAD: 'Ubezpieczenie samochodu online. Czy to się opłaca?',
    FOOTER_QUESTION_9_HEAD: 'Dlaczego kalkulator OC AC Benefia24.pl zawiera tak mało pytań?',
    FOOTER_QUESTION_10_HEAD: 'Czy kalkulator OC online Benefia24.pl jest bezpieczny?',
    FOOTER_QUESTION_11_HEAD: 'Jakich dokumentów potrzebuję, by skorzystać z kalkulatora OC AC?',
    FOOTER_QUESTION_12_HEAD: 'Oblicz OC i AC w Benefia24.pl',
    FOOTER_QUESTION_0: 'Kalkulator OC AC Benefia24.pl pozwala obliczyć cenę ubezpieczenia OC i AC w zaledwie kilkanaście sekund. To nie wszystko! Kalkulator ubezpieczenia samochodu OC/AC umożliwia także zakup dodatkowych ubezpieczeń. Są to m.in. ubezpieczenie NNW (następstw nieszczęśliwych wypadków), czy ubezpieczenie Assistance. Przekonaj się sam jakie to proste. Już teraz oblicz ubezpieczenie samochodu online.',
    FOOTER_QUESTION_1: 'Ubezpieczenie OC jest w Polsce obowiązkowe dla wszystkich pojazdów mechanicznych, które są zarejestrowane. Polisa ta pokrywa nie tylko szkody rzeczowe, również osobowe, które powstały w trakcie użytkowania takiego pojazdu. Dlatego niezwykle ważne jest pilnowanie terminów. Nie można dopuścić, by nasz samochód nie był objęty ochroną ubezpieczeniową w zakresie obowiązkowego ubezpieczenia OC posiadacza pojazdu. Korzystając z darmowego kalkulatora ubezpieczenia OC, możesz kupić nie tylko ubezpieczenie OC, które jest obowiązkowym ubezpieczeniem pojazdu. Możliwe jest również rozszerzenie ochrony, dlatego warto też przyjrzeć się bliżej ofertom na najlepsze ubezpieczenie AC. W przeciwieństwie do OC nie jest to ubezpieczenie obowiązkowe. Należy do grupy ubezpieczeń dobrowolnych. Ubezpieczenie AC ma szereg zalet. Chroni nasz samochód również wtedy, gdy zostanie on uszkodzony lub skradziony, a sprawca pozostanie nieuchwytny. Koszt polisy OC oraz AC możesz teraz wyliczyć za pomocą szybkiego kalkulatora OC AC. W ten sposób kupisz ubezpieczenie samochodu online. Bez wychodzenia z domu!',
    FOOTER_QUESTION_2: 'Kalkulator  ubezpieczeń OC/AC to szybkie, bezpieczne i bezpłatne narzędzie do obliczenia składki OC i AC. W bardzo krótkim czasie możesz poznać ofertę na ubezpieczenie samochodu. Wystarczy, że odpowiesz na 2 pytania, a resztą zajmie się kalkulator OC i AC. Pomagamy zaoszczędzić Twój czas. Koniec z odpowiadaniem na dziesiątki pytań. Nie musisz już szukać danych w swoich dokumentach. Dobre ubezpieczenie auta online nigdy nie było prostsze. A jeśli masz jakieś wątpliwości, zawsze możesz skontaktować się z jednym z naszych doradców.',
    FOOTER_QUESTION_3: 'Skorzystanie z kalkulatora OC AC Benefia24.pl jest całkowicie darmowe. To narzędzie pozwala w prosty i szybki sposób obliczyć składkę ubezpieczeniową bez ponoszenia żadnych dodatkowych kosztów. Udostępniamy Ci darmowy kalkulator OC, który dodatkowo oszczędza Twój czas. Przetestuj go już teraz i przekonaj się sam, jak szybko obliczysz tanie OC i dobre AC dla swojego samochodu.',
    FOOTER_QUESTION_4: 'Ubezpieczenie samochodu w Benefia24.pl to nie tylko tanie ubezpieczenie OC, to też  dobre ubezpieczenie AC, NNW (następstw nieszczęśliwych wypadków), czy ubezpieczenie Assistance. Dzięki temu możesz znacznie rozszerzyć ochronę swojego samochodu a korzystanie z kalkulatora OC/AC jest całkowicie bezpłatne, więc nie zobowiązuje cię do zakupu polisy.',
    FOOTER_QUESTION_5: 'Nasz kalkulator OC pozwala obliczyć składkę na ubezpieczenie samochodu osobowego zarejestrowanego w Polsce, należącego do osoby prywatnej. Co więcej, w Benefia24.pl ubezpieczysz również pojazdy zarejestrowane w Polsce takie jak motocykl, motorowe lub quad. Nasz kalkulator OC i AC zaproponuje kilka wariantów ubezpieczenia. Zależy to od typu pojazdu. Dzięki temu będziesz mógł wybrać najlepszą dla siebie opcję i cieszyć się pewną ochroną ubezpieczeniową.',
    FOOTER_QUESTION_6: 'Cały proces płatności przebiega równie szybko, co obliczenie składki. Dlatego możesz zapłacić przelewem online, Blikiem za pomocą operatora płatności PayU albo przelewem tradycyjnym. Decyzja należy do Ciebie. Z Benefia24.pl zakup ubezpieczenia OC lub AC to czysta przyjemność bez wychodzenia z domu.',
    FOOTER_QUESTION_7: 'Wszystkie niezbędne dokumenty wysyłamy na wskazany przez Ciebie adres e-mail. W tym także Ogólne Warunki Ubezpieczenia (OWU). Jeśli założyłeś konto klienta w momencie zawierania umowy ubezpieczenia możesz również po zalogowaniu się do niego, ponownie pobrać dokumenty.',
    FOOTER_QUESTION_8: 'Oczywiście! Nasz kalkulator OC AC pozwala w łatwy i szybki sposób kupić dobre ubezpieczenie samochodu online. W Benefia24.pl możesz przejść przez cały proces zakupu online. Od rozpoczęcia kalkulacji po dokonanie płatności za ochronę ubezpieczeniową. To wszystko bez wychodzenia z domu, a całość zajmie Ci maksymalnie kilka minut. Ubezpieczenie samochodu w Benefia24.pl możesz kupić w 2 lub 4 ratach albo zapłacić całą kwotę jednorazowo.',
    FOOTER_QUESTION_9: 'Większość dostępnych na naszym rynku kalkulatorów OC AC składa się z kilkudziesięciu pytań. Udzielenie odpowiedzi na wszystkie zajmuje dużo czasu. Często musimy także odszukać dane w dokumentach, co również nie jest komfortowe. Dlatego w Benefia24.pl wyszliśmy naprzeciw oczekiwaniom Klientów. Stworzyliśmy kalkulator OC i AC online, który potrzebuje tylko 2 informacji. To numer rejestracyjny pojazdu i data urodzenia właściciela. Reszta danych uzupełniana jest z bazy danych CEPiK i UFG. Cały proces zakupu ubezpieczenia samochodu lub motocykla zajmuje teraz zaledwie kilka minut, a nie kilkadziesiąt. Przekonaj się sam jakie to proste. Ty oszczędzasz czas, a my możemy przedstawić Ci konkretną ofertę na ubezpieczenie OC lub AC.',
    FOOTER_QUESTION_10: 'Jak najbardziej. Każda informacja podawana w kalkulatorze OC AC jest zabezpieczona przed dostępem osób trzecich. Zadbaliśmy o infrastrukturę zapewniającą bezpieczeństwo. Wdrożyliśmy też certyfikat SSL. Benefia nie przekazuje Twoich danych innym podmiotom. Używa ich jedynie do przedstawienia jak najlepszej oferty na ubezpieczenie samochodu lub motocykla. Więcej informacji na ten temat znajdziesz w naszej polityce prywatności i regulaminie.',
    FOOTER_QUESTION_11: 'Numer rejestracyjny znajdziesz w dowodzie rejestracyjnym. To wszystko. Nie potrzebujesz żadnych dodatkowych dokumentów, aby wyliczyć i kupić ubezpieczenie samochodu lub motocykla w Benefia24.pl. Chcesz sprawdzić najlepszą cenę ubezpieczenia OC, który nie jest Twoją własnością? Będziesz potrzebował jeszcze dowód osobisty właściciela. Do obliczenia składki w naszym kalkulatorze OC AC niezbędna jest data urodzenia właściciela pojazdu.',
    FOOTER_QUESTION_12: 'Podaj numer rejestracyjny pojazdu oraz datę urodzenia jego właściciela. Następnie nasz kalkulator OC przedstawi propozycje na ochronę ubezpieczeniową. To Ty zdecydujesz, czy wybierasz podstawowy wariant ubezpieczenia OC lub pakiet obejmujący ubezpieczenie AC. Mogą to być również dodatki w postaci np. ubezpieczenia NNW czy ubezpieczenia Assistance. Kalkulator ubezpieczeń Benefia24.pl umożliwia dokładne zapoznanie się z każdym z pakietów. W szczegółach oferty znajdziesz informacje odnośnie zakresu ochrony i sumy ubezpieczenia.' +
        '<p> To nie wszystko. Kalkulator OC AC pozwala dobierać produkty ubezpieczeniowe według własnego uznania, tworząc własne pakiety. Wystarczy, że zaznaczysz wybrany przez siebie wariant ubezpieczenia. Zakup ubezpieczenia samochodu lub motocykla online w Benefia24.pl jest prosty i intuicyjny. </p>',
};
