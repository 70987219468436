import { Grid } from '@material-ui/core';
import React, { FC, useEffect, useRef, useState } from 'react';
import checkIcon from '../assets/check.svg';
import { isNotDefined } from '../../../utils/Object.utils';

import {
  WaitingWindowLoaderContentImg,
  WaitingWindowLoaderContentWrapper,
  WaitingWindowLoaderLabel,
  WaitingWindowLoaderProgress
} from './WaitingWindowLoaderContent.style';

export interface WaitingWindowLoaderContentProps {
  visible: boolean;
  text: string;
  onFinish: () => void;
  canFinish: boolean;
  finishImediatily: boolean;
  setLoadingSpeed?: number;
}

export const WaitingWindowLoaderContent: FC<WaitingWindowLoaderContentProps> = (
  props: WaitingWindowLoaderContentProps
) => {
  const COMPLETED = 100;
  const WAITING_TIME_IN_SECONDS = 3;
  const WAITING_IN_SECONDS = props.setLoadingSpeed ?? WAITING_TIME_IN_SECONDS;
  const ONE_SECOND = 1000;
  const XS_SIZE = 12;

  const timeoutRef = useRef<number | null>(null);
  const minProgress = Math.ceil(COMPLETED / WAITING_IN_SECONDS);
  const maxProgress = Math.floor(COMPLETED / (WAITING_IN_SECONDS - 1)) - 1;
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    if (props.visible) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeoutRef.current = window.setTimeout(calculateProgressState, ONE_SECOND);
    }
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressValue, props.visible]);

  useEffect(() => {
    if (props.finishImediatily) {
      tryClosingPopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.finishImediatily]);

  const calculateProgressState = () => {
    const newProgressValue = getNewProgressValue();
    if (newProgressValue >= COMPLETED) {
      tryClosingPopup();
    } else {
      setProgressValue(newProgressValue);
    }
  };

  const getNewProgressValue = (): number => {
    const progressGrowth = minProgress + getAdditionalProgressValue();
    return Math.min(COMPLETED, progressValue + progressGrowth);
  };

  const getAdditionalProgressValue = (): number => {
    return Math.ceil((maxProgress - minProgress) * Math.random());
  };

  const tryClosingPopup = (): void => {
    if (isNotDefined(props.canFinish) || props.canFinish) {
      setProgressValue(COMPLETED);
      timeoutRef.current && clearTimeout(timeoutRef.current);
      props.onFinish();
    }
  };

  return (
    <WaitingWindowLoaderContentWrapper
      item
      container
      alignItems="flex-end"
      xs={XS_SIZE}
    >
      {progressValue > 0 && (
        <Grid
          item
          container
          alignItems="baseline"
          justifyContent="space-between"
          xs={XS_SIZE}
        >
          <Grid item>
            <WaitingWindowLoaderLabel>{props.text}</WaitingWindowLoaderLabel>
          </Grid>
          <Grid item>
            {progressValue === COMPLETED &&
              <WaitingWindowLoaderContentImg src={checkIcon} alt="" />
            }
          </Grid>
        </Grid>
      )}
      <Grid item xs={XS_SIZE}>
        <WaitingWindowLoaderProgress
          variant="determinate"
          value={progressValue}
        />
      </Grid>
    </WaitingWindowLoaderContentWrapper>
  );
};
