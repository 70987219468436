export const COLORS = {
  black: '#000000',
  transparent: 'rgba(0,0,0,0)',
  transparentWhite: 'rgba(255, 255, 255, 0)',
  transparentWhite78: 'rgba(255, 255, 255, 0.78)',
  primaryDark: '#000000',
  blackOpaque: '#0000004D',
  white: '#fff',
  backgroundWhite: '#fbfbfb',
  primaryRed: 'rgb(227, 30, 36)',

  secondary: '#F4F10A',
  secondaryHover: '#C3C108',

  almostBlack: '#1B1B19',
  greyMid: '#B0B8BC',
  darkGrey: '#5E6870',
  darkerGrey: '#5e6870',

  lightestGrey: '#f8f8f8',
  lighterGrey: '#D6DEE5',
  lightGrey: '#acbcc8',
  lightGreyBlueTwo: '#acbcc8',
  redish: '#FF4C2F',
  greyPlaceholder: '#5E6870',
  backGroundSecondary: '#373F41',

  actionHover: `rgba(227, 30, 36, 0.05)`,
  actionFocused: `rgba(227, 30, 36, 0.1)`,
  actionActive: `rgba(227, 30, 36, 0.15)`
};
