import * as Yup from 'yup';
import plateNumberValidator from './plateNumber';
import { isStringEmpty } from '../utils/String.utils';

interface PlateValidatorMessagesType {
  wrongFormat: string;
  required?: string;
}

export const vehiclePlateValidator = (
  isRequired = false, messageObject: PlateValidatorMessagesType
): Yup.StringSchema => {
  return Yup.string()
    .test('required', messageObject.required ?? '',
      value => isRequired ? !isStringEmpty(value) : true)
    .test('plateFormat', messageObject.wrongFormat,
      value => plateNumberValidator(value, !isRequired));
};
