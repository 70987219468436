import React, { FC } from 'react';
import Translate from '../../../../../../translations/translate/Translate.component';
import { useTenant } from '../../../../../../config/tenants/Tenants.provider';
import FormContainer from '../../../../components/FormContainer';
import { Content, Header, IconContainer, Info, ManualWayLink, NoFoundInfoWrapper } from './NoFoundInfo.style';
import { BasicInfoConfigurationType } from '../../tenants/tenant.type';

type NoFoundInfoProps = {
    isNotFound: boolean;
    onClick: () => void;
}

export const NoFoundInfo: FC<NoFoundInfoProps> = ({ isNotFound, onClick }: NoFoundInfoProps) => {
  const { BasicInfoConfiguration } = useTenant<BasicInfoConfigurationType>();
  
  if (!isNotFound) {
    return null;
  }

  return (
    <NoFoundInfoWrapper $isNotFound={isNotFound}>
      <FormContainer $paddingVertical={'1.5rem'} boxShadow={BasicInfoConfiguration.boxShadow}>
        <Content>
          <IconContainer>
            <img src={BasicInfoConfiguration.alertIcon} alt="not found" />
          </IconContainer>
          <div>
            <Header>
              <Translate keyName='CAR_NOT_FOUND_HEADER' />
            </Header>
            <Info>
              <Translate keyName='CAR_NOT_FOUND_INFO' />
            </Info>
            <ManualWayLink onClick={onClick}>
              <Translate keyName='MANUAL_INPUT_BUTTON' />
            </ManualWayLink>
          </div>
        </Content>
      </FormContainer>
    </NoFoundInfoWrapper>
  );
};
