import {
  Button,
  Typography,
  useTheme,
} from '@sky-distribution/react-commons-ui';
import React, { FC } from 'react';
import { HeroBox } from '../HeroBox';
import { ContentBlock } from '../HeroBox/content/ContentBlock';
import { ItemDetailsModalProps } from '../ItemDetailsModal.types';
import {
  ModalContainer,
  QuestionContainer,
  StyledButtonWrapper,
} from './ItemDetailsModalContent.styles';

export const ItemDetailsModalContent: FC<ItemDetailsModalProps> = ({
  details,
  data,
  handleOnClick,
  buttonText,
}: ItemDetailsModalProps) => {
  const theme = useTheme();

  if (!details) {
    return null;
  }

  const { questions } = details;

  return (
    <div>
      <ModalContainer>
        <HeroBox details={details} buttonText={''} />
        {questions.map((question, qidx) => (
          <QuestionContainer key={`q-${qidx}`}>
            <Typography
              component="span"
              variant={theme.typography.h4}
              color={theme.palette.text.paragraph.primary}
            >
              {question.name}
            </Typography>
            {question.answer.map((block, aidx) => (
              <ContentBlock key={`k-${aidx}`} block={block} data={data} />
            ))}
          </QuestionContainer>
        ))}
      </ModalContainer>
      {buttonText ? (
        <StyledButtonWrapper>
          <Button
            typeStyle={'secondary'}
            blockMode
            onClick={handleOnClick}
            data-e2e="detailsModalOK"
          >
            {buttonText}
          </Button>
        </StyledButtonWrapper>
      ) : null}
    </div>
  );
};
