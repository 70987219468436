import { Tenant } from '../../../../config/tenants/tenant.type';
import { AppStateActions, APP_STATE_ACTION_TYPES } from './AppStateAction.type';
import { Consent } from '@sky-distribution/react-commons-ui';

export interface AppState {
  viewedPages: string[];
  nextToView: string;
  isCallBackWidgetOpen: boolean;
  consentsList: Consent[];
  tenants: Tenant[];
}

export const initialState: AppState = {
  nextToView: '',
  viewedPages: [],
  isCallBackWidgetOpen: false,
  consentsList: [],
  tenants: []
};

// eslint-disable-next-line complexity
export const appStateReducer = (
  state = initialState,
  action: AppStateActions
): AppState => {
  switch (action.type) {
    case APP_STATE_ACTION_TYPES.BACK_POP: {
      return {
        ...state,
        viewedPages: action.payload.viewedPages,
        nextToView: action.payload.viewedPages[action.payload.viewedPages.length - 1]
      };
    }
    case APP_STATE_ACTION_TYPES.VIEWED_PAGES:
      return {
        ...state,
        viewedPages: state.viewedPages.includes(action.payload.currentPage)
          ? [...state.viewedPages]
          : [...state.viewedPages, action.payload.currentPage]
      };
    case APP_STATE_ACTION_TYPES.LAST_VIEWED_PAGES:
      return {
        ...state,
        nextToView: action.payload.nextToView
      };
    case APP_STATE_ACTION_TYPES.IS_CALL_BACK_WIDGET_OPEN:
      return {
        ...state,
        isCallBackWidgetOpen: action.payload.isCallBackWidgetOpen
      };
    case APP_STATE_ACTION_TYPES.CONSENTS_LIST:
      return {
        ...state,
        consentsList: action.payload.consentsList
      };
    case APP_STATE_ACTION_TYPES.TENANTS_LIST:
      return {
        ...state,
        tenants: [...action.payload.tenants]
      };
    default:
      return state;
  }
};
