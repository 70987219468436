import { PolicyDateActionsType, POLICY_DATE_ACTIONS } from './PolicyDate.type';
import { USER_INFO_ACTIONS, UserInfoRetrievedDataAction } from '../../../redux/userInfo/UserInfoActions.type';
import { AppActions } from '../../../../../redux/actions/AppActions.type';

const initialState = {} as PolicyDateState;

export const policyDateReducer =
  (state = initialState, action: AppActions): PolicyDateState => {
    switch (action.type) {
      case POLICY_DATE_ACTIONS.SAVE_POLICY_START:
        return {
          ...state,
          start: (action as PolicyDateActionsType).data
        };
      case USER_INFO_ACTIONS.RETRIEVE_USER_INFO:
        return { ...state, ...(action as UserInfoRetrievedDataAction).data.policyDate };
      default:
        return state;
    }
  };

export interface PolicyDateState {
  start: string;
}
