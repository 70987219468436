import { Dispatch } from 'redux';
import { AppActions } from '../../../../../redux/actions/AppActions.type';
import {
  VASE_ACTIONS,
  VaseSaveAction,
  VaseResetAction
} from './VaseActions.type';
import { AddonModel } from '../../../../../common/components/addon/service/AddonModel';

export const saveVaseData = (selectedVases: AddonModel[]) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(saveVaseDataAction(selectedVases));
  };
};

export const saveVaseDataAction = (selectedVases: AddonModel[]): VaseSaveAction => {
  return {
    type: VASE_ACTIONS.SAVE_VASES_DATA,
    data: selectedVases
  };
};

export const resetVaseData = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(resetVaseDataAction());
  };
};

export const resetVaseDataAction = (): VaseResetAction => {
  return {
    type: VASE_ACTIONS.RESET_VASES_DATA
  };
};
