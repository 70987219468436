import React, { FC } from 'react';

import { StyledBeesafeSuspenseSpinner } from './BeesafeSuspenseSpinner.style';

export const BeesafeSuspenseSpinner: FC = () => {
  return (
    <StyledBeesafeSuspenseSpinner>
      <div className="hexagons">
        <div className="hexagon" />
        <div className="hexagon" />
        <div className="hexagon" />
        <div className="hexagon" />
        <div className="hexagon" />
        <div className="hexagon" />
        <div className="hexagon" />
      </div>
    </StyledBeesafeSuspenseSpinner>
  );
};

export default BeesafeSuspenseSpinner;
