import React from 'react';
import { ReactComponent as ArrowSVG } from '../assets/icon-arrow.svg';
import { Arrow, DropdownLabel } from './OfferDropdownLabel.style';
import { OfferDropdownLabelType } from './OfferDropdownLabel.type';

export const OfferDropdownLabel: React.FC<OfferDropdownLabelType> = ({
  label,
  isExpanded,
}: OfferDropdownLabelType) => (
  <>
    <DropdownLabel>{label}</DropdownLabel>
    <Arrow isExpanded={isExpanded}>
      <ArrowSVG />
    </Arrow>
  </>
);
