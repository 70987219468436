/* eslint-disable max-lines, camelcase */
import { enMessages as basicInfoEnMessages } from '../../../../modules/userdata/containers/BasicInfo/tenants/beesafe/translations/languages/EN.lang';
import { LanguageDictionaryType } from '../beesafe.type';

export const enMessages: LanguageDictionaryType = {
  //BasicInfo
  ...basicInfoEnMessages,

  PAGE_IS_LOADING: 'Loading...',

  //validation
  TOO_SHORT: 'Too Short',
  TOO_LONG: 'Too long',
  REQUIRED: 'Required',
  MIN_LENGTH_OF_VIN: 'VIN must be longer than 12 characters',
  MAX_LENGTH_OF_VIN: 'VIN must be less than 17 characters long',
  FIELD_MUST_BE_CHECKED: 'Field must be checked',
  ADDRESS_STREET_NOT_CONTAIN_SPECIAL_CHARACTERS: 'The street name cannot contain special characters',
  ADDRESS_CITY_NOT_CONTAIN_SPECIAL_CHARACTERS: 'The city name cannot contain special characters',
  ADDRESS_HOUSE_NO_NOT_CONTAIN_SPECIAL_CHARACTERS: 'House number cannot contain special characters',
  ADDRESS_FLAT_NOT_CONTAIN_SPECIAL_CHARACTERS: 'Flat number cannot contain special characters',
  WRONG_ZIP_CODE: 'Invalid zip code format',
  ZIP_CODE_NOT_EXIST: `Given zip code doesn't exist`,
  WRONG_FORMAT_OF_PHONE: 'Wrong format of phone',
  PHONE_CANNOT_START_WITH_ZERO: 'Phone can not start with 0',
  WRONG_PLATE_FORMAT: 'Invalid plate number',
  USER_SHOULD_BE_ADULT: 'User should be at least 18 years old',
  WRONG_PESEL_FORMAT: 'Wrong pesel number',
  PESEL_HAS_ALREADY_BEEN_PROVIDED: 'This PESEL has already been provided elsewhere',
  WRONG_EMAIL_ADDRESS: 'Wrong email address',
  NAME_CONTAINS_LETTERS_ONLY: 'The name can contain letters and special character "-" only',
  SURNAME_CONTAINS_LETTERS_ONLY: 'The surname can contain letters and special character "-" only',
  SURNAME_NOT_MATCH: '',
  EMAILS_NOT_MATCH: 'Podany adres e-mail nie pokrywa się z zapisanym',
  ENTER_A_VALID_NUMBER: 'Enter a valid number',
  VEHICLE_YEAR_IS_NOT_SUPPORTED: 'We do not support vehicle of this year',
  NAME_NOT_CONTAIN_WHITESPACES: 'The name cannot contain whitespaces',
  ONLY_FIRST_NAME_ALLOWED: 'Only first name without whitespaces',

  //ordinal
  '2_ORDINAL': 'Second',
  '3_ORDINAL': 'Third',
  '4_ORDINAL': 'Fourth',
  '5_ORDINAL': 'Fifth',

  //page template
  FOOTER_ENCRYPTED_MESSAGE: 'Secured connection',
  FOOTER_KNF_MESSAGE: 'Polish Financial Supervision Authority',
  FOOTER_COMPENSA_MESSAGE: 'We are part of Compensa group',
  // steps bar
  STEP: 'Step:',
  STEPS_BAR_CAR: 'Car',
  STEPS_BAR_OWNER: 'Owner',
  STEPS_BAR_OFFER: 'Offer',
  STEPS_BAR_DETAILS: 'Details',
  STEPS_BAR_ADDRESS: 'Address',
  STEPS_BAR_SUMMARY: 'Summary',
  STEPS_BAR_PAYMENT: 'Payment',
  DONE: 'Ready',

  // basic info
  OR_TEXT: 'or',
  NEXT: 'Next',
  EDIT: 'Edit',

  CAR_PLATE_PLACEHOLDER: 'Car plate number',

  REG_ELECTRONIC_SERVICES: 'Regulations for the provision of electronic services',
  PROCESSING_PERSONAL_DATA_INFO_BEESAFE: 'Information regarding the processing of personal data by Beesafe and Compensa',
  PROCESSING_PERSONAL_DATA_INFO_BENEFIA: 'Information regarding the processing of personal data by Benefia and Compensa',
  PROCESSING_PERSONAL_DATA_INFO_COMPENSA: 'Information regarding the processing of personal data by Compensa',

  BACK: 'Back',
  FOOTER_DOCUMENTS: 'Documents',

  // personal data
  PERSONAL_DATA_FIRSTNAME: 'Name',
  PERSONAL_DATA_SURNAME: 'Surname',

  // additional data in manual path
  PESEL_LABEL: 'PESEL Number',

  // address
  ADDRESS_STREET: 'Street',
  ADDRESS_NO: 'House no.',
  ADDRESS_FLAT: 'flan No.',
  ADDRESS_CITY: 'City',
  ADDRESS_ZIPCODE: 'Zip code',

  //car info
  CAR_INFO_YEAR_PLACEHOLDER: 'Year of construction',
  CAR_INFO_VIN_NUMBER_PLACEHOLDER: 'VIN Number',
  CAR_INFO_DO_NOT_HAVE_MODEL: 'I don\'t see my car model',
  CAR_INFO_DO_NOT_HAVE_VERSION: 'I don\'t see my car version',

  // confirm data

  SHOW_MORE: 'Show more',
  SHOW_LESS: 'Show less',
  // offer waiting popup
  WAITING_OFFER_HEADER: 'It\'s almost everything ...',
  WAITING_OFFER_STEP_ONE: 'We analyze your data ...',
  WAITING_OFFER_STEP_TWO: 'We form a coherent whole ...',
  WAITING_OFFER_STEP_THREE: 'Some minor corrections ...',
  WAITING_OFFER_STEP_FOUR: 'Bzzzyt and now!',

  // Ufg waiting popup
  WAITING_BASIC_INFO_HEADER: 'We collect data from the Insurance Guarantee Fund (UFG) database',
  WAITING_BASIC_INFO_STEP_ONE: 'We analyze your vehicle data ...',
  WAITING_BASIC_INFO_STEP_TWO: 'We collect personal data ...',
  WAITING_BASIC_INFO_STEP_THREE: 'We collect address data ...',

  // offer card
  AS_A_GIFT: 'as a gift',
  // offer info
  ADD_CO_OWNER: 'Add co-owner',
  DELETE_CO_OWNER: 'delete',
  OWNER: 'owner',
  ALREADY_PAYED_OR_INVALID: 'Offer is already payed or invalid',
  // vase selection page
  OFFER_PAGE_DISCLAIMER: 'The prices are approximate and subject to change after entering the details.',
  // policy start date page
  POLICY_START_HEADER: 'When you would like to start your insurance?',

  // offer summary
  YOUR_OFFER_LABEL: 'Your offer:',
  YOUR_OFFER_LABEL_NEW: 'Your new offer:',
  SUM_LABEL: 'Total',

  //Rewards program
  POINTS: 'points',

  //policy info
  EMAIL_PLACEHOLDER: 'Email adress',

  //Email component
  EMAIL_HEADER: '',
  PHONE_PLACEHOLDER: 'Phone number',
  WHY_WE_NEED_EMAIL: 'Email is needed to:',
  EMAIL_SEND_INFO: 'Sending information documents',
  EMAIL_COMMUNICATION_INFO: 'for communication process',
  EMAIL_FIELD_INFORMATION: 'We send offer on your email',
  PHONE_NUMBER_FIELD_INFORMATION: 'Contact in case of problems with the purchase',
  PHONE_NUMBER_CONTACT_FIELD_INFORMATION: 'Needed to perform the contract and to log into the customer\'s account',

  // appBar
  LOGO_TITLE: 'BeeSafe homepage',
  LOGO_ALT: 'kalkulator OC',
  FROM: 'From',
  CLOSE: 'Close',

  //Drawer
  THE_PRICE_WILL_APPEAR: 'The price will appear here soon',
  YOUR_OFFER: 'Your offer:',
  CALLBACK_TITLE: '',
  CALLBACK_PHONE_CAPTION: '',
  CALLBACK_PHONE_NUMBER: '',
  DISCOUNT: 'Discount',
  DISCOUNT_ERROR: 'Can\'t display discount',
  DISCOUNT_VALIDATION_VALUE_TOO_BIG: 'Discount too big',
  DISCOUNT_VALIDATION_REQUIRED: 'Required',
  DISCOUNT_VALIDATION_POSITIVE: 'Number cannot be negative',

  // canNotBeInsured
  CAN_NOT_BE_INSURED: 'It seems, we can not offer anything to you.',
  CAN_NOT_BE_INSURED_MORE_INFO: 'In order to receive an insurance offer, contact the consultant at +48 500 965 626',

  // Owner summary
  OWNER_VEHICLE_INFORMATION: 'Vehicle owner information',
  OWNER_VEHICLE_SUBHEADER: 'We need that information to issue your policy.',
  OWNER_VEHICLE_SUBHEADER_GDPR: 'We hide your surname for your safety',

  // Confirmation of insurance
  COI_HEADER: 'It will happen here!',
  COI_INFO: 'Here you can exchange points for prizes, find additional services and information about your policy. We\'re working on this part of the site. We will end soon.',

  //pay by link
  PAY_BY_LINK_WAITING_WINDOW_HEADER: 'Wait a minute - we are processing your payment.',
  PAY_BY_LINK_WAITING_WINDOW_STEP_ONE: 'We save the address data ...',
  PAY_BY_LINK_WAITING_WINDOW_STEP_TWO: 'We save personal data ...',
  PAY_BY_LINK_WAITING_WINDOW_STEP_THREE: 'We save the address data ...',


  //different quotes
  CONTACT_US: 'Contact us',
  SOMETHING_WENT_WRONG: 'Something went wrong, please try again.',
  DEBUG_INFO: 'Copy error message',
  DEBUG_INFO_MESSAGE: 'Logs to help us fix this error will be copied to your clipboard. However, they do not indicate how it was triggered - that needs to be specified in Incident Management task by paste these logs via CTRL+V.',
  COPY_AND_REDIRECT_TO_IM_BOARD: 'Copy and go to Incident Management Board',
  REDIRECT_TO_IM_BOARD: 'Go to Incident Management Board',
  NO_DEBUG_INFO_TO_COPY: 'No data to copy. You have to gather them manually. For more details check this guide:',
  DEBUG_INFO_GUIDE: 'Confluence',
  
  // retrieve
  RETRIEVE_HEADER: 'We are fetching all your offer data. Please wait.',
  NOT_FOUND_TITLE: 'Page not found.',
  NOT_FOUND_DESCRIPTION: 'You will be redirected in:',

  // payment plans
  PAYMENT_FREQUENCY_HEADER: '',
  PAYMENT_FREQUENCY_everyyear: '',
  PAYMENT_FREQUENCY_everysixmonths: '',
  PAYMENT_FREQUENCY_INSTALLMENT_SUMMARY: '',
  PAYMENT_FREQUENCY_DOWN_PAYMENT: '',
  PAYMENT_FREQUENCY_DRAWER_SUMMARY: '',
  PAYMENT_FREQUENCY_SAVINGS_TEXT: 'Save:',
  PAYMENT_FREQUENCY_INSTALLMENT_everyyear: '',
  PAYMENT_FREQUENCY_INSTALLMENT_everysixmonths: '',
  PAYMENT_FREQUENCY_INSTALLMENT_everythreemonths: '',
  PAYMENT_FREQUENCY_everythreemonths: '',

  //cookies
  ACCEPT: 'Accept',
  OK_ACCEPT: 'OK',
  USE_COOKIES: 'See Cookies Policy.',
  COOKIES_PREFIX: 'Beesafe.pl uses cookies to adapt its content to your preferences, collect'
    + 'statistics and optimize the performance of Beesafe.pl. Using Beesafe.pl means that cookies '
    + 'will be saved on your device. You can change your browser settings at any time. ',
  COOKIES_URL: 'https://beesafe.pl/polityka-prywatnosci/',
  COOKIES_AGREEMENT_TEXT: ' ',

  // details modal
  GET_IT: 'OK, I get it',
  CLOSE_DETAILS: 'Close',

  // payment interrupted
  PAYMENT_INTERRUPTED_HEADER: 'Your payment has been interrupted',
  PAYMENT_INTERRUPTED_DESC: 'You can repeat the payment by clicking on the link in the e-mail.',


  // payment timeout
  PAYMENT_TIMEOUT_HEADER: 'We need time for processing payment.',
  PAYMENT_TIMEOUT_DESC: 'We\'ll send you the reply with the status on the next working day at latest',
  PAYMENT_TIMEOUT_DESC_2: 'Have questions? Call us:',

  // contact
  CONTACT_PHONE: '+48 500 96 56 26',

  // declarative path
  DECLARATIVE_HEADER: 'Enter the owner details',

  SAVE_CALCULATION_TITLE: 'Save offer',
  SAVE_CALCULATION_INFO: 'Type your email address, which will be used for send offer link.',
  SAVE_CALCULATION: 'Save offer',
  SAVE_CALCULATION_SHORT: 'Save',
  SAVE_CALCULATION_SAVED: 'We saved offer!',
  SAVE_CALCULATION_SAVED_DESCRIPTION: 'Your offer is valid 30days. Fill rest part of data in free moment. We will wait!',

  // info modal
  MODAL_INFO_SAVE: 'Save',
  MODAL_INFO_TITLE: 'Personal data',
  MODAL_INFO_HEADING: 'Personal data updates may change assurance price.',

  // vehicle types
  VEHICLE_TYPE_CAR: 'Car',
  VEHICLE_TYPE_MOTORCYCLE: 'Motorcycle',

  VEHICLE_USER_AGE_CONSENT_RANGE_INEXPERIENCED: 'By clicking "Next" you declare, that the vehicle will be driven only by persons over 25 years old',
  VEHICLE_USER_AGE_CONSENT_RANGE_EXPERIENCED: 'By clicking "Next" you declare, that the vehicle will be driven only by persons over 28 years old'
};
