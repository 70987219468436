import format from 'date-fns/format';
import DateFnsUtils from '@date-io/date-fns';

export class PlLocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date: Date) {
    return format(date, 'd MMM yyyy', { locale: this.locale });
  }

  getCalendarHeaderText(date: Date) {
    return format(date, 'LLLL yyyy', { locale: this.locale });
  }
}
