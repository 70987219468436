import { ComponentType, FC } from 'react';
import { OfferDetailItem } from '../../common/components/addon/packageAddon/OfferDetails';
import { SpinnerType } from '../../modules/userdata/components/spinners/spinner.type';
import { CookieConfiguration } from '../../common/components/cookies/tenants/Cookie.type';

export enum TenantsEnum {
  Beesafe = 'beesafe',
  Benefia = 'benefia',
  Compensa = 'compensa',
}
export type Tenant = `${TenantsEnum}`

export enum CsouHostEnum {
  Benefia24 = 'benefia24',
  BenefiaMultiOffer = 'benefiaMultiOffer',
}

export type CsouHost = CsouHostEnum.Benefia24 | CsouHostEnum.BenefiaMultiOffer;

export type OfferDetailsDataType = {
  [k: string]: OfferDetailItem;
};

export type LogoConfiguration = {
  url: string;
  display: boolean;
  component: ComponentType;
  width?: string;
  href?: string;
};

export type TenantConfiguration = {
  tenantType: Tenant;
  tenantId: Tenant;
  basePath: string;
  firstLogo: LogoConfiguration;
  secondLogo: LogoConfiguration;
  suspenseLoader: {
    spinner: FC<SpinnerType>;
  };
  offerDetailsData: OfferDetailsDataType;
  CookieConfiguration: CookieConfiguration;
};
