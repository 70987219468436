export const enMessages = {
  //Contact agreements
  CONSENTS_REQUIRED: 'First two agreements are required to finish this step.',
  ACCEPT_ALL_CONSENTS: 'Accept all consents',
  SHOW_CONSENTS: 'Show consents',

  CONTACT_CONSENT_1:
    'I agree to receive trade and marketing information from Benefia Ubezpieczenia SP.z o. o. using telecommunication devices'
    + ' and so-called automatic calling systems, choosing electronic messages (e-mail, SMS/MMS, internet services,'
    + ' including social networks) as a form of contact.',
  CONTACT_CONSENT_2:
    'I agree to receive trade and marketing information from Benefia Ubezpieczenia SP.z o. o. using telecommunication'
    + ' devices and so-called automatic calling systems, choosing voice calls (telephone conversation, IVR voice messages)'
    + ' as a form of contact.',
  CONTACT_CONSENT_3:
    'I agree that Benefia Ubezpieczenia SP.z o. o. will make decisions against me based solely on automated processing,'
    + ' including through profiling, of my personal data such as: first name, last name, contact details, data about the'
    + ' place of residence, date of birth, information about types of insurance, for its own marketing purposes'
    + ' and in order to present an appropriate offer.',
  CONTACT_CONSENT_4:
    'I agree that Compensa Towarzystwo Ubezpieczeń S. A. Vienna Insurance Group has made decisions towards me based'
    + ' solely on automated processing, including through profiling, of my personal data such as: first name, last name,'
    + ' contact details, residence, gender, date of birth, information on insurance types and insurance contract history'
    + ' (including information about insurance products I have used in the past), including to the extent that these data'
    + ' may be protected by insurance, for its own marketing purposes and in order to present an appropriate offer, during'
    + ' the duration of the insurance contract.',
  CONTACT_CONSENT_5:
    'I agree to the advertisement from Compensa Towarzystwo Ubezpieczeń S.A. Vienna Insurance Group and Compensa Towarzystwo Ubezpieczeń'
    + ' na Życie S. Vienna Insurance Group with headquarters in Warsaw, commercial and marketing information from the so-called'
    + ' Automatic automatic systems, selecting as the e-mail system: electronic services (SMS / MMS), websites, including social'
    + ' networking sites).',
  CONTACT_CONSENT_6:
    'I\'ve read the terms and conditions and I\'m going to the website.',
  CONTACT_CONSENT_6_REQUIRED_TEXT: '',
  DOCUMENT_LINK_CONSENT_6_TEXT_1: 'Terms of Service by electronic means via the website www.benefia24.pl',
  CONTACT_CONSENT_7: 'I agree to receive from Compensa Towarzystwo Ubezpieczeń S.A. Vienna Insurance Group'
    + 'with its registered office in Warsaw commercial and marketing information using telecommunication '
    + 'devices and so-called automatic calling systems, choosing as a form of contact: voice calls '
    + '(telephone conversation, IVR voice messages).',
  CONTACT_CONSENT_8:
    'I agree that my personal data such as: first name, last name, contact details, residence, gender, date of birth,'
    + ' information on insurance types and insurance contract history (including information about insurance products'
    + ' I have used in the past), including to the extent that such data may be protected by insurance secrecy and'
    + ' including data collected in the future, will be processed by Compensa S. A. Vienna Insurance Group with its'
    + ' registered office in Warsaw (Al. Jerozolimskie 162, 02-342 warszawa), for its own marketing purposes,'
    + ' made decisions to me based solely on automated processing, including through profiling, in order to determine'
    + ' preferences or needs in terms of insurance products and other financial products, and in order to submit'
    + ' an appropriate offer, after the termination of the insurance contract.',

  DETAILS: 'Details'
};
