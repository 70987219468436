import { MEDIA_QUERY, Typography } from '@sky-distribution/react-commons-ui';
import styled from 'styled-components';
import { ReactComponent as Back } from '../assets/back.svg';

export const StyledBackIcon = styled(Back)`
  left: 3rem;
  position: absolute;
  top: 3.5rem;
  ${MEDIA_QUERY.below('sm')} {
    left: 1.5rem;
    top: 1.5rem;
  }
`;

export const OfferModalHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const TitleTypography = styled(Typography)`
  font-weight: 700;
`;
