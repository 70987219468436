/* eslint-disable max-lines-per-function */
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CallBackWidgetWrapper } from '@sky-distribution/frontend-callback-widget';
import React, { FC, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { TenantsEnum } from '../../../config/tenants/tenant.type';
import { useTenant } from '../../../config/tenants/Tenants.provider';
import { useTheme } from '../../../config/tenants/Theme.provider';
import OfferLoadingModal from '../../../modules/userdata/components/offerLoadingModal/OfferLoadingModal.component';
import { StepsIndicatorConfigurationType } from '../../../modules/userdata/containers/StepsIndicator/tenants/tenant.type';
import { RootState } from '../../../redux/reducers/RootReducer';
import routes from '../../../routing/Routes.config';
import { TranslationContext } from '../../../translations/translate/globalTranslate/TranslationProvider';
import Translate from '../../../translations/translate/Translate.component';
import { CommonRootStateType } from '../../redux/reducers/CommonRootReducer';
import { useFeatureFlag } from '../../utils/useFeatureFlags/useFeatureFlag';
import { setCallBackWidgetOpen } from '../appState/redux/AppState.actions';
import {
  ActiveStep,
  ActiveStepMobile,
  ActiveStepName,
  Logo,
  LogoContainer,
  Phone,
  PhoneContainer,
  ProgressBarContainer,
  ProgressBarProgress,
  SaveContainer,
  SaveOfferContainer,
  StepsIndicatorContainer,
  StepsIndicatorWrapper,
  StyledSaveOfferButton,
  StyledSaveOfferText
} from './StepsIndicator.style';


export interface StepListElementType {
  id?: number;
  active: boolean;
  label: string;
}

export type IStepsBarProps = StateProps & {
  steps: StepListElementType[];
  notVisible?: boolean;
  isTransactionLoading?: boolean;
  showSaveOfferModal?: () => void;
};

const saveOfferButtonVisibleOn = [
  routes.OFFER,
  routes.POLICY_START,
  routes.OWNER_SUMMARY,
  routes.VEHICLE_DETAILS,
  routes.ADDRESS,
  routes.CONTACT,
  routes.SUMMARY
];

const saveOfferButtonVisibleForQuotedTenant = [`${TenantsEnum.Beesafe}`];

type Props = PropsWithChildren<IStepsBarProps & DispatchProps>;

// eslint-disable-next-line complexity
const StepsIndicator: FC<Props> = (props: Props) => {
  const theme = useTheme();
  const tenant = useTenant();
  const matchesDownMdBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useContext(TranslationContext);
  const [isOfferSaveButtonVisible, setIsOfferSaveButtonVisible] =
    useState(false);
  const history = useHistory();
  const {
    quotedTenant,
    showSaveOfferModal,
    isTransactionLoading = false,
    isCallBackWidgetOpen,
    setCallBackWidgetOpen
  } = props;
  const { firstLogo, StepsIndicatorConfiguration } =
    useTenant<StepsIndicatorConfigurationType>();
  const activeItem = props.steps.find((step) => step.active);
  const activeItemIndex = props.steps.findIndex((step) => step.active);
  const SAVEQUOTE_BUTTON_ENABLED = useFeatureFlag('BEE-327-save-quote');
  const isSaveOfferButtonShown =
    isOfferSaveButtonVisible &&
    StepsIndicatorConfiguration.retrieve.isSaveOfferButtonShown;

  const isSaveOfferButtonVisible = () => {
    return setIsOfferSaveButtonVisible(
      saveOfferButtonVisibleOn.includes(history.location.pathname) &&
      SAVEQUOTE_BUTTON_ENABLED
    );
  };

  useEffect(() => {
    isSaveOfferButtonVisible();
    const unlink = history.listen(() => {
      isSaveOfferButtonVisible();
    });
    return unlink;
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const getPhoneIcon = useCallback(() => {
    if (
      StepsIndicatorConfiguration.retrieve.phoneContainer.shouldRedirectToPhone && isMobile
    ) {
      return (
        <a
          href={StepsIndicatorConfiguration.retrieve.phoneContainer.phoneNumber}
        >
          <PhoneContainer
            id={
              StepsIndicatorConfiguration.retrieve.phoneContainer
                .phoneContainerId
            }
            onClick={() => setCallBackWidgetOpen(true)}
          >
            <Phone />
          </PhoneContainer>
        </a>
      );
    }

    return (
      <PhoneContainer
        id={
          StepsIndicatorConfiguration.retrieve.phoneContainer.phoneContainerId
        }
        onClick={() => setCallBackWidgetOpen(true)}
      >
        <Phone />
      </PhoneContainer>
    );
  }, [isMobile]);
  const ONE_HUNDRED = 100;
  const progressPercentage =
    (activeItemIndex + 1) / props.steps.length * ONE_HUNDRED;

  return (
    <>
      <StepsIndicatorWrapper $notVisible={props.notVisible ?? false}>
        <StepsIndicatorContainer>
          <LogoContainer>
            <Link to={'/'}>
              <Logo src={firstLogo.url} alt={t('LOGO_ALT')} />
            </Link>
          </LogoContainer>
          <ActiveStep>
            <p>
              {t('STEP')}{' '}
              {activeItem && (
                <ActiveStepName>
                  <Translate keyName={activeItem.label} />
                </ActiveStepName>
              )}
            </p>
          </ActiveStep>
          {!isTransactionLoading && (
            <SaveContainer>
              <SaveOfferContainer>
                {(quotedTenant ? saveOfferButtonVisibleForQuotedTenant.includes(quotedTenant) : false)
                  && isSaveOfferButtonShown &&
                  (
                    <StyledSaveOfferButton
                      type="button"
                      typeStyle='outlined'
                      onClick={showSaveOfferModal}
                      className="buttonMore"
                    >
                      <StyledSaveOfferText>
                        {matchesDownMdBreakpoint
                          ? t('SAVE_CALCULATION_SHORT')
                          : t('SAVE_CALCULATION')}
                      </StyledSaveOfferText>
                    </StyledSaveOfferButton>
                  )}
              </SaveOfferContainer>
              {getPhoneIcon()}
            </SaveContainer>
          )}
        </StepsIndicatorContainer>
        <ProgressBarContainer>
          <ProgressBarProgress progressPercentage={progressPercentage} />
        </ProgressBarContainer>
        <ActiveStepMobile container justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={7}>
            <p>
              {t('STEP')}{' '}
              {activeItem && (
                <ActiveStepName>
                  <Translate keyName={activeItem.label} />
                </ActiveStepName>
              )}
            </p>
          </Grid>
        </ActiveStepMobile>
      </StepsIndicatorWrapper>
      <CallBackWidgetWrapper
        isCallBackWidgetOpen={isCallBackWidgetOpen}
        handleCloseCallBackWidget={() => setCallBackWidgetOpen(false)}
        tenantId={tenant.tenantId}
      />
      <OfferLoadingModal />
    </>
  );
};

const mapDispatchToProps = (dispatch: Function) => ({
  setCallBackWidgetOpen: (isCallBackWidgetOpen: boolean) =>
    dispatch(setCallBackWidgetOpen(isCallBackWidgetOpen))
});

const mapStateToProps = (state: CommonRootStateType & RootState) => ({
  isCallBackWidgetOpen: state.appState.isCallBackWidgetOpen,
  quotedTenant: state.userData.offerInfo.quotedTenant
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(StepsIndicator);
