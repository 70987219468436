import { beesafeTheme } from '@sky-distribution/react-commons-ui';
import { BasicInfoConfigurationType, ConfigurationKey } from '../tenant.type';
import alertIcon from './assets/alert.svg';
import homeBgDesktop from './assets/bg_desktop.svg';
import homeBgMobile from './assets/bg_mobile.svg';
import datePickerIcon from './assets/datepicker.svg';

const tenantConfiguration: BasicInfoConfigurationType = {
  [ConfigurationKey]: {
    datePickerIcon: datePickerIcon,
    alertIcon: alertIcon,
    homeBgDesktop: homeBgDesktop,
    homeBgMobile: homeBgMobile,
    displayOrLabel: true,
    desktopVerticalBackgroundPosition: '10rem',
    styledHeaderMarginBottom: '0',
    footerLinks: [
      {
        href: '/regulaminy',
        translationKey: 'REG_ELECTRONIC_SERVICES'
      },
      {
        href: '/polityka-prywatnosci',
        translationKey: 'PROCESSING_PERSONAL_DATA_INFO_BEESAFE'
      },
      {
        href: 'https://www.benefia24.pl/NewAssets/docs/Informacje_o_administratorze_danych_osobowych.pdf',
        translationKey: 'PROCESSING_PERSONAL_DATA_INFO_BENEFIA'
      },
      {
        href: 'https://beesafe.pl/dokumenty/',
        translationKey: 'FOOTER_DOCUMENTS'
      }
    ],
    startTitle: {
      displayTitle: true,
      displaySubtitle: true,
      style: {
        customMargin: '2rem',
        customWidth: '95%',
        customH1Color: beesafeTheme.palette.text.header
      }
    },
    boxShadow: beesafeTheme.shadow.contentBox,
    startPageForm: {
      width: '27.125rem',
      padding: '1.2rem',
      inputs: {
        height: '3.5rem'
      }
    }
  }
};

export default tenantConfiguration;
