"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.commonENMessages = void 0;
exports.commonENMessages = {
    FOOTER_POPULAR_QUESTIONS: 'Popular questions about buy OC AC insurance',
    FOOTER_MAIN_INFO: '',
    FOOTER_QUESTION_1_HEAD: '',
    FOOTER_QUESTION_2_HEAD: '',
    FOOTER_QUESTION_3_HEAD: '',
    FOOTER_QUESTION_4_HEAD: '',
    FOOTER_QUESTION_5_HEAD: '',
    FOOTER_QUESTION_6_HEAD: '',
    FOOTER_QUESTION_7_HEAD: '',
    FOOTER_QUESTION_8_HEAD: '',
    FOOTER_QUESTION_9_HEAD: '',
    FOOTER_QUESTION_10_HEAD: '',
    FOOTER_QUESTION_11_HEAD: '',
    FOOTER_QUESTION_12_HEAD: '',
    FOOTER_QUESTION_13_HEAD: '',
    FOOTER_QUESTION_14_HEAD: '',
    FOOTER_QUESTION_15_HEAD: '',
    FOOTER_QUESTION_16_HEAD: '',
    FOOTER_QUESTION_17_HEAD: '',
    FOOTER_QUESTION_18_HEAD: '',
    FOOTER_QUESTION_19_HEAD: '',
    FOOTER_QUESTION_20_HEAD: '',
    FOOTER_QUESTION_21_HEAD: '',
    FOOTER_QUESTION_22_HEAD: '',
    FOOTER_QUESTION_23_HEAD: '',
    FOOTER_QUESTION_24_HEAD: '',
    FOOTER_QUESTION_25_HEAD: '',
    FOOTER_QUESTION_1: '',
    FOOTER_QUESTION_2: '',
    FOOTER_QUESTION_3: '',
    FOOTER_QUESTION_4: '',
    FOOTER_QUESTION_5: '',
    FOOTER_QUESTION_6: '',
    FOOTER_QUESTION_7: '',
    FOOTER_QUESTION_8: '',
    FOOTER_QUESTION_9: '',
    FOOTER_QUESTION_10: '',
    FOOTER_QUESTION_11: '',
    FOOTER_QUESTION_12: '',
    FOOTER_QUESTION_13: '',
    FOOTER_QUESTION_14: '',
    FOOTER_QUESTION_15: '',
    FOOTER_QUESTION_16: '',
    FOOTER_QUESTION_17: '',
    FOOTER_QUESTION_18: '',
    FOOTER_QUESTION_19: '',
    FOOTER_QUESTION_20: '',
    FOOTER_QUESTION_21: '',
    FOOTER_QUESTION_22: '',
    FOOTER_QUESTION_23: '',
    FOOTER_QUESTION_24: '',
    FOOTER_QUESTION_25: '',
};
