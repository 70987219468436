import React, { FC } from 'react';
import {
  StyledDebugInfoRedirectButton,
  StyledDebugInfoMessage,
  StyledDebugInfoEmptyError
} from './DebugInfo.styled';
import Translate from '../../../../../translations/translate/Translate.component';
import { clear } from './redux/DebugInfo.actions';
import { connect } from 'react-redux';
import { copyToClipboard, isDebugInfoEmpty } from './utils/DebugInfo.util';
import { OnboardingStateType } from '../../../../../redux/types/AppStore.type';

type Props = DispatchProps & StateProps;

const DebugInfoModalContent: FC<Props> = (props: Props) => {

  const handleRedirectButtonOnClick = async () => {
    const imUrl = 'https://skydistribution.atlassian.net/jira/software/c/projects/IM/boards/39';
    await copyToClipboard(props.debugInfo);
    props.clearDebugInfo();
    window.open(imUrl, '_blank', 'noopener');
  };

  const renderStyledDebugInfoEmptyError = () => {
    const debugInfoGuideUrl = 'https://skydistribution.atlassian.net/wiki/spaces/MC/pages/182782702/Reporting+Incident#How-to-gather-debug-information';

    return (
      <StyledDebugInfoEmptyError>
        <Translate keyName='NO_DEBUG_INFO_TO_COPY' />
        {' '}
        <a target='_blank' rel='noreferrer' href={debugInfoGuideUrl}>
          <Translate keyName='DEBUG_INFO_GUIDE' />
        </a>
      </StyledDebugInfoEmptyError>
    );
  };

  return (
    <>
      <StyledDebugInfoMessage>
        <Translate keyName='DEBUG_INFO_MESSAGE' />
      </StyledDebugInfoMessage>
      {isDebugInfoEmpty(props.debugInfo) && renderStyledDebugInfoEmptyError()}
      <StyledDebugInfoRedirectButton onClick={handleRedirectButtonOnClick}>
        <Translate keyName={isDebugInfoEmpty(props.debugInfo) ? 'REDIRECT_TO_IM_BOARD' : 'COPY_AND_REDIRECT_TO_IM_BOARD' } />
      </StyledDebugInfoRedirectButton>
    </>
  );
};

const mapDispatchToProps = (dispatch: Function) => ({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clearDebugInfo: () => dispatch(clear())
});

const mapStateToProps = (state: OnboardingStateType) => ({
  debugInfo: state.debugInfo
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(DebugInfoModalContent);
