const YEAR_START = 0, YEAR_END = 2;
const MONTH_START = 2, MONTH_END = 4;
const DAY_START = 4, DAY_END = 6;

const PeselMapping = [{
  step: 80,
  base: 1800
}, {
  step: 60,
  base: 2200
}, {
  step: 40,
  base: 2100
}, {
  step: 20,
  base: 2000
}, {
  step: 0,
  base: 1900
}];

export const getBirthDateFromPesel = (pesel?: string): (Date | undefined) => {
  if (!pesel) {
    return undefined;
  }

  const rawYear = parseInt(pesel.substring(YEAR_START, YEAR_END), 10);
  const rawMonth = parseInt(pesel.substring(MONTH_START, MONTH_END), 10);
  const day = parseInt(pesel.substring(DAY_START, DAY_END), 10);

  const def = PeselMapping.find(({ step }) => rawMonth > step);
  
  if (!def) {
    return undefined;
  }

  return new Date(rawYear + def.base, rawMonth - def.step - 1, day, 0, 0, 0, 0);
};
