import React, { ChangeEvent, FC, FocusEvent, useCallback } from 'react';
import {
  getFieldValueFromObject,
  isDefined
} from '../../../utils/Object.utils';
import { StyledInput } from '../CustomInput.style';
import { getIn, useFormikContext } from 'formik';
import { isInputChecked } from '../../../utils/isInputChecked';
import { FormikCustomInputProps } from './FormikCustomInput.type';

export const FormikCustomInput: FC<FormikCustomInputProps> = (
  props: FormikCustomInputProps
) => {
  const formikProps = useFormikContext<{ [key: string]: unknown }>();
  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (isDefined(props.setFieldTouched)) {
        props.setFieldTouched(props.fieldName, true, false);
      }
      const destValue = props.toUpperCase
        ? event.target.value.toUpperCase()
        : event.target.value;

      props.setFieldValue(
        props.fieldName,
        destValue,
        !!getIn(formikProps.errors, props.fieldName)
      );
    },
    [props, formikProps]
  );

  const handleOnBlur = useCallback((event: FocusEvent<HTMLInputElement>) => {
    let destValue = event.target.value;

    if (props.setIsInputActive) {
      props.setIsInputActive(false);
    }

    if (props.ignoreSpaces) {
      destValue = destValue.replace(/\s/g, '');
    }

    props.setFieldValue(props.fieldName, destValue.trim(), true);

    if (isDefined(props.setFieldTouched)) {
      props.setFieldTouched(props.fieldName, true, true);
    }
  }, [props]);

  const handleOnFocus = useCallback(() => {
    if (props.setIsInputActive) {
      props.setIsInputActive(true);
    }
  }, [props]);

  return (
    <StyledInput
      id={props.id}
      name={props.fieldName}
      value={getFieldValueFromObject(props.values, props.fieldName) ?? ''}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      className={props.className}
      placeholder={props.placeholder}
      variant="outlined"
      fullWidth
      label={props.label}
      type={props.type}
      error={props.error}
      disabled={props.disabled}
      $checked={isInputChecked(getFieldValueFromObject(props.values, props.fieldName), props.error)}
    />
  );
};
