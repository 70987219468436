import React, { createContext, PropsWithChildren, useContext } from 'react';
import { TenantConfiguration, TenantsEnum } from './tenant.type';

export const TENANT_NAME = process.env.REACT_APP_TENANT ?? TenantsEnum.Beesafe;

export const configuration: TenantConfiguration = require(`./${TENANT_NAME}`).default;

export const TenantContext = createContext<TenantConfiguration>(configuration);

export function useTenant<TConfiguration = {}>(): TenantConfiguration & TConfiguration {
  return useContext(TenantContext) as TenantConfiguration & TConfiguration;
}

export const TenantProvider = (props: PropsWithChildren<{}>) => {
  return (
    <TenantContext.Provider value={configuration}>
      { props.children }
    </TenantContext.Provider>
  );
};

export function withTenant<TProps extends object, TConfig extends object = {}>(
  Component: React.ComponentType<TProps>,
  localConfiguration: TConfig & TenantConfiguration
): React.ComponentClass<TProps> {
  class C extends React.Component<TProps> {
    render = () => {
      return (
        <TenantContext.Provider value={{ ...configuration, ...localConfiguration }}>
          <Component {...this.props} />
        </TenantContext.Provider>
      );
    };
  }
  return C;
}

