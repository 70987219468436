import React from 'react';
import { TranslationWrapperProps } from './TranslationWrapper.type';
import { useI18n } from './use18n';

export const TranslationWrapper = ({
  commonPLMessages,
  commonENMessages,
  plMessages,
  enMessages,
  language,
  fallBackLanguage,
  children,
}: TranslationWrapperProps): JSX.Element => {
  useI18n({
    commonPLMessages,
    commonENMessages,
    plMessages,
    enMessages,
    language,
    fallBackLanguage,
  });
  return <>{children}</>;
};
