import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { TranslationContext } from '../../../../../translations/translate/globalTranslate/TranslationProvider';
import { FormikInputFormProps } from '../../../../../common/components/formik/Formik.type';
import { ReactComponent as SaveIco } from '../../../../../assets/images/userData/modal/save.svg';
import { StyledSaveOfferInfoBox } from './SaveOfferModal.style';
import { Modal } from '../Modal/Modal.component';
import { StyledSaveButton } from '../Modal/Modal.style';
import { EmailInput } from '../../../inputs/Email/EmailInput.component';
import { useSaveOfferModalProvider } from '../../../../../providers/SaveOfferModalProvider/useSaveOfferModalProvider';
import type { SaveOfferData } from '../../../../../providers/types';
import { useConsentsModalContext } from '../../../../../providers/ConsentsModalProvider/ConsentsModalProvider';
import { useDispatch } from 'react-redux';
import { savePersonalData } from '../../../containers/PersonalData/redux/PersonalData.actions';
import { ConsentsTypes } from '../../../containers/ContactInfo/components/ConsentForm/Consent.d';
import Consents from '../../../../../providers/ConsentsModalProvider/Consents.util';

type Props = FormikInputFormProps & {
  nextToViewPage: string;
}

const SaveOfferModal: FC<Props> = (
  props: Props
) => {
  const [isInputActive, setIsInputActive] = useState(false);
  const { t } = useContext(TranslationContext);
  const { onSubmit, isFormSubmitting } = useSaveOfferModalProvider();
  const { setConsentsNames, consentsState } = useConsentsModalContext();
  const dispatch = useDispatch();

  const { setFieldValue, touched, errors, values, setFieldTouched, isValid, nextToViewPage } =
    props;

  useEffect(() => {
    setConsentsNames(
      Consents.get().map((consentData: ConsentsTypes) => consentData.name)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { email } = values;
    email && dispatch(savePersonalData({ email }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentsState]);

  useEffect(() => {
    values.consents = consentsState;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentsState]);

  const isSubmitButtonDisabled = () => {
    return isFormSubmitting || !isValid;
  };

  const setIsInputActiveOnMobile = useCallback((inputState: boolean) => {
    if (isMobile) {
      setIsInputActive(inputState);
    }
  }, []);

  return (
    <Modal
      isNarrow
      header={t('SAVE_CALCULATION_TITLE')}
      belowHeader={
        <StyledSaveOfferInfoBox>
          <SaveIco />
          {t('SAVE_CALCULATION_INFO')}
        </StyledSaveOfferInfoBox>
      }
      isScrolled={false}
      content={
        <>
          <EmailInput
            setFieldValue={setFieldValue}
            values={values}
            error={errors?.email && touched?.email}
            setFieldTouched={setFieldTouched}
            disabled={isFormSubmitting}
            hideRowInfo
            setIsInputActive={setIsInputActiveOnMobile}
          />
        </>
      }
      buttons={
        <StyledSaveButton
          type='button'
          disabled={isSubmitButtonDisabled()}
          onClick={() => onSubmit(values as SaveOfferData, nextToViewPage)}
        >
          {t('SAVE_CALCULATION')}
        </StyledSaveButton>
      }
      styledModalButtonsWrapperProps={{ isInputActive }}
    />
  );
};

export default SaveOfferModal;
