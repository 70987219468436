import React from 'react';
import { CallBackWindowHeaderProps } from '../../../../../../components/CallBackWindow/CallBackWindowHeader/CallBackWindowHeader.types';
import { HeaderContainer, HeaderControl } from './Header.style';

export const Header = ({
  children,
  isFulfilled,
}: CallBackWindowHeaderProps): JSX.Element => {
  return (
    <HeaderContainer isFulfilled={isFulfilled}>
      <HeaderControl>{children}</HeaderControl>
    </HeaderContainer>
  );
};

export default Header;
