import React, { FC, useContext } from 'react';
import {
  StyledIco,
  StyledSaveOfferSuccessDescription
} from './SaveOfferSuccessModal.style';
import { TranslationContext } from '../../../../../translations/translate/globalTranslate/TranslationProvider';
import { ReactComponent as BeeIco } from '../../../../../assets/images/userData/modal/bee.svg';
import { Modal } from '../Modal/Modal.component';
import { StyledSaveButton } from '../Modal/Modal.style';
import { useModalContext } from '../../../../../providers/ModalProvider/ModalProvider';

export const SaveOfferSuccessModal: FC = () => {
  const { t } = useContext(TranslationContext);
  const { setIsModalVisible } = useModalContext();

  return (
    <Modal
      isNarrow
      header= {t('SAVE_CALCULATION_SAVED')}
      content={
        <>
          <StyledIco>
            <BeeIco />
          </StyledIco>
          <StyledSaveOfferSuccessDescription>
            {t('SAVE_CALCULATION_SAVED_DESCRIPTION')}
          </StyledSaveOfferSuccessDescription>
        </>
      }
      buttons={<StyledSaveButton
        type="button"
        onClick={() => setIsModalVisible(false)}
      >
        {t('CLOSE')}
      </StyledSaveButton>}
    />
  );
};
