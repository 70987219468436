import { TextField, TextFieldProps } from '@material-ui/core';
import styled from 'styled-components';

type StyledInputProps = TextFieldProps & {
  $checked?: boolean;
}

export const StyledInput = styled(TextField)<StyledInputProps>`
  & input {
    color: ${({ theme }) => theme.palette.input.text.selected};
  }
  
  &&& {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    .MuiFilledInput-root {
      border-radius: 0.2rem;
    }
    .MuiFilledInput-underline:after,
    .MuiFilledInput-underline:before {
      border-bottom: none;
    }
    .MuiInputLabel-filled.MuiInputLabel-shrink {
      width: max-content;
    }
    .MuiOutlinedInput-input{
      background: white;
    }
    
    .MuiFormLabel-root {
      color: ${p => p.$checked ? p.theme.palette.input.border.selected : p.theme.palette.input.text.base}; 
    }
    
    .Mui-focused {
      &.MuiFormLabel-root {
        color: ${({ theme }) => theme.palette.input.border.selected}; 
      }
    }
  
    .Mui-error {
      &.MuiFormLabel-root {
        color: ${({ theme }) => theme.palette.error.background.base}; 
      }
    }
    
    .MuiOutlinedInput-root {

      .MuiOutlinedInput-notchedOutline {
        border-color: ${p => p.$checked ? p.theme.palette.input.border.selected : p.theme.palette.input.text.base}; 
      }      

      &.Mui-focused {
        color:  ${({ theme }) => theme.palette.input.border.selected}; 
        .MuiOutlinedInput-notchedOutline {
          border-color: ${({ theme }) => theme.palette.input.border.selected}; 
        }
      }
      &.Mui-error {
        color:  ${({ theme }) => theme.palette.input.border.selected}; 
        .MuiOutlinedInput-notchedOutline {
          border-color: ${({ theme }) => theme.palette.error.background.base}; 
        }
      }
    }
  }
`;
