import React, { useContext } from 'react';
import { ICONS } from '../global/icons';

export interface AssetsContextType {
  [k: string]: string | undefined;
}

export interface AssetsProviderProps {
  children: React.ReactNode;
  assets?: AssetsContextType;
}

export const AssetsContext = React.createContext<AssetsContextType>(ICONS);

export const useAssets = (): AssetsContextType => {
  return React.useContext(AssetsContext);
};

export const AssetsProvider = ({
  children,
  assets,
}: AssetsProviderProps): JSX.Element => {
  const contextAssets = useContext(AssetsContext);
  const value = { ...contextAssets, ...assets };
  return (
    <AssetsContext.Provider value={value}>{children}</AssetsContext.Provider>
  );
};
