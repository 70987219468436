import React from 'react';
import { StyledContainer, StyledHeader, StyledText } from './ThankYou.style';
const ThankYou = (): JSX.Element => {
  return (
    <StyledContainer>
      <StyledHeader>Dziękujemy!</StyledHeader>
      <StyledText>
        Oddzwonimy do Ciebie w najbliższym możliwym terminie.
      </StyledText>
      <StyledText>Pracujemy w dni robocze: 8:00 - 18:00</StyledText>
    </StyledContainer>
  );
};

export default ThankYou;
