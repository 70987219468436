import {
  CloseLoadingQuoteModalAction,
  FinishQuoteAction,
  QUOTE_ACTIONS,
  ResetQuoteStatusAction,
  StartQuoteAction
} from './Quote.type';

const resetQuoteStatusAction = (): ResetQuoteStatusAction => ({
  type: QUOTE_ACTIONS.RESET_QUOTATION
});

const startQuoteAction = (isRecalculation: boolean): StartQuoteAction => ({
  type: QUOTE_ACTIONS.START_QUOTATION,
  payload: {
    isRecalculation
  }
});

const closeLoadingQuoteModalAction = (): CloseLoadingQuoteModalAction => ({
  type: QUOTE_ACTIONS.CLOSE_LOADING_MODAL
});

const finishQuoteAction = (): FinishQuoteAction => ({
  type: QUOTE_ACTIONS.FINISH_QUOTATION
});

export {
  resetQuoteStatusAction,
  startQuoteAction,
  closeLoadingQuoteModalAction,
  finishQuoteAction
};

