
import React, { FC, useState } from 'react';
import { useConsentsModalContext } from '../../../../../../providers/ConsentsModalProvider/ConsentsModalProvider';
import { useTranslate } from '../../../../../../translations/translate/globalTranslate/TranslationProvider';
import { ConsentsTypes } from '../ConsentForm/Consent.d';
import { CloudInfoIcon, CloudInfoIconWrapper, ToolTipBoxArrow, ToolTipConsent, ToolTipConsentDesc, ToolTipConsentWrapper, ToolTipInfoBox } from './ToolTipConsent.style';

const MAX_CLOSED_CONSENT_LENGTH = 172;

interface ToolTipConsentsProps {
  isDesktop?: boolean;
  width?: number;
}

export const getConsentTextTeaser = (consentText: string): string => {
  const consentTextTeaser = consentText.substring(0, MAX_CLOSED_CONSENT_LENGTH);
  return consentText.length <= MAX_CLOSED_CONSENT_LENGTH
    ? consentTextTeaser
    : `${consentTextTeaser}[...]`;
};

export const ToolTipConsents: FC<ToolTipConsentsProps> = ({ isDesktop, width }) => {
  const { consentsData } = useConsentsModalContext();
  const [showToolInfo, setShowToolInfo] = useState<boolean>(false);
  const translate = useTranslate();

  const handleMouseEnter = (isDesctop: boolean | undefined) => {
    isDesctop && setShowToolInfo(prevCheck => !prevCheck);
  };

  return (
    <ToolTipConsentWrapper e2e-data="ToolTipConsentWrapper">
      <CloudInfoIconWrapper e2e-data="CloudInfoIcon" data-testid="CloudInfoIconID"
        onMouseEnter={() => handleMouseEnter(isDesktop)} onMouseLeave={() => handleMouseEnter(isDesktop)}
        onTouchStart={() => handleMouseEnter(!isDesktop)} onTouchEnd={() => handleMouseEnter(!isDesktop)}>
        <CloudInfoIcon>i</CloudInfoIcon>
      </CloudInfoIconWrapper>

      <ToolTipInfoBox
        onMouseEnter={() => handleMouseEnter(isDesktop)} onMouseLeave={() => handleMouseEnter(isDesktop)}
        showToolInfo={showToolInfo} isDesktop={isDesktop} width={width}><ToolTipBoxArrow isDesktop={isDesktop}/>{<>
          {
            consentsData.map(({ rawName, name, requiredText }: ConsentsTypes) => {
              return (
                <ToolTipConsent key={rawName}>
                  {getConsentTextTeaser(translate(name))}{rawName === 'consentTerms' &&
                    <ToolTipConsentDesc>{requiredText && translate(requiredText)}</ToolTipConsentDesc>}
                </ToolTipConsent>
              );
            })
          }
        </>}</ToolTipInfoBox>
    </ToolTipConsentWrapper >
  );
};
