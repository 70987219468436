"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.commonPLMessages = void 0;
exports.commonPLMessages = {
    FOOTER_POPULAR_QUESTIONS: 'Popularne pytania do zakupu ubezpieczenia OC AC',
    FOOTER_MAIN_INFO: 'Kalkulator OC AC Beesafe pozwala sprawdzić aktualne oferty na ubezpieczenie OC. Dodatkowo sprawdzisz też pakiety OC i Autocasco. Ponadto, będziesz mógł zakupić polisy dobrowolne. Są to pełne assistance NNW, ubezpieczenie assistance opon czy assistance szyb. A to wszystko w zaledwie kilka chwil. Wybrane ubezpieczenie będziesz mógł kupić szybko i bezpiecznie online. Zachęcamy także do kontaktu z naszymi konsultantami. Są dostępni pod numerem telefonu 500 96 56 26.',
    FOOTER_QUESTION_1_HEAD: 'Jak działa nasz kalkulator OC AC NNW?',
    FOOTER_QUESTION_2_HEAD: 'Czy wszystkie pytania w kalkulatorze Beesafe są obowiązkowe?',
    FOOTER_QUESTION_3_HEAD: 'Ile kosztuje skorzystanie z kalkulatora OC samochodu?',
    FOOTER_QUESTION_4_HEAD: 'Ile czasu trwa wypełnienie formularza w kalkulatorze OC AC?',
    FOOTER_QUESTION_5_HEAD: 'Czy można wykonać kalkulację OC za pomocą smartfona?',
    FOOTER_QUESTION_6_HEAD: 'Czym oprócz ceny różnią się oferty na ubezpieczenie OC?',
    FOOTER_QUESTION_7_HEAD: 'Czy kalkulator OC uwzględnia zniżki na ubezpieczenie?',
    FOOTER_QUESTION_8_HEAD: 'Czy ubezpieczenie OC i AC jest obowiązkowe?',
    FOOTER_QUESTION_9_HEAD: 'Dla jakich aut obliczysz ceny polis w kalkulatorze?',
    FOOTER_QUESTION_10_HEAD: 'Jakie jeszcze polisy kupisz w kalkulatorze ubezpieczeń?',
    FOOTER_QUESTION_11_HEAD: 'Czy mogę obliczyć cenę OC dla samochodu, którego jeszcze nie mam?',
    FOOTER_QUESTION_12_HEAD: 'Czy współwłaściciel samochodu może obliczyć cenę OC w kalkulatorze Beesafe?',
    FOOTER_QUESTION_13_HEAD: 'Czy po wykonaniu kalkulacji muszę kupić ubezpieczenie OC w Beesafe?',
    FOOTER_QUESTION_14_HEAD: 'Ile czasu potrzebuje kalkulator OC Beesafe na przedstawienie oferty?',
    FOOTER_QUESTION_15_HEAD: 'Dlaczego zakup OC i AC na Beesafe.pl się opłaca?',
    FOOTER_QUESTION_16_HEAD: 'Wybrałem ubezpieczenie auta. Czy mogę kupić je przez internet?',
    FOOTER_QUESTION_17_HEAD: 'Czy moje dane w kalkulatorze ubezpieczeń Beesafe są bezpieczne?',
    FOOTER_QUESTION_18_HEAD: 'Dlaczego warto zaufać Beesafe?',
    FOOTER_QUESTION_19_HEAD: 'W jaki sposób weryfikowane są dane o historii ubezpieczeniowej?',
    FOOTER_QUESTION_20_HEAD: 'Jakie dane są niezbędne, by skorzystać z kalkulatora OC Beesafe?',
    FOOTER_QUESTION_21_HEAD: 'Wątpliwości przy korzystaniu z kalkulatora OC AC Beesafe?',
    FOOTER_QUESTION_22_HEAD: 'Dlaczego kalkulator OC Beesafe pyta o tak mało danych? Czy to bezpieczne?',
    FOOTER_QUESTION_23_HEAD: 'Czy samochód osobowy to jedyny pojazd, dla którego mogę obliczyć składkę ubezpieczenia?',
    FOOTER_QUESTION_24_HEAD: 'Jakie dokumenty są niezbędne, aby skorzystać z kalkulatora OC?',
    FOOTER_QUESTION_25_HEAD: 'Jak otrzymam polisę zakupioną poprzez kalkulator OC AC Beesafe?',
    FOOTER_QUESTION_26_HEAD: 'Co to jest porównywarka OC?',
    FOOTER_QUESTION_27_HEAD: 'Czy istnieje kalkulator OC bez podawania danych?',
    FOOTER_QUESTION_28_HEAD: 'Gdzie kupić najtańsze ubezpieczenie OC?',
    FOOTER_QUESTION_29_HEAD: 'Jak działa kalkulator ubezpieczenia?',
    FOOTER_QUESTION_30_HEAD: 'Który darmowy kalkulator OC wybrać?',
    FOOTER_QUESTION_31_HEAD: 'Co to jest ubezpieczenie samochodu?',
    FOOTER_QUESTION_32_HEAD: 'Jak obliczyć sobie OC?',
    FOOTER_QUESTION_33_HEAD: 'Jaką porównywarkę OC wybrać?',
    FOOTER_QUESTION_34_HEAD: 'Kalkulator OC AC - który jest najlepszy?',
    FOOTER_QUESTION_35_HEAD: 'Jak wyliczyć najtańsze OC?',
    FOOTER_QUESTION_36_HEAD: 'Ile za OC i AC?',
    FOOTER_QUESTION_37_HEAD: 'Gdzie najlepsze OC i AC?',
    FOOTER_QUESTION_38_HEAD: 'Czy można mieć OC i AC w różnych firmach?',
    FOOTER_QUESTION_39_HEAD: 'Ile płaci się za OC?',
    FOOTER_QUESTION_1: 'Kalkulator ubezpieczeń samochodowych Beesafe to nowoczesne rozwiązanie. Pozwala w krótkim czasie poznać ofertę na ubezpieczenie pojazdu. Na podstawie Twoich odpowiedzi, Beesafe tworzy oferty na polisę komunikacyjną. Nasz kalkulator OC AC oszczędza Twój czas. Nie musisz już odpowiadać na wiele pytań. Za pomocą naszego kalkulatora ubezpieczeń, możesz kupić ubezpieczenie OC online. A także zapisać wyniki kalkulacji na później, bez konieczności ponownego wypełniania formularza. Jeśli masz wątpliwości, zawsze możesz skontaktować się z naszymi doradcami.',
    FOOTER_QUESTION_2: 'Nasz formularz składa się zaledwie z kilku pytań. Dzięki temu oszczędzasz czas, a my możemy przedstawić Ci dobrą ofertę. W naszym kalkulatorze ubezpieczenia OC i AC nie ma zbędnych pytań. Prosimy tylko o podanie tych danych, które są dla nas niezbędne do obliczenia dokładnej ceny polisy.',
    FOOTER_QUESTION_3: 'Z przyjemnością informujemy, że korzystanie z naszego kalkulatora OC AC jest bezpłatne. Udostępniamy Ci darmowy kalkulator OC, który oszczędza Twój czas. Już teraz przekonaj się, jakie jest to proste. Sprawdź, jak szybko otrzymasz wyliczenie składki na ubezpieczenie komunikacyjne swojego auta!',
    FOOTER_QUESTION_4: 'Nasze narzędzie pozwala przejść cały proces w kilka chwil. Przed startem kalkulacji przygotuj tylko dwa dokumenty. To prawo jazdy oraz dowód rejestracyjny. Po udzieleniu odpowiedzi, wyświetlimy Ci naszą ofertę na ubezpieczenie OC samochodu i Autocasco.',
    FOOTER_QUESTION_5: 'Nasz kalkulator ubezpieczeń jest narzędziem dostosowanym do obsługi na wszystkich urządzeniach mobilnych i komputerach. Jeśli więc korzystasz z telefonu, przekonaj się sam jakie to wygodne i oblicz ubezpieczenie OC!',
    FOOTER_QUESTION_6: 'Kalkulator ubezpieczeniowy Beesafe to narzędzie, które umożliwia wybór i zakup polisy dopasowanej do Twoich potrzeb. Do standardowego OC możesz wybrać dodatkowe produkty ubezpieczeniowe. Np. autocasco, ubezpieczenia miejskie oraz usługi dodatkowe. Np. „Wygodny kierowca”, który zapewnia przegląd lub naprawę pojazdu door-to-door.',
    FOOTER_QUESTION_7: 'Tak, obliczając składkę na ubezpieczenie, nasz kalkulator OC bierze pod uwagę Twoją historię ubezpieczeniową. Znaczenie mają też szkody wypłacane z Twojej polisy OC lub AC do 7 lat wstecz.',
    FOOTER_QUESTION_8: '<p>Ubezpieczenie OC jest polisą obowiązkową. Za jej brak lub przerwę w ciągłości ochrony ubezpieczeniowej grożą surowe kary. ' +
        'Mogą one wynieść nawet kilka tysięcy złotych. Polisa OC musi być ważna. Nie ma znaczenia czy pojazd jest w ruchu, czy też nie jest użytkowany.' +
        '</p><p>Polisa AC (Autocasco) jest ubezpieczeniem dobrowolnym, podobnie jak assistance, ubezpieczenie opon itd. Warto jednak' +
        ' rozważyć zakup tego ubezpieczenia, zwłaszcza gdy wartość naszego auta jest wysoka. Autocasco chroni nasze finanse w razie kradzieży auta,' +
        ' czy też uszkodzenia.</p>',
    FOOTER_QUESTION_9: 'W tej chwili nasz kalkulator OC AC umożliwia sprawdzenie cen polis komunikacyjnych dla prywatnych aut osobowych. W kilkadziesiąt sekund obliczy wiążące oferty dla pojazdów wykonanych po 1990 roku.',
    FOOTER_QUESTION_10: '<p>Oczywiście nie musisz ograniczać się wyłącznie do zakupu obowiązkowego OC lub popularnego Autocasco. W Beesafe możesz' +
        ' znacznie rozszerzyć zakres ochrony własnego samochodu i zapewnić całościowe ubezpieczenie. Nasza oferta jest bogata w dodatki. Co' +
        ' jeszcze możesz zakupić za pośrednictwem naszego kalkulatora ubezpieczeń?</p><br /><p><span>NNW</span> – to polisa od następstw' +
        ' nieszczęśliwych wypadków. Zapewnia ochronę życia i zdrowia wszystkich pasażerów podróżujących samochodem. Odszkodowanie' +
        ' wypłacane jest zarówno za uszczerbek na zdrowiu, jak i w przypadku śmierci. <br /> <span>Ubezpieczenie assistance' +
        ' opony</span> – uszkodzenie opony zawsze zdarza się akurat wtedy, gdy bardzo nam się spieszy. Mając tę polisę,' +
        ' możesz być pewien, że wymienimy oponę za Ciebie. Jeśli to konieczne, odholujemy pojazd do najbliższego warsztatu. <br /><span>Ubezpieczenie' +
        ' assistance szyby</span> – jeśli uszkodzeniu w Twoim aucie uległa szyba, zapewnimy możliwie szybką jej wymianę.' +
        ' Nie będzie to miało wpływu na Twoje zniżki na Autocasco! <br /> <span>Ubezpieczenie assistance</span> – w ramach tej' +
        ' usługi zapewniamy pomoc specjalistów, w razie awarii Twojego auta. To jednak nie koniec.' +
        ' W zależności od wybranego przez Ciebie pakietu, zapewnimy Ci pojazd zastępczy lub możliwość noclegu w hotelu. <br />' +
        '<span>Ubezpieczenie miejskie</span> – to ubezpieczenie OC i NNW w jednym. Działa, gdy poruszasz się swoim lub wynajętym' +
        ' rowerem. A także hulajnogą, skuterem, deskorolką czy segwayem. <br /></p>',
    FOOTER_QUESTION_11: 'Tak, chociaż zajmie to trochę więcej czasu. Wystarczy jednak, że odpowiesz na kilka dodatkowych pytań, a my przygotujemy dla Ciebie odpowiednią ofertę. Kalkulator OC AC umożliwia obliczenie składki dla auta, którego nie jesteśmy właścicielem. Pamiętaj jednak, że przedstawiona przez nas oferta jest wiążąca w dniu jej wyświetlenia. Jeśli będziesz chciał kupić ubezpieczenie OC lub AC w późniejszym czasie, ceny mogą się nieznacznie różnić.',
    FOOTER_QUESTION_12: 'Oczywiście, że tak! Do obliczenia składki dla pojazdu nie potrzebujesz pozostałych współwłaścicieli. Wystarczy, że na formularzu podasz wszystkie niezbędne dane. Dzięki temu rzetelnie przygotujemy dla Ciebie ofertę. Będzie uwzględniała historię ubezpieczeniową wszystkich współwłaścicieli, a cena którą wyświetlimy, będzie wiążąca.',
    FOOTER_QUESTION_13: 'Nie, dokonanie kalkulacji ma na celu jedynie przedstawienie dostępnych ofert. Tylko od Ciebie zależy, czy zdecydujesz się na zakup. Wtedy skorzystasz z dostępnych opcji i dodatkowych usług dostępnych w świecie Beesafe. Nasz kalkulator OC AC umożliwia zapoznanie się z każdą z dostępnych ofert.',
    FOOTER_QUESTION_14: '<p>Cenę ubezpieczenia własnego samochodu poznasz po zaledwie kilkudziesięciu sekundach, gdy skorzystasz' +
        ' z naszego kalkulatora OC. Tak szybko w Polsce jeszcze nie było! Zaoszczędzony czas to dopiero początek korzyści,' +
        ' jakie niesie ze sobą zakup OC i/lub AC w Beesafe. Ponadto, oferujemy szereg profitów, jak np. programy nagród.' +
        ' Zawsze gdy kupujesz za pośrednictwem naszego kalkulatora ubezpieczeń zyskujesz punkty. Wymieniasz je na' +
        ' nagrody dopasowane do Twoich potrzeb.</p><p>Zakup ubezpieczenia komunikacyjnego na beesafe.pl' +
        ' to także ogromna wygoda. Składkę obliczysz w dowolnym momencie i dowolnym' +
        ' miejscu, na telefonie, komputerze lub tablecie. Co więcej, zakupu możesz dokonać również o wybranej' +
        ' przez siebie porze. Dzięki temu unikniesz przerwy w ubezpieczeniu. A co za tym idzie także i kary' +
        ' za brak polisy od UFG. Nie musisz już odpowiadać na dziesiątki pytań. Wystarczy wypełnić kilka pól' +
        ' formularza, aby poznać obowiązującą cenę polisy, którą kupisz online!</p>',
    FOOTER_QUESTION_15: 'Ubezpieczenie OC online to obecnie najbardziej komfortowe rozwiązanie. W Beesafe przez cały proces, od rozpoczęcia kalkulacji po sam zakup polisy, możesz przejść całkowicie online. Bez wychodzenia z domu! Ubezpieczenie kupisz przez internet poprzez kalkulator OC AC, natychmiast po wyborze oferty. Całość zajmie Ci zaledwie kilka minut!',
    FOOTER_QUESTION_16: 'Blik, płatność przelewem tradycyjnym a może szybkie przelewy online? To od Ciebie zależy sposób płatności. Najważniejsze, że cały proces zakupu ubezpieczenia przez nasz kalkulator OC jest całkowicie bezpieczny. Dodatkowo chroniony przed dostępem niepowołanych osób.',
    FOOTER_QUESTION_17: 'Oczywiście! Wszystkie dane, które podajesz w formularzu naszego kalkulatora OC AC są całkowicie bezpieczne. Chronimy je przed dostępem osób trzecich. To wszystko dzięki rozbudowanej infrastrukturze odpowiedzialnej za bezpieczeństwo, licznym procedurom oraz wdrożonemu certyfikatowi SSL. Możesz być pewien, że dane nie zostaną przekazane innym podmiotom, a płatności online są chronione. Pamiętaj, że Twoje dane potrzebne są nam wyłącznie do obliczenia składki polisy OC AC. Wszelkie informacje na ten temat znajdziesz w naszej polityce prywatności oraz regulaminie serwisu.',
    FOOTER_QUESTION_18: '<p>Jesteśmy internetowym agentem ubezpieczeniowym jednego z największych towarzystw' +
        ' ubezpieczeniowych w naszym kraju – Compensa TU SA. Beesafe jest zarejestrowana na liście agentów' +
        ' ubezpieczeniowych KNF. Obie spółki wchodzą w skład międzynarodowej grupy ubezpieczeniowej' +
        ' Vienna Insurance Group (VIG). Działa ona na 25 rynkach Europy Centralnej i Wschodniej.</p>' +
        '<p>Beesafe tworzy grupa specjalistów ds. ubezpieczeń, którzy posiadają wiele lat doświadczenia' +
        ' w tej branży. Z kolei nasz kalkulator OC i AC to efekt wielu miesięcy pracy i testów.' +
        ' Miały one na celu stworzenie narzędzia całkowicie odpowiadającego Twoim potrzebom i oczekiwaniom.' +
        ' Umożliwia on nie tylko sprawdzenie oferty i zakup ubezpieczenia online. To także możliwość' +
        ' bezpośredniego kontaktu z naszymi doradcami. Służą oni pomocą w zakupie ubezpieczenia' +
        ' samochodu najlepiej dopasowanego do Twoich potrzeb.</p>',
    FOOTER_QUESTION_19: 'Wszystkie dane dotyczące Twoich polis komunikacyjnych OC i AC są weryfikowane przy pomocy Ubezpieczeniowego Funduszu Gwarancyjnego UFG. To tam gromadzone są informacje o historii zawieranych umów ubezpieczeniowych, a także zgłoszonych szkód. Na podstawie historii ubezpieczeń z UFG nasz kalkulator OC nalicza dodatkowe zniżki. Pod warunkiem, że nie miałeś na koncie żadnych stłuczek w ciągu ostatnich 7 lat.',
    FOOTER_QUESTION_20: 'Nigdy wcześniej obliczenie składki na ubezpieczenie OC nie było prostsze. Wystarczy, abyś podał nam swoją datę urodzenia i numer rejestracyjny pojazdu. To wszystko! Nie pytamy o nic więcej, aby sprawnie przejść przez proces kalkulacji. Ubezpieczenie samochodu z Beesafe jest proste, szybkie i przede wszystkim wygodne.',
    FOOTER_QUESTION_21: 'Na którymś etapie wykonywania kalkulacji na ubezpieczenie samochodu lub motocykla napotkałeś problem? Skorzystaj z naszej pomocy telefonicznej pod numerem 500 96 56 26. Nasi doradcy z przyjemnością odpowiedzą na Twoje pytania. Nasz kalkulator OC to bezpieczne narzędzie. Nie udostępniamy Twojego numeru osobom trzecim ani innym podmiotom. Już teraz oblicz swoje OC w najszybszym kalkulatorze ubezpieczeń komunikacyjnych na rynku!',
    FOOTER_QUESTION_22: 'Gdy tworzyliśmy nasz kalkulator OC, zadaliśmy sobie proste pytanie. Dlaczego mamy uzupełniać kolejne okienka opasłych formularzy? Czy nie da się prościej? Ograniczyliśmy więc zadawanie pytań do 2 niezbędnych informacji. Tj. daty urodzenia właściciela i numeru rejestracyjnego pojazdu. Resztą zajmuje się nasz system. Pobiera on dane z UFG, Eurotax oraz CEPiK. Na ich podstawie tworzy ofertę na ubezpieczenie samochodu lub motocykla. Co ważne, cały proces jest całkowicie bezpieczny. Szczególny nacisk kładziemy na kwestię zabezpieczeń i uszczelnienia systemu. Dokładnie tak, aby nie został on naruszony.',
    FOOTER_QUESTION_23: 'Nie, kalkulator OC AC Beesafe pozwala na wykonanie kalkulacji składki ubezpieczeniowej także dla jednośladów. Jeśli posiadasz motocykl, możesz skorzystać z naszego kalkulatora w taki sam sposób, co w przypadku samochodów. Tutaj również wystarczy nam numer rejestracyjny i data urodzenia właściciela. W razie jakichkolwiek problemów, zadzwoń pod numer 500 96 56 26. Nasi doradcy pomogą Ci na każdym etapie zakupu ubezpieczenia.',
    FOOTER_QUESTION_24: 'Nie pamiętasz numeru rejestracyjnego swojego pojazdu? Przed przystąpieniem do kalkulacji przygotuj dowód rejestracyjny samochodu. To wszystko! Chcesz sprawdzić cenę ubezpieczenia OC lub AC dla auta, które nie jest Twoją własnością? Wtedy będziesz potrzebował również dowód osobisty właściciela, niezbędny do podania jego daty urodzenia. Możesz także skorzystać z tradycyjnego formularza. Wówczas kalkulator OC Beesafe poprosi Cię również o dane z prawa jazdy.',
    FOOTER_QUESTION_25: 'Wszystkie niezbędne dokumenty polisowe, w tym również Ogólne Warunki Ubezpieczenia, wyślemy na wskazany przez Ciebie adres e-mail. Ponadto, w każdej chwili masz do nich dostęp po zalogowaniu się na stronie <a href="https://beesafe.pl/portalklienta/logowanie">Beesafe - Portal klienta</a> .',
    FOOTER_QUESTION_26: 'Wyjaśnienie tego, co to jest porównywarka OC, zacznijmy od informacji, że to nowoczesne rozwiązanie, które w łatwy i szybki sposób pozwala na zestawienie ofert wielu ubezpieczycieli w jednym miejscu. Kalkulator OC AC od Beesafe to formularz online, w którym intuicyjnie i bez problemów odpowiesz na pytania dotyczące pojazdu i jego właściciela i w efekcie otrzymasz korzystną ofertę. Powyżej znajduje się kalkulator ubezpieczeniowy, dzięki któremu wykonasz kalkulację polisy dla swojego pojazdu. Nie chcesz podawać zbyt wielu wrażliwych danych? Doskonale się składa, ponieważ porównywarka Beesafe wymaga tylko 2 informacji do obliczenia składki.',
    FOOTER_QUESTION_27: 'Aby stworzyć korzystną i dopasowaną do klienta ofertę, ubezpieczyciele potrzebują zazwyczaj wielu szczegółowych danych, a każda informacja ma dla nich znaczenie. Informacje dotyczące samochodu takie jak model, marka, pojemność silnika czy rodzaj paliwa są standardem, ale oprócz tego formularze wymagają też podania takich danych jak m. in. PESEL, płeć, kod pocztowy, a nawet stan cywilny i ilość dzieci. Jeśli więc masz opór przed podawaniem tych informacji, pamiętaj, że kalkulator Beesafe działa inaczej. W takim razie, czy istnieje kalkulator OC bez podawania danych? Oczywiście nie do końca, jednak nasz kalkulator ogranicza niezbędne informacje do absolutnego minimum.',
    FOOTER_QUESTION_28: 'Kalkulator ubezpieczeń OC AC Beesafe to świetne narzędzie do znalezienia korzystnej oferty. Po wypełnieniu prostego formularza, szybko i sprawnie otrzymasz kalkulację polisy. Możesz porównać oferty i zdecydować, co jest dla ciebie najkorzystniejsze. Oczywiście ceny OC różnią się od siebie, ponieważ wysokość składki zależy od wielu czynników. Najmniej za OC zapłacą kierowcy ze sporym doświadczeniem za kierownicą, którzy mają czystą historię ubezpieczeniową i wypracowali już maksymalne zniżki. Na obniżenie składki wpływa także niewielka pojemność silnika, miejsce zamieszkania w niewielkiej miejscowości i wiele innych czynników. Okazuje się, że jeśli spełniasz te kryteria, możesz liczyć na składkę OC w wysokości tylko ok. 300-400 zł.',
    FOOTER_QUESTION_29: 'Kalkulator ubezpieczenia Beesafe został stworzony z myślą o wygodzie klientów. Nie jest więc narzędziem dla specjalistów, ale właśnie dla ciebie, jeśli masz potrzebę lub ochotę z niego skorzystać. Wystarczy jedynie, że wpiszesz w okna formularza online odpowiednie informacje. Beesafe to najszybszy kalkulator na rynku. Musisz uzupełnić w nim tylko dwie informacje – numer rejestracyjny pojazdu oraz datę urodzenia. Jak działa Kalkulator ubezpieczenia Beesafe? Otóż jest on połączony z bazami UFG, CEPiK oraz Eurotax. Dzięki temu błyskawicznie pobiera dane i na tej podstawie szacuje jak najkorzystniejszą składkę.',
    FOOTER_QUESTION_30: 'Kalkulator OC AC to doskonałe narzędzie, które pozwala na sprawdzenie oferty ubezpieczeń komunikacyjnych, których potrzebujesz dla twojego samochodu. Możesz to zrobić w kilka chwil, na każdym urządzeniu z dostępem do Internetu, na dodatek nie ponosząc żadnych kosztów. Zachodzi więc pytanie, który darmowy kalkulator OC wybrać? Na pewno ten, który oferuje jak najwięcej korzyści swoim użytkownikom. Dlatego wybierz kalkulator OC AC Beesafe.',
    FOOTER_QUESTION_31: 'W świetle polskiego prawa każdy zarejestrowany samochód musi posiadać ważne ubezpieczenie OC. Polisa odpowiedzialności cywilnej posiadaczy pojazdów mechanicznych jest obowiązkowa i od tej reguły nie ma absolutnie żadnego wyjątku. Ubezpieczenie OC stanowi zabezpieczenie poszkodowanych, którzy doznali szkody w zdarzeniu drogowym i chroni sprawcę tego zdarzenia przed ich roszczeniami. W skrócie - sprawca wypadku czy kolizji poruszający się ubezpieczonym samochodem może odetchnąć z ulgą - wszelkie koszty spowodowanych przez niego szkód zostaną pokryte z ubezpieczenia samochodu.',
    FOOTER_QUESTION_32: 'Wyliczenie wysokości składki OC w teorii wydaje się procesem niezwykle skomplikowanym. W praktyce kalkulację możemy przeprowadzić samodzielnie. Jak obliczyć sobie OC? Czy wyliczenie składki możliwe jest bez osobistego kontaktu z agentem? Jak najbardziej! W tym celu możesz skorzystać z dostępnych w Internecie narzędzi, które na podstawie podanych przez Ciebie danych, przygotowują dedykowaną kalkulację składki OC. Większość dostępnych narzędzi działa na podstawie bardzo podobnego schematu i wymaga podania szeregu informacji. Kalkulator OC AC Beesafe, aby sprawnie przeprowadzić Cię przez cały proces kalkulacji składki ubezpieczeniowej, wymaga podania jedynie dwóch danych. Teraz już wiesz, jak obliczyć sobie OC i zaoszczędzić bezcenny czas.',
    FOOTER_QUESTION_33: 'Dziś bez problemu obliczymy wysokość składki obowiązkowego ubezpieczenia OC samodzielnie, bez wychodzenia z domu i pomocy agenta. Na rynku znajdziemy szereg porównywarek OC, które ułatwią nam wyliczenie składki i porównanie kolejnych ofert. Wszystkie działają na podobnej zasadzie - Ty musisz podać szereg informacji dotyczących ubezpieczanego pojazdu oraz danych dotyczących kierowcy, a porównywarka w oparciu o odpowiednie algorytmy przygotuje dla Ciebie kalkulację. Aby przejść cały proces wyliczenia składki musisz wygospodarować kilka minut, by podać wszystkie niezbędne informacje. Znacznie szybszym rozwiązaniem jest kalkulator OC AC od Beesafe, gdzie wystarczą tylko 2 informacje do wyliczenia składki na ubezpieczenie pojazdu.',
    FOOTER_QUESTION_34: 'Większość tego typu narzędzi bazuje na podobnym schemacie - wśród danych, które niezbędne są do wykonania kalkulacji znajdziemy te, które dotyczą samego pojazdu i jego parametrów technicznych, a także informacje dotyczące profilu właściciela auta. Choć narzędzie to umożliwia szybkie wyliczenie składki ubezpieczenia, na uzupełnienie koniecznych danych musimy poświęcić parę chwil. Wybierz taki, który jest nie tylko intuicyjny w obsłudze, ale skraca także proces kalkulacji do niezbędnego minimum. Korzystając z kalkulatora OC/AC od Beesafe zaoszczędzisz swój cenny czas, ponieważ cały proces trwa tutaj zaledwie kilka chwil. Do wyliczenia składki potrzebujesz jedynie dwóch danych - numeru rejestracyjnego pojazdu oraz daty urodzenia właściciela.',
    FOOTER_QUESTION_35: 'Kupując auto sprawdźmy, które auta przez ubezpieczycieli traktowane są jako bezpieczniejsze. Niemałe znaczenie ma też pojemność silnika i to, gdzie będziemy poruszać się autem. Małe miejscowości stanowią dużo mniejsze zagrożenie niż centra dużych aglomeracji miejskich.',
    FOOTER_QUESTION_36: 'Kiedy w Internecie poszukujemy informacji dotyczących tego, ile zazwyczaj kosztuje OC oraz AC najczęściej otrzymujemy informację, że tego rodzaju pakiet polis może kosztować od 1000 do 2500 zł. Zazwyczaj kupienie takiego zestawu jest o wiele tańsze niż kupienie samego obowiązkowego ubezpieczenia komunikacyjnego, a następnie kupowanie ubezpieczeń nieobowiązkowych, na przykład autocasco. Zawsze warto zweryfikować, o ile zostanie podniesiona zostanie nasza składka OC, jeśli dokupimy polisę dodatkową. Bardzo często różnica pomiędzy obowiązkowym ubezpieczeniem komunikacyjnym OC, a ubezpieczeniem OC+AC w pakiecie, jest nieduża.',
    FOOTER_QUESTION_37: 'Obecnie można skorzystać z kalkulatora OC AC i wygodny sposób wyliczyć sobie składkę za 12 miesięcy ochrony polisą w domu. Właściciel pojazdu ma wtedy możliwość przyjrzenia się wszystkim oferowanym przez towarzystwo ubezpieczeniowe wariantom i może wybrać zarówno same obowiązkowe ubezpieczenie komunikacyjne, jak i pakiet ubezpieczeń. Bardzo często pakiet OC i AC jest niewiele droższy od zakupu samego obowiązkowego ubezpieczenia komunikacyjnego, a zakres ochrony, do którego zobowiązany jest zakład ubezpieczeń znacznie się poszerza. Już teraz sprawdź ofertę na pakiet OC i AC od Beesafe.',
    FOOTER_QUESTION_38: 'Teoretycznie tak. Warto dowiedzieć się, czy towarzystwo ubezpieczeniowe zechce nam sprzedać dodatkowe, nieobowiązkowe ubezpieczenie komunikacyjne, na przykład autocasco, jeśli posiadamy obowiązkowe ubezpieczenie komunikacyjne w innej firmie. Jednakże najwygodniej jest dokupić dobrowolne AC w tym towarzystwie, w którym mamy już polisę OC. Zazwyczaj najkorzystniejsze warunki zaoferowane zostaną właścicielom pojazdu wtedy, gdy decyduje się on na zakup pakietu OC i AC jednocześnie.',
    FOOTER_QUESTION_39: 'To, ile płaci się za OC zależy od bardzo wielu czynników. Wysokość stawek za ubezpieczenie OC zazwyczaj opiera się na wielu elementach. W zależności od towarzystwa ubezpieczeniowego przypisywana jest im różna waga w ramach oceny ryzyka, z jakim wiąże się ubezpieczenia naszego pojazdu. Możemy jednak uogólniając stwierdzić, że obecnie średnio za ubezpieczenie OC zapłacimy około 600 zł.',
};
