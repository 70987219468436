import styled from 'styled-components';

export const FormRowInfo = styled.p`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => theme.palette.text.paragraph.primary};
  font-size: .75rem;
  margin: .1875rem 0 -1.3125rem 0.625rem;
  font-weight: 400;
`;

export const ConsentsRow = styled.div<{mobileMarginLeft?: string}>`
    display: flex;
    justify-content: space-between;
    width: 92%;
    padding: 1.25rem 0 .5rem .25rem;
    ${({ theme }) => theme.breakpoints.down('sm')} {
        margin-left: ${props => props.mobileMarginLeft ? props.mobileMarginLeft : ''};
        width: 100%;
    }
`;

export const StyledFormRow = styled.div`
  width: 100%;
  > div {
    margin-bottom: 8px;
  }
`;
