import styled, { css } from 'styled-components';
import { Duration } from './ToggleSelectHorizontal.type';

const SelectedRecommendedTextCss = css`
  opacity: 1;
`;

export const StyledWrapper = styled.div`
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-bottom: 0.75rem;
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledTitle = styled.div`
  font-family: ${(p) => p.theme.typography.fontFamily};
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.563rem;
  text-align: center;
  white-space: nowrap;
`;

export const StyledCaption = styled.div<{
  isSelected?: boolean;
}>`
  color: ${(p) => (p.isSelected ? p.theme.palette.brand.color : 'inherit')};
  font-family: ${(p) => p.theme.typography.fontFamily};
  font-size: ${(p) => p.theme.typography.body1.fontSize};
  line-height: 0.75rem;
  text-align: center;
`;
export const StyledRecommendedText = styled.p<{
  isSelected?: boolean;
  duration?: Duration;
}>`
  background-color: ${({ theme }) => theme.palette.brand.color};
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  bottom: 100%;
  color: ${({ theme }) => theme.palette.commons.background.container};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 0.75rem;
  left: 50%;
  line-height: 1.5;
  margin: 0;
  opacity: 0;
  padding: 0 0.7rem;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  transition: all ease-in-out;
  transition-duration: ${({ duration }) => (duration as string) || '0s'};
  width: 75%;
  ${({ isSelected }) => isSelected && SelectedRecommendedTextCss}
`;
