import React, { PropsWithChildren } from 'react';
import {
  GenericErrorPageHeaderWrapper,
  StyledHeader
} from './GenericErrorPageHeader.styled';

export interface GenericErrorPageHeaderProps {
  message?: string;
}

type Props = PropsWithChildren<GenericErrorPageHeaderProps>;

export const GenericErrorPageHeader: React.FC<Props> = (props: Props) => (
  <GenericErrorPageHeaderWrapper>
    <StyledHeader>{props.message}</StyledHeader>
  </GenericErrorPageHeaderWrapper>
);
