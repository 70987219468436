import { Container } from '@material-ui/core';
import React, { FC, PropsWithChildren, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { CSSObject } from 'styled-components';
import { CommonRootStateType } from '../../../../common/redux/reducers/CommonRootReducer';
import { useModalContext } from '../../../../providers/ModalProvider/ModalProvider';
import FooterImages from './footerImages/FooterImages.component';
import { StyledWrapper } from './PageTemplate.style';

export const AppBarHeight = '5rem';

interface PageTemplateProps extends StateProps {
  backgroundImage?: string;
  backgroundImageMd?: string | null;
  footerText?: string;
  currentStep?: number;
  style?: CSSObject;
  withoutDrawer?: boolean;
  footerLinks?: FooterLink[];
  horizontalBackgroundPosition?: {
    md: string;
  };
  verticalBackgroundPosition?: {
    md?: string;
    lg?: string;
  };
  customImagePaddingBottom?: string;
  disableGutters?: boolean;
  renderImages?: boolean;
}

export interface FooterLink {
  href: string;
  txt: string;
}

type Props = PropsWithChildren<PageTemplateProps>;

const PageTemplate: FC<Props> = (props: Props) => {
  const { isModalVisible, modal } = useModalContext();

  const {
    children,
    footerText,
    withoutDrawer,
    backgroundImageMd,
    backgroundImage,
    horizontalBackgroundPosition,
    verticalBackgroundPosition,
    disableGutters,
    customImagePaddingBottom,
    renderImages = true,
    style
  } = props;

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <StyledWrapper
        $backgroundImageSrc={backgroundImage}
        $backgroundImageMdSrc={backgroundImageMd}
        $horizontalPositionMd={horizontalBackgroundPosition?.md}
        $verticalPositionMd={verticalBackgroundPosition?.md}
        $verticalPositionLg={verticalBackgroundPosition?.lg}
        $customImagePaddingBottom={customImagePaddingBottom}
        style={style}
      >
        <Container maxWidth="xl" disableGutters={disableGutters}>
          {children as ReactElement}
        </Container>
      </StyledWrapper>
      {renderImages && (
        <FooterImages
          withoutDrawer={withoutDrawer}
          footerText={footerText}
          footerLinks={props.footerLinks}
        />
      )}
      {isModalVisible && modal}
    </>
  );
};

const mapStateToProps = (state: CommonRootStateType) => {
  return {
    translate: state.translate.t
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(PageTemplate);
