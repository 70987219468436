import React, { FC } from 'react';
import { StyledPageLoader } from './PageLoader.style';
import { useTenant } from '../../../../../config/tenants/Tenants.provider';
import { useTheme } from 'styled-components';


export const PageLoader: FC = () => {
  const { suspenseLoader } = useTenant();
  const theme = useTheme();
  const Spinner = suspenseLoader.spinner;
  return (
    <StyledPageLoader>
      <Spinner color={theme.palette.brand.color} />
    </StyledPageLoader>
  );
};

export default PageLoader;
