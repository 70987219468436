import { DISCOUNT_ACTIONS, DiscountState, GrantedDiscount, InitialOfferId } from './discount.type';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {} as DiscountState;

export const grantedDiscountReducer = createReducer(initialState, {
  [DISCOUNT_ACTIONS.SET_GRANTED_DISCOUNT]: (state: DiscountState, action: GrantedDiscount): DiscountState => ({
    ...state,
    grantedDiscount: action.payload
  }),

  [DISCOUNT_ACTIONS.SET_INITIAL_OFFER_ID]: (state: DiscountState, action: InitialOfferId): DiscountState => ({
    ...state,
    initialOfferId: action.payload
  })
});
