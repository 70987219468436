import { ErrorObject, ERROR_HANDLER_ACTIONS, ErrorHandlerRedirectAction } from './ErrorHandlerAction.type';

export const errorHandlerRedirect = (errorObject: ErrorObject): ErrorHandlerRedirectAction => {
  return {
    type: ERROR_HANDLER_ACTIONS.REDIRECT_ERROR_HANDLER,
    error: errorObject
  };
};

export const resetErrorHandler = () => {
  return {
    type: ERROR_HANDLER_ACTIONS.RESET_ERROR_HANDLER
  };
};
