export const POLICY_DATE_ACTIONS = {
  SAVE_POLICY_START: 'USER_DATA/SAVE_POLICY_START'
} as const;
  
export interface PolicyStartSaveAction {
    type: typeof POLICY_DATE_ACTIONS.SAVE_POLICY_START;
    data: string;
  }
  
export type PolicyDateActionsType = PolicyStartSaveAction;
