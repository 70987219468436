import React from 'react';
import styled from 'styled-components';

const transitionDuration = '0.3s';

export const OfferDropdownLabelContainer = styled.div<{
  width: React.CSSProperties['width'];
}>`
  align-items: center;
  background-color: ${(p) => p.theme.palette.commons.tonal.base};
  border-radius: 0.75rem;
  box-sizing: border-box;
  color: ${(p) => p.theme.palette.text.paragraph.primary};
  cursor: pointer;
  display: inline-flex;
  font-family: ${(p) => p.theme.typography.fontFamily};
  font-size: ${(p) => p.theme.typography.text1.fontSize};
  font-weight: 600;
  justify-content: space-between;
  transition: ${transitionDuration};
  width: ${({ width }) => (typeof width === 'number' ? `${width}rem` : width)};

  &:hover {
    background-color: ${(p) => p.theme.palette.commons.tonal.hover};
  }
`;

export const OfferDropdownWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const DialogWrapper = styled.div`
  background-color: ${(p) => p.theme.palette.commons.background.container};
  border-radius: 1rem 1rem 0 0;
  padding: 1rem;
  width: 100vw;
`;

export const DialogHeaderContent = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledDialogCloseButton = styled.div`
  background: transparent;
  border: none;
  cursor: pointer;
  margin-bottom: 1.5rem;
  outline: none;
  padding: 0.25rem;
  & > svg {
    fill: ${(p) => p.theme.palette.brand.color};
    height: 1rem;
    width: 1rem;
  }
`;

export const OfferDropdownContent = styled.div<{
  width: React.CSSProperties['width'];
}>`
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  opacity: 1;
  transition: opacity ${transitionDuration} ease-out;
  width: ${({ width }) => (typeof width === 'number' ? `${width}rem` : width)};
  z-index: 20;
`;
