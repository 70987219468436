/* eslint-disable max-lines, camelcase */
export const plMessages = {
  //Contact agreements
  CONSENTS_REQUIRED: 'Ta zgoda jest wymagana',
  ACCEPT_ALL_CONSENTS: 'Akceptuję wszystkie zgody',
  SHOW_CONSENTS: 'Pokaż zgody',

  CONTACT_CONSENT_1:
    'Wyrażam zgodę na otrzymywanie od BeeSafe Spółka z o.o. informacji handlowo-marketingowych z użyciem urządzeń'
    + ' telekomunikacyjnych i tzw. automatycznych systemów wywołujących, wybierając jako formę kontaktu wiadomości'
    + ' elektroniczne (e-mail, SMS/MMS, serwisy internetowe, w tym portale społecznościowe).',
  CONTACT_CONSENT_2:
    'Wyrażam zgodę na otrzymywanie od BeeSafe Spółka z o.o. informacji handlowo-marketingowych z użyciem urządzeń'
    + ' telekomunikacyjnych i tzw. automatycznych systemów wywołujących, wybierając jako formę kontaktu połączenia'
    + ' głosowe (rozmowa telefoniczna, komunikaty głosowe IVR).',
  CONTACT_CONSENT_3:
    'Wyrażam zgodę, aby BeeSafe Spółka z o.o. podejmowała wobec mnie decyzje oparte wyłącznie o zautomatyzowane'
    + ' przetwarzanie, w tym poprzez profilowanie, moich danych osobowych takich jak: imiona, nazwisko, dane'
    + ' kontaktowe, dane o miejscu zamieszkania, data urodzenia, informacje o rodzajach ubezpieczeń, we własnych'
    + ' celach marketingowych oraz w celu przedstawienia odpowiedniej oferty.',
  CONTACT_CONSENT_4:
    'Wyrażam zgodę, aby Compensa Towarzystwo Ubezpieczeń S.A. Vienna Insurance Group podejmowała wobec mnie decyzje'
    + ' oparte wyłącznie o zautomatyzowane przetwarzanie, w tym poprzez profilowanie, moich danych osobowych takich'
    + ' jak: imiona, nazwisko, dane kontaktowe, dane o miejscu zamieszkania, płci, dacie urodzenia, informacje o rodzajach'
    + ' ubezpieczeń oraz historia Umowy ubezpieczenia (w tym informacje o produktach ubezpieczeniowych, z których'
    + ' korzystałem/am w przeszłości), w tym w zakresie w jakim te dane mogą być chronione tajemnicą ubezpieczeniową'
    + ' oraz w tym także dane zebrane w przyszłości, we własnych celach marketingowych oraz w celu przedstawienia'
    + ' odpowiedniej oferty, w trakcie obowiązywania Umowy ubezpieczenia.',
  CONTACT_CONSENT_5:
    'Wyrażam zgodę na otrzymywanie od Compensa Towarzystwo Ubezpieczeń S.A. Vienna Insurance Group oraz Compensa'
    + ' Towarzystwo Ubezpieczeń na Życie S.A. Vienna Insurance Group z siedzibami w Warszawie informacji handlowo-marketingowych'
    + ' z użyciem urządzeń telekomunikacyjnych i tzw. automatycznych systemów wywołujących, wybierając jako formę kontaktu:'
    + ' Wiadomości elektroniczne (e-mail, SMS/MMS, serwisy internetowe, w tym portale społecznościowe).',
  CONTACT_CONSENT_6:
    'Zapoznałem się i akceptuję Regulamin świadczenia usług drogą elektroniczną i załączony do niego Regulamin'
    + ' programu lojalnościowego.',
  CONTACT_CONSENT_6_REQUIRED_TEXT: '* Zgoda wymagana do dokonania zakupu. Dlaczego? Zapewniamy obsługę klienta online.',
  DOCUMENT_LINK_CONSENT_6_TEXT_1: '',
  CONTACT_CONSENT_7: '',

  DETAILS: 'Szczegóły'

};
