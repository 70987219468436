import { Dispatch } from 'redux';
import { AddonModel } from '../../../../../common/components/addon/service/AddonModel';
import { Offer } from '../../../../../common/components/offerCard/model/Offer.model';
import { Tenant } from '../../../../../config/tenants/tenant.type';
import { AppActions } from '../../../../../redux/actions/AppActions.type';
import {
  OfferInfoResetOfferData, OFFER_INFO_ACTIONS, UserIsBackFromSummary
} from './OfferInfoActions.type';

export interface OfferData {
  offer: Offer;
  addons: AddonModel[];
  vases: AddonModel[];
}

export const saveOfferInfo = (offerData: OfferData) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: OFFER_INFO_ACTIONS.SAVE_OFFER_SUCCESS,
      selectedOffersData: [],
      offer: offerData.offer,
      addons: offerData.addons,
      vases: offerData.vases
    });
  };
};

export const addonsUpdated = (updateStatus: boolean) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: OFFER_INFO_ACTIONS.SELECT_ADDONS_UPDATED,
      status: updateStatus
    });
  };
};

export const resetOfferData = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(resetOfferDataAction());
  };
};

export const resetOfferDataAction = (): OfferInfoResetOfferData => ({
  type: OFFER_INFO_ACTIONS.RESET_OFFER_DATA
});

export const setUserIsBackFromSummary = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(setUserIsBackFromSummaryAction());
  };
};

export const setQuotedTenantAction = (quotedTenant: Tenant) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: OFFER_INFO_ACTIONS.SET_QUOTED_TENANT,
      quotedTenant
    });
  };
};

const setUserIsBackFromSummaryAction = (): UserIsBackFromSummary => {
  return {
    type: OFFER_INFO_ACTIONS.BACK_FROM_SUMMARY
  };
};
