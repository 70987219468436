import styled from 'styled-components';

type OfferSelectContentOptionsWrapperType = {
  useModal: boolean;
};

export const OfferSelectContentOptionsWrapper = styled.div<OfferSelectContentOptionsWrapperType>`
  background: ${(p) => p.theme.palette.commons.background.container};
  border-radius: 0.5rem;
  box-shadow: ${(p) => (p.useModal ? 'none' : p.theme.shadow.dropdown)};
  font-family: ${(p) => p.theme.typography.fontFamily};
  padding: ${(p) => (p.useModal ? '0' : '0.5rem')};
`;

export const OfferSelectFooter = styled.div`
  color: ${(p) => p.theme.palette.brand.color};
  cursor: pointer;
  font-family: ${(p) => p.theme.typography.fontFamily};
  font-weight: 600;
  margin: 0.875rem 0;
  text-align: center;
  transition: 0.3s;
  &:hover {
    text-decoration: underline;
  }
`;
