/* eslint-disable no-magic-numbers */
import { DebugInfoObject } from './DebugInfoUtil.type';

const COPY_CLIPBOARD_TIMEOUT = 1100; // 1100 is timeouts lowest possible value which works.

export const handleDisplayAlert = async (debugInfo: DebugInfoObject) => {
  const debugInfoAlertEnabled = String(process.env.REACT_APP_DEBUG_INFO_ALERT_ENABLED ?? window.DEBUG_INFO_ALERT_ENABLED);
  const isDebugInfoAlertEnabled = debugInfoAlertEnabled === 'true';
  if (isCCUserAuthenticated(debugInfo) && isDebugInfoAlertEnabled) {
    await displayAlert(debugInfo);
  }
};

export const isCCUserAuthenticated = (debugInfo: DebugInfoObject) => Boolean(
  debugInfo.requestHeaders['X-Agent-Token']
);

export const displayAlert = async (debugInfo: DebugInfoObject) => {
  if (isRedirectionConfirmed()) {
    // setTimeout is required to allow the confirmation box to fully close and change document focus.
    // Without setTimeout, we get an exception: 'Uncaught (in promise) DOMException: Document is not focused'
    // Tested with multiple lower values but sometimes the above exception is triggered.
    // Tested with async/await, same outcome/exception
    await setTimeout( async () => await copyToClipboard(debugInfo), COPY_CLIPBOARD_TIMEOUT);
  }
};

/**
* The below might've used translation alas redux-i18n does not provide
* functionality to translate strings outside of react components and installing
* additional library just for that seems like an overkill
*/
const confirmMessage = 'Wykryto błąd. Kliknij OK aby skopiować logi pomagające w jego naprawie. Sposób jego wywołania należy opisać w zgłoszeniu IM, wklejając dodatkowo logi za pomocą CTRL+V.';
const isRedirectionConfirmed = () => window.confirm(confirmMessage);

export const copyToClipboard = async (debugInfo: DebugInfoObject) => {
  // template string requires no indentation to pass tests
  const payloadToMarkdown = `
\`\`\`json\n${JSON.stringify(debugInfo, null, 2)}\n\`\`\`
`;

  await navigator.clipboard.writeText(payloadToMarkdown);
};

export const isDebugInfoEmpty = (debugInfo: DebugInfoObject) => Boolean(
  !Object.keys(debugInfo).length
);
