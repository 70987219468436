export const MF_POLICY_SUMMARY_ACTIONS = {
  SET_SHOULD_RECALCULATE_FLAG: 'MF_POLICY_SUMMARY/SET_SHOULD_RECALCULATE_FLAG'
} as const;

export interface MfPolicySummaryState {
  shouldRecalculate: boolean;
}

export interface ShouldRecalculateSetFlagAction {
  type: typeof MF_POLICY_SUMMARY_ACTIONS.SET_SHOULD_RECALCULATE_FLAG;
  payload: boolean;
}

