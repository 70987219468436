import { Offer } from '../../../../common/components/offerCard/model/Offer.model';
import { CreateSubmissionResult, SubmissionAddons } from '../../service/Submission.type';

export const SESSION_ACTIONS = {
  CREATE_SUBMISSION: 'USER_DATA/CREATE_SUBMISSION',
  UPDATE_SUBMISSION: 'USER_DATA/UPDATE_SUBMISSION',
  QUOTE_SUBMISSION: 'USER_DATA/QUOTE_SUBMISSION',
  SELECT_QUOTE: 'USER_DATA/SELECT_QUOTE',
  SUBMISSION_NOT_ACCEPTED: 'USER_DATA/SUBMISSION_NOT_ACCEPTED',
  HIDE_NAVIGATION: 'USER_DATA/HIDE_NAVIGATION',
  SHOW_NAVIGATION: 'USER_DATA/SHOW_NAVIGATION',
  MARK_AS_RENEWAL: 'USER_DATA/MARK_AS_RENEWAL'
} as const;

export interface SessionCreateSubmissionAction {
  type: typeof SESSION_ACTIONS.CREATE_SUBMISSION;
  data: CreateSubmissionResult;
}

export interface SessionUpdateSubmissionAction {
  type: typeof SESSION_ACTIONS.UPDATE_SUBMISSION;
  data: SessionState;
}

export interface SessionQuoteSubmissionAction {
  type: typeof SESSION_ACTIONS.QUOTE_SUBMISSION;
  offers: Offer[];
  packageAddons: SubmissionAddons[];
  vases: SubmissionAddons[];
  maximumDiscountAmount: number;
  discountAmount: number;
}

export interface SessionSelectQuoteSubmissionAction {
  type: typeof SESSION_ACTIONS.SELECT_QUOTE;
}

export interface SessionHideNavigationAction {
  type: typeof SESSION_ACTIONS.HIDE_NAVIGATION;
}

export interface SessionShowNavigationAction {
  type: typeof SESSION_ACTIONS.SHOW_NAVIGATION;
}

export type SessionSubmissionChangeActions =
  | SessionCreateSubmissionAction
  | SessionUpdateSubmissionAction
  | SessionQuoteSubmissionAction
  | SessionSelectQuoteSubmissionAction

export type SessionActionsType =
  | SessionSubmissionChangeActions
  | SessionHideNavigationAction
  | SessionShowNavigationAction
  | MarkAsRenewalActionType;

export interface SessionState {
  quoteUuid?: string;
  sessionUuid?: string;
  quoteId?: string;
  isNavigationHidden?: boolean;
  agentToken?: string; //set only by backend with cc portal retrieve
  returnPath?: string;
  renewalUuid?: string;
  isRenewal?: boolean;
}

export interface MarkAsRenewalActionType {
    type: typeof SESSION_ACTIONS.MARK_AS_RENEWAL;
    data: boolean;
  }
