import { MEDIA_QUERY } from '@sky-distribution/react-commons-ui';
import styled from 'styled-components';

export const HeaderContainer = styled.header`
  align-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.header};
  display: flex;
  min-height: 4.5rem;
  margin: 0 0 1.5rem 0;

  & > h3 {
    font-family: Poppins, sans-serif;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.4;
    margin: 0;
    margin-right: 1rem;
    padding: 0;
    white-space: pre-line;

    ${MEDIA_QUERY.below("md")} {
      display: block;
      margin: 0 2rem 0 0;
    }
  }
`;
