import {
  ConfigurationKey,
  ConsentsConfigurationType
} from '../consentFormTenants.type';
import getDefaultConsents from '../shared/getDefaultConsents';

const tenantConfiguration: ConsentsConfigurationType = {
  [ConfigurationKey]: {
    ToolTipConsentsComponent: () => null,
    defaultConsents: [
      {
        name: 'CONTACT_CONSENT_6',
        rawName: 'consentTerms',
        required: true,
        requiredText: 'CONTACT_CONSENT_6_REQUIRED_TEXT'
      },
      ...getDefaultConsents()
    ]
  }
};

export default tenantConfiguration;
