import React, { FC, useContext, useRef } from 'react';
import I18n, { ITranslations } from 'redux-i18n';
import { LanguageType, TranslationContextProviderProps, TranslationContextType, TranslationsType } from './Translate.type';
import TranslationProvider from './translate/globalTranslate/TranslationProvider';

const TENANT_NAME = require('../config/tenants').TENANT_NAME;
export const commonTranslations: TranslationsType = require(`./common-translations/${TENANT_NAME}/index.translations`).default;

const defaultConfig: TranslationContextType = {
  translations: {} as TranslationsType,
  mergeTranslations: () => undefined
};

const TranslationContext = React.createContext<TranslationContextType>(defaultConfig);
export const useTranslation = () => useContext<TranslationContextType>(TranslationContext);

export const TranslationContextProvider: FC<TranslationContextProviderProps> = ({ children }: TranslationContextProviderProps) => {
  const translations = useRef<TranslationsType>(commonTranslations);

  const mergeTranslations = (oldTranslations: TranslationsType, newTranslations: TranslationsType) => {
    for (const key in newTranslations) {
      const prop = key as LanguageType;
      newTranslations[prop] = Object.assign(oldTranslations[prop], newTranslations[prop]);
    }
    return newTranslations;
  };

  const updateTranslations = (newTranslations: TranslationsType) => {
    translations.current = mergeTranslations(translations.current, newTranslations);
  };

  const contextValue = {
    translations: translations.current,
    mergeTranslations: updateTranslations
  };

  return (
    <TranslationContext.Provider value={contextValue as TranslationContextType}>
      { /* TODO: BEE-8095 */ }
      { /* @ts-ignore */ }
      <I18n translations={translations.current as ITranslations} initialLang="pl" fallbackLang="en">
        <TranslationProvider>
          {children}
        </TranslationProvider>
      </I18n>
    </TranslationContext.Provider>
  );
};
