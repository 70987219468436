import styled from 'styled-components';

export const FooterHeight = '20.425rem';

export const FooterImagesWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const FooterContainer = styled.div<{
  withoutDrawer: boolean | undefined;
  containerMaxWidth?: string;
  isParentContainerCentered?: boolean;
}>`
  ${(props) =>
    props.containerMaxWidth && `max-width: ${props.containerMaxWidth};`}
  ${(props) => props.isParentContainerCentered && 'margin: 0 auto;'}
  ${(props) =>
    !props.withoutDrawer
      ? 'padding-bottom: 10rem;'
      : 'padding: 1rem 0;'
};
`;

export const CallBackIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5rem;
`;

export const FooterLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  padding: 1rem;
  row-gap: 1.5rem;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-left: 0rem;
    padding-right: 0rem;
  }
`;

export const FooterLogoInfo = styled.p`
  font-size: 0.5rem;
  max-width: 6rem;
  color: ${({ theme }) => theme.palette.input.text.base};
  text-align: center;
  margin: 0;
`;

export const FooterLogoImage = styled.img`
  height: 1.5625rem;
  width: auto;
  display: inline-block;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-width: 100%;
  }
`;
