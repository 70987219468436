import { createReducer } from '@reduxjs/toolkit';
import { UserInfoRetrievedDataAction, USER_INFO_ACTIONS } from '../../../redux/userInfo/UserInfoActions.type';
import { AggregatorState } from '../service/Aggregator.type';
import { AGGREGATOR_ACTIONS } from './Aggregator.type';

const initialState: AggregatorState = {};

export const aggregatorReducer = createReducer(initialState, {
  [USER_INFO_ACTIONS.RETRIEVE_USER_INFO]: (state: AggregatorState, action: UserInfoRetrievedDataAction): AggregatorState => ({
    ...state,
    ...action.data.aggregator
  }),
  [AGGREGATOR_ACTIONS.CLEAR_DATA]: (): AggregatorState => ({
    ...initialState
  })
});
