import React from 'react';
import { CallBackWindowHeaderProps } from '../../../../../../components/CallBackWindow/CallBackWindowHeader/CallBackWindowHeader.types';
import { HeaderContainer, Logo } from './Header.style';
export const Header = ({
  children,
}: CallBackWindowHeaderProps): JSX.Element => {
  return (
    <HeaderContainer>
      <Logo />
      {children}
    </HeaderContainer>
  );
};

export default Header;
