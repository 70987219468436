// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing,camelcase
declare let __webpack_public_path__: string | undefined;
import { ThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import { createRoot } from 'react-dom/client';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Router } from 'react-router-dom';
import packageJson from '../package.json';
import App from './client';
import { prepareBasePath } from './config/tenants/common';
import { muiTheme, Themed } from './config/tenants/Theme.provider';
import { history } from './routing/History';
import { getDeployEnvName } from './utils/getDeployEnvName';

// eslint-disable-next-line camelcase, prefer-const,@typescript-eslint/no-unused-vars
__webpack_public_path__ = `/${prepareBasePath('kalkulator-oc-ac')}/`;

Sentry.init({
  dsn: 'https://d4c27e5b21ed454c9446810b81f915b4@o479791.ingest.sentry.io/5525346',
  release: `frontend-onboarding@${packageJson.version}`,
  environment: getDeployEnvName(),
  attachStacktrace: true,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
    })
  ],
  normalizeDepth: 10,
  tracesSampleRate: 0.1
});

const Main = () => {
  return (
    <ThemeProvider theme={muiTheme}>
      <Themed>
        <Router history={history}>
          <App />
        </Router>
      </Themed>
    </ThemeProvider>
  );
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<Main />);
