import { MEDIA_QUERY } from '@sky-distribution/react-commons-ui';
import styled from 'styled-components';
import { ReactComponent as LogoIcon } from '../../../../../../assets/beesafe_logo_logotype.svg';

export const HeaderContainer = styled.div`
  align-items: center;
  box-sizing: border-box;
  justify-content: flex-end;
  display: flex;
  padding: 2rem 2rem 0 2rem;
  position: relative;
  width: 100%;

  ${MEDIA_QUERY.below('sm')} {
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.palette.commons.background.container};
    border: solid 0.0625rem ${({ theme }) => theme.palette.commons.divider};
    box-sizing: border-box;
    display: flex;
    height: 5rem;
    padding: 1.75rem 1.25rem;
    position: relative;
    width: 100%;
  }

  & > svg,
  a {
    cursor: pointer;
    height: 1rem;
  }
`;

export const Logo = styled(LogoIcon)`
  display: none;

  ${MEDIA_QUERY.below('sm')} {
    display: block;
    padding: 0;
    width: 6rem;
  }
`;
