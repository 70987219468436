import {
  QuoteState,
  QUOTE_ACTIONS,
  StartQuoteAction
} from './Quote.type';
import { createReducer } from '@reduxjs/toolkit';

const initialState: QuoteState = {
  isRecalculation: false,
  isLoadingModalVisible: false,
  canFinishQuotation: false
};

export const quoteReducer = createReducer(initialState, {
  [QUOTE_ACTIONS.RESET_QUOTATION]: (): QuoteState => ({
    ...initialState
  }),
  [QUOTE_ACTIONS.START_QUOTATION]: (state: QuoteState, action: StartQuoteAction): QuoteState => ({
    ...state,
    isRecalculation: action.payload.isRecalculation,
    canFinishQuotation: false,
    isLoadingModalVisible: true
  }),
  [QUOTE_ACTIONS.CLOSE_LOADING_MODAL]: (state: QuoteState): QuoteState => ({
    ...state,
    isLoadingModalVisible: false
  }),
  [QUOTE_ACTIONS.FINISH_QUOTATION]: (state: QuoteState): QuoteState => ({
    ...state,
    canFinishQuotation: true
  })
});
