import React, { ReactNode } from 'react';
import { TextContainer } from './Description.style';

export interface DescriptionProps {
  children: ReactNode;
  size?: number;
}

export function Description({ children, size }: DescriptionProps): JSX.Element {
  return <TextContainer size={size}>{children}</TextContainer>;
}
