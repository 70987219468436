import { FC } from 'react';
import type { ConsentsTypes } from '../Consent';
import { TranslationsType as BeesafeTranslationsType, LanguageDictionaryType as BeesafeLanguageDictionaryType } from './beesafe/translations/beesafe.type';
import { TranslationsType as BenefiaTranslationsType, LanguageDictionaryType as BenefiaLanguageDictionaryType } from './benefia/translations/benefia.type';
import { TranslationsType as CompensaTranslationsType, LanguageDictionaryType as CompensaLanguageDictionaryType } from './compensa/translations/compensa.type';

export const ConfigurationKey = 'ConsentsConfiguration';

export type ConsentsConfigurationType = {
  [ConfigurationKey]: {
    ToolTipConsentsComponent: FC;
    defaultConsents: ConsentsTypes[];
  };
};

export type ConsentFormTranslationsType = BeesafeTranslationsType | BenefiaTranslationsType | CompensaTranslationsType;
export type ConsentFormLanguageDictionaryType = BeesafeLanguageDictionaryType | BenefiaLanguageDictionaryType | CompensaLanguageDictionaryType;
