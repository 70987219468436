/* eslint-disable complexity */
import { Offer } from '../../../../../common/components/offerCard/model/Offer.model';
import { AppActions } from '../../../../../redux/actions/AppActions.type';
import { SESSION_ACTIONS } from '../../../redux/session/Session.type';
import { USER_INFO_ACTIONS } from '../../../redux/userInfo/UserInfoActions.type';
import { OfferInfoState, OfferSelectedItems } from './OfferInfo.type';
import {
  OfferInfoSavedAction, OFFER_INFO_ACTIONS
} from './OfferInfoActions.type';

const initialState = {
  selectedOffer: {}
} as OfferInfoState;

const getUpdatedSelectedOffersData = ({
  offer: { id },
  addons,
  vases
}: OfferInfoSavedAction, oldData?: OfferSelectedItems[]): OfferSelectedItems[] => {
  const negativeIndex = -1;
  const selectedOffersData: OfferSelectedItems[] = oldData ?? [];
  if (id) {
    const index = selectedOffersData.findIndex((selectedOfferData) => selectedOfferData.offerId === id);
    const newSelectedOffersData: OfferSelectedItems = {
      offerId: id,
      addons: addons,
      vases: vases
    };
    index > negativeIndex ?
      selectedOffersData[index] = newSelectedOffersData :
      selectedOffersData.push(newSelectedOffersData);
  }
  return selectedOffersData;
};

export const offerInfoReducer = (
  state = initialState,
  action: AppActions
): OfferInfoState => {
  switch (action.type) {
    case OFFER_INFO_ACTIONS.SAVE_OFFER_SUCCESS: {
      return {
        ...state,
        selectedOffersData: getUpdatedSelectedOffersData(action, state.selectedOffersData),
        selectedOffer: action.offer,
        selectedAddons: action.addons,
        selectedVases: action.vases
      };
    }
    case SESSION_ACTIONS.QUOTE_SUBMISSION:
      return {
        ...state,
        offerData: action.offers,
        packageAddons: action.packageAddons,
        vases: action.vases,
        maximumDiscountAmount: action.maximumDiscountAmount,
        discountAmount: action.discountAmount
      };
    case OFFER_INFO_ACTIONS.SELECT_ADDONS_UPDATED:
      return {
        ...state,
        selectAddonsUpdate: action.status
      };
    case USER_INFO_ACTIONS.RETRIEVE_USER_INFO:
      return { ...state, ...action.data.offerInfo };
    case OFFER_INFO_ACTIONS.RESET_OFFER_DATA:
      return {
        ...state,
        offerData: [],
        packageAddons: [],
        selectedOffer: {} as Offer,
        selectedOffersData: [],
        selectedAddons: [],
        vases: [],
        selectedVases: [],
        isBackFromSummary: false
      };
    case OFFER_INFO_ACTIONS.BACK_FROM_SUMMARY:
      return {
        ...state,
        isBackFromSummary: true
      };
    case OFFER_INFO_ACTIONS.SET_QUOTED_TENANT: {
      return {
        ...state,
        quotedTenant: action.quotedTenant
      };
    }
    default:
      return state;
  }
};
