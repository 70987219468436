import React, { FC } from 'react';
import {
  StyledCaption,
  StyledRecommendedText,
  StyledTitle,
  StyledWrapper,
} from './ToggleSelectHorizontal.style';
import { OptionComponentType } from './ToggleSelectHorizontal.type';

export const ToggleSelectHorizontalOption: FC<OptionComponentType> = ({
  title,
  caption,
  value,
  recommendedText,
  duration,
  selectedValue,
  selectedLabel,
  haveValue,
}: OptionComponentType) => {
  const isSelected = selectedValue === value && !!haveValue;
  return (
    <StyledWrapper>
      {recommendedText && (
        <StyledRecommendedText isSelected={isSelected} duration={duration}>
          {recommendedText}
        </StyledRecommendedText>
      )}
      <div>
        <StyledTitle>{title}</StyledTitle>
        {(caption || selectedLabel) && (
          <StyledCaption isSelected={isSelected}>
            {isSelected && selectedLabel ? selectedLabel : caption}
          </StyledCaption>
        )}
      </div>
    </StyledWrapper>
  );
};

export default ToggleSelectHorizontalOption;
