"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.enMessages = void 0;
exports.enMessages = {
    FOOTER_POPULAR_QUESTIONS: 'Popular questions about buy OC AC insurance Benefia',
    FOOTER_QUESTION_0_HEAD: '',
    FOOTER_QUESTION_1_HEAD: '',
    FOOTER_QUESTION_2_HEAD: '',
    FOOTER_QUESTION_3_HEAD: '',
    FOOTER_QUESTION_4_HEAD: '',
    FOOTER_QUESTION_5_HEAD: '',
    FOOTER_QUESTION_6_HEAD: '',
    FOOTER_QUESTION_7_HEAD: '',
    FOOTER_QUESTION_8_HEAD: '',
    FOOTER_QUESTION_9_HEAD: '',
    FOOTER_QUESTION_10_HEAD: '',
    FOOTER_QUESTION_11_HEAD: '',
    FOOTER_QUESTION_12_HEAD: '',
    FOOTER_QUESTION_13_HEAD: '',
    FOOTER_QUESTION_0: '',
    FOOTER_QUESTION_1: '',
    FOOTER_QUESTION_2: '',
    FOOTER_QUESTION_3: '',
    FOOTER_QUESTION_4: '',
    FOOTER_QUESTION_5: '',
    FOOTER_QUESTION_6: '',
    FOOTER_QUESTION_7: '',
    FOOTER_QUESTION_8: '',
    FOOTER_QUESTION_9: '',
    FOOTER_QUESTION_10: '',
    FOOTER_QUESTION_11: '',
    FOOTER_QUESTION_12: '',
};
