import styled from 'styled-components';
import hex from '../../../../../common/assets/img/pageLoader/hex.svg';

export const StyledBeesafeSuspenseSpinner = styled.div`
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 170px;
    height: 170px;
  
    .hexagons {
        position: relative;
        border-radius: 100%;
        padding: 5%;
        top: 30px;
        left: 35px;
        
        .hexagon {
            position: absolute;
            width:40px;
            height:46px;
            background: url(${hex}) no-repeat;
            transform-origin: center;
            
            :nth-child(1) {
                animation: animateHex 3s infinite;
            }
            
            :nth-child(2) {
                left: 53px;
                animation: animateHex 3s 0.2s infinite;
            }
            
            :nth-child(3) {
                left: -13px;
                top: 46px;
                animation: animateHex 3s 1s infinite;
            }
            
            :nth-child(4) {
                left: 31px;
                top: 46px;
                animation: animateHex 3s 1.2s infinite;
            }
            
            :nth-child(5) {
                left: 75px;
                top: 46px;
                animation: animateHex 3s 0.4s infinite;
            }
            
            :nth-child(6) {
                top: 84px;
                animation: animateHex 3s 0.8s infinite;
            }
            
            :nth-child(7) {
                left: 53px;
                top: 84px;
                animation: animateHex 3s 0.6s infinite;
            }
        }
    }
    
    @keyframes rotateSmall {
        100% {
            transform: rotate(1turn);
        }
    }
    
    @keyframes rotateLarge {
        0% {
            clip-path: inset(0px 30px 30px 0px);
            -webkit-clip-path: inset(0px 30px 30px 0px);
        }
        50% {
            clip-path: inset(0px 150px 150px 0px);
            -webkit-clip-path: inset(0px 150px 150px 0px);
        }
        100% {
            transform: rotate(1turn);
            clip-path: inset(0px 30px 30px 0px);
            -webkit-clip-path: inset(0px 30px 30px 0px);
        }
    }
    
    @keyframes animateHex {
        0% {
            transform: scale(1.02);
        }
        20%,
        50% {
            transform: scale(0.6);
            opacity: 0;
        }
        65% {
            transform: scale(1.02);
            opacity: 1;
        }
    }

`;
