import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { triggerDataLayerPageChange } from '../modules/userdata/utils/Gtm.events';

export default function withTagManager<OuterProps extends object>(
  Component: React.ComponentType<OuterProps>,
  viewIdentifier: string
): React.ComponentClass<OuterProps> {
  const componentDisplayName =
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    Component.displayName ||
    Component.name ||
    'Component';

  class C extends React.Component<OuterProps> {
    static displayName = `WithTagManager(${componentDisplayName})`;

    componentDidMount() {
      triggerDataLayerPageChange({
        templateName: viewIdentifier
      });
    }

    render = () => {
      return <Component {...this.props} />;
    };

  }

  return hoistNonReactStatics(
    C,
    Component as React.ComponentClass<OuterProps> // cast type to ComponentClass (even if FC)
  );
}
