import * as PropTypes from 'prop-types';
import React, { Component, createContext, useContext, PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';
import { initTranslateMethod } from './redux/Translate.actions';
import { TranslationContextType } from './TranslationContext.type';

type Props = PropsWithChildren<DispatchProps>;

export interface TranslateFn<T = string> {
  (textKey: T, params?: string[] | { [key: string]: string }, comment?: string): string;
}

export const TranslationContext = createContext<TranslationContextType>({} as TranslationContextType);

export function useTranslate(): TranslateFn {
  const { t: translate } = useContext(TranslationContext);
  return translate;
}

export class TranslationProvider extends Component<Props> {
  static contextTypes = {
    t: PropTypes.func.isRequired
  };
  context!: TranslationContextType;

  componentDidMount(): void {
    this.setActualLanguage();
  }

  componentDidUpdate(): void {
    this.setActualLanguage();
  }

  setActualLanguage = (): void => {
    this.props.setTranslateMethod(this.context.t);
  };

  render(): JSX.Element {
    return <TranslationContext.Provider value={this.context}>{this.props.children}</TranslationContext.Provider>;
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
  setTranslateMethod: bindActionCreators(initTranslateMethod, dispatch)
});

interface DispatchProps {
  setTranslateMethod: (translate: TranslateFn) => void;
}

export default connect(null, mapDispatchToProps)(TranslationProvider);
