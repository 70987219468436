import React, { lazy } from 'react';
import { Tenant, TenantsEnum, ThankYouProps } from './Tenants.types';

// Beesafe components
const BeesafeBase = lazy(
  () => import('./beesafe/components/Base/Base.component'),
);
const BeesafeThankYou = lazy(
  () => import('./beesafe/components/ThankYou/ThankYou.component'),
);
const BeesafeFailed = lazy(
  () => import('./beesafe/components/Failed/Failed.component'),
);
const BeesafeHeader = lazy(
  () => import('./beesafe/components/Header/Header.component'),
);

// Benefia components
const BenefiaBase = lazy(
  () => import('./benefia/components/Base/Base.component'),
);
const BenefiaThankYou = lazy(
  () => import('./benefia/components/ThankYou/ThankYou.component'),
);
const BenefiaFailed = lazy(
  () => import('./benefia/components/Failed/Failed.component'),
);
const BenefiaHeader = lazy(
  () => import('./benefia/components/Header/Header.component'),
);

// Compensa components
const CompensaBase = lazy(
  () => import('./compensa/components/Base/Base.component'),
);
const CompensaThankYou = lazy(
  () => import('./compensa/components/ThankYou/ThankYou.component'),
);
const CompensaFailed = lazy(
  () => import('./compensa/components/Failed/Failed.component'),
);
const CompensaHeader = lazy(
  () => import('./compensa/components/Header/Header.component'),
);

import { CallBackWindowHeaderProps } from '../../../components/CallBackWindow/CallBackWindowHeader/CallBackWindowHeader.types';

export const getComponents = (
  tenantId: Tenant,
): {
  Base: React.FC;
  Failed: React.FC;
  ThankYou: React.FC<ThankYouProps>;
  Header: React.FC<CallBackWindowHeaderProps>;
} => {
  switch (tenantId) {
    case TenantsEnum.Beesafe:
      return {
        Base: BeesafeBase,
        Failed: BeesafeFailed,
        ThankYou: BeesafeThankYou,
        Header: BeesafeHeader,
      };
    case TenantsEnum.Compensa:
      return {
        Base: CompensaBase,
        Failed: CompensaFailed,
        ThankYou: CompensaThankYou,
        Header: CompensaHeader,
      };
    case TenantsEnum.Benefia:
      return {
        Base: BenefiaBase,
        Failed: BenefiaFailed,
        ThankYou: BenefiaThankYou,
        Header: BenefiaHeader,
      };
    default:
      return {
        Base: BeesafeBase,
        Failed: BeesafeFailed,
        ThankYou: BeesafeThankYou,
        Header: BeesafeHeader,
      };
  }
};
