import { AddonModel } from '../../../../../common/components/addon/service/AddonModel';
import { Offer } from '../../../../../common/components/offerCard/model/Offer.model';
import { Tenant } from '../../../../../config/tenants/tenant.type';

export interface OfferInfoSavedAction {
  type: typeof OFFER_INFO_ACTIONS.SAVE_OFFER_SUCCESS;
  offer: Offer;
  addons: AddonModel[];
  vases: AddonModel[];
}

export interface QuotedTenantAction {
  type: typeof OFFER_INFO_ACTIONS.SET_QUOTED_TENANT;
  quotedTenant: Tenant;
}

export interface OfferInfoResetOfferData {
  type: typeof OFFER_INFO_ACTIONS.RESET_OFFER_DATA;
}

export interface UserIsBackFromSummary {
  type: typeof OFFER_INFO_ACTIONS.BACK_FROM_SUMMARY;
}

export interface SelectAddonsUpdated {
  type: typeof OFFER_INFO_ACTIONS.SELECT_ADDONS_UPDATED;
  status: boolean;
}

export interface SetQuotedTenant {
  type: typeof OFFER_INFO_ACTIONS.SET_QUOTED_TENANT;
  quotedTenant: Tenant;
}

export const OFFER_INFO_ACTIONS = {
  SELECT_ADDONS_UPDATED: 'USER_DATA/SELECT_ADDONS_UPDATED',
  SAVE_OFFER_SUCCESS: 'USER_DATA/SAVE_OFFER_SUCCESS',
  RESET_OFFER_DATA: 'USER_DATA/RESET_OFFER_DATA',
  BACK_FROM_SUMMARY: 'USER_DATA/BACK_FROM_SUMMARY',
  SET_QUOTED_TENANT: 'USER_DATA/SET_QUOTED_TENANT'
} as const;

export type OfferInfoActionsType =
  | OfferInfoSavedAction
  | OfferInfoResetOfferData
  | SelectAddonsUpdated
  | SetQuotedTenant
  | UserIsBackFromSummary;
