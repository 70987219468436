import React, { FC } from 'react';
import { PARTNER_ICONS } from '../../../../global';
import { ContentPartners } from './Partners.styled';

export const Partners: FC = () => {
  return (
    <ContentPartners>
      {PARTNER_ICONS.map((icon, idx) => (
        <div className="iconContainer" key={`icon-${idx}`}>
          <img alt="" className="icon" src={icon} />
        </div>
      ))}
    </ContentPartners>
  );
};
