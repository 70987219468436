import { Action, Dispatch } from 'redux';
import { IGetTranslateFunctionResponse } from 'redux-i18n';
import {
  TranslateMethodInitAction,
  TRANSLATE_ACTIONS
} from './TranslateAction.type';

export const initTranslateMethod = (translateMethod: IGetTranslateFunctionResponse) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch(translateMethodInitAction(translateMethod));
  };
};

export const translateMethodInitAction = (
  translateMethod: IGetTranslateFunctionResponse
): TranslateMethodInitAction => {
  return {
    type: TRANSLATE_ACTIONS.METHOD_INIT,
    t: translateMethod
  };
};
