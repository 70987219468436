import styled from 'styled-components';

export const ModalContainer = styled.div`
  font-size: 0.875rem;
  line-height: 1.64;
  margin-bottom: 1rem;
`;

export const QuestionContainer = styled.div`
  && {
    border-top: 0.0625rem solid
      ${(props) => props.theme.palette.commons.divider};
    margin-top: 1rem;
    padding-top: 1rem;
  }
`;

export const StyledButtonWrapper = styled.div`
  background: ${(props) => props.theme.palette.commons.background.container};
  bottom: 0;
  position: sticky;
`;
