import { AxiosRequestConfig } from 'axios';
import { Tenant } from '../../../config/tenants/tenant.type';
import { AGENT_TOKEN_HEADER_NAME, CSOU_HOST_HEADER, QUOTED_TENANT_HEADER_NAME } from '../RequestService';
import { getCSOUHostHeader } from './getCSOUHostHeader.util';

interface GetRequestHeaders {
  agentToken?: string;
  quotedTenant?: Tenant;
} 

export const getRequestHeaders = ({
  quotedTenant,
  agentToken
}: GetRequestHeaders): AxiosRequestConfig['headers'] => {
  const headers: AxiosRequestConfig['headers'] = {};

  if (agentToken) {
    headers[AGENT_TOKEN_HEADER_NAME] = String(agentToken);
  }

  if (quotedTenant) {
    headers[QUOTED_TENANT_HEADER_NAME] = quotedTenant;
  }

  headers[CSOU_HOST_HEADER] = getCSOUHostHeader(quotedTenant);

  return headers;
};
