import React from 'react';
import { HeaderContainer } from './HeaderWithIcon.style';
interface HeaderWithIconProps {
  children?: React.ReactNode;
}

export const HeaderWithIcon: React.FC<HeaderWithIconProps> = ({
  children,
}: HeaderWithIconProps) => {
  return <HeaderContainer>{children}</HeaderContainer>;
};
