import { Button } from '@sky-distribution/react-commons-ui';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { resetErrorHandler } from '../../../../common/components/errorHandler/redux/ErrorHandler.actions';
import { useTenant } from '../../../../config/tenants/Tenants.provider';
import { AppState } from '../../../../redux/store/AppStore';
import withTagManager from '../../../../routing/withTagManager.hoc';
import { useTranslate } from '../../../../translations/translate/globalTranslate/TranslationProvider';
import Translate from '../../../../translations/translate/Translate.component';
import PageTemplate from '../../components/pageTemplate/PageTemplate.component';
import { OrTextLabel } from './components/OrTextLabel/OrTextLabel.component';
import DebugInfo from './DebugInfo/DebugInfo.component';
import {
  ButtonContainer, ContentWrapper, GenericErrorPageContent, ImageWrapper, OrTextLabelWrapper
} from './GenericErrorPage.styled';
import { GenericErrorPageHeader } from './header/GenericErrorPageHeader.component';
import { GenericErrorPageConfigurationType } from './tenants/tenant.type';

const VIEW_IDENTIFIER = 'Error';

type Props = StateProps &
  DispatchProps & {
    handleBackButton?: () => void;
  };

export const GenericErrorPage: React.FC<Props> = (props: Props) => {
  const t = useTranslate();
  const {
    GenericErrorPageConfiguration
  } = useTenant<GenericErrorPageConfigurationType>();

  const handleBackButton = () => {
    if (props.handleBackButton) {
      props.handleBackButton();
      return;
    }

    window.history.back();
  };

  const handleContactButton = () => {
    window.location.href = GenericErrorPageConfiguration.contactUrl;
  };

  useEffect(() => {
    if (props?.error?.code) {
      props.clearError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <GenericErrorPageContent $wrapperJustifyContent={GenericErrorPageConfiguration.styles.GenericErrorPageContent.justifyContent}>
        <ContentWrapper>
          <GenericErrorPageHeader message={t('SOMETHING_WENT_WRONG')} />
          <ButtonContainer>
            <Button
              type='button'
              onClick={handleBackButton}
              className='tempClassBack'
              typeStyle='primary'
              blockMode
            >
              <Translate keyName='BACK_TO_PREVIOUS_PAGE' />
            </Button>
            <OrTextLabelWrapper>
              <OrTextLabel />
            </OrTextLabelWrapper>
            <Button
              type='button'
              onClick={handleContactButton}
              className='tempClassNext'
              typeStyle='outlined'
              blockMode
            >
              <Translate keyName='CONTACT_US' />
            </Button>
            <DebugInfo />
          </ButtonContainer>
        </ContentWrapper>
        <ImageWrapper url={GenericErrorPageConfiguration.errorImage} />
      </GenericErrorPageContent>
      <PageTemplate withoutDrawer style={{
        minHeight: '0'
      }}/>
    </>
  );
};

const mapDispatchToProps = (dispatch: Function) => ({
  clearError: () => dispatch(resetErrorHandler())
});

const mapStateToProps = (state: AppState) => ({
  error: state.errorHandler
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTagManager(GenericErrorPage, VIEW_IDENTIFIER));
