export const DISCOUNT_ACTIONS = {
  SET_GRANTED_DISCOUNT: 'SET_GRANTED_DISCOUNT',
  SET_INITIAL_OFFER_ID: 'SET_INITIAL_OFFER_ID'
};

export interface GrantedDiscount {
  type: typeof DISCOUNT_ACTIONS.SET_GRANTED_DISCOUNT;
  payload: number;
}

export interface InitialOfferId {
  type: typeof DISCOUNT_ACTIONS.SET_INITIAL_OFFER_ID;
  payload: string;
}

export interface DiscountState {
  grantedDiscount: number;
  initialOfferId: string;
}
