
export const PAYMENT_INFO_ACTIONS = {
  SET_FINAL_PRICE: 'PAYMENT/SET_FINAL_PRICE',
  RESET_FINAL_PRICE: 'PAYMENT/RESET_FINAL_PRICE'
} as const;

export interface PaymentSetFinalPriceAction {
  type: typeof PAYMENT_INFO_ACTIONS.SET_FINAL_PRICE;
  data: number;
}

export interface PaymentClearedFinalPriceAction {
  type: typeof PAYMENT_INFO_ACTIONS.RESET_FINAL_PRICE;
}
export type PaymentAction = PaymentSetFinalPriceAction | PaymentClearedFinalPriceAction;
