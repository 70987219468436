
import { beesafeTheme } from '@sky-distribution/react-commons-ui';
import breakpoints from '../../common/breakpoints';

export const theme = {
  ...beesafeTheme,
  breakpoints: breakpoints,
};

export default theme;
