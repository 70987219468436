import styled from 'styled-components';
import { StartPageInputs } from '../../../../../modules/userdata/containers/BasicInfo/tenants/tenant.type';

type DatePickerContainerProps = {
    $styles?: StartPageInputs;
};

export const DatePickerContainer = styled.div<DatePickerContainerProps>`
    height: ${({ $styles }) => $styles?.height ?? '3.5rem'};
`;
