import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components';
import { Button, ButtonProps } from '@material-ui/core';
import { useTenant } from '../../../../../../config/tenants/Tenants.provider';
import { BasicInfoConfigurationType, ManualButtonStyles } from '../../tenants/tenant.type';

interface ManualWayButtonProps {
    $isNotFound?: boolean;
    $styles?: ManualButtonStyles;
}

const getNotFoundStyle = (props: ThemedStyledProps<ManualWayButtonProps, DefaultTheme>) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { BasicInfoConfiguration } = useTenant<BasicInfoConfigurationType>();
  const { manualButton } = BasicInfoConfiguration ?? {};
  return props.$isNotFound ? `
            color: ${manualButton?.color};
            text-decoration: none;
            border-radius: ${manualButton?.radius ?? '0.5rem'};
            border: solid 1px ${manualButton?.borderColor ?? props.theme.palette.button.border.outline.base};
            height: 3.75rem;
            margin-top: 1rem;
            width: ${manualButton?.width ?? '100%'};
        ` : 'width: 100%; ';
};

export const ManualWayButton = styled(Button) <ButtonProps & ManualWayButtonProps>`
    && {
        color: ${({ theme, $styles }) => $styles?.color ?? theme.palette.button.text.outline.base};
        text-transform: none;
        font-size: ${({ $styles }) => $styles?.fontSize ?? '1.125rem'};
        line-height: 1rem;
        padding: 1rem;
        ${getNotFoundStyle}
        
        :hover {
            text-decoration: underline;
            background-color: transparent;
        }
    }
`;
