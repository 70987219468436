import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { AnimatedLabel } from '../../../AnimatedLabel/AnimatedLabel';
import { Picker } from '../../BeeDatePicker.const';
import { usePickerContext } from '../../contexts';
import { Placeholder } from './DateForm.const';
import {
  DateFormWrapper,
  StyledFakeInput,
  StyledFakeInputWrapper,
} from './DateForm.styled';
import { getDatePicker } from './DateForm.utils';
import { DayInput, MonthInput, YearInput } from './components';
export const DateForm: React.FC = () => {
  const [hovered, setHovered] = useState<boolean>(false);
  const handleMouseEnter = (): void => setHovered(true);
  const handleMouseLeave = (): void => setHovered(false);
  const {
    state: {
      date,
      isDirty,
      isFocused,
      inputLabel,
      errorMsg,
      error,
      readOnly,
      isFormFilled,
    },
    dispatch,
  } = usePickerContext();
  const isError = !!errorMsg || !!error;
  const isVisible = isFocused || isDirty || isError || isFormFilled;

  const setVisiblePicker = React.useCallback(
    (event) => {
      if (readOnly) {
        return;
      }
      const { id, name } = event.target;

      dispatch({
        type: 'SET_VISIBLE_PICKER',
        payload: getDatePicker(date, id || name),
      });
    },
    [date, readOnly, dispatch],
  );

  return (
    <DateFormWrapper
      readOnly={readOnly}
      isFocused={isFocused}
      isValid={!errorMsg && (isDirty || isFormFilled)}
      isError={isError}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <AnimatedLabel
        focused={isVisible && !isError}
        visible={isVisible}
        label={inputLabel}
        error={errorMsg ? errorMsg : error ? error : undefined}
        readOnly={readOnly}
        isHovered={hovered}
      />
      {isMobile ? (
        <StyledFakeInputWrapper>
          <StyledFakeInput
            id={Picker.DAY}
            onClick={setVisiblePicker}
            tabIndex={0}
          >
            {!isVisible ? null : date.day || Placeholder.DAY}
          </StyledFakeInput>
          {isVisible && '/'}
          <StyledFakeInput
            id={Picker.MONTH}
            onClick={setVisiblePicker}
            tabIndex={0}
          >
            {!isVisible ? null : date.month || Placeholder.MONTH}
          </StyledFakeInput>
          {isVisible && '/'}
          <StyledFakeInput
            id={Picker.YEAR}
            onClick={setVisiblePicker}
            tabIndex={0}
          >
            {!isVisible ? null : date.year || Placeholder.YEAR}
          </StyledFakeInput>
        </StyledFakeInputWrapper>
      ) : (
        <>
          <DayInput setVisiblePicker={setVisiblePicker} isVisible={isVisible} />
          {isVisible && '/'}
          <MonthInput
            setVisiblePicker={setVisiblePicker}
            isVisible={isVisible}
          />
          {isVisible && '/'}
          <YearInput
            setVisiblePicker={setVisiblePicker}
            isVisible={isVisible}
          />
        </>
      )}
    </DateFormWrapper>
  );
};
