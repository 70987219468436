export const COLORS = {
  primaryGreen: '#88CA11',
  secondary: '#007C33',
  lightestGrey: '#f8f8f8',

  shuttleGray: '#5E6870',
  lightGrey: '#acbcc8',
  geyser: '#D6DEE5',
  white: '#ffffff',
  backgroundWhite: '#fbfbfb',
  black: '#000000',
  mainTextBlack: '#1B1B19',
  redish: '#FF4C2F',

  primaryDark: '#000000',
  blackOpaque: '#0000004D',
  backGroundSecondary: '#373F41',
  cloud: '#88CA1112',
  almostBlack: '#1B1B19',
  greyMid: '#B0B8BC',
  darkGrey: '#5E6870',
  additionalTextGray: '#3a3a3a',
  darkerGrey: '#5e6870',
  lighterGrey: '#D6DEE5',
  greyPlaceholder: '#5E6870',

  actionHover: '#88CA1112',
  actionFocused: '#88CA1112',
  actionActive: '#88CA11',
  secondaryHover: '#C3C108',
  imageCover: 'rgba(0, 0, 0, 0.40)',
  iconBackground: '#007C33',
  iconBackgroundHover: '#E8E8E899',
  toggleActive: '#E31E240A',

  transparent: 'rgba(0,0,0,0)',
  transparentWhite: 'rgba(255, 255, 255, 0)',
  transparentWhite78: 'rgba(255, 255, 255, 0.78)',
};
