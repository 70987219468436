import Grid from '@material-ui/core/Grid';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslate } from '../../../translations/translate/globalTranslate/TranslationProvider';
import {
  WaitingWindowLoaderDesktopContainer,
  WaitingWindowLoaderMobileContainer,
  WaitingWindowLoaderProgressContainer,
  WaitingWindowLoaderContainer,
  WaitingWindowLoaderHeader
} from './WaitingWindowLoader.style';
import { WaitingWindowLoaderContent } from './progressContent/WaitingWindowLoaderContent.component';
import { useTenant } from '../../../config/tenants/Tenants.provider';
import { WaitingWindowLoaderConfigurationType } from './tenants/tenant.type';

interface WaitingWindowLoaderType {
  canClose: boolean;
  headerText: string;
  stepsText: string[];
  setVisible: (visible: boolean) => void;
  setLoadingSpeed?: number;
}

export const WaitingWindowLoader: FC<WaitingWindowLoaderType> = (props: WaitingWindowLoaderType) => {
  const HIDE_WINDOW_TIMEOUT = 1000;
  const FIRST_STEP = 0;
  const SECOND_STEP = 1;
  const THIRD_STEP = 2;
  const FOURTH_STEP = 3;
  const translate = useTranslate();
  const [hideWindow, setHideWindow] = useState(false);
  const [stepSecondVisible, setStepSecondVisible] = useState(false);
  const [stepThirdVisible, setStepThirdVisible] = useState(false);
  const [stepFourthVisible, setStepFourthVisible] = useState(false);
  const { WaitingWindowLoaderConfiguration } = useTenant<WaitingWindowLoaderConfigurationType>();
  const Spinner = WaitingWindowLoaderConfiguration.loader.spinner;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const timeoutRef = useRef<number | null>(null);
  useEffect(() => {

    if (hideWindow) {
      timeoutRef.current = window.setTimeout(() => {
        props.setVisible(false);
      }, HIDE_WINDOW_TIMEOUT);
    }
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideWindow]);

  return (
    <WaitingWindowLoaderContainer>
      <Grid container justifyContent='center' alignItems="flex-start">
        <Grid item xs={12} sm={8} md={8}>
          <WaitingWindowLoaderHeader>
            {translate(props.headerText)}
          </WaitingWindowLoaderHeader>
          <WaitingWindowLoaderDesktopContainer item xs={2}>
            <Spinner size={{ height: 250, width: 250 }} />
          </WaitingWindowLoaderDesktopContainer>
          <WaitingWindowLoaderMobileContainer item>
            <Spinner size={{ height: 150, width: 150 }} />
          </WaitingWindowLoaderMobileContainer>
          <WaitingWindowLoaderProgressContainer>
            <WaitingWindowLoaderContent
              text={translate(props.stepsText[FIRST_STEP])}
              visible={true}
              canFinish={true}
              onFinish={() => setStepSecondVisible(true)}
              setLoadingSpeed={props.setLoadingSpeed}
              finishImediatily={props.canClose} />
            <WaitingWindowLoaderContent
              text={translate(props.stepsText[SECOND_STEP])}
              visible={stepSecondVisible}
              canFinish={true}
              onFinish={() => setStepThirdVisible(true)}
              setLoadingSpeed={props.setLoadingSpeed}
              finishImediatily={props.canClose} />
            {props?.stepsText?.[THIRD_STEP] &&
              <WaitingWindowLoaderContent
                text={translate(props.stepsText[THIRD_STEP])}
                visible={stepThirdVisible}
                canFinish={true}
                setLoadingSpeed={props.setLoadingSpeed}
                onFinish={() => props.stepsText[THIRD_STEP] ? setStepFourthVisible(true) : setHideWindow(true)}
                finishImediatily={props.canClose} />
            }
            {props?.stepsText?.[FOURTH_STEP] &&
              <WaitingWindowLoaderContent
                text={translate(props.stepsText[FOURTH_STEP])}
                visible={stepFourthVisible}
                canFinish={props.canClose}
                setLoadingSpeed={props.setLoadingSpeed}
                onFinish={() => setHideWindow(true)}
                finishImediatily={props.canClose} />
            }
          </WaitingWindowLoaderProgressContainer>
        </Grid>

      </Grid>
    </WaitingWindowLoaderContainer>
  );
};
