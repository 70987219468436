import React, { FC } from 'react';
import { OfferSelectContentType } from './OfferSelectContent.type';
import { ToggleSelect } from '../ToggleSelect/ToggleSelect';
import {
  OfferSelectContentOptionsWrapper,
  OfferSelectFooter,
} from './OfferSelectContent.style';

export const OfferSelectContent: FC<OfferSelectContentType> = ({
  children,
  options,
  onChange,
  useModal = false,
  value,
  dataE2e,
  showSelectedText,
}: OfferSelectContentType) => {
  return (
    <OfferSelectContentOptionsWrapper useModal={useModal}>
      <ToggleSelect
        dataE2e={dataE2e}
        options={options}
        onChange={onChange}
        value={value}
        showSelectedText={showSelectedText}
      />
      <OfferSelectFooter>{children}</OfferSelectFooter>
    </OfferSelectContentOptionsWrapper>
  );
};

export default OfferSelectContent;
