import * as React from 'react';
import { ReactComponent as CallIcon } from '../../assets/mini-phone.svg';
import { CallNowSectionContainer } from './CallNowSection.style';

export type CallNowSection = {
  onClick?: () => void;
  children?: React.ReactNode;
};

export const CallNowSection: React.FC<CallNowSection> = ({
  onClick,
}: CallNowSection) => {
  return (
    <CallNowSectionContainer onClick={onClick} className="call-now-section">
      <CallIcon />
      <div>
        Lub zadzwoń: <strong>+48 500 965 626</strong>
        <br />
        Pracujemy w dni robocze: 8:00 - 18:00
      </div>
    </CallNowSectionContainer>
  );
};
