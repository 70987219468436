import { useEffect, useRef } from 'react';
import i18n, { TFunction } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { TranslationWrapperProps } from './TranslationWrapper.type';

const mergeTranslations = (
  common: Record<string, string>,
  messages: Record<string, string>,
): Record<string, string> => Object.assign({}, common, messages);

export const useI18n = ({
  commonPLMessages,
  commonENMessages,
  plMessages,
  enMessages,
  language,
  fallBackLanguage,
}: TranslationWrapperProps): void => {
  const initialized = useRef<boolean>(false);
  const i18nInstance = useRef<Promise<TFunction> | null>(null);
  if (!initialized.current) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    i18nInstance.current = i18n.use(initReactI18next).init({
      resources: {
        pl: {
          translation: mergeTranslations(commonPLMessages, plMessages),
        },
        en: {
          translation: mergeTranslations(commonENMessages, enMessages),
        },
      },
      lng: language,
      fallbackLng: fallBackLanguage,

      interpolation: {
        escapeValue: false,
      },
    });
    initialized.current = true;
  }

  useEffect(() => {
    i18nInstance.current?.then(async () => {
      await i18n.changeLanguage(language);
    });
  }, [language]);

  useEffect(() => {
    i18nInstance.current?.then(() => {
      i18n.addResourceBundle(
        'pl',
        'translations',
        mergeTranslations(commonPLMessages, plMessages),
        false,
        true,
      );
      i18n.addResourceBundle(
        'en',
        'translations',
        mergeTranslations(commonENMessages, enMessages),
        false,
        true,
      );
    });
  }, [commonPLMessages, commonENMessages, plMessages, enMessages]);
};
