"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createConfiguration = void 0;
const regex = /^FOOTER_QUESTION_\d+_HEAD$/;
const createConfiguration = (tenantType, messages) => {
    const getAmountOfQuestions = () => Object.keys(messages).filter((key) => regex.exec(key)).length;
    return {
        tenantType,
        getAmountOfQuestions,
    };
};
exports.createConfiguration = createConfiguration;
