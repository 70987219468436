import { benefiaTheme } from '@sky-distribution/react-commons-ui';
import { BasicInfoConfigurationType, ConfigurationKey } from '../tenant.type';
import alertIcon from './assets/alert.svg';
import homeBgDesktop from './assets/bg_desktop.png';
import datePickerIcon from './assets/datepicker.svg';

const tenantConfiguration: BasicInfoConfigurationType = {
  [ConfigurationKey]: {
    datePickerIcon: datePickerIcon,
    alertIcon: alertIcon,
    homeBgDesktop: homeBgDesktop,
    homeBgMobile: undefined,
    displayOrLabel: false,
    desktopVerticalBackgroundPosition: '10rem',
    styledHeaderMarginBottom: '7rem',
    footerLinks: [
      {
        href: 'https://www.benefia24.pl/NewAssets/docs/Regulamin_ubezpieczenia_komunikacyjne.pdf',
        translationKey: 'REG_ELECTRONIC_SERVICES'
      },
      {
        href: 'https://www.benefia24.pl/NewAssets/docs/Informacje_o_administratorze_danych_osobowych.pdf',
        translationKey: 'PROCESSING_PERSONAL_DATA_INFO_BENEFIA'
      },
      {
        href: 'https://www.benefia24.pl/ubezpieczenia24/descriptions/Dokumenty.aspx',
        translationKey: 'FOOTER_DOCUMENTS'
      }
    ],    
    startTitle: {
      displayTitle: false,
      displaySubtitle: true,
      style: {
        customMargin: '1rem 0 5rem',
        customWidth: '100%',
        customH1Color: benefiaTheme.palette.text.header
      }
    },
    startPageForm: {
      width: '27.125rem',
      padding: '1.2rem',
      inputs: {
        height: '3.5rem'
      }
    },
    boxShadow: benefiaTheme.shadow.contentBox
  }
};

export default tenantConfiguration;
