import { FlagsConfiguration } from '../common/utils/useFeatureFlags/prepareFeaturesFromEnv';
import RequestService from '../common/http/RequestService';
import { isProdBuild } from '../utils/getDeployEnvName';
import featureFlagsUrl  from '../.features';

export const getFeatureFlagsFromEnv =
  async (): Promise<FlagsConfiguration> => {
    const productionMode = isProdBuild();

    if (!productionMode) {
      return  JSON.parse(process.env.REACT_APP_FEATURE_FLAGS as string);
    }
    const request = new RequestService(featureFlagsUrl);
    return await request.get<FlagsConfiguration>(
      '',
      {},
      {
        CacheControl: "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    );
  };
