import { AppActions } from '../../../../../redux/actions/AppActions.type';
import { SESSION_ACTIONS } from '../../../redux/session/Session.type';
import { USER_INFO_ACTIONS } from '../../../redux/userInfo/UserInfoActions.type';
import { VASE_ACTIONS } from './VaseActions.type';
import { VasesState } from './Vases.type';

const initialState = {} as VasesState;

export const vasesReducer =
  (state = initialState, action: AppActions): VasesState => {
    switch (action.type) {
      case SESSION_ACTIONS.QUOTE_SUBMISSION:
        return {
          ...state,
          vasesData: action.vases
        };
      case USER_INFO_ACTIONS.RETRIEVE_USER_INFO:
        return { ...state, ...action.data.vases };
      case VASE_ACTIONS.SAVE_VASES_DATA:
        return {
          ...state,
          selectedVases: action.data
        };

      case VASE_ACTIONS.RESET_VASES_DATA:
        return {
          ...state,
          selectedVases: []
        };
      default:
        return state;
    }
  };
