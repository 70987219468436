//ToDo https://skydistribution.atlassian.net/browse/BEE-8608
import CompensaSpinner from '../../../../../modules/userdata/components/spinners/compensaSpinner/CompensaSpinner';
import { ConfigurationKey, WaitingWindowLoaderConfigurationType } from '../tenant.type';

const configuration: WaitingWindowLoaderConfigurationType = {
  [ConfigurationKey]: {
    loader: {
      spinner: CompensaSpinner
    }
  }
};

export default configuration;
