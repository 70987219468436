import React from 'react';
import ContainerTranslate from './ContainerTranslate';
import { TranslationsType } from './Translate.type';

function withTranslations<OuterProps extends object>(
  Component: React.ComponentType<OuterProps>,
  translations: TranslationsType
): React.ComponentClass<OuterProps> {

  class C extends React.Component<OuterProps> {

    render = () => {
      return (
        <ContainerTranslate containerTranslations={translations}>
          <Component {...this.props} />
        </ContainerTranslate>
      );
    };

  }
  return C;
}

export default withTranslations;
