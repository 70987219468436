/* eslint-disable no-case-declarations */
import { RadioSlideIndex } from '../../components/RenderPicker/components/RadioSlide/RadioSlide.const';
import { isValidPickerDate } from '../../helpers/dateValidators';
import { isDatePickerFormFilled } from '../../helpers/isDatePickerFormFilled';
import { isValueEqualToInitial } from '../../helpers/isValueEqualToInitial';
import { PickerAction, PickerState } from './PickerContext.types';
import {
  convertPickerDateToString,
  getPickerDate,
  getRadioSlideIndex,
} from './PickerContext.utils';

export function pickerReducer(
  state: PickerState,
  action: PickerAction,
): PickerState {
  switch (action.type) {
    case 'SET_VISIBLE_PICKER':
      const picker = action.payload;
      const radioSlideIndex = state.date.year
        ? getRadioSlideIndex(picker)
        : RadioSlideIndex.YEAR;

      return {
        ...state,
        visiblePicker: picker,
        radioSlideIndex,
        isFocused: picker !== null,
      };
    case 'SET_DATE':
      const initialDate = getPickerDate(state.initialDate);
      const updatedDate = {
        ...state.date,
        ...action.payload,
      };

      return {
        ...state,
        date: updatedDate,
        isDirty: isValueEqualToInitial(updatedDate, initialDate),
        isFormFilled: isDatePickerFormFilled(updatedDate),
      };
    case 'VALIDATE_PICKER_DATE':
      const { validators, errorMessages } = state.validatorProps;
      const pickerStringDate = convertPickerDateToString(state.date);

      for (const validatorName in validators) {
        const isExternalValid = validators?.[validatorName](pickerStringDate);

        if (!isExternalValid) {
          return {
            ...state,
            errorMsg: errorMessages?.[validatorName] || state.dateIsInvalidMsg,
          };
        }
      }

      const isValidDate = isValidPickerDate(state.date, state.datesRange);

      if (isValidDate || (pickerStringDate === '' && !state.required)) {
        return {
          ...state,
          errorMsg: null,
          isFocused: !state.isDirty ? false : state.isFocused,
          isDirty: false,
        };
      }

      return {
        ...state,
        errorMsg: state.dateIsInvalidMsg,
      };
    default:
      return state;
  }
}
