/* eslint-disable max-lines, camelcase */
import { LanguageDictionaryType } from '../benefia.type';

export const enMessages: LanguageDictionaryType = {
  BASIC_INFO_HEADER_B: 'Calculator OC AC',
  BASIC_INFO_HEADER_C: 'Calculate the premium in 60 seconds and buy online',
  MANUAL_INPUT_DESCRIPTION: 'Don\'t know the registration number?',
  MANUAL_INPUT_BUTTON: 'Select a vehicle manually',
  BIRTH_DATE_PLACEHOLDER: 'Owner birth date',
  CAR_NOT_FOUND_HEADER: 'We did not find your vehicle',
  CAR_NOT_FOUND_INFO: 'Check that the data is correct or',
  TRY_AGAIN: 'Try again'
};
