import DownloadIcon from './icons/download.svg';
import OCIcon from './icons/oc.svg';
import ACIcon from './icons/ac.svg';
import NNWIcon from './icons/nnw.svg';
import HelpInTripIcon from './icons/help.svg';
import BLSIcon from './icons/bls.svg';
import AssistanceIcon from './icons/assistance.svg';
import WindowIcon from './icons/windows.svg';
import SportIcon from './icons/sport.svg';
import UrbanIcon from './icons/urban.svg';
import BatteryIcon from './icons/battery.svg';
import WorkshopIcon from './icons/workshop.svg';
import SupportIcon from './icons/support.svg';
import TiresIcon from './icons/tire.svg';
import LuggageIcon from './icons/luggage.svg';
import RewardIcon from './icons/reward.svg';

import AllegroImg from './icons/Allegro.pl_sklep.svg';
import BlinkeeImg from './icons/blinkee-logo-header.svg';
import BpImg from './icons/BP_Helios_logo.svg';
import CostaImg from './icons/costa.svg';
import PanekImg from './icons/logo_panek_cs_type.svg';
import TidalImg from './icons/Tidal-logo.svg';

export const ICONS: Record<string, string> = {
  DownloadIcon,
  OCIcon,
  ACIcon,
  NNWIcon,
  HelpInTripIcon,
  BLSIcon,
  AssistanceIcon,
  WindowIcon,
  SportIcon,
  UrbanIcon,
  BatteryIcon,
  WorkshopIcon,
  SupportIcon,
  TiresIcon,
  LuggageIcon,
  RewardIcon,
};

export const PARTNER_ICONS: string[] = [
  AllegroImg,
  BlinkeeImg,
  BpImg,
  CostaImg,
  PanekImg,
  TidalImg,
];
