import styled from 'styled-components';

export const StyledSaveOfferInfoBox = styled.div`
    font-family: 'Poppins', sans-serif;
    background: #f5f5ff;
    border-radius: .5rem;
    padding: 1rem 1.25rem;
    font-weight: 400;
    color: ${({ theme }) => theme.palette.text.header};
    display: flex;
    align-items: center;
    
    svg {
       min-width: 2rem;
       margin-right: .875rem;
    }
`;

export const StyledFieldWrapper = styled.div<{last?: boolean}>`
  padding-bottom: ${props => props.last ? '0' : '2rem'}
`;
