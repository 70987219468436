import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { markAsRenwalAction } from '../../modules/userdata/redux/session/Session.action';
import { URLSearchParams } from '@sky-distribution/react-commons-ui';

export const useRenewalProvider = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (isRenewal(searchParams)) {
      dispatch(markAsRenwalAction(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

function isRenewal(searchParams: URLSearchParams) {
  return searchParams.has('type') && searchParams.getFirst('type') === 'renewal';
}
