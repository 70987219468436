import { TenantConfiguration, TenantsEnum } from '../tenant.type';
import BeesafeSuspenseSpinner from '../../../modules/userdata/components/spinners/beesafeSuspenseSpinner/BeesafeSuspenseSpinner';
import cookieConfiguration from '../../../common/components/cookies/tenants/beesafe';
import firstLogoUrl, { ReactComponent as FirstLogo } from './assets/firstLogo.svg';
import secondLogoUrl, { ReactComponent as SecondLogo } from './assets/secondLogo.svg';
import OfferDetailsData from './OfferDetails.data.json';
import { prepareBasePath } from '../common';
const configuration: TenantConfiguration = {
  tenantType: TenantsEnum.Beesafe,
  tenantId: TenantsEnum.Beesafe, // X-Tenant-Id header value passed to backend in order to indicate brand for particular request
  basePath: prepareBasePath('kalkulator-oc-ac'),
  firstLogo: { url: firstLogoUrl, component: FirstLogo, display: true, width: '8.75rem', href: 'https://beesafe.pl' },
  secondLogo: { url: secondLogoUrl, component: SecondLogo, display: false },
  suspenseLoader: {
    spinner: BeesafeSuspenseSpinner,
  },
  CookieConfiguration: cookieConfiguration,
  offerDetailsData: OfferDetailsData
};

export default configuration;
