import React, { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { CommonStyle } from './common/shared/styles/CommonStyle';
import { FeatureFlagProvider } from './common/utils/useFeatureFlags/useFeatureFlag';
import { FEATURE_COOKIE_NAME, getFeatureFlags } from './config/getFeatureFlags';
import { TenantProvider } from './config/tenants/Tenants.provider';
import ErrorBoundary from './modules/errorBoundary/ErrorBoundary.component';
import ErrorHandlerProvider from './providers/ErrorProvider/ErrorProvider';
import store from './redux/store/AppStore';
import { TranslationContextProvider } from './translations/Translate.provider';
import { FlagsConfiguration } from './common/utils/useFeatureFlags/prepareFeaturesFromEnv';

import App from './modules/App';
import { getFeatureFlagsFromEnv } from './config/getFeatureFlagsFromEnv';
import BeesafeSuspenseSpinner from './modules/userdata/components/spinners/beesafeSuspenseSpinner/BeesafeSuspenseSpinner';

export const Client = () => {
  const [flagsFromEnv, setFlagsFromEnv] = useState<FlagsConfiguration>();
  const [isConfigLoaded, setConfigLoaded] = useState<boolean>(false); 

  useEffect(() => {
    document.getElementById('jss-server-side')?.remove();
    getFeatureFlagsFromEnv().then((data) => {
      setFlagsFromEnv(data);
      setConfigLoaded(true);
    });
  }, []);

  
  return (
    <TenantProvider>
      <Provider store={store}>
        <TranslationContextProvider>
          <HelmetProvider>
            <ErrorHandlerProvider>
              {isConfigLoaded
                ? 
                <FeatureFlagProvider
                  getter={() =>
                    getFeatureFlags(
                      flagsFromEnv
                    )
                  }
                  eventName={FEATURE_COOKIE_NAME}
                >
                  <CommonStyle />
                  <ErrorBoundary>
                    <App />
                  </ErrorBoundary>
                </FeatureFlagProvider>
                : <BeesafeSuspenseSpinner />
              }
            </ErrorHandlerProvider>
          </HelmetProvider>
        </TranslationContextProvider>
      </Provider>
    </TenantProvider>
  );
};

export default Client;
