import { Grid, LinearProgress } from '@material-ui/core';
import React from 'react';
import styled, { keyframes } from 'styled-components';


export const WaitingWindowLoaderContentWrapper = styled(Grid)`
  height: 50px;
  margin-bottom: 1rem;
`;

export const WaitingWindowLoaderLabel = styled.p`
  margin-top: 0;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.81;
  color: ${({ theme }) => theme.palette.commons.tonal.base};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 0.8125rem;
  }
`;

export const WaitingWindowLoaderProgress = styled(({ ...otherProps }) =>
  <LinearProgress {...otherProps} />
)` &&& {
    height: 5;
    background-color: ${({ theme }) => theme.palette.commons.background.dimmed};

    div{
      background-color: ${({ theme }) => theme.palette.commons.background.container};
    }
  }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const WaitingWindowLoaderContentImg = styled.img`
  animation: ${fadeIn} 1s;
`;
