import { Grid, GridProps } from '@material-ui/core';
import { CSSProperties } from 'react';
import styled from 'styled-components';

interface StyledFormWrapperProps {
  $marginTop?: string;
}

export const StyledFormWrapper = styled(Grid) <StyledFormWrapperProps>`
    && {
        margin: 0;
        margin-top: ${props => props.$marginTop};
    }
`;

interface StyledFormContainerProps {
  $paddingVertical?: string;
  $transparent?: boolean;
  $desktopWidth?: CSSProperties['width'];
  $boxShadow?: CSSProperties['boxShadow'];
  $desktopHeight?: CSSProperties['height'];
}

export const generatePaddings = (props: StyledFormContainerProps) => {
  const paddingTop = props.$paddingVertical ?? '1rem';
  const paddingBottom = props.$paddingVertical ?? '1rem';

  return `${paddingTop} 0.938rem ${paddingBottom}`;
};

export const StyledFormContainer = styled(Grid) <GridProps & StyledFormContainerProps> `
    &&& {
        background-color: ${({ $transparent, theme }) => $transparent ? 'transparent' : theme.palette.commons.background.container};
        margin: 0;
        padding: 0;
        form {
            width: 100%;
        }
        ${({ theme }) => theme.breakpoints.up('sm')} {
           margin: 0 0 2rem;
           padding: ${generatePaddings};
           border-radius: 1rem;
           max-width: ${({ $desktopWidth = '27.125rem' }) => $desktopWidth};
           min-width: ${({ $desktopWidth = '27.125rem' }) => $desktopWidth};
           box-shadow: ${({ $boxShadow, theme }) => $boxShadow ?? theme.shadow.contentBox};
           min-height: ${({ $desktopHeight = 'none' }) => $desktopHeight};
           max-height: ${({ $desktopHeight = 'none' }) => $desktopHeight};
        }
    }
`;
