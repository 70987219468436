import TagManager from 'react-gtm-module';
import { safeWindow } from '../../../utils/window';

export interface TriggerDataLayerProps {
  event?: string;
  templateName?: string;
  sessionUUID?: string;
  quoteUuid?: string;
  error?: Error;
  quoteID?: string;
  transactionID?: string;
}

const triggerDataLayerEvent = (props: TriggerDataLayerProps) => {
  if (safeWindow.GOOGLE_GTM_ID) {
    const tagManagerArgs = {
      dataLayer: props
    };
    TagManager.dataLayer(tagManagerArgs);
  }
};

export default triggerDataLayerEvent;
