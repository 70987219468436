import { AppActions } from '../../../../../redux/actions/AppActions.type';
import { USER_INFO_ACTIONS } from '../../../redux/userInfo/UserInfoActions.type';
import { AddressInfoDataType } from '../service/AddressInfo.type';
import { ADDRESS_ACTIONS } from './AddressInfoActions.type';

const initialState = {} as AddressInfoDataType;

export const addressReducer = (
  state = initialState,
  action: AppActions
): AddressInfoDataType => {
  switch (action.type) {
    case ADDRESS_ACTIONS.DATA_SAVED:
      return { ...state, ...action.data };
    case USER_INFO_ACTIONS.RETRIEVE_USER_INFO:
      return { ...state, ...action.data.addressData };
    default:
      return state;
  }
};
