import { UserInfoRetrievedDataAction } from './../../../redux/userInfo/UserInfoActions.type';
import { PaymentPlanType, PaymentPlanTypeRaw } from './PaymentFrequency.type';

export const PAYMENT_FREQUENCY_ACTIONS = {
  SAVE_PAYMENT_FREQUENCY_DATA: 'USER_DATA/SAVE_PAYMENT_FREQUENCY_DATA',
  GET_PAYMENT_FREQUENCY_OPTIONS: 'USER_DATA/GET_PAYMENT_FREQUENCY_OPTIONS',
  RESET_PAYMENT_FREQUENCY: 'USER_DATA/RESET_PAYMENT_FREQUENCY'
} as const;

export interface PaymentFrequencySave {
  type: typeof PAYMENT_FREQUENCY_ACTIONS.SAVE_PAYMENT_FREQUENCY_DATA;
  data: PaymentPlanType;
}

export interface PaymentFrequencyOptions {
  type: typeof PAYMENT_FREQUENCY_ACTIONS.GET_PAYMENT_FREQUENCY_OPTIONS;
  data: PaymentPlanTypeRaw[];
}

export interface PaymentFrequencyReset {
  type: typeof PAYMENT_FREQUENCY_ACTIONS.RESET_PAYMENT_FREQUENCY;
}

export type PaymentFrequencyActionsType = PaymentFrequencySave | PaymentFrequencyOptions | PaymentFrequencyReset | UserInfoRetrievedDataAction;
