import { DateFromPickerType } from '../BeeDatePicker.types';
import {
  DAY_LENGTH,
  MONTH_LENGTH,
  YEAR_LENGTH,
} from '../components/RenderPicker/RenderPicker.const';

export function isDatePickerFormFilled({
  day,
  month,
  year,
}: Partial<DateFromPickerType>): boolean {
  if (!day || !month || !year) {
    return false;
  }

  return (
    day.length === DAY_LENGTH &&
    month.length === MONTH_LENGTH &&
    year.length === YEAR_LENGTH
  );
}
