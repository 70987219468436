import styled, { css } from 'styled-components';
import type { BasicInfoConfigurationType } from '../../tenants/tenant.type';

type CustomStyle = {
  customStyle: BasicInfoConfigurationType['BasicInfoConfiguration']['startTitle']['style'];
};

const FlexTemplate = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ResponsiveTextTemplate = css`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 1.3rem;
    margin-right: -0.4375rem;
    margin-top: -1.25rem;
    justify-content: center;
  }
  ${({ theme }) => theme.breakpoints.between('sm', 'md')} {
    text-align: center;
    font-size: 2rem;
  }
`;

const TextStylesTemplate = css`
  font-size: 2.5rem;
  margin: 0;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin: 0.5rem 0;
  }
`;

export const StartTitleWrapper = styled.div<CustomStyle>`
  color: ${({ theme }) => theme.palette.text.header};
  ${FlexTemplate};
  text-align: center;
  flex-wrap: wrap;
  width: ${({ customStyle }) => customStyle.customWidth};
  line-height: 1.5;
  font-weight: bold;
  margin: ${({ customStyle }) => customStyle.customMargin};
  margin-top: 0.5rem;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    justify-content: center;
    margin: 0;
  }
`;

export const StyledTitleH1 = styled.h1`
  ${ResponsiveTextTemplate};
  ${TextStylesTemplate};
`;

export const StyledTitleParagraph = styled.p`
  ${FlexTemplate};
  ${ResponsiveTextTemplate};
  ${TextStylesTemplate};
`;

export const StyledSubtitleParagraph = styled.p<CustomStyle>`
  ${FlexTemplate};
  ${ResponsiveTextTemplate};
  ${TextStylesTemplate};
  color: ${({ customStyle }) => customStyle.customH1Color};
  &:last-child {
    width: 100%;
  }
`;
