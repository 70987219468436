import styled from 'styled-components';

export const StyledFlexWrapper = styled.span`
  align-items: center;
  display: flex;
`;
export const StyledTitleBold = styled.span`
  font-size: 1rem;
  font-weight: 700;
  margin-right: 0.25rem;
`;
export const StyledTitleNormal = styled.span`
  font-weight: normal;
`;
export const StyledCaptionNormal = styled.span`
  font-weight: normal;
  margin-right: 0.25rem;
`;
export const StyledCaptionBold = styled.span`
  font-weight: 700;
`;
export const StyledColumnWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
export const StyledColumn = styled.div`
  border-left: 1px solid black;
  border-right: 1px solid black;
  min-height: 30rem;
  width: 20rem;
`;
