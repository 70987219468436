import { GridSpacing } from '@material-ui/core/Grid/Grid';
import React, { CSSProperties, FC } from 'react';
import { StyledFormContainer, StyledFormWrapper } from './FormContainer.style';

const FORM_CONTAINER_SIZE_XS = 12;
const FORM_CONTAINER_SIZE_SM = 7;
const FORM_CONTAINER_SIZE_MD = 5;
const FORM_CONTAINER_SIZE_LG = 4;
const DEFAULT_GRID_SPACING = 3;

interface FormContainerProps {
  id?: string;
  $paddingVertical?: string;
  spacing?: GridSpacing;
  marginTop?: string;
  transparent?: boolean;
  desktopWidth?: CSSProperties['width'];
  desktopHeight?: CSSProperties['height'];
  children: React.ReactElement[] | React.ReactElement;
  boxShadow?: CSSProperties['boxShadow'];
}

const FormContainer: FC<FormContainerProps> = ({ transparent, $paddingVertical, spacing, children, id, marginTop, desktopWidth, boxShadow, desktopHeight }: FormContainerProps) => (
  <StyledFormWrapper
    container
    justifyContent='center'
    $marginTop={marginTop}
  >
    <StyledFormContainer
      id={id}
      item
      container
      justifyContent='center'
      xs={FORM_CONTAINER_SIZE_XS}
      sm={FORM_CONTAINER_SIZE_SM}
      md={FORM_CONTAINER_SIZE_MD}
      lg={FORM_CONTAINER_SIZE_LG}
      spacing={spacing ?? DEFAULT_GRID_SPACING}
      $paddingVertical={$paddingVertical}
      $transparent={transparent}
      $desktopWidth={desktopWidth}
      $desktopHeight={desktopHeight}
      $boxShadow={boxShadow}
    >
      {children}
    </StyledFormContainer>
  </StyledFormWrapper>
);

export default FormContainer;
