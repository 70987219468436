import { createReducer } from '@reduxjs/toolkit';
import {
  SetRedirectUrlAction, TransactionState,
  TRANSACTION_ACTIONS
} from './Transaction.type';

const initialState: TransactionState = {
  redirectUrl: '',
  isLoading: false,
  isLoadingModalVisible: false,
  canFinish: false
};

export const transactionReducer = createReducer(initialState, {
  [TRANSACTION_ACTIONS.TRANSACTION_QUOTATION]: (state: TransactionState, action: SetRedirectUrlAction): TransactionState => ({
    ...state,
    redirectUrl: action.payload
  }),
  [TRANSACTION_ACTIONS.CLEAR_REDIRECT_URL]: (state: TransactionState): TransactionState => ({
    ...state,
    redirectUrl: ''
  }),
  [TRANSACTION_ACTIONS.START_QUOTATION]: (state: TransactionState): TransactionState => ({
    ...state,
    isLoading: true,
    canFinish: false,
    isLoadingModalVisible: true
  }),
  [TRANSACTION_ACTIONS.CLOSE_LOADING_MODAL]: (state: TransactionState): TransactionState => ({
    ...state,
    isLoadingModalVisible: false
  }),
  [TRANSACTION_ACTIONS.FINISH_QUOTATION]: (state: TransactionState): TransactionState => ({
    ...state,
    isLoading: false,
    canFinish: true
  })
});
