import styled from 'styled-components';

export const StyledSaveOfferSuccessDescription = styled.p`
    font-family: 'Poppins', sans-serif;
    font-size: .875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    color: ${({ theme }) => theme.palette.text.header};
    text-align: center;
    max-width: 75%;
    margin: 1rem auto 0 auto;
`;

export const StyledIco = styled.div`
    display: flex;
    justify-content: center;
`;
