import { FetchUserInfoType, UserInfoData } from './FetchUserInfo.type';
import { UriBuilder } from '../../../common/http/UriBuilder';
import format from 'date-fns/format';
import { onboardingRequestService } from '../../../http/RequestService.http';

export const getUserInfoRequest = async (
  userInfoRequestParams: UserInfoData
  // eslint-disable-next-line require-await
): Promise<FetchUserInfoType> => {
  const url = new UriBuilder().all('vehicle').all('info').build();

  return onboardingRequestService.get<FetchUserInfoType>(
    url,
    {
      ...userInfoRequestParams,
      birthDate: format(userInfoRequestParams.birthDate, 'yyyy-MM-dd')
    },
    {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0'
    }
  );
};
