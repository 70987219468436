export interface FlagsConfiguration {
    default: Record<string, boolean>;
    [k: string]: Record<string, boolean>;
}

// eslint-disable-next-line complexity
export const prepareFeatureFlagsForEnv = (flags: FlagsConfiguration, rawEnvName: string | undefined) => {
  let featureFlags: Record<string, boolean> = flags.default;
  const tenantName = (process.env.REACT_APP_TENANT ?? '').toLowerCase().trim();
  const envFullName = rawEnvName ? rawEnvName.toLowerCase().trim() : '';
  const envGroupName = envFullName.replace(/^(prod|uat|dev).*/, '$1');

  if (tenantName) {
    featureFlags = { ...featureFlags, ...flags[`${tenantName}-default`] };
  }

  if (envGroupName && flags[envGroupName]) {
    featureFlags = { ...featureFlags, ...flags[envGroupName] };
  }

  if (envFullName && flags[envFullName]) {
    featureFlags = { ...featureFlags, ...flags[envFullName] };
  }

  return featureFlags;
};
