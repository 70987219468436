import styled from 'styled-components';

export const OLD_COOKIE_BAR_HEIGHT_PX = 72;

export const StyledCookies = styled.div`
  position: fixed;
  bottom: 0;  
  left: 0;  
  right: 0; 
  background-color: ${({ theme }) => theme.palette.commons.tonal.base};
  z-index: 9;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: ${({ theme }) => theme.typography.info.fontWeight};
  font-style: normal;
  font-size: ${({ theme }) => theme.typography.info.fontSize};
  line-height: ${({ theme }) => theme.typography.info.lineHeight};
  text-align: left;
  .container {
    color: ${({ theme }) => theme.palette.text.header};
    padding: 0.9375rem 0;
    display: flex;
    align-items: center;
    height: ${OLD_COOKIE_BAR_HEIGHT_PX}px;
    ${({ theme }) => theme.breakpoints.only('sm')} {
      margin-left: 7rem;
      line-height: 1.5;
    }
    ${({ theme }) => theme.breakpoints.only('md')} {
      margin-left: 7rem;
    }
    ${({ theme }) => theme.breakpoints.only('lg')} {
      margin-left: 7rem;
    }
    ${({ theme }) => theme.breakpoints.only('xl')} {
      margin-left: 23rem;
    }
    ${({ theme }) => theme.breakpoints.only('xs')} {
      margin-bottom: .5rem;
      margin-top: .5rem;
      margin-left: 1rem;
      line-height: 1.4;
    }
  };
  .reference {
    color: ${({ theme }) => theme.palette.text.header};
    text-decoration: none;
    font-weight: 900;
  }
  .accept {
    background-color: ${({ theme }) => theme.palette.commons.tonal.base};
    color: ${({ theme }) => theme.palette.text.link};
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.palette.button.border.outline.base};
    margin: 0 0 0 0.625rem;
    width: 4.875rem;
    height: 2.25rem;
    font-size: 0.875rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    margin-left: 1.5rem;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    
    ${({ theme }) => theme.breakpoints.only('xs')} {
      margin-left: .5rem;
    }
    &:hover{
      cursor: pointer;
    }
  }
  .content-grid {
    ${({ theme }) => theme.breakpoints.only('xs')} {
      max-width: 70%;
    }
    ${({ theme }) => theme.breakpoints.only('md')} {
      max-width: 75%
    }
    ${({ theme }) => theme.breakpoints.up('lg')} {
      max-width: 65%;
    }
  }
`;
