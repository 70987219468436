import { useModalContext } from '../ModalProvider/ModalProvider';
import type { ConsentsModalContextType } from '../types';
import type { CustomerConsents, ConsentSchema } from '../../modules/userdata/containers/ContactInfo/components/ConsentForm/Consent';
import { OnboardingStateType } from '../../redux/types/AppStore.type';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import Consents from './Consents.util';
import { saveCustomerConsents } from '../../modules/userdata/containers/ContactInfo/components/ConsentForm/redux/Consents.actions';
import { InputProps } from './../../modules/userdata/inputs/types.d';

export const useConsentsModalProvider = (): ConsentsModalContextType => {
  const { isModalVisible } = useModalContext();
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [consentsData, setConsentData] = useState<ConsentSchema>(Consents.get());
  const [consentsState, setConsentsState] = useState<Partial<CustomerConsents>>();
  const [consentsNames, setConsentsNames] = useState<string[]>();
  const [onModalClose, setOnModalClose] = useState(()=>()=>{});

  const dispatch = useDispatch();
  const { customerConsents } = useSelector((state: OnboardingStateType) => state.userData);

  useEffect(() => {
    setConsentData(consentsNames ? Consents.get({ where: 'name', is: consentsNames }) : Consents.get());
  }, [consentsNames]);

  useEffect(() => {
    setConsentsState(Consents.getStatusForForm(consentsData, customerConsents));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentsData]);

  useEffect(() => {
    if (consentsState) {
      setIsAllSelected(Object.values(consentsState).every(value => value));
      /* TODO: BEE-8095 */
      /* @ts-ignore */
      dispatch(saveCustomerConsents(consentsState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentsState]);

  useEffect(() => {
    if (!isModalVisible) {
      setConsentsNames([]);
      setConsentData(Consents.get());
    }
  }, [isModalVisible]);

  const handleSelectAll = (setFieldTouched: NonNullable<InputProps['setFieldTouched']>, setFieldValue: InputProps['setFieldValue']) => {
    const updatedConsents: Partial<CustomerConsents> = {};
    const newValue = !isAllSelected;
    if (consentsData) {
      for (const consent of consentsData) {
        setFieldValue(`consents[${consent.rawName}]`, newValue, true);
        setFieldTouched(`consents[${consent.rawName}]`, true);
        updatedConsents[consent.rawName as keyof CustomerConsents] = newValue;
      }
      setConsentsState(updatedConsents);
    }
  };

  const setConsent = (consent: string, val: boolean, setFieldValue: (key:string, value: string | number | boolean, shouldValidate?:boolean) => void) => {
    const consentRawName = consent.match(/\[([^)]+)\]/);
    const consentToUpdate = Array.isArray(consentRawName) ? consentRawName[1] : consent;
    setConsentsState({ ...consentsState, ...{ [consentToUpdate]: val } });
    setFieldValue(`consents[${consentToUpdate}]`, val, true);
  };

  const saveConsents = () => {
    if (consentsState) {
      /* TODO: BEE-8095 */
      /* @ts-ignore */
      dispatch(saveCustomerConsents(consentsState));
    }
  };

  return {
    isAllSelected,
    setIsAllSelected,
    handleSelectAll,
    setConsent,
    consentsData,
    consentsState,
    consentsNames,
    setConsentsNames,
    onModalClose,
    setOnModalClose,
    saveConsents
  };
};
