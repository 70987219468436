import styled, { DefaultTheme, css } from 'styled-components';

export const getBorderColor = (
  theme: DefaultTheme,
  isValid = false,
  isFocused = false,
  isError = false,
  readOnly = false,
): string => {
  if (readOnly) {
    return theme.palette.input.border.base;
  }
  if (isError) {
    return theme.palette.error.text.base;
  }
  if (isFocused || isValid) {
    return theme.palette.input.border.selected;
  }
  return theme.palette.input.text.base;
};

export const DateFormWrapper = styled.div<{
  isFocused: boolean;
  isValid: boolean;
  isError: boolean;
  readOnly?: boolean;
}>`
  border-radius: 0.25rem;
  box-sizing: border-box;
  font-size: 1rem;
  height: 100%;
  position: relative;

  ${({ theme, isValid, isFocused, isError, readOnly }) => css`
    border: ${isFocused && !readOnly ? '2px solid' : '1px solid'};
    border-color: ${getBorderColor(
      theme,
      isValid,
      isFocused,
      isError,
      readOnly,
    )};
    color: ${readOnly
      ? theme.palette.input.border.base
      : theme.palette.input.border.hover};

    &:hover {
      border-color: ${getBorderColor(
        theme,
        isValid,
        isFocused,
        isError,
        readOnly,
      )};
      & div {
        color: ${getBorderColor(theme, isValid, isFocused, isError, readOnly)};
      }
    }
  `}
`;

export const StyledInput = styled.input`
  background-color: transparent;
  border: none;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 1rem;
  height: 100%;
  text-align: center;
  width: calc(100% / 3 - 0.6875rem);

  &:focus {
    outline: none;
  }

  ${({ theme, readOnly }) => css`
    color: ${readOnly
      ? theme.palette.input.border.base
      : theme.palette.input.border.hover};

    &::placeholder {
      color: ${({ theme }) => theme.palette.input.text.base};
    }
  `}
`;

export const StyledFakeInput = styled.div`
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  font-size: 1rem;
  height: 100%;
  justify-content: center;
  width: calc(100% / 3 - 0.6875rem);
`;

export const StyledFakeInputWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;
