import * as Yup from 'yup';
import { isStringEmpty } from '../utils/String.utils';
import { isEmailValid } from '@sky-distribution/validators-common';

interface EmailValidatorMessagesType {
  wrongFormat: string;
  required?: string;
}

export const emailValidator = (email: string, canBeEmpty?: boolean) => {
  if (canBeEmpty && isStringEmpty(email)) {
    return true;
  }

  return isEmailValid(email);
};

export const emailYupValidator = (
  isRequired = false,
  messageObject: EmailValidatorMessagesType
): Yup.StringSchema => {
  return Yup.string()
    .test('required', messageObject.required ?? '', (value) =>
      isRequired ? !isStringEmpty(value) : true
    )
    .test('emailFormat', messageObject.wrongFormat, (value) =>
      emailValidator(value, !isRequired)
    );
};
