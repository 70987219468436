import styled from 'styled-components';
import { ToggleSelectOptionWrapperType } from './ToggleSelect.type';

export const ToggleSelectOptionsWrapper = styled.div`
  border-radius: 0.5rem;
  font-family: ${(p) => p.theme.typography.fontFamily};
`;

export const ToggleSelectOptionWrapper = styled.div<ToggleSelectOptionWrapperType>`
  align-items: center;
  border: 1px solid ${(p) => p.theme.palette.button.border.select.base};
  &:hover {
    border: 2px solid ${(p) => p.theme.palette.button.border.select.hover};
  }

  ${(p) =>
    p.isSelected &&
    `
   background: ${p.theme.palette.button.background.select.selected};
   border: 1px solid ${p.theme.palette.button.border.select.selected};
   `};
  border-radius: 0.5rem;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-family: ${(p) => p.theme.typography.fontFamily};
  justify-content: ${(p) => (p.isCentered ? 'center' : 'space-between')};
  margin-bottom: 0.75rem;
  padding: 0.75rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ToggleSelectDescriptionWrapper = styled.div`
  margin-right: 4rem;
`;

export const ToggleSelectOptionTitle = styled.div`
  color: ${(p) => p.theme.palette.brand.color};
  font-family: ${(p) => p.theme.typography.fontFamily};
  font-weight: bold;
`;

export const ToggleSelectOptionCaption = styled.div`
  color: ${(p) => p.theme.palette.text.paragraph.secondary};
  font-family: ${(p) => p.theme.typography.fontFamily};
  font-size: ${(p) => p.theme.typography.text1.fontSize};
`;
export const ToggleSelectOptionPrice = styled.div`
  color: ${(p) => p.theme.palette.brand.color};
  font-family: ${(p) => p.theme.typography.fontFamily};
  font-size: 0.75rem;
  line-height: 0.875rem;
  white-space: nowrap;
`;
