import errorImage from '../../assets/red-error-img.png';
import { ConfigurationKey } from '../tenant.type';

const tenantConfiguration = {
  [ConfigurationKey]: {
    contactUrl: 'https://www.benefia24.pl/pomoc.aspx',
    errorImage,
    styles: {
      GenericErrorPageContent: {
        justifyContent: 'center'
      }
    }
  }
};

export default tenantConfiguration;
