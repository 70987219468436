import { Dispatch } from 'redux';
import { getRequestHeaders } from '../../../../../common/http/utils/getRequestHeaders.util';
import { AppActions } from '../../../../../redux/actions/AppActions.type';
import { AppState } from '../../../../../redux/store/AppStore';
import {
  fetchPaymentFrequencyOptions,
  highlightSingleInstalmentPayment,
  PaymentFrequencySession
} from '../service/PaymentFrequency.service';
import { PaymentPlanType, PaymentPlanTypeRaw } from './PaymentFrequency.type';
import {
  PaymentFrequencyOptions,
  PaymentFrequencyReset, PaymentFrequencySave, PAYMENT_FREQUENCY_ACTIONS
} from './PaymentFrequencyActions.type';

export const savePaymentFrequencyData = (data : PaymentPlanType) =>
  (dispatch: Dispatch<AppActions>) => {
    dispatch(savePaymentFrequencyDataAction(data));
  };

export const savePaymentFrequencyDataAction = (data : PaymentPlanType) : PaymentFrequencySave => ({
  type: PAYMENT_FREQUENCY_ACTIONS.SAVE_PAYMENT_FREQUENCY_DATA,
  data
});

export const getPaymentFrequencyOptions = (data: PaymentFrequencySession) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const headers = getRequestHeaders({
      agentToken: data?.agentToken,
      quotedTenant: getState().userData.offerInfo.quotedTenant
    });
  
    const paymentFrequencyOptions = await fetchPaymentFrequencyOptions({
      ...data,
      isRenewal: Boolean(getState().userData.session.isRenewal)
    }, headers);
    const paymentOptions = highlightSingleInstalmentPayment(paymentFrequencyOptions.paymentPlans);
    dispatch(getPaymentFrequencyOptionsAction(paymentOptions));
  };

export const getPaymentFrequencyOptionsAction = (paymentFrequencyData: PaymentPlanTypeRaw[]) : PaymentFrequencyOptions => ({
  type: PAYMENT_FREQUENCY_ACTIONS.GET_PAYMENT_FREQUENCY_OPTIONS,
  data: paymentFrequencyData
});

export const resetPaymentFrequency = () => (dispatch: Dispatch<AppActions>) => dispatch(resetPaymentFrequencyAction());

export const resetPaymentFrequencyAction = () : PaymentFrequencyReset => ({
  type: PAYMENT_FREQUENCY_ACTIONS.RESET_PAYMENT_FREQUENCY
});
