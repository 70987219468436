import { DeclarativeData } from '@sky-distribution/mf-declarative-path';
import { Consent } from '@sky-distribution/react-commons-ui';
import { UriBuilder } from '../../../../../common/http/UriBuilder';
import { onboardingRequestService } from '../../../../../http/RequestService.http';
import { UpdateSubmissionApiResponse } from '../../../service/Submission.type';
import { SubmitDeclarationDataApiRequest } from './DeclarativeData.type';

export const submitDeclarationDataRequest = (
  submitDeclarationDataApiRequest: SubmitDeclarationDataApiRequest
): Promise<UpdateSubmissionApiResponse> => {
  const url = new UriBuilder().all('submission').all('declarative-update').build();
  return onboardingRequestService.put<UpdateSubmissionApiResponse>(url, submitDeclarationDataApiRequest);
};

export const shouldConsentsBeValidated = (
  data: DeclarativeData | null
) => Boolean(data?.phoneNumber ?? data?.email);

export const areConsentsValid = (
  consentsList: Consent[],
  data: DeclarativeData | null
) => {
  if (!shouldConsentsBeValidated(data)) {
    return true;
  }
  return consentsList.every((consent) =>
    consent.isRequired
      ? data?.consents?.[consent.rawName as keyof typeof data.consents]
      : true
  );
};

export const formatDate = (date: Date): string => {
  const dateLength = 10;
  const utc = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  return utc.toISOString().slice(0, dateLength);
};

export const isDeclarativeDataValid = (date: DeclarativeData | null): boolean => {
  if (!date) {
    return false;
  }
  const notValueLength = -1;
  const { birthDate, carPurchaseYear, zipCode, ocHistory } = date;

  const ocHistoryLength = ocHistory?.length ?? notValueLength;
  return Boolean(birthDate && zipCode && carPurchaseYear && ocHistoryLength > notValueLength);
};
