import { BeeDatePicker } from '@sky-distribution/react-commons-ui';
import { format } from 'date-fns';
import { useFormikContext } from 'formik';
import React, { FC, useCallback, useMemo } from 'react';
import { isDateValid } from '../../../../../utils/isDateValid';
import {
  getFieldValueFromObject
} from '../../../../utils/Object.utils';
import { DatePickerProps } from './DatePicker.type';
import { DatePickerContainer } from './DatePicker.styles';

export const DatePicker: FC<DatePickerProps> = (
  {
    fieldName,
    id,
    label = '',
    maxDate,
    minDate,
    initialFocusedDate,
    dateIsInvalidMsg,
    requiredMsg,
    disabled,
    pickerZIndex,
    onlyFutureDates,
    setFieldTouched = () => { },
    setFieldValue,
    values,
    autoswitchToNextTab = false,
    genericStyles
  }: DatePickerProps
) => {
  const formikProps = useFormikContext<{ [key: string]: unknown }>();
  
  const displayError = (fieldName: string): string | null => {
    const errExist = Boolean(formikProps.errors[fieldName]);
    const isTouched = Boolean(formikProps.touched[fieldName]);
    return isTouched && errExist ? formikProps.errors[fieldName] as string : null;
  };
  
  const handleOnChange = useCallback(
    (value: Date | null): void => {
      setFieldTouched(fieldName, true, true);
      setFieldValue(fieldName, value, true);
    },
    [fieldName, setFieldTouched, setFieldValue]
  );

  const value = useMemo(
    (): string | undefined => {
      const value = getFieldValueFromObject(values, fieldName);
      const isValid = isDateValid(value);
      return isValid ? format(new Date(value), 'yyyy-MM-dd') : undefined;
    },
    [values, fieldName]
  );

  const openToYear = useMemo(() => {
    const value = initialFocusedDate?.split('.')[0];
    return value ? Number(value) : undefined;
  }, [initialFocusedDate]);
  
  return (
    <DatePickerContainer $styles={genericStyles}>
      <BeeDatePicker
        id={id}
        inputLabel={label}
        retrieveDate={value}
        startDate={minDate}
        endDate={maxDate}
        openToYear={openToYear}
        validatorProps={{
          onCorrectChange: (value: string) => {
            const isValid = isDateValid(value);
            isValid && handleOnChange(new Date(value));
          },
          onIncorrectChange: (value: string | undefined = '') => {
            const isValid = isDateValid(value);
            handleOnChange(isValid ? new Date(value) : null);
          }
        }}
        dateIsInvalidMsg={displayError(fieldName) ?? dateIsInvalidMsg}
        required={!formikProps.errors[fieldName]}
        requiredMsg={formikProps.errors[fieldName] ? undefined : requiredMsg}
        readOnly={disabled}
        error={displayError(fieldName)}
        pickerZIndex={pickerZIndex}
        onlyFutureDates={onlyFutureDates}
        datesRange={{
          minDate,
          maxDate
        }}
        autoswitchToNextTab={autoswitchToNextTab}
      />
    </DatePickerContainer>
  );
};

