import { AnyAction } from 'redux';
import { transformedState } from './sentry';

export const rewriteData = 'removed for privacy';

export const transformedActions = [
  {
    actionName: 'USER_DATA/PERSONAL_DATA_SAVED',
    transformer: (action: AnyAction): AnyAction => ({
      ...action,
      data: rewriteData
    })
  },
  {
    actionName: 'USER_DATA/ADDRESS_SAVED',
    transformer: (action: AnyAction): AnyAction => ({
      ...action,
      data: rewriteData
    })
  },
  {
    actionName: 'USER_DATA/FETCH_USER_INFO',
    transformer: (action: AnyAction): AnyAction => ({
      ...action,
      data: {
        ...action.data,
        person: rewriteData,
        address: rewriteData
      },
      personInfo: rewriteData
    })
  },
  {
    actionName: 'USER_DATA/RETRIEVE',
    transformer: (action: AnyAction): AnyAction => ({
      ...action,
      //we are using method for transforming state but in this case we want to transform part of state (userdata)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data: transformedState({ userData: action.data } as any).userData
    })
  },
  {
    actionName: 'DECLARATIVE_DATA/DATA_SAVED',
    transformer: (action: AnyAction): AnyAction => ({
      ...action,
      data: {
        ...action.data,
        zipCode: rewriteData,
        birthDate: rewriteData,
        phoneNumber: rewriteData,
        email: rewriteData
      }
    })
  },
  {
    actionName: 'CO_OWNERS/DATA_SAVED',
    transformer: (action: AnyAction): AnyAction => ({
      ...action,
      data: rewriteData
    })
  },
  {
    actionName: 'persist/REHYDRATE',
    transformer: (action: AnyAction): AnyAction => ({
      ...action,
      payload: transformedState(action.payload)
    })
  }
];
