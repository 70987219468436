import { Tenant, TenantsEnum, CsouHostEnum } from '../../../config/tenants/tenant.type';
import { configuration } from '../../../config/tenants/Tenants.provider';

export const getCSOUHostHeader = (quotedTenantName?: Tenant) => {
  if (quotedTenantName === TenantsEnum.Benefia) {
    return CsouHostEnum.BenefiaMultiOffer;
  }

  if (configuration.tenantId === TenantsEnum.Benefia) {
    return CsouHostEnum.Benefia24;
  }
  
  return '';
};
