import { AddonModel } from '../../../../../common/components/addon/service/AddonModel';

export const VASE_ACTIONS = {
  SAVE_VASES_DATA: 'USER_DATA/SAVE_VASES_DATA',
  RESET_VASES_DATA: 'USER_DATA/RESET_VASES_DATA'
} as const;

export interface VaseSaveAction {
  type: typeof VASE_ACTIONS.SAVE_VASES_DATA;
  data: AddonModel[];
}

export interface VaseResetAction {
  type: typeof VASE_ACTIONS.RESET_VASES_DATA;
}

export type VaseActionsType = VaseSaveAction | VaseResetAction;
