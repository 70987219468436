import { CustomerConsents } from './../Consent.d';

export const CONSENTS_ACTIONS = {
  DATA_SAVED: 'CUSTOMER_CONSENTS/DATA_SAVED',
  CLEAR_CUSTOMER_CONSENTS: 'CUSTOMER_CONSENTS/CLEAR_DATA'
} as const;

export interface CustomerConsentsSavedAction {
  type: typeof CONSENTS_ACTIONS.DATA_SAVED;
  data: Partial<CustomerConsents>;
}

export interface CustomerConsentsClearedAction {
  type: typeof CONSENTS_ACTIONS.CLEAR_CUSTOMER_CONSENTS;
}

export type ConsentsActionsType = CustomerConsentsSavedAction | CustomerConsentsClearedAction;
