/* eslint-disable max-lines, camelcase */
export const plMessages = {
  BASIC_INFO_HEADER_A: 'Compensa -',
  BASIC_INFO_HEADER_B: 'Kalkulator OC AC',
  BASIC_INFO_HEADER_C: 'Oblicz składkę w 60s',
  MANUAL_INPUT_DESCRIPTION: 'Nie znasz numeru rejestracyjnego?',
  MANUAL_INPUT_BUTTON: 'WYBIERZ SAMOCHÓD RĘCZNIE',
  BIRTH_DATE_PLACEHOLDER: 'Data urodzenia właściciela',
  CAR_NOT_FOUND_HEADER: 'Nie znaleźliśmy Twojego pojazdu',
  CAR_NOT_FOUND_INFO: 'Wpisz dane ponownie lub',
  TRY_AGAIN: 'Spróbuj ponownie'
};
